import moment from "moment-timezone";
import React, {Component} from 'react';
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CardColumns from "react-bootstrap/CardColumns";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import {Redirect} from "react-router";


const Emoji = props => (
    <span
        className="emoji"
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
    >
        {props.symbol}
    </span>
);


export class QuestCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: false,
            viewSelected: null
        };
        this.clickContact = this.clickContact.bind(this);
        this.clickSkip = this.clickSkip.bind(this)
    }


    clickContact(event) {
        this.setState({
            viewSelected: '/contacts/contact/' + this.props.contact.id.toString()
        })
    }

    clickSkip(event) {
        this.setState({hide: true})
    }

    render() {
        if (!this.props.questName || this.state.hide) {
            return <></>
        }
        if (this.state.viewSelected) {
            return <Redirect to={this.state.viewSelected}/>
        }
        return (
            <div className={'skin-card quest-card-1'}>
                <div className={'skin-card-header'}>{this.props.emojiSymbol &&
                    <Emoji symbol={this.props.emojiSymbol}
                           label="sheep"/>}{this.props.questName}</div>
                <div className={'skin-card-title'}>{this.props.questTitle}</div>

                        <div>{this.props.questText}</div>
                        {!!this.props.contact && <>
                            <div>{moment(this.props.contact.last_contact).format('LL LT')}</div>
                            <div>{moment(this.props.contact.last_contact).fromNow()}</div>
                        </>
                        }
                        {!!this.props.datetime &&
                        <div>{moment(this.props.datetime).format('LL LT')}</div>
                        }
                        <div>
                            {!!this.props.contact &&
                            <button className={'skin-button'}
                                         onClick={this.clickContact}>View</button>}
                        </div>
            </div>
        )
    }

}


export class CoachCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: false,
            editMode: true,
            editing: false,
            note: '',
            coachCards: null
        };
        this.clickHide = this.clickHide.bind(this);
        this.onFormItemChange = this.onFormItemChange.bind(this);
        this.cardCallback = this.cardCallback.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getCards = this.getCards.bind(this);
        this.getCardsCallback = this.getCardsCallback.bind(this);
    }

    componentDidMount() {
        if (!this.props.coachCard) {
            this.getCards();
        } else {
            this.setState({coachCards: [this.props.coachCard]})
        }
    }

    clickHide(event) {
        // TODO: hide should 'mark as read'
        this.setState({hide: true})
    }

    cardCallback(data) {
        this.setState({coachCard: data, note: data.note})
    }

    cardErrorCallback(data) {

    }

    onSubmit(event) {
        event.preventDefault();
        const coachCardId = event.currentTarget.dataset.coachCardId;
        this.setState({editing: false});
        this.props.authenticator.queuedPut(
            'learner/cards',
            coachCardId,
            {id:coachCardId, note: this.state.note},
            this.cardCallback, this.cardErrorCallback)
    }

    getCardsCallback(data) {
        this.setState({coachCards: data})
    }

    getCards() {
        this.props.authenticator.queuedGet(
            'learner/cards',
            this.getCardsCallback, () => {
            })
    }

    onFormItemChange(event) {
        this.setState({editing: true, note: event.currentTarget.value})
    }

    render() {
        if (!this.state.coachCards) {
            return <div id={'no-coach-cards'}/>
        }
        return (
            <>
                {this.state.coachCards.map((coachCard, index) => {
                    if (!this.props.editMode && !coachCard.note) {
                        return <div id={'no-coach-card'} key={index}/>
                    }
                    return <div className={'skin-card coach-card-1'} key={index}>
                            <div className={'skin-card-header'}>{this.props.emojiSymbol &&
                                <Emoji symbol={this.props.emojiSymbol}
                                       label="sheep"/>} Coach {coachCard.coach.name}</div>

                                    {this.props.editMode && <>
                                        <Form onSubmit={this.onSubmit} data-coach-card-id={coachCard.id}>
                                            <Form.Control as="textarea" rows="5"
                                                          placeholder="Uplifting Affirmation"
                                                          size="sm"
                                                          name="notes"
                                                          id="notes"
                                                          defaultValue={coachCard.note}
                                                          onChange={this.onFormItemChange}
                                            />
                                            <div>Last
                                                Updated: {moment(coachCard.modified).fromNow()}</div>
                                            {this.state.editing && <Button
                                                type={'submit'}>Save</Button>}
                                        </Form>
                                    </>}
                                    {!this.props.editMode && <div>{coachCard.note}</div>}

                                    <Row>
                                        <Col><Button variant={'warning'}
                                                     size="sm"
                                                     onClick={this.clickHide}>Hide</Button></Col>
                                        {!!this.props.contact &&
                                        <Col><Button size="sm"
                                                     onClick={this.clickContact}>View</Button></Col>}
                                    </Row>
                        </div>
                })}
            </>
        )
    }

}

export class ContactCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: false,
            viewSelected: null
        };
        this.clickCard = this.clickCard.bind(this);
        this.clickSkip = this.clickSkip.bind(this)
        this.markContactAsViewed = this.markContactAsViewed.bind(this)
        this.viewedCallback = this.viewedCallback.bind(this)
    }


    clickCard(event) {
        this.setState({viewSelected: '/contacts/contact/' + this.props.contact.id.toString()})
    }

    clickSkip(event) {
        const contactId = this.props.contact.id;
        const contactName = this.props.contact.name;
        this.markContactAsViewed(contactId, contactName);
        // this.setState({hide: true});

    }

    viewedCallback(data) {
        if (!!this.props.callback) {
            this.props.callback(this.props.cardId);
        }
    }

    markContactAsViewed(contactId, name) {

        let today = new Date();
        let bodyDict = {
            id: contactId,
            name: name,
            last_viewed: today,
            created_by: this.props.authenticator.account.user.id,
            modified_by: this.props.authenticator.account.user.id
        };

        this.props.authenticator.queuedPut('contacts', contactId,
            bodyDict, this.viewedCallback, null)
    }

    render() {
        if (!this.props.contact || this.state.hide || !this.props.authenticator) {
            return <></>
        }

        if (this.state.viewSelected) {
            return <Redirect to={this.state.viewSelected}/>
        }

        // const cardId = this.props.cardId;
        const contact = this.props.contact;
        const colorDict = this.props.authenticator.library.status_color_dict;
        let backgroundColor = 'grey';
        let textColor = 'black';
        if (colorDict.hasOwnProperty(contact.status)) {
            backgroundColor = colorDict[contact.status].background_color;
            textColor = colorDict[contact.status].text_color;
        }

        return (
            <Card style={{width: '18rem'}}>
                <Card.Body>
                    <Card.Header style={{
                        backgroundColor: backgroundColor,
                        color: textColor
                    }}>{this.props.emojiSymbol &&
                    <Emoji symbol={this.props.emojiSymbol}
                           label="sheep"/>}{this.props.name}</Card.Header>
                    <Card.Title>{contact.name}&nbsp;<Badge
                        variant="primary">{Math.trunc(contact.score)}</Badge></Card.Title>
                    <Card.Body>
                        <Row
                            className="justify-content-md-center">{moment(contact.last_contact).format('LL LT')}</Row>
                        <Row
                            className="justify-content-md-center">{moment(contact.last_contact).fromNow()}</Row>
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col><Button variant={'warning'} size="sm"
                                         onClick={this.clickSkip}>Skip</Button></Col>
                            <Col><Button size="sm"
                                         onClick={this.clickCard}>View</Button></Col>
                        </Row>
                    </Card.Footer>
                </Card.Body>
            </Card>
        )
    }

}

//depricated
export default class SuggestionCards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            suggestions: null,
            coaches: null,
            edna: null
        };
        this.getCoachesCallback = this.getCoachesCallback.bind(this);
        this.getSuggestionsCallback = this.getSuggestionsCallback.bind(this);
        this.cardCallback = this.cardCallback.bind(this);
    }

    componentDidMount() {
        this.getSuggestions();
    }

    getSuggestionsCallback(data) {
        this.setState({suggestions: null},
            () => {
                this.setState({suggestions: data}, this.getCoaches)
            })
    }

    getSuggestions() {
        this.props.authenticator.queuedGet(
            'suggestions',
            this.getSuggestionsCallback
        );
    }

    getCoachesCallback(data) {
        this.setState({
            coaches: data
        });
    }

    getCoaches() {
        this.props.authenticator.queuedGet(
            'learner/cards',
            this.getCoachesCallback
        );
    }


    cardCallback() {
        // this.setState({suggestions:null}, this.getSuggestions)
        this.getSuggestions();
    }

    render() {
        return (this.state.suggestions ?
            <Container>
                <CardColumns>
                    {this.state.suggestions.todays_transcripts >= 0 &&
                    <QuestCard
                        cardId={'todays_effort'}
                        questName={'Daily Challenge'}
                        questTitle={this.state.suggestions.todays_transcripts > 5 ? 'Great Job!' : 'You Can Do It!!!'}
                        questText={'The Goal Is: 6 New Transcripts Today. You have noted ' + this.state.suggestions.todays_transcripts + ' Contacts today.'}
                        emojiSymbol={'📅'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                    {this.state.coaches && this.state.coaches.map((coachCard, index) => {
                        return <CoachCard
                            contact={null}
                            cardId={index}
                            coachName={coachCard.coach.user.first_name + ' ' + coachCard.coach.user.last_name}
                            coachText={coachCard.note}
                            emojiSymbol={'📅'}
                            key={index}
                            datetime={coachCard.modified}
                            authenticator={this.props.authenticator}
                            callback={this.cardCallback}
                        />
                    })}
                    {this.state.suggestions.thank_you &&
                    <ContactCard
                        contact={this.state.suggestions.thank_you}
                        cardId={'thank_you'}
                        name={'Thankful Advisor'}
                        emojiSymbol={'🙏🏻'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }

                    {this.state.suggestions.follow_up_soon &&
                    <ContactCard
                        contact={this.state.suggestions.follow_up_soon}
                        cardId={'follow_up_soon'}
                        name={'Fast Advisor'}
                        emojiSymbol={'⏰'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                    {this.state.suggestions.follow_up_normally &&
                    <ContactCard
                        contact={this.state.suggestions.follow_up_normally}
                        cardId={'follow_up_normally'}
                        name={'Curious Advisor'}
                        emojiSymbol={'👍'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                    {this.state.suggestions.follow_up_eventually &&
                    <ContactCard
                        contact={this.state.suggestions.follow_up_eventually}
                        cardId={'follow_up_eventually'}
                        name={'Tenacious Advisor'}
                        emojiSymbol={'👩‍🏭'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                    {this.state.suggestions.brand_value &&
                    <ContactCard
                        contact={this.state.suggestions.brand_value}
                        cardId={'brand_value'}
                        name={'Brand Advisor'}
                        emojiSymbol={'👍'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                    {this.state.suggestions.personal_value &&
                    <ContactCard
                        contact={this.state.suggestions.personal_value}
                        cardId={'personal_value'}
                        name={'Personal Advisor'}
                        emojiSymbol={'👍'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                    {this.state.suggestions.inclusion &&
                    <ContactCard
                        contact={this.state.suggestions.inclusion}
                        cardId={'inclusion'}
                        name={'Inclusion Advisor'}
                        emojiSymbol={'👍'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                    {this.state.suggestions.affinity &&
                    <ContactCard
                        contact={this.state.suggestions.affinity}
                        cardId={'affinity'}
                        name={'Affinity Advisor'}
                        emojiSymbol={'👍'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                    {this.state.suggestions.initial_contact &&
                    <ContactCard
                        contact={this.state.suggestions.initial_contact}
                        cardId={'initial_contact'}
                        name={'Ambassadorial Advisor'}
                        emojiSymbol={'🖖'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                    {this.state.suggestions.score &&
                    <ContactCard
                        contact={this.state.suggestions.score}
                        cardId={'score'}
                        name={'Super Star Advisor'}
                        emojiSymbol={'🌟'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />
                    }
                </CardColumns>


            </Container>
            : <Spinner animation="border"
                       role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>)
    }


}

