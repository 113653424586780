import React, {Component} from "react";
import {GOOGLE_API} from "../Settings";


export default class GoogleLogins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        this.setState({redirect: true})
    }

    render() {
        if (this.state.redirect) {
            window.location = GOOGLE_API;
        }
        return <div>

            <img onClick={this.onClick}
                 width={191}
                 height={46}
                 className="mr-3"
                 src="google/btn_google_signin_light_normal_web.png"
                 alt="Login w/ Google"
            />

            {/*{!this.props.authenticator.account.google_token &&*/}
            {/*    <Button onClick={this.onClick}>Login to Google</Button>*/}
            {/*}*/}
            {/*{this.props.authenticator.account.google_token &&*/}
            {/*    <Button>Remove Google Access (not working yet)</Button>*/}
            {/*}*/}
        </div>
    }

}
