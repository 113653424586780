import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import 'react-day-picker/lib/style.css';
import DurationSelector from "./DurationSelector";
import EventTypeAvailabilities from "./EventTypeAvailability";
import EventTypeDateRange from "./EventTypeDateRange";
import FrequencyModal from "./FrequencyModal";

// // import './EventTypeDateRange.css';

class EventTypeCard extends Component {

    constructor(props) {
        super(props);
        this._clickAddNew = this._clickAddNew.bind(this);
        this._editExisting = this._editExisting.bind(this);
    }


    _clickAddNew() {
        this.props.callback()
    }

    _editExisting() {
        this.props.callback(this.props.eventType)
    }


    render() {
        return <>

            {!!!this.props.eventType ? (<div className={'public-type-card'} onClick={this._clickAddNew}>Add
                    New</div>) : (<div className={'public-type-card'}
                    onClick={this._editExisting}>{this.props.eventType.name}</div>
            )
            }
        </>

    }
}


class EventTypeForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventType: null,
            enabled: true,
            name: '',
            duration: '',
            nameSlug: null,
            errors: {},
            submitted: false,
            showFrequencyModal: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this._onNameChange = this._onNameChange.bind(this);
        this._onDurationChange = this._onDurationChange.bind(this);
        this._onDurationChangeFromSelector = this._onDurationChangeFromSelector.bind(this);
        this._onSubmitCallback = this._onSubmitCallback.bind(this);
        this._onSubmitErrorCallback = this._onSubmitErrorCallback.bind(this);
        this.onEnabledChange = this.onEnabledChange.bind(this);
        this.deleteEventTypeCallback = this.deleteEventTypeCallback.bind(this);
        this.deleteEventType = this.deleteEventType.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
        this.saveNew = this.saveNew.bind(this);
        this.showFrequencyModal = this.showFrequencyModal.bind(this);
        this.frequencyModalCallback = this.frequencyModalCallback.bind(this);
        this.hideFrequencyModal = this.hideFrequencyModal.bind(this);
    }

    componentDidMount() {
        this.setState({eventType: this.props.eventType});
        if (!!this.props.eventType) {
            this.setState(
                {
                    enabled: this.props.eventType.enabled,
                    name: this.props.eventType.name,
                    duration: this.props.eventType.duration
                })
        }
    }

    _onSubmitCallback(data) {
        if (!!!data) {
            return;
        }
        if (this.state.eventType) {
            this.props.callback(data);
        }
        this.setState({submitted: true, eventType: data, errors: {}});
    }

    _onSubmitErrorCallback(data, status) {
        this.setState({submitted: false, errors: data})
    }

    showFrequencyModal() {
        this.setState({showFrequencyModal: true})
    }

    hideFrequencyModal() {
        this.setState({showFrequencyModal: false})
    }

    frequencyModalCallback(data) {
        if (data) {
            this.setState(prevState => ({
                eventType: {
                    ...prevState.eventType,
                    eventtypefrequency: data
                },
            }), this.hideFrequencyModal)
        } else {
            this.hideFrequencyModal()
        }
    }

    onSubmit(event) {
        event.preventDefault();
        const name = this.state.name;
        const duration = this.state.duration;
        const enabled = this.state.enabled;
        let data = {
            name: name,
            duration: duration,
            account: this.props.authenticator.account.id,
            name_slug: '',
            enabled: enabled
        };
        let accessPoint = 'event_types';
        if (!this.state.eventType) {
            this.props.authenticator.queuedPost(
                accessPoint,
                data,
                this._onSubmitCallback,
                this._onSubmitErrorCallback)
        } else {
            data.id = this.state.eventType.id;
            data.name_slug = this.state.eventType.name_slug;
            this.props.authenticator.queuedPut(
                accessPoint,
                data.id,
                data,
                this._onSubmitCallback,
                this._onSubmitErrorCallback)
        }
    }

    saveNew(event) {
        this.onSubmit(event);
    }

    _onNameChange(event) {
        this.setState({name: event.target.value})
    }

    _onDurationChange(event) {
        this.setState({duration: event.target.value})
    }

    onEnabledChange(event) {
        this.setState({enabled: event.target.checked})
    }

    _onDurationChangeFromSelector(duration) {
        this.setState({duration: duration})
    }

    deleteEventTypeCallback() {
        this.props.callback()
    }

    deleteEventType() {
        let accessPoint = 'event_types';
        this.props.authenticator.queuedDelete(
            accessPoint,
            this.state.eventType.id,
            this.deleteEventTypeCallback,
            null)
    }

    cancelEdit() {
        this.props.callback()
    }


    render() {
        return <Container>

            <Row>
                {this.state.errors && this.state.errors.detail &&
                <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                {this.state.errors && this.state.errors.non_field_errors &&
                <Alert variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={this.onSubmit}>

                        <Form.Row>
                            {this.state.name && this.state.duration &&
                                <>
                            <Form.Group as={Col}>
                                <Button type={'submit'}>Save {this.state.eventType && '& Close'}</Button>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Check
                                    type="switch"
                                    id="enabled-switch"
                                    label={this.state.enabled ? 'Turn Off' : 'Turn On'}
                                    checked={this.state.enabled}
                                    onChange={this.onEnabledChange}
                                />
                            </Form.Group>
                            </>
                                }
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label column={'name'}>
                                    Event Type Name
                                </Form.Label>
                                <Form.Control type="text"
                                              id={'name'}
                                              name={'name'}
                                              placeholder="New Event Type Name"
                                              onChange={this._onNameChange}
                                              value={this.state.name}
                                              required
                                              isInvalid={!!this.state.errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {this.state.errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label column={'duration'}>
                                    Event Type Duration
                                </Form.Label>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <DurationSelector
                                            duration={this.state.duration}
                                            callback={this._onDurationChangeFromSelector}/>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Control type="number"
                                                      id={'duration'}
                                                      name={'duration'}
                                                      placeholder="Duration in Minutes"
                                                      value={this.state.duration}
                                                      onChange={this._onDurationChange}
                                                      required
                                                      isInvalid={!!this.state.errors.duration}
                                                      min="1" max="480" step="1"
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Control.Feedback type="invalid">
                                    {this.state.errors.duration}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                    {!!this.state.eventType && this.state.eventType.id &&
                    <>
                        <EventTypeDateRange
                            authenticator={this.props.authenticator}
                            eventType={this.state.eventType}
                            callback={(data) => (null)}
                        />
                        <EventTypeAvailabilities
                            authenticator={this.props.authenticator}
                            eventType={this.state.eventType}
                            callback={(data) => (null)}
                        />
                    </>
                    }
                    <br/>
                    {this.state.eventType && this.state.showFrequencyModal &&
                        <FrequencyModal
                            authenticator={this.props.authenticator}
                            eventType={this.state.eventType}
                            show={this.state.showFrequencyModal}
                            callback={this.frequencyModalCallback}
                        />
                    }
                    {this.state.eventType && <Button
                        onClick={this.showFrequencyModal}
                        variant={'outline-primary'}>Advanced</Button>}
                    <br/>
                    <br/>
                    <br/>
                    <Button variant={'secondary'} onClick={this.cancelEdit}>Cancel</Button>
                    {this.state.eventType && <Button variant={'warning'} onClick={this.deleteEventType}>Delete</Button>}
                    <br/>
                    <br/>
                </Col>
            </Row>
        </Container>

    }
}

export {EventTypeCard};
export default EventTypeForm;
