import React, {Component} from 'react'
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";

class AddUrlModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,

            success: false,

            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,
            eventType: null,
            eventTypeFrequency: {},

            id: null,

            url: '',
            urlIsValid: false,
            urlValidationmessage: '',
            urltype: '',
            urltypeIsValid: false,
            urltypeValidationmessage: '',
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.submitSuccess = this.submitSuccess.bind(this);
        this.submitFailure = this.submitFailure.bind(this);
    }

    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates);
    }

    submitFailure(data) {
        this.setState({isSaved: false, submitted: false, errors: data})
    }

    submitSuccess(data) {
        if (!!!data) {return;}
        this.setState({show: false, success: true, isSaved: true});
        this.props.callback(data);
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        let body = {
            url:this.state.url,
            url_type:this.state.urltype,
            contact:this.props.contact.id
        };
        this.props.authenticator.queuedPost(
            'urls',
            body,
            this.submitSuccess,
            this.submitFailure)
    }


    render() {

        if (!this.props.contact) {
            return <></>
        }
        return (

            <Modal
                show={this.props.show}
                size="lg"
                aria-labelledby="url-modal-title-vcenter"
                centered
                onHide={this.props.hideCallback}
                className={'skin-modal'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="url-modal-title-vcenter">
                        Add url
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.errors && this.state.errors.detail &&
                    <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                    {this.state.errors && this.state.errors.non_field_errors &&
                    <Alert
                        variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                    
                    <Form id={'url-form'}
                          name={'url-form'}
                          onSubmit={this.onSubmit}
                          validated={!this.state.isSaved}
                    >
                        <FormGroup>
                            <Form.Label column={'url'}>
                                URL
                            </Form.Label>
                            <Form.Control type="text"
                                          id={'url'}
                                          name={'url'}
                                          onChange={this.onFieldChange}
                                          value={this.state.url}
                                          isInvalid={!!this.state.errors.url}
                                          placeholder={'http://domain.extension'}
                                          required
                            />
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.url}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {this.state.urlValidationmessage}
                            </Form.Control.Feedback>
                            <Form.Label column={'urltype'}>
                                URL Type (optional)
                            </Form.Label>
                            <Form.Control type="text"
                                          id={'urltype'}
                                          name={'urltype'}
                                          onChange={this.onFieldChange}
                                          value={this.state.urltype}
                                          isInvalid={!!this.state.errors.url_type}
                                          placeholder={'work, personal, private, etc.'}
                            />
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.url_type}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {this.state.urltypeValidationmessage}
                            </Form.Control.Feedback>
                        </FormGroup>
                        <Button type={'submit'}
                                disabled={this.state.isSaved}>Submit</Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        )
    }

}

export default AddUrlModal;
