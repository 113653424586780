import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";


export default class DurationSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            duration: null
        };
        this._onClick = this._onClick.bind(this);
        this._callback = this._callback.bind(this);
    }

    componentDidMount() {
        this.setState({duration: this.props.duration})
    }

    _callback() {
        this.props.callback(this.state.duration)
    }

    _onClick(event) {
        let duration;
        switch (event.target.name) {
            case '15m-duration-selector-button':
                duration = 15;
                break;
            case '30m-duration-selector-button':
                duration = 30;
                break;
            case '45m-duration-selector-button':
                duration = 45;
                break;
            case '60m-duration-selector-button':
                duration = 60;
                break;
            default:
                break;
        }
        this.setState({duration: duration}, this._callback);
    }

    render() {
        return <div>
            <ButtonGroup aria-label="Duration">
                <Button name={'15m-duration-selector-button'}
                        variant="outline-primary"
                        onClick={this._onClick}>15m</Button>
                <Button name={'30m-duration-selector-button'}
                        variant="outline-primary"
                        onClick={this._onClick}>30m</Button>
                <Button name={'45m-duration-selector-button'}
                        variant="outline-primary"
                        onClick={this._onClick}>45m</Button>
                <Button name={'60m-duration-selector-button'}
                        variant="outline-primary"
                        onClick={this._onClick}>60m</Button>

            </ButtonGroup>
        </div>
    }

}