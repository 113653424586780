import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link} from "react-router-dom";
import {AddContactButton} from "./addContact";
import ContactSearch from "./ContactSearch";
import RecentContactMenu from "./RecentContactMenu";

class EDNAHeader extends Component {


    render() {
        const bg = this.props.privacyMode ? 'secondary' : 'dark';
        return <>
            <Navbar collapseOnSelect={true} expand="lg" bg={bg} variant="dark" fixed={'top'} style={{zIndex:1000}}>
            <Navbar.Brand as={Link} to="/"><div
                className="small-logo d-inline-block align-top"
            /></Navbar.Brand>
                <Nav>
                    <Nav.Item>
                        <Form inline>
                            <ContactSearch authenticator={this.props.authenticator}
                                           searchCallback={this.props.searchCallback}
                                           contactListOpen={false}
                            />
                        </Form>
                    </Nav.Item>
                </Nav>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    {/*<Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>*/}
                    <Nav.Item><Nav.Link as={Link} to="/contacts"  eventKey={2}>Contacts</Nav.Link></Nav.Item>
                    <Nav.Item>
                        <RecentContactMenu authenticator={this.props.authenticator}/>
                    </Nav.Item>
                    <Nav.Item><Nav.Link as={Link} eventKey={5} to={'/activities'}>Activities</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link as={Link} eventKey={5} to={'/' + this.props.authenticator.account.accountprofilename.public_name}>Profile</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link as={Link} to={'/coaching'}  eventKey={4}>Coaching</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link as={Link} to={'/relationships'}  eventKey={13}>Relationships</Nav.Link></Nav.Item>
                </Nav>
                <div className="mr-auto">
                <AddContactButton
                    authenticator={this.props.authenticator}
                />
                </div>
                <Nav>
                    <Nav.Item>
                        <NavDropdown
                            drop={'left'}
                            title={this.props.authenticator.account.user.username}
                            id="account-settings">
                            <NavDropdown.Item eventKey={12} onClick={this.props.privacyModeCallback}>{!this.props.privacyMode ? <span>Privacy Mode</span> : <span>Normal Mode</span>}</NavDropdown.Item>
                            <NavDropdown.Item href="/import" eventKey={7}>Import</NavDropdown.Item>
                            <NavDropdown.Item href="/account" eventKey={9}>Settings</NavDropdown.Item>
                            <NavDropdown.Item href="/subscriptions" eventKey={9}>Subscriptions</NavDropdown.Item>
                            <NavDropdown.Item href="/treasure-shop" eventKey={9}>Treasure Shop</NavDropdown.Item>
                            <NavDropdown.Item href="/help" eventKey={9}>Help</NavDropdown.Item>
                            <NavDropdown.Item eventKey={11}><Button onClick={this.props.forceLogoutButton}>Logout</Button></NavDropdown.Item>
                        </NavDropdown>
                    </Nav.Item>
                </Nav>

            </Navbar.Collapse>
        </Navbar>

        <div style={{marginTop: '3.6rem'}} />
        </>
    }
}

export default EDNAHeader;
