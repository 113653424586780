import moment from "moment-timezone";
import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";

export default class ContactBirthdayModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,
            id: null,

            birthday: '',
            birthdayIsValid: false,
            birthdayValidationmessage: '',
            birthdayValue: ''
        };
        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSubmitErrorCallback = this.onSubmitErrorCallback.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
    }

    componentDidMount() {
        const contact = this.props.contact;
        this.setState({
            id: contact.id,
            birthday: contact.birthday || '',
        });
    }

    onFieldChange(event) {
        let isValid = event.target.validity.valid;
        let validationMessage = event.target.validationMessage;
        let value = event.target.value;

        if (isValid) {
            const validDate = moment(value);
            if (validDate !== 'Invalid date') {
                this.setState({birthdayValue: validDate, errors: {...this.state.errors, birthday:null}})
                validationMessage = null;
            } else {
                isValid = false;
                validationMessage = 'Invalid Date, should be YYYY MM DD';
                this.setState({errors: {birthday: ['Invalid Date, should be YYYY MM DD']}})
            }
        }

        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        if (validationMessage) {
            newStates.isValid = false;
        } else {
            newStates.isValid = true;
        }
        newStates[fieldName] = value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates);
    }

    onSubmitErrorCallback(data, status) {
        this.setState({submitted: false, errors: data})
    }

    onSubmitCallback(data) {
        if (!!!data) {return;}
        this.props.callback(data)
    }

    onSubmit(event) {
        event.preventDefault();

        this.setState({isSaved: true});
        let data = {
            id: this.state.id,
            birthday: moment(this.state.birthdayValue).format('YYYY-MM-DD'),
            name: this.props.contact.name,
            created_by: this.props.authenticator.account.user.id
        };
        this.props.authenticator.queuedPut(
            'contacts',
            this.state.id,
            data,
            this.onSubmitCallback,
            this.onSubmitErrorCallback,
            {partial: true}
        )

    }

    onHide(event) {
        this.props.callback()
    }

    render() {
        return <Modal
            show={this.props.show}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.onHide}
            className={'skin-modal'}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Birthday Editor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={this.onSubmit}
                      validated={!this.state.isSaved}>
                    {this.state.errors && this.state.errors.detail &&
                    <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                    {this.state.errors && this.state.errors.non_field_errors &&
                    <Alert variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                    <FormGroup>
                        <Form.Label column={'birthday'}>
                            Birthday
                        </Form.Label>
                        <Form.Control type="text"
                                      id={'birthday'}
                                      name={'birthday'}
                                      onChange={this.onFieldChange}
                                      value={this.state.birthday}
                                      required
                                      isInvalid={!!this.state.errors.birthday}
                        />
                        <Form.Control.Feedback type="valid">
                            {moment(this.state.birthdayValue).format('dddd LL')}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.birthday}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.birthdayValidationmessage}
                        </Form.Control.Feedback>

                    </FormGroup>
                    <Button type={'submit'}
                            disabled={this.state.isSaved}>Save</Button>
                </Form></Modal.Body>
        </Modal>
    }
}