import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import {BrowserRouter, Link, Redirect, Route, Switch} from "react-router-dom";
import './App.css';
// import './components/skins/default/defaultskin.css'
import About from "./components/About";
import AccountSettings from "./components/AccountSettings";
import Advisors from "./components/Advisors";
import Coaching from "./components/Coaching";
import Contact from "./components/Contact";
import EdnaApiAuthenticator from "./components/EdnaApi";
import EDNAHeader from "./components/EDNAHeader";
import ExperienceBar from "./components/ExperienceBar";
import GoogleCredentialsCallback from "./components/GoogleCredentialsCallback";
import Importer from "./components/Importer";
import Learner from "./components/Learner";
import LoginForm from "./components/LoginForm";
import LootShop from "./components/LootShop";
import MainContactList from "./components/MainList";
import NewUserRegistration from "./components/NewUserRegistration";
import TokenedPasswordResetForm, {PasswordResetForm} from "./components/PasswordReset";
import {PaymentCallback, PaymentCanceled} from "./components/Payments";
import PrivacyPolicy from "./components/PrivacyPolicy";
import PublicHelpPage from "./components/PublicHelpPage";
import PublicProfile from "./components/PublicProfile";
import RegistrationResponse from "./components/RegistrationResponse";
import RelationshipForce from "./components/RelationshipForce";
import RetailIndex from "./components/RetailIndex";
import RetailPricing from "./components/RetailPricing";
import SearchResults from "./components/SearchResults";
import SecurityPolicy from "./components/SecurityPolicy";
import Subscriptions from "./components/Subscriptions";
import TermsOfService from "./components/TermsOfService";
import TransactionStats from "./components/TransactionStats";


function NotFound() {
    return <div>App - 404 - Not Found -- <Link to="/">Home</Link></div>
}

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            forceLogout: false,
            authenticator: null,
            searchResults: [],
            searchValue: '',
            authenticating: true,
            privacyMode: false,
            experience: {},
            next: null
        };

        this.forceLogoutButton = this.forceLogoutButton.bind(this);
        this.onLogin = this.onLogin.bind(this);
        this.onAuthenticationLost = this.onAuthenticationLost.bind(this);
        this.onIsAuthenticated = this.onIsAuthenticated.bind(this);
        this.searchCallback = this.searchCallback.bind(this);
        this.privacyModeCallback = this.privacyModeCallback.bind(this);
        this.setExperience = this.setExperience.bind(this);
        this.routeNext = this.routeNext.bind(this);
    }

    componentDidMount() {
        this.instantiateAuthenticator();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !nextState.authenticating;
    }

    setExperience(experience) {
        this.setState({experience: experience})
    }


    privacyModeSuccessCallback(data) {

    }
    privacyModeFailCallback(data) {

    }

    privacyModeCallback(event) {
        const newPrivacyMode = !this.state.privacyMode;
        this.setState({privacyMode: newPrivacyMode});
        const accountId = this.state.authenticator.account.id;
        this.state.authenticator.queuedPut(
            'account',
            accountId,
            {id:accountId, privacy_mode: newPrivacyMode},
            this.privacyModeSuccessCallback,
            this.privacyModeFailCallback
        )
    }


    instantiateAuthenticator() {
        if (this.state.authenticator) {
            this.state.authenticator.stop()
        }
        const authenticator = new EdnaApiAuthenticator(
            this.onAuthenticationLost,
            this.onIsAuthenticated,
            this.setExperience);
        this.setState({
            authenticator: authenticator
        });

    }

    onAuthenticationLost() {
        this.setState({
            isAuthenticated: false,
            authenticating: false
        });
    }

    onLogin(event, loginFormState) {
        const username = loginFormState.username;
        const password = loginFormState.password;
        this.state.authenticator.login(username, password)
    }

    onIsAuthenticated() {
        const privacyMode = this.state.authenticator.account.privacy_mode;
        this.setState({
            isAuthenticated: true,
            authenticating: false,
            privacyMode: privacyMode
        });
    }

    forceLogoutButton(event) {
        this.state.authenticator.logout();
        this.setState({
                isAuthenticated: false
            }
        )
    }

    searchCallback(contacts) {
        if (!contacts) {
            contacts = [];
        }
        this.setState({searchResults: []},
            () => {
                this.setState({searchResults: contacts})
            })
    }

    _loginForm = (props) => {
        const next = props.location.pathname;
        const newProps = {...props,
            authenticator: this.state.authenticator,
            onLogin: this.onLogin,
            next: next
        };
        return <LoginForm {...newProps}/>
    };

    _registrationResponse = (props) => {
        const newProps = {...props,
            authenticator: this.state.authenticator,
        };
        return <RegistrationResponse {...newProps}/>
    };

    routeNext() {
        const next = this.state.next;
        // this.setState({next: null});
        return <BrowserRouter><Redirect to={next} /></BrowserRouter>
    }

    getStyles = (ref) => {
        // TODO: impliment this as a Context.
        if (!ref) {
            return;
        }
        // const computed = window.getComputedStyle(ref);
        // const brandValue = computed.getPropertyValue("--brand-value");
        // const personalValue = computed.getPropertyValue("--personal-value");
        // const inclusion = computed.getPropertyValue("--inclusion");
        // const affinity = computed.getPropertyValue("--affinity");
        // console.log({
        //     brandValue: brandValue,
        //     personalValue: personalValue,
        //     inclusion: inclusion,
        //     affinity: affinity
        // })
        // this.setState({
        //     brandValue: brandValue,
        //     personalValue: personalValue,
        //     inclusion: inclusion,
        //     affinity: affinity
        // })
    };

    render() {
        document.title = 'EM';
        if (!this.state.authenticator || this.state.authenticating) {
            return (<Spinner animation="border"
                             role="status">
                <span className="sr-only">Checking Authentication...</span>
            </Spinner>)
        }

        // TODO: do this server side.
        const url = window.location.origin;
        if (!url.includes('localhost') && !url.includes('https')) {
            window.location = `https:${url.split(':')[1]}`
        }

        // if (this.state.next) {
        //     return this.routeNext()
        // }

        console.log('App.render', this.state);

        if (!this.state.isAuthenticated && !!this.state.authenticator && !this.state.authenticating) {
            return (
                    <BrowserRouter>
                        <Switch>
                            <Route exact path={'/'}
                                   render={(props) => {
                                       return <RetailIndex
                                           props={props}
                                           authenticator={this.state.authenticator}/>
                                   }}
                            />
                            <Route exact path={'/pricing'}
                                   render={(props) => {
                                       return <RetailPricing
                                           props={props}
                                           authenticator={this.state.authenticator}/>
                                   }}
                            />
                            <Route exact path={'/about'}
                                   render={(props) => {
                                       return <About
                                           props={props}
                                           authenticator={this.state.authenticator}/>
                                   }}
                            />
                            <Route exact path="/help" render={
                                (props) => {
                                    return <PublicHelpPage
                                        {...props}
                                        authenticator={this.state.authenticator}
                                    />
                                }}
                            />
                            <Route exact path={'/privacy'}
                                   render={(props) => {
                                       return <PrivacyPolicy
                                           props={props}
                                           authenticator={this.state.authenticator}/>
                                   }}
                            />
                            <Route exact path={'/security'}
                                   render={(props) => {
                                       return <SecurityPolicy
                                           props={props}
                                           authenticator={this.state.authenticator}/>
                                   }}
                            />
                            <Route exact path={'/terms'}
                                   render={(props) => {
                                       return <TermsOfService
                                           props={props}
                                           authenticator={this.state.authenticator}/>
                                   }}
                            />
                            <Route exact path={'/login'}
                                   render={this._loginForm}
                            />
                            <Route exact path={'/credentials'}
                                render={
                                (props) => {return <Container>
                                    <Row>
                                        <GoogleCredentialsCallback
                                            props={props}
                                            authenticator={this.state.authenticator}
                                        />
                                    </Row>
                                </Container>}
                                } />
                            <Route exact path="/register/:token"
                                   render={this._registrationResponse}
                            />

                            <Route path="/register" render={
                                (props) => {
                                    return <NewUserRegistration
                                        {...props}
                                        authenticator={this.state.authenticator}
                                    />
                                }
                            }/>

                            <Route exact path="/reset-password/:token"
                                   render={
                                       (props) => {
                                           return <TokenedPasswordResetForm
                                               {...props}
                                               authenticator={this.state.authenticator}
                                           />
                                       }
                                   }
                            />

                            <Route path="/reset-password" render={
                                (props) => {
                                    return <PasswordResetForm
                                        {...props}
                                        authenticator={this.state.authenticator}
                                    />
                                }
                            }/>


                            <Route exact path={'/import'}
                                   render={this._loginForm}
                            />
                            <Route exact path={'/advisors'}
                                   render={this._loginForm}
                            />
                            <Route path="/account" component={this._loginForm}/>
                            <Route path="/advisors" component={this._loginForm}/>
                            <Route path="/contacts" component={this._loginForm}/>
                            <Route path="/coaching" component={this._loginForm}/>
                            <Route path="/learner/:learnerId" component={this._loginForm}/>
                            <Route path="/learner" component={this._loginForm}/>

                            <Route path="/:publicName/:typeSlug?/:token?" render={
                                (props) => {
                                    return <PublicProfile
                                        {...props}
                                        authenticator={this.state.authenticator}
                                    />
                                }
                            }/>

                            <Route component={this._loginForm}/>
                        </Switch>
                    </BrowserRouter>
                )

        }

        if (this.state.isAuthenticated) {
            return (
            <div className="App">
                {this.state.authenticator.account.selected_skin && <style
                    ref={ref => this.getStyles(ref)}>{this.state.authenticator.account.selected_skin.skin.css}</style>}
                <BrowserRouter>
                <EDNAHeader searchCallback={this.searchCallback}
                            forceLogoutButton={this.forceLogoutButton}
                            authenticator={this.state.authenticator}
                            privacyMode={this.state.privacyMode}
                            privacyModeCallback={this.privacyModeCallback}
                />
                    <Switch>
                        <Route exact path={'/'}>
                            <Redirect to={'/advisors'}/>
                        </Route>

                        {/*<Route exact path={'/'}*/}
                        {/*       render={*/}
                        {/*           (props) => {*/}
                        {/*               return <IndexView*/}
                        {/*                   authenticator={this.state.authenticator}*/}
                        {/*                   searchResults={this.state.searchResults}*/}
                        {/*               />*/}
                        {/*           }*/}
                        {/*       }*/}
                        {/*/>*/}
                        <Route exact path={'/login'}
                               render={this._loginForm}
                        />
                        <Route exact path={'/search'}>
                            <SearchResults
                                authenticator={this.state.authenticator}
                                privacyMode={this.state.privacyMode}
                                searchResults={this.state.searchResults}
                            />
                        </Route>
                        <Route exact path={'/advisors'}>
                            <Advisors
                                authenticator={this.state.authenticator}
                                privacyMode={this.state.privacyMode}
                            />
                        </Route>
                        <Route exact path={'/credentials'}
                               render={
                                   (props) => {return <Container>
                                       <Row>
                                           <GoogleCredentialsCallback
                                               {...props}
                                               authenticator={this.state.authenticator}
                                           />
                                       </Row>
                                   </Container>}
                               } />
                        <Route path={'/contacts/contact/:contactId'}
                               render={
                                   (props) => {
                                       return <Contact
                                           authenticator={this.state.authenticator}
                                           privacyMode={this.state.privacyMode}
                                       />
                                   }
                               }>
                        </Route>
                        <Route exact path={'/contacts'}>
                            <Container>
                                <Row>
                                    <MainContactList
                                        authenticator={this.state.authenticator}
                                    />
                                </Row>
                            </Container>
                        </Route>
                        <Route path={'/import'}>
                            <Importer
                                authenticator={this.state.authenticator}
                            />
                        </Route>
                        <Route path={'/coaching'}>
                            <Coaching
                                authenticator={this.state.authenticator}
                            />
                        </Route>
                        <Route path={'/learner/:learnerId'}
                               render={
                                   (props) => {
                                       return <Learner
                                           {...props}
                                           authenticator={this.state.authenticator}
                                       />
                                   }
                               }>
                        </Route>
                        <Route path={'/account'}>
                            <AccountSettings
                                authenticator={this.state.authenticator}
                            />
                        </Route>
                        <Route path={'/treasure-shop'}>
                            <LootShop
                                authenticator={this.state.authenticator}
                            />
                        </Route>

                        <Route exact path="/help" render={
                            (props) => {
                                return <PublicHelpPage
                                    {...props}
                                    authenticator={this.state.authenticator}
                                />
                            }
                        }/>

                        <Route path={'/activities'}>
                            <TransactionStats
                                authenticator={this.state.authenticator}
                            />
                        </Route>

                        <Route path={'/relationships'}>
                            <RelationshipForce
                                authenticator={this.state.authenticator}
                            />
                        </Route>

                        <Route path={'/subscriptions'} render={
                            (props) => {
                                return <Subscriptions
                                    {...props}
                                    authenticator={this.state.authenticator}
                                />
                            }
                        }/>

                        {/*<Route path={'/payments'}>*/}
                        {/*    <Payments*/}
                        {/*        authenticator={this.state.authenticator}*/}
                        {/*    />*/}
                        {/*</Route>*/}

                        <Route path={'/payment_callback'} render={
                            (props) => {
                                return <PaymentCallback
                                    {...props}
                                    authenticator={this.state.authenticator}
                                />
                            }
                        }/>

                        <Route path={'/payment_canceled'} render={
                            (props) => {
                                return <PaymentCanceled
                                    {...props}
                                    authenticator={this.state.authenticator}
                                />
                            }
                        }/>

                        <Route path="/:publicName/:typeSlug?/:token?" render={
                            (props) => {
                                return <PublicProfile
                                    {...props}
                                    authenticator={this.state.authenticator}
                                />
                            }
                        }/>
                        <Route component={NotFound}/>
                    </Switch>
                </BrowserRouter>
                <div style={{marginBottom: '3.6rem'}}/>
                <Navbar fixed="bottom" bg="light">
                    <Nav style={{width:'100%', paddingLeft: '1rem'}}>
                        <Nav.Item style={{width:'100%'}}>
                            <ExperienceBar
                                experience={this.state.experience}/>
                        </Nav.Item>
                    </Nav>
                </Navbar>
            </div>
        )
        }
        return (<div>App.render - Loading</div>)
    }
}

export default App;
