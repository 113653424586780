import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import {Redirect} from "react-router";
import {Link} from "react-router-dom";


export class PasswordResetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
            submitted: false,
            isSaved: true,
            isValid: false,
            eventType: null,
            eventTypeFrequency: {},

            id: null,

            email: '',
            emailIsValid: false,
            emailValidationmessage: '',
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitErrorCallback = this.onSubmitErrorCallback.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
    }

    componentDidMount() {
        // const account = this.props.authenticator.account;
        // this.setState({
        //     email: account.user.email,
        // });
    }

    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates, () => {});
    }

    onSubmitErrorCallback(data, status) {
        this.setState({isSaved: false, submitted: false, errors: data})
    }

    onSubmitCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState({submitted: true})
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        let data = {
            email: this.state.email
        };
        this.props.authenticator.queuedPost(
            'password_reset',
            data,
            this.onSubmitCallback,
            this.onSubmitErrorCallback
        )
    }
    render() {
        if (this.state.submitted) {
            return <div className={'public-container'}>
                <div className={'skin-row'} style={{width: '100%'}}><img className={'public-container-logo'} src={'https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/logo192.png'}  alt={'em-logo'}/></div>
                <div className={'skin-row'}><h3>Experience Master Password Reset</h3></div>
                <div className={'skin-row'}>Check {this.state.email} for a reset link.</div>
            </div>
        }

        return <div className={'public-container'}>
            <div className={'skin-row'} style={{width: '100%'}}><img className={'public-container-logo'} src={'https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/logo192.png'}  alt={'em-logo'}/></div>
            <div className={'skin-row'}><h3>Experience Master Password Reset</h3></div>
            <Form onSubmit={this.onSubmit}
                  validated={!this.state.isSaved}>
                {!!this.state.errors && !this.state.errors.email &&
                <Alert variant={'danger'}>{this.state.errors[0]}</Alert>}
                {this.state.errors && this.state.errors.detail &&
                <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                {this.state.errors && this.state.errors.non_field_errors &&
                <Alert
                    variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                <FormGroup>
                    <Form.Label column={'email'}>
                        Enter the email address you used to register.
                    </Form.Label>
                    <Form.Control type="email"
                                  id={'email'}
                                  name={'email'}
                                  onChange={this.onFieldChange}
                                  value={this.state.email}
                                  required
                                  isInvalid={this.state.errors && !!this.state.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors && this.state.errors.email}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.emailValidationmessage}
                    </Form.Control.Feedback>

                </FormGroup>
                <Button type={'submit'}
                        disabled={this.state.isSaved}>Submit</Button>
            </Form>

        </div>
    }
}

export default class TokenedPasswordResetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitted: false,
            isSaved: true,
            isValid: true,
            eventType: null,
            eventTypeFrequency: {},

            id: null,

            passwordInvalid: false,

            apassword: '',
            apasswordIsValid: false,
            apasswordValidationmessage: '',
            apassword2: '',
            apassword2IsValid: false,
            apassword2Validationmessage: '',

            token: null,
            tokenValid: false,
            tokenResponse: null,

            loginSuccess: false
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitErrorCallback = this.onSubmitErrorCallback.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
        this.passwordCheck = this.passwordCheck.bind(this);
        this.validateToken = this.validateToken.bind(this);
        this.validResponse = this.validResponse.bind(this);
        this.invalidResponse = this.invalidResponse.bind(this);
        this.loginSuccess = this.loginSuccess.bind(this);
        this.loginFailure = this.loginFailure.bind(this);
        this.login = this.login.bind(this);
    }

    componentDidMount() {
        const {token} = this.props.match.params;
        this.setState({token:token}, this.validateToken)
    }


    validResponse(data) {
        this.setState({tokenValid: true, tokenResponse: data})
    }

    invalidResponse(data) {
        this.setState({tokenValid: false, tokenResponse: data})
    }

    validateToken() {
        this.props.authenticator.queuedGet(
            'password_reset',
            this.validResponse,
            this.invalidResponse,
            this.state.token
        )
    }

    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates, () => this.passwordCheck());
    }

    onSubmitErrorCallback(data, status) {
        this.setState({isSaved: false, submitted: false, errors: data || {errors: {detail: 'submission error'}}})
    }

    onSubmitCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState({submitted: true}, this.login)
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        let data = {
            token: this.state.token,
            password: this.state.apassword
        };
        this.props.authenticator.queuedPut(
            'password_reset',
            this.state.token,
            data,
            this.onSubmitCallback,
            this.onSubmitErrorCallback
        )
    }

    passwordCheck() {
        if (this.state.apassword !== this.state.apassword2) {
            this.setState({
                passwordInvalid: true,
                errors: {user: {password: 'Does not match.'}}
            })
        } else {
            if (this.state.passwordInvalid) {
                let newErrors = {...this.state.errors};
                delete newErrors.user.password;
                this.setState({
                    passwordInvalid: false,
                    errors: newErrors
                })
            }
        }
    }

    loginSuccess(data) {
        this.setState({loginSuccess: true});
    }

    loginFailure(data) {
        this.setState({loginSuccess: false, errors:{detail: data}});
    }

    login() {
        const username = this.state.tokenResponse.user.username;
        const password = this.state.apassword;
        this.props.authenticator.loginWithCallback(
            username,
            password,
            this.loginSuccess,
            this.loginFailure
        )
    }

    render() {
        if (this.state.loginSuccess) {
            return <Redirect to={'/advisors'}/>
        }

        if (!this.state.tokenValid) {
            return <div className={'public-container'}>
                <div className={'skin-row'} style={{width: '100%'}}><img className={'public-container-logo'} src={'https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/logo192.png'}  alt={'em-logo'}/></div>
                <div className={'skin-row'}><h3>Experience Master Password Reset</h3></div>
                <div className={'skin-row'}>Invalid or Expired Token</div>
                <div className={'skin-row'}><Link to={'/reset-password'}>Try Again</Link></div>
            </div>
        }

        return <div className={'public-container'}>
            <div className={'skin-row'} style={{width: '100%'}}><img className={'public-container-logo'} src={'https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/logo192.png'}  alt={'em-logo'}/></div>
            <div className={'skin-row'}><h3>Experience Master Password Reset</h3></div>
            <div className={'skin-row'}>{this.state.isValid && this.state.tokenResponse && this.state.tokenResponse.user.email}</div>
            <Form onSubmit={this.onSubmit}
                  validated={!this.state.isSaved}>
                {this.state.errors && this.state.errors.detail &&
                <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                {this.state.errors && this.state.errors.non_field_errors &&
                <Alert
                    variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                <FormGroup>
                    <Form.Label column={'apassword'}>
                        New Password
                    </Form.Label>
                    <Form.Control type="password"
                                  id={'apassword'}
                                  name={'apassword'}
                                  autoComplete="off"
                                  onChange={this.onFieldChange}
                                  value={this.state.apassword}
                                  isInvalid={this.state.errors && !!this.state.errors.password}
                                  placeholder={'Input New Password'}
                                  minLength={9}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors && this.state.errors.password}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.apasswordValidationmessage}
                    </Form.Control.Feedback>
                    <Form.Label column={'apassword2'}>
                        Confirm Password
                    </Form.Label>
                    <Form.Control type="password"
                                  id={'apassword2'}
                                  name={'apassword2'}
                                  autoComplete="new-password"
                                  onChange={this.onFieldChange}
                                  value={this.state.apassword2}
                                  isInvalid={this.state.errors && !!this.state.errors.password}
                                  minLength={9}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.apassword2Validationmessage}
                    </Form.Control.Feedback>

                </FormGroup>
                <Button type={'submit'}
                        disabled={this.state.isSaved}>Submit</Button>
            </Form>

        </div>
    }
}