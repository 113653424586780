import _ from "lodash";
import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import {Link} from "react-router-dom";
import moment from "moment-timezone";


export default class ImportRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            importRequest: null,
            timer: null,
            contacts: [],
            displayContactsButton: true
        };
        this.getImportRequest = this.getImportRequest.bind(this);
        this.getImportRequestCallback = this.getImportRequestCallback.bind(this);
        this.setTimer = this.setTimer.bind(this);
        this.clearTimer = this.clearTimer.bind(this);
        this.displayImportedContacts = this.displayImportedContacts.bind(this);
        this.deleteButton = this.deleteButton.bind(this);
        this.deleteContact = this.deleteContact.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
        this.deleteErrorCallback = this.deleteErrorCallback.bind(this);
    }

    componentDidMount() {
        // set timer
        const status = this.props.importRequest.status.name;
        if (status !== 'ERROR') {
            this.setTimer()
        }
    }

    componentWillUnmount() {
        this.clearTimer()
    }

    setTimer() {
        this.setState({timer: setInterval(this.getImportRequest, 500)})
    }

    clearTimer() {
        clearInterval(this.state.timer)
    }

    getImportRequestCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState({importRequest: data});
        const status = data.status.name;
        if (status === 'QUEUED' || status === 'PROCESSING') {
            this.setTimer()
        }
    }

    getImportRequest() {
        this.clearTimer();
        this.props.authenticator.queuedGet(
            'import_request',
            this.getImportRequestCallback,
            null,
            this.props.importRequest.id)
    }

    displayImportedContacts() {
        const request = this.state.importRequest || this.props.importRequest;
        this.setState({displayContactsButton: false}, () => {
            this.setState({contacts: request.contacts})
        })
    }

    deleteErrorCallback(data) {
        this.setState({errors: data, submitting: false})
    }

    deleteCallback(data) {
        if (!!!data) {
            return;
        }
        const contactId = this.state.deletingId;
        const newContacts = [...this.state.contacts];
        _.remove(newContacts, function (contact) {
            return contactId === contact.id;
        });
        this.setState({
            contacts: newContacts,
            submitting: false, deletingId: null
        });
    }

    deleteContact(contactId) {
        this.setState({submitting: true, deletingId: contactId}, () => {
            this.props.authenticator.queuedDelete(
                'contacts', contactId,
                this.deleteCallback, this.deleteErrorCallback)
        })
    }

    deleteButton(event) {
        const contactId = parseInt(event.currentTarget.dataset.contactId);
        this.deleteContact(contactId)
    }

    render() {
        const request = this.state.importRequest || this.props.importRequest;
        return <div>
            <h4>Import Request # {request.id}</h4>
            <div>Status: {request.status.name}</div>
            <div>Last Updated: {moment(request.modified).fromNow()}</div>
            {request.current_record !== request.total_records ?
            <div>On Record: {request.current_record} of {request.total_records}</div> :
                <div>Total Records: {request.total_records}</div>}
            {request.errors_count > 0 &&
            <div>Errors: {request.errors_count}</div>}
            {request.status.name !== 'DONE' && <div>
                <div>
                    {moment().add((request.total_records - request.current_record) * request.average_speed, 's').fromNow()}
                </div>
                <ProgressBar
                    now={request.current_record / request.total_records * 100}/>
            </div>}
            {this.state.displayContactsButton && this.state.contacts.length === 0 && request.contacts.length > 0 && request.status.name === 'DONE' &&
            <Button onClick={this.displayImportedContacts}>Display {request.contacts.length} Imported
                Contacts</Button>
            }
            {this.state.contacts.length > 0 &&
            <Table striped style={{textAlign: 'left'}}>
                <thead>
                <tr>
                    <th colSpan={3}>Imported Contacts</th>
                </tr>
                </thead>
                <tbody>
                {this.state.contacts.map((contact, index, original) => {
                    return <tr key={index}>
                        <td width={'75%'}>
                            <div>
                                <Link
                                    to={'contacts/contact/' + contact.id + '/'}>{contact.name}</Link>
                            </div>
                        </td>
                        <td><Button variant={'danger'}
                                    size={'sm'}
                                    data-contact-id={contact.id}
                                    onClick={this.deleteButton}
                        >X</Button></td>
                    </tr>
                })}
                </tbody>
            </Table>
            }
        </div>
    }


}