import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";


export default class ContactNameModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,
            eventType: null,
            eventTypeFrequency: {},

            id: null,
            
            name: '',
            nameIsValid: false,
            nameValidationmessage: '',

            givenname: '',
            givennameIsValid: false,
            givennameValidationmessage: '',

            familyname: '',
            familynameIsValid: false,
            familynameValidationmessage: '',

            additonalname: '',
            additonalnameIsValid: false,
            additonalnameValidationmessage: '',

            nameprefix: '',
            nameprefixIsValid: false,
            nameprefixValidationmessage: '',

            namesuffix: '',
            namesuffixIsValid: false,
            namesuffixValidationmessage: '',
            
            nickname: '',
            nicknameIsValid: false,
            nicknameValidationmessage: '',
            
        };
        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSubmitErrorCallback = this.onSubmitErrorCallback.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
    }

    componentDidMount() {
        const contact = this.props.contact;
        this.setState({
            id: contact.id,
            name: contact.name,
            givenname: contact.given_name || '',
            familyname: contact.family_name || '',
            additonalname: contact.additional_name || '',
            nameprefix: contact.name_prefix || '',
            namesuffix: contact.name_suffix || '',
            nickname: contact.nickname || ''
        });
    }

    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        if (validationMessage) {
            newStates.isValid = false;
        } else {
            newStates.isValid = true;
        }
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates);
    }

    onSubmitErrorCallback(data, status) {
        this.setState({submitted: false, errors: data})
    }

    onSubmitCallback(data) {
        if (!!!data) {return;}
        this.props.callback(data)
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        let data = {
            id: this.state.id,
            name: this.state.name,
            given_name: this.state.givenname,
            family_name: this.state.familyname,
            additional_name: this.state.additonalname,
            name_prefix: this.state.nameprefix,
            name_suffix: this.state.namesuffix,
            nickname: this.state.nickname,
            created_by: this.props.contact.created_by
        };
        this.props.authenticator.queuedPut(
            'contacts',
            this.state.id,
            data,
            this.onSubmitCallback,
            this.onSubmitErrorCallback
        )

    }

    onHide(event) {
        this.props.callback()
    }

    render() {
        return <Modal
            show={this.props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.onHide}
            className={'skin-modal'}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Name Editor
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={this.onSubmit}
                      validated={!this.state.isSaved}>
                    {this.state.errors && this.state.errors.detail &&
                    <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                    {this.state.errors && this.state.errors.non_field_errors &&
                    <Alert variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                    <FormGroup>
                        <Form.Label column={'name'}>
                            Display Name
                        </Form.Label>
                        <Form.Control type="text"
                                      id={'name'}
                                      name={'name'}
                                      onChange={this.onFieldChange}
                                      value={this.state.name}
                                      required
                                      isInvalid={!!this.state.errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.name}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.nameValidationmessage}
                        </Form.Control.Feedback>

                        <Form.Label column={'givenname'}>
                            Given Name
                        </Form.Label>
                        <Form.Control type="text"
                                      id={'givenname'}
                                      name={'givenname'}
                                      onChange={this.onFieldChange}
                                      value={this.state.givenname}
                                      isInvalid={!!this.state.errors.givenname}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.givenname}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.givennameValidationmessage}
                        </Form.Control.Feedback>

                        <Form.Label column={'familyname'}>
                            Family Name
                        </Form.Label>
                        <Form.Control type="text"
                                      id={'familyname'}
                                      name={'familyname'}
                                      onChange={this.onFieldChange}
                                      value={this.state.familyname}
                                      isInvalid={!!this.state.errors.familyname}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.familyname}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.familynameValidationmessage}
                        </Form.Control.Feedback>
                        
                        <Form.Label column={'additonalname'}>
                            Additional Name
                        </Form.Label>
                        <Form.Control type="text"
                                      id={'additonalname'}
                                      name={'additonalname'}
                                      onChange={this.onFieldChange}
                                      value={this.state.additonalname}
                                      isInvalid={!!this.state.errors.additonalname}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.additonalname}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.additonalnameValidationmessage}
                        </Form.Control.Feedback>

                        <Form.Label column={'nameprefix'}>
                            Name Prefix
                        </Form.Label>
                        <Form.Control type="text"
                                      id={'nameprefix'}
                                      name={'nameprefix'}
                                      onChange={this.onFieldChange}
                                      value={this.state.nameprefix}
                                      isInvalid={!!this.state.errors.nameprefix}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.nameprefix}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.nameprefixValidationmessage}
                        </Form.Control.Feedback>
                        
                        <Form.Label column={'namesuffix'}>
                            Name Suffix
                        </Form.Label>
                        <Form.Control type="text"
                                      id={'namesuffix'}
                                      name={'namesuffix'}
                                      onChange={this.onFieldChange}
                                      value={this.state.namesuffix}
                                      isInvalid={!!this.state.errors.namesuffix}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.namesuffix}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.namesuffixValidationmessage}
                        </Form.Control.Feedback>
                        
                        <Form.Label column={'nickname'}>
                            Nickname
                        </Form.Label>
                        <Form.Control type="text"
                                      id={'nickname'}
                                      name={'nickname'}
                                      onChange={this.onFieldChange}
                                      value={this.state.nickname}
                                      isInvalid={!!this.state.errors.nickname}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.nickname}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.nicknameValidationmessage}
                        </Form.Control.Feedback>

                    </FormGroup>
                    <Button type={'submit'}
                            disabled={this.state.isSaved}>Save</Button>
                </Form></Modal.Body>
        </Modal>
    }
}