import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment-timezone";
import PropTypes from 'prop-types';
import React, {Component} from 'react'
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import Button from "react-bootstrap/Button";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Row from 'react-bootstrap/Row';
import {Redirect, withRouter} from 'react-router-dom';

import '../styles/react-contextmenu.css'
import './Contact.css'
import ContactBirthdayModal from "./ContactBirthdayModal";
import ContactForm from "./ContactForm";
import ContactNameModal from "./ContactNameModal";
import ContactUrls from './ContactUrls';
import EmailAddresses from './EmailAddresses';
import Locations from "./Locations";
import {SendMessage} from './MessageTemplates';
import PhoneNumbers from './PhoneNumbers';
import Relationships from './Relationships';
import ScoreBox from './ScoreBox';
import SharedConnection from './SharedConnection';
import ShareModal from "./ShareModal";
import {SubscriptionCheck} from "./Subscriptions";
import Transcripts from './Transcripts';


/*
 * Contact - The primary object of focus in EDNA.
 */
class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            coaches: null,
            contact: null,
            contactId: null,
            refreshTranscripts: false,
            enableTranscripts: true,
            previouslyViewedOn: null,
            isCoach: false,
            account: null,
            editMode: false,
            resetNewTranscript: false,
            showContactNameModal: false,
            showContactBirthdayModal: false,
            showShareModal: false
        };
        this.getContact = this.getContact.bind(this);
        this.getContactCallback = this.getContactCallback.bind(this);
        this.refreshTranscripts = this.refreshTranscripts.bind(this);
        this.enableTranscripts = this.enableTranscripts.bind(this);

        this.menuItemCallback = this.menuItemCallback.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
        this.menuOnSelect = this.menuOnSelect.bind(this);
        this.newTranscriptCallback = this.newTranscriptCallback.bind(this);
        this.clickWorkflowButton = this.clickWorkflowButton.bind(this);
        this.putContact = this.putContact.bind(this);
        this.clickShowNameModal = this.clickShowNameModal.bind(this);
        this.nameModalCallback = this.nameModalCallback.bind(this);
        this.deleteButton = this.deleteButton.bind(this);
        this.shareModalCallback = this.shareModalCallback.bind(this);
        this.hideShareModalCallback = this.hideShareModalCallback.bind(this);
        this.clickShowContactBirthdayModal = this.clickShowContactBirthdayModal.bind(this);
        this.birthdayModalCallback = this.birthdayModalCallback.bind(this);
        this.hideBirthdayModalCallback = this.hideBirthdayModalCallback.bind(this);
        this.refreshContact = this.refreshContact.bind(this);
    }

    /**
     * After mounting the component 'will update.'
     */
    componentDidMount() {
        const contactId = this.props.match.params.contactId;
        const hash = this.props.location.hash;
        this.setState({editMode: hash === '#edit'});
        this.setState({contactId: contactId});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const contactId = parseInt(this.props.match.params.contactId);
        if (this.state.contactId && this.state.contactId !== contactId) {
            this.setState({contactId: contactId});
            const hash = this.props.location.hash;
            this.setState({editMode: hash === '#edit'});
            this.getContact(contactId)
        }
    }

    getContactCallback(data) {
        if (!data) {
            this.setState({contact: {}})
            return;
        }

        this.setState({contact: null}, () => {

            this.setState({
                contact: data,
                previouslyViewedOn: data.last_viewed,
            })
        })
    }

    /**
     * Use /contactId to 'get' contact from REST API.
     */
    getContact(contactId) {
        this.props.authenticator.queuedGet('contacts',
            this.getContactCallback, null, contactId, null)
    }

    refreshContact() {
        this.getContact(this.state.contactId)
    }

    viewedCallback(data) {
    }

    markContactAsViewed(contactId, name) {

        let today = new Date();
        let bodyDict = {
            id: contactId,
            name: name,
            last_viewed: today,
            created_by: this.props.authenticator.account.user.id,
            modified_by: this.props.authenticator.account.user.id
        };

        this.props.authenticator.queuedPut('contacts', contactId,
            bodyDict, this.viewedCallback, null)
    }


    // TODO: this is convoluted and weird.
    enableTranscripts() {
        this.setState({enableTranscripts: true, refreshTranscripts: false})
    }

    refreshTranscripts() {
        window.location.reload();
        // this.setState({enableTranscripts: false, refreshTranscripts: true},
        //     this.enableTranscripts)
    }

    // TODO: change to moment
    daysSince(stringDate) {
        const dateObject = new Date(stringDate);
        let result = ((Date.now() - dateObject) / (1000 * 60 * 60 * 24)).toFixed(2);
        return result
    }

    deleteCallback() {
        this.setState({contact: {id: null}})
    }

    menuItemCallback(menuItem, recordId) {
        if (menuItem === 'delete') {
            this.props.authenticator.queuedDelete(
                'contacts', recordId, this.deleteCallback)
        }
    }

    deleteButton(event) {
        this.props.authenticator.queuedDelete(
            'contacts', this.state.contactId,
            this.deleteCallback)
    }


    menuOnSelect(eventKey, event) {
        if (eventKey === 'edit') {
            this.setState({editMode: true});
        }
        if (eventKey === 'view') {
            this.setState({editMode: false});
            this.getContact(this.state.contactId);
        }
        if (eventKey === 'delete') {
            this.setState({editMode: false});
            this.deleteButton();
        }
        if (eventKey === 'share') {
            this.setState({showShareModal: true});
        }
    }

    newTranscriptCallback(newRecord) {
        // window.location.reload();

        this.setState({enableTranscripts: false}, () => (
            this.setState({enableTranscripts: true})));
    }

    clickWorkflowButton(event) {
        const contact = this.state.contact;
        contact.status = event.target.value;
        this.setState({contact: contact}, this.putContact)
    }

    putContactSuccess = (data) => {
        this.setState({contact: data})
    };

    putContact() {
        this.props.authenticator.queuedPut(
            'contacts',
            this.state.contact.id,
            this.state.contact,
            this.putContactSuccess,
            () => (null)
        )
    }

    clickShowNameModal(event) {
        this.setState({showContactNameModal: true})
    }

    nameModalCallback(data) {
        this.setState({showContactNameModal: false});
        this.getContact(this.state.contact.id);
    }

    shareModalCallback(data) {
        this.setState({showShareModal: false});
        this.getContact(this.state.contact.id);
    }

    hideShareModalCallback(data) {
        this.setState({showShareModal: false});
    }


    clickShowContactBirthdayModal(event) {
        this.setState({showContactBirthdayModal: true})
    }

    birthdayModalCallback(data) {
        this.setState({showContactBirthdayModal: false});
        this.getContact(this.state.contact.id);
    }

    hideBirthdayModalCallback(data) {
        this.setState({showContactBirthdayModal: false});
    }

    daysUntilBirthday(birthday) {
        const newBirthday = moment(birthday);
        const days = newBirthday.year(moment().year()).diff(moment(), 'days')
        if (days > 0) {
            return 'In ' + days.toString() + ' days.'
        }
        if (days < 0) {
            return 'Was ' + (-1 * days).toString() + ' days ago.'
        }
        return 'TODAY!!!'
    }

    render() {

        const contact = this.state.contact;
        if (!!!contact) {
            return <div>Contact Loading...</div>
        }
        if (!!!contact.id) {
            return <Redirect to={'/'}/>
        }
        if (!!contact && contact.id) {
            document.title = 'EM:' + contact.name;
        }

        const birthday = contact.birthday ? moment(contact.birthday, "YYYY-MM-DD") : null;
        const daysUntilBirthday = birthday ? this.daysUntilBirthday(birthday) : null;
        return (
            <div>
                <SubscriptionCheck authenticator={this.props.authenticator}/>
                {!!contact && contact && <>
                    <ContactNameModal authenticator={this.props.authenticator}
                                      contact={contact}
                                      callback={this.nameModalCallback}
                                      show={this.state.showContactNameModal}
                    />
                    <ContactBirthdayModal
                        authenticator={this.props.authenticator}
                        contact={contact}
                        callback={this.birthdayModalCallback}
                        show={this.state.showContactBirthdayModal}
                    />

                <Navbar collapseOnSelect
                        fixed={'top'}
                        expand="lg"
                        onSelect={this.menuOnSelect}
                        className={'contact-navbar'}
                >
                {/*<nav className={'contact-navbar navbar navbar-expand-lg fixed-top'}>*/}
                    <Navbar.Brand>
                        <h1 align={"left"}
                            className={'smaller-mobile-font'}>
                            {contact.name}
                            {this.state.editMode &&
                            <Button onClick={this.clickShowNameModal}>
                                Edit Name</Button>}</h1>
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav.Item>
                            <NavDropdown title="File" id="basic-nav-dropdown">
                                {/*<NavDropdown.Item*/}
                                {/*    eventKey="share">Share</NavDropdown.Item>*/}
                                <NavDropdown.Item eventKey={'delete'}>Delete</NavDropdown.Item>
                            </NavDropdown>
                        </Nav.Item>
                        <Nav.Link eventKey={'share'} href="">
                            Share with
                        </Nav.Link>

                        {this.state.editMode ?
                            <Nav.Link eventKey={'view'} href="#edit">
                                Stop Editing
                            </Nav.Link> :
                            <Nav.Link eventKey={'edit'} href="#view">
                                Edit
                            </Nav.Link>
                        }
                        {contact.em_user &&
                        <Nav.Link eventKey={'profile'} href={'/'+contact.em_user.profile}>
                            EM Profile
                        </Nav.Link>}

                        {/*<Nav.Item>*/}
                        {/*    <NavDropdown title="View" id="basic-nav-dropdown">*/}
                        {/*        <NavDropdown.Item>Transcripts</NavDropdown.Item>*/}
                        {/*        <NavDropdown.Item>Message*/}
                        {/*            Templates</NavDropdown.Item>*/}
                        {/*        <NavDropdown.Item>Privacy*/}
                        {/*            Mode</NavDropdown.Item>*/}
                        {/*    </NavDropdown>*/}
                        {/*</Nav.Item>*/}
                    </Navbar.Collapse>
                {/*</nav>*/}
                    </Navbar>
                </>
                }
                <div style={{marginTop: '9rem'}} />
                <Row className={this.props.privacyMode ? 'normal-display-flex' : 'privacy-no-display'}>
                    <Container><div className={'text-center'}>Privacy Mode</div></Container>
                </Row>
                <Container className={'contact-body'}>
                    {!!contact && contact && <>
                        <div className="timing-buttons">
                            <button value={3}
                                    onClick={this.clickWorkflowButton}
                                    className={'timing-button ' + (contact.status === 3 ? 'never' : 'never-outline')}>Never</button>
                            <button value={12}
                                    onClick={this.clickWorkflowButton}
                                    className={'timing-button ' + (contact.status === 12 ? 'first-contact' : 'first-contact-outline')}>First-Contact</button>
                            <button value={27}
                                    onClick={this.clickWorkflowButton}
                                    className={'timing-button ' + (contact.status === 27 ? 'thank-you' : 'thank-you-outline')}>Thank You</button>
                           <button value={34}
                                    onClick={this.clickWorkflowButton}
                                    className={'timing-button ' + (contact.status === 34 ? 'sooner' : 'sooner-outline')}>Sooner</button>
                            <button value={11}
                                    onClick={this.clickWorkflowButton}
                                    className={'timing-button ' + (contact.status === 11 ? 'later' : 'later-outline')}>Later</button>
                            <button value={25}
                                    onClick={this.clickWorkflowButton}
                                    className={'timing-button ' + (contact.status === 25 ? 'sleep' : 'sleep-outline')}>Sleep</button>
                        </div>

                        <Row name={'top-row'} className={this.props.privacyMode ? 'privacy-no-display' : 'normal-display-flex'}>
                            <Col>
                                <ContactForm
                                    authenticator={this.props.authenticator}
                                    contact={contact}
                                    editMode={this.state.editMode}
                                />

                            </Col>

                            <Col>
                                <ScoreBox
                                    authenticator={this.props.authenticator}
                                    contact={contact}
                                    editMode={this.state.editMode}
                                />
                            </Col>
                            <Col lg="4" className={'contact-contact-box'}>
                                <Row align={'left'}>
                                    <Col>{birthday ? <><div>Birthday: {birthday.format('dddd LL')}</div><div>({birthday.fromNow()}, {daysUntilBirthday})</div></> : <span>Ask for their Birthday.</span>} {this.state.editMode &&
                                    <Button onClick={this.clickShowContactBirthdayModal} className={'skin-button'}>
                                        Edit Birthday</Button>}
                                </Col>
                                </Row>
                                <Row>
                                    <PhoneNumbers
                                        authenticator={this.props.authenticator}
                                        contact={contact}
                                        editMode={this.state.editMode}
                                    />
                                </Row>
                                <Row>
                                    <EmailAddresses
                                        authenticator={this.props.authenticator}
                                        contact={contact}
                                        editMode={this.state.editMode}
                                    />
                                </Row>
                                <Row>
                                    <Locations
                                        authenticator={this.props.authenticator}
                                        contact={contact}
                                        editMode={this.state.editMode}
                                    />
                                </Row>
                                <Row>
                                    <Relationships
                                        authenticator={this.props.authenticator}
                                        contact={contact}
                                        editMode={this.state.editMode}
                                    />
                                </Row>
                                <Row>
                                    <ContactUrls
                                        authenticator={this.props.authenticator}
                                        contact={contact}
                                        editMode={this.state.editMode}
                                    />
                                </Row>
                                {!this.state.editMode && !this.props.privacyMode &&
                                <div className={'contact-details-box skin-column'}>
                                    {contact.last_viewed ?
                                        <div className={'skin-row'}>
                                            <div className={'contact-details-first-column'}>Last&nbsp;Viewed:</div>
                                            <div className={'contact-details-column'}>
                                                <div className={'skin-row'}>{moment(contact.last_viewed).fromNow()}</div>
                                                <div className={'skin-row'}>{!!contact && moment(contact.last_viewed).format('dddd LL LT')}</div>
                                            </div>
                                        </div>
                                        :
                                        <div className={'skin-row'}>First View</div>
                                    }
                                    {contact.last_contact ?
                                        <div className={'skin-row'}>
                                            <div className={'contact-details-first-column'}>Last&nbsp;Contact:</div>
                                            <div className={'contact-details-column'}>
                                                <div className={'skin-row'}>{!!contact && moment(contact.last_contact).fromNow()}</div>
                                                <div className={'skin-row'}>{!!contact && moment(contact.last_contact).format('dddd LL LT')}</div>
                                            </div>
                                        </div>
                                        :
                                        <div className={'skin-row'}>Never Contacted</div>
                                    }
                                    <div className={'skin-row'}>
                                        <div className={'contact-details-first-column'}>Suggested:</div>
                                        <div className={'contact-details-column'}>{!!contact.next_ping && moment(contact.next_ping).format('dddd LL')}</div>
                                    </div>
                                    <div className={'skin-row'}>
                                        <div className={'contact-details-first-column'}>Created:</div>
                                        <div className={'contact-details-column'}>{moment(contact.created).format('dddd LL LT')}</div>
                                    </div>
                                    <div className={'skin-row'}>
                                        <div className={'contact-details-first-column'}>Created&nbsp;By:</div>
                                        <div className={'contact-details-column'}>
                                            {this.props.authenticator.account.user.id === contact.created_by.id ?
                                                <span>You</span> :
                                                <span>{contact.created_by.first_name}&nbsp;{contact.created_by.last_name}</span>}
                                        </div>
                                    </div>
                                    <div className={'skin-row'}>
                                        <div className={'contact-details-first-column'}>Modified:</div>
                                        <div className={'contact-details-column'}>{moment(contact.modified).format('dddd LL LT')}</div>
                                    </div>
                                    {!!contact.modified_by &&
                                    <div className={'skin-row'}>
                                        <div className={'contact-details-first-column'}>
                                        Modified&nbsp;By:
                                        </div>
                                        <div className={'contact-details-column'}>
                                            {this.props.authenticator.account.user.id === contact.modified_by.id ?
                                                <span>You</span> :
                                                <span>{contact.modified_by.first_name}&nbsp;{contact.modified_by.last_name}</span>}
                                        </div>
                                    </div>
                                    }
                                </div>
                                    }
                            </Col>
                        </Row>
                        {/*<Row><Col>*/}
                        {/*    {!!contact && !this.state.resetNewTranscript &&*/}
                        {/*    <NewTranscript*/}
                        {/*        contact={contact}*/}
                        {/*        callback={this.newTranscriptCallback}*/}
                        {/*        authenticator={this.props.authenticator}*/}
                        {/*    />}*/}
                        {/*</Col></Row>*/}
                    </>
                    }

                    <SendMessage contact={contact}
                                 callback={this.newTranscriptCallback}
                                 authenticator={this.props.authenticator}/>

                    {/*<Row className={this.props.privacyMode ? 'privacy-no-display' : 'normal-display-flex'}>*/}
                    {/*    <Col className='text-left'>*/}
                    {/*        <Container>*/}
                    {/*        <Accordion*/}
                    {/*            as={Card}*/}
                    {/*            className={'accordion-container'}*/}
                    {/*            key={'message-templates'}*/}
                    {/*        >*/}
                    {/*            <Accordion.Toggle*/}
                    {/*                as={Card.Header}*/}
                    {/*                eventKey={'message-templates'}*/}
                    {/*                onClick={this.onClick}*/}
                    {/*                className='text-center'*/}
                    {/*            >*/}
                    {/*                Message Templates*/}
                    {/*            </Accordion.Toggle>*/}
                    {/*            <Accordion.Collapse*/}
                    {/*                eventKey={'message-templates'}>*/}
                    {/*                <Container style={{padding: '1rem'}}>*/}
                    {/*        <UserMessageTemplates*/}
                    {/*            contact={contact}*/}
                    {/*            authenticator={this.props.authenticator}*/}
                    {/*            refreshTranscriptsCallback={this.refreshTranscripts}*/}
                    {/*        />*/}
                    {/*        <MessageTemplates*/}
                    {/*            contact={contact}*/}
                    {/*            authenticator={this.props.authenticator}*/}
                    {/*            refreshTranscriptsCallback={this.refreshTranscripts}*/}
                    {/*        />*/}
                    {/*                </Container>*/}
                    {/*            </Accordion.Collapse>*/}
                    {/*        </Accordion>*/}
                    {/*        </Container>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    {contact && this.state.enableTranscripts &&
                    <Row className={this.props.privacyMode ? 'privacy-no-display' : 'normal-display-flex'}>
                        <Col className='text-left'>
                            <Transcripts
                                contact={contact}
                                authenticator={this.props.authenticator}
                            />
                        </Col>
                    </Row>
                    }

                    {contact &&
                    <Row className={this.props.privacyMode ? 'privacy-no-display' : 'normal-display-flex'}>
                        <Col>
                            <SharedConnection
                                contact={contact}
                                authenticator={this.props.authenticator}
                                callback={this.refreshContact}
                            />
                        </Col>
                    </Row>
                    }
                    {this.state.showShareModal &&
                    <ShareModal
                        authenticator={this.props.authenticator}
                        contact={contact}
                        show={this.state.showShareModal}
                        callback={this.shareModalCallback}
                        hideCallback={this.hideShareModalCallback}
                    />}

                </Container>
            </div>
        )
    }

}


/**
 * PropTypes to for Contact, to require the new library.
 * @type {{contact: *, typeLibrary: *}}
 */
Contact.propTypes = {
    authenticator: PropTypes.object.isRequired
};

export default withRouter(Contact);
