import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import RetailMenu from "./RetailMenu";

export default class SecurityPolicy extends Component {
    render() {
        return <div style={{fontFamily: ['Dosis', 'serif']}}>
            <RetailMenu authenticator={this.props.authenticator}/>
            <Container className="text-left">
            <h1>Experience Master Security Policies</h1>
            <Table className="features text-left" border>
                <tbody className="text-left">
                <tr>
                    <td colSpan="2"  className="text-center">
                        Where we Compute your Data
                    </td>
                </tr>
                <tr className="feature">
                    <td width={'25%'}>
                        Amazon Web Services
                    </td>
                    <td>
                        Experience Master’s physical infrastructure is hosted
                        and managed within&nbsp;
                        <a href="https://aws.amazon.com/security/">Amazon’s
                            secure data centers.</a>&nbsp;
                        EM leverages all of the platform’s built-in
                        security, privacy and redundancy features. AWS
                        continually monitors its data centers for risk and
                        undergoes assessments to ensure compliance with industry
                        standards. Amazon’s data center operations have been
                        accredited under: ISO 27001, SOC 1 and SOC 2/SSAE
                        16/ISAE 3402 (Previously SAS 70 Type II), PCI Level 1,
                        FISMA Moderate and Sarbanes-Oxley (SOX).
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        AWS Elastic BeanStalk
                    </td>
                    <td>
                        The Website uses AWS Elastic BeanStalk to provide
                        high availability resources.
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Backups
                    </td>
                    <td>
                        All backups are maintained in the Cloud, and secured
                        using the same features as the production data.
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Encryption
                    </td>
                    <td>
                        Data that passes through Us is encrypted, both in
                        transit and at rest. All connections from the browser to
                        The Website are encrypted in transit using TLS
                        SHA-256 with RSA Encryption. EM requires HTTPS for
                        all services.
                    </td>
                </tr>
                </tbody>
                <tbody className={'text-left'}>
                <tr>
                    <td colSpan="2" className="text-center">
                        <span>Security and Compliance</span>
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Background checks
                    </td>
                    <td>
                        All Our employees go through a thorough background
                        check before hire.
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Training
                    </td>
                    <td>
                        The data here is very personal, all
                        employees are trained on security and data handling to
                        ensure that they uphold our strict commitment to the
                        privacy and security of your data.
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Confidentiality
                    </td>
                    <td>
                        All employees sign a confidentiality agreement before
                        they start with Us.
                    </td>
                </tr>
                <tr className="text-center">
                    <td colSpan="2">
                        Reliability and redundancy
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Business continuity and disaster recovery
                    </td>
                    <td>
                        We have experience in disaster recovery and business
                        continuity from years of experience, we carry the
                        industry's best practices.
                    </td>
                </tr>
                <tr className="text-center">
                    <td colSpan="2">
                        SDLC: Software Development Lifecycle
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Routine audits
                    </td>
                    <td>
                        We use Elastic resources and Cloud Watch to monitor.
                        We also practice TDD to provide high software quality.
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        New releases
                    </td>
                    <td>
                        We use detailed environment specifications to transition
                        code from developer through a 6 stage continuous
                        integration / testing system. Releases are rolled out
                        and we attempt to limit releases to Tuesdays wherever
                        possible.
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Quality Assurance
                    </td>
                    <td>
                        We have a competent group of release testers and a
                        staged release system to promote User Acceptance Testing
                        before pushing new features.
                    </td>
                </tr>
                <tr className="text-center">
                    <td colSpan="2">
                        Vulnerability Mitigation
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Mobile device management (MDM)
                    </td>
                    <td>
                        Employees develop in AWS virtual environments.
                        This keeps both the code and the data in the secured
                        Cloud resources.
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Malicious software prevention
                    </td>
                    <td>
                        We employ Malware Bytes to regularly prevent incursions.
                        Our employees are all well informed about Phishing and
                        other types of attacks.
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Vulnerability scanning
                    </td>
                    <td>
                        With today's toxic internet environments, and our
                        background in Devops Security, we push hard to
                        keep everything well patched. A healthy Network is a
                        clean network.
                    </td>
                </tr>
                </tbody>
                <tbody className={'text-left'}>
                <tr>
                    <td colSpan="2" className="text-center">
                            <span>Application security measures</span>
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Login credential protection
                    </td>
                    <td>
                        All third party credentials are established using
                        OAuth, thus only secure tokens are stored. We do
                        also support raw SMTP credentials, so those can be
                        stored in The Website's databases. We recommend DFA for
                        all third party resources.
                    </td>
                </tr>
                <tr className="feature">
                    <td>
                        Deprovisioning
                    </td>
                    <td>
                        Third-party connections my be broken at any time.
                        The Website will delete all credentials upon User
                        Account Deletion.
                    </td>
                </tr>
                </tbody>
            </Table>
            </Container>
        </div>
    }
}