import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import RetailMenu from "./RetailMenu";

export default class PrivacyPolicy extends Component {

    render() {
        return <div style={{fontFamily: ['Dosis', 'serif']}}>
            <RetailMenu authenticator={this.props.authenticator}/>
            <Container>
                <h3>Experience Master the App</h3>
                <h4>Privacy Policy</h4>
                <div>
                    <div>Experience Master is a division of Fossa Tracks, Inc., an American owned and operated company.</div>
                    <div>Fossa Tracks, Inc.</div>
                    <div>1925 Palomar Oaks Way, Suite 107</div>
                    <div>Carlsbad, CA 92008</div>
                    <div><a href={'mailto:michael@experiencemaster.com'}>michael@experiencemaster.com</a></div>
                </div>

                <Container>
                    <div>
                        <h1>Privacy Policy Notice</h1>
                    </div>
                    <div>
                        <p>Effective January 1, 2020</p>
                        <p>
                            Fossa Tracks, Inc, dba Experience Master (“EM”, “We”, or “Us”) recognizes
                            the importance of
                            privacy. We want Users to feel confident using our
                            services, and this
                            privacy notice (“Notice”) is to help Users understand
                            and describe how we
                            collect, use, and disclose User information.
                        </p>
                        <p>
                            This Policy describes the following:
                        </p>
                        <ul>
                            <li>What we collect;</li>
                            <li>How we use User information;</li>
                            <li>Who has access to User information;</li>
                            <li>Where we process User information;</li>
                            <li>Reasoning for using User information;</li>
                            <li>User rights and choices;</li>
                            <li>Security and Storage of User information;</li>
                            <li>Third party links and exports;</li>
                            <li>Users outside the United States;</li>
                            <li>Privacy Shield compliance;</li>
                            <li>CCPA Compliance;</li>
                            <li>Changes to the Notice and Contact Information;
                                and
                            </li>
                            <li>Contacting EM.</li>
                        </ul>
                        <p>
                            The sum of all Experience master tools are collectively
                            called “The Website.” “Users” are the humans
                            who have created an EM account, and “Invitees”
                            are people who have scheduled a
                            meeting with a User but may or may not have
                            registered with
                            EM. A “Visitor” is an un-credentialed viewer.
                            Additional terms from from The Website may apply.
                        </p>
                        <h3>
                            What We Collect
                        </h3>
                        <p>
                            We collect your Personal Information to be able
                            to bill you for services. User tracking is done
                            internally, no third party services (e.g. Google
                            Analytics) are used to track User usage of
                            The Website.
                        </p>
                        <p>
                            In order to help you protect Users, Invitees,
                            and Viewers promote behaviors which provide only
                            the information that is specifically necessary
                            for using The Website. For example, public
                            scheduling requests only a name, email address,
                            date, and time.
                        </p>
                        <p>
                            No Kids: EM is specifically not designed for
                            children under sixteen (16) years of age and
                            we do not knowingly collect personal information
                            from children under 16.
                            If we discover that a child under 16 has provided us
                            with personal information, we will promptly delete
                            such personal information from our
                            systems.
                        </p>
                        <h3>
                            Information
                            You Provide
                        </h3>
                        <p>
                            The Website collects the following information,
                            directly from Users, Invitees, or Viewers,
                            specific third party tools are note:
                        </p>
                        <ul>
                            <li>
                                <strong>Contact Information.</strong>
                                A User, Invitee, or Visitor may volunteer
                                names, emails, datetimes, and other contact
                                information. Invitees seeing EM branding
                                may request alternative methods from the
                                originating User.
                            </li>
                            <li>
                                <strong>Scheduling Information.</strong>
                                A User may use the EM Scheduling engine to
                                express availabilities. Only the Owner of
                                the User Account may see the details of
                                any scheduled events. Scheduled Events are
                                purged after the scheduled datetime.
                            </li>
                            <li>
                                <strong>Billing Information.</strong>
                                We use third-party payment processors
                                which collect and store your
                                billing address and credit card information. We
                                may store provided information from the
                                payment-processor:
                                the last four digits of your credit
                                card number, card type, and the expiration date.
                            </li>
                        </ul>
                        <h3>Logging and Authentication
                        </h3>
                        <p>
                            We use cookies and temporary browser storage to
                            help with authentication.
                            We collect information on all views of The Website,
                            but zero of that information leaves our servers:
                        </p>
                        <ul>
                            <li>
                                <strong>Log &amp; Device data.</strong>
                                HTTP provides extensive Browser information
                                such as versions, and
                                the GeoLoc of the request.
                                We record this information to improve
                                The Website. Users can adjust what information
                                the Browser sends, see documentation from the
                                device manufacturer or software provider.
                            </li>
                            <li>
                                <strong>Cookie data.</strong>
                                We allow Users to select what type of
                                persistent session key storage is used, cookie,
                                browser storage, or None.
                            </li>
                            <li>
                                <strong>
                                    Other Web Site Analytics Services.
                                </strong>
                                We do not use third party data analytics for
                                normal website usage. Some marketing may require
                                additional landing pages to have
                                external analytics.
                            </li>
                        </ul>
                        <h3>
                            How We Use
                            Your Information
                        </h3>
                        <p>We may use information that we collect about you,
                            including personal information, to:</p>
                        <ul>
                            <li>
                                <strong>Enable The Website.</strong>
                                The Website is founded on using User information
                                to provide you a more fulfilling experience.
                                We don't contact your Contacts. We do use your
                                Payment Information to alert you of payment
                                activities.
                            </li>
                            <li>
                                <strong>Improve The Website.</strong>
                                The Website must constantly improve,
                                we use in-house
                                Analytics to improve the User experience.
                            </li>
                            <li>
                                <strong>Communicate with you about:</strong>
                                <ul>
                                    <li>
                                        <strong>Services.</strong>
                                        Emails verifying your services.
                                        Event creation emails and
                                        Opt-In Event Reminders.
                                    </li>
                                    <li>
                                        <strong>Promotional.</strong>
                                        We do have an opt-out promotional email
                                        feature visit User Settings for more.
                                        Contacts, Invitees, and Visitors are
                                        never independently solicited.
                                    </li>
                                    <li>
                                        <strong>Help.</strong>
                                        We will respond to your questions or
                                        comments using the
                                        User registered email.
                                    </li>
                                    <li>
                                        <strong>Administrative.</strong>
                                        Services change. When services change,
                                        we will contact you.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Everyone's Rights and
                                    Interests.</strong>
                                Your information may help us protect our
                                mutual rights and interests, or possibly those
                                of another person. We do use your information to
                                enforce this Policy and our Terms of Service.
                            </li>
                            <li>
                                <strong>Legal Compliance.</strong>
                                We carefully comply with all government
                                regulations and work closely with
                                law enforcement or other
                                governmental authorities, during all
                                legal proceedings involving The Website.
                            </li>
                            <li>
                                <strong>Other.</strong>
                                We also may use your information to manage our
                                business
                                or perform functions as otherwise described to
                                you at the time of
                                collection subject to your consent.
                            </li>
                        </ul>
                        <h3>With
                            Whom We May Share Your Information
                        </h3>
                        <p>
                            We do not share User information other than
                            specifically mentioned within this document for
                            financial or legal purposes.
                        </p>
                        <p>
                            We do not use Contact or Invitee data to market.
                        </p>
                        <p>
                            We do share User information in the following ways:
                        </p>
                        <ul>
                            <li>
                                <strong>Government Entities.</strong>
                                <p>
                                    We will provide government agencies with
                                    appropriately solicited User information.
                                </p>
                                <strong>Financial Providers</strong>
                                <p>
                                    We may engage Service Providers to process
                                    credit card transactions or
                                    other payment methods.
                                </p>
                            </li>
                            <li>
                                <strong>Corporate Accounts.</strong>
                                <p>
                                    If your company is paying for your account,
                                    and you use The Website
                                    as an employee of a company,
                                    that company may request to administer
                                    your User account.
                                </p>
                            </li>
                        </ul>
                        <h3>
                            Your Abilities</h3>
                        <ul>
                            <li>
                                <strong>Control your Account.</strong>
                                Your control your Account information. You
                                may update or even delete your account yourself.
                                As long as your Account billing is up to date,
                                your account will remain intact.
                            </li>
                            <li>
                                <strong>E-mail.</strong>
                                If you opt out of receiving marketing
                                communications from us, we may
                                continue to send to you emails regarding your
                                account status which are not optional. If you
                                do not wish to receive any
                                emails from The Website ever, you have the
                                option to delete your account.
                            </li>
                            <li>
                                <strong>Cookies.</strong>
                                The Website intends to provide limited
                                opt-out features for cookies and tokens as they
                                are used for Authentication and Payments.
                            </li>
                            <li>
                                <strong>Additional Rights.</strong>
                                Subject to local law, likely have the right to
                                complain to your local data protection
                                authority at any time.
                            </li>
                        </ul>
                        <h3>
                            Security
                            and Storage of Information
                        </h3>
                        <p>Our security practices are discussed on
                            our security page:
                            <Link to={'https://experiencemaster.com/security'}>
                                https://experiencemaster.com/security</Link>
                            .
                        </p>
                        <p>We are specifically attempting to provide
                            exceptional security to protect the personal data
                            that you provide from loss, misuse, and
                            unauthorized access, or disclosure. But...
                            there is no guarantee that personal
                            data will be absolutely safe from access,
                            alteration, or destruction by a
                            breach of any of our physical, technical, and
                            managerial safeguards.
                            We are going to do our very best:
                            <Link to={'https://experiencemaster.com/security'}>
                                https://experiencemaster.com/security</Link>
                        </p>
                        <p>
                            You should take steps yourself to protect against
                            unauthorized access to your devices and accounts by,
                            taking simple steps like using a unique and complex
                            password. We are not responsible
                            for any lost, stolen, or compromised passwords or
                            for any activity on your account via
                            unauthorized password activity.
                        </p>
                        <p>
                            We retain the personal data to
                            perform our contractual and legal obligations, and
                            for any applicable
                            statute of limitations periods for the purposes of
                            bringing and defending claims.
                        </p>
                        <h3>
                           Third-Party Link
                        </h3>
                        <p>
                            Users may add URLs to their templates. Thus it is
                            possible The Website could contain references to
                            third-party websites and applications. This is at
                            the behest of the originating User and would need to
                            be dealt with by that User. We do not endorse
                            these parties, their content, or any products
                            and services they offer, and we are not responsible
                            for the information practices
                            of such third-party websites or applications.
                        </p>
                        <p>
                            We reserve the right to ban paid Users for content
                            deemed inappropriate for public consumption by way
                            of the Experience Master product line.
                        </p>
                        <h3>
                            Users Outside the
                            USA
                        </h3>
                        <p>
                            Our application and database servers are located
                            with Amazon Cloud
                            Services in the United States. Amazon Cloud Services
                            has certified with
                            the Department of Commerce that it adheres to the
                            Privacy Shield
                            Principles under the EU-U.S. Privacy Shield
                            framework and the Swiss-U.S.
                            Privacy Shield framework.
                        </p>
                        <p>
                            If you are an individual located in the European
                            Economic Area, the United
                            Kingdom, Canada or another jurisdiction outside of
                            the United States with
                            laws and regulations governing personal data
                            collection, use, and
                            disclosure that differ from United States laws,
                            please be aware that
                            information we collect (including through the use of
                            methods such as
                            Cookies and other web technologies) will be
                            processed and stored in the
                            United States or in other countries where we or our
                            third-party services
                            providers have operations. By submitting your
                            personal information to
                            The Website and using The Website, you expressly
                            consent to having your personal
                            data transferred to, processed, and stored in the
                            United States or another
                            jurisdiction which may not offer the same level of
                            privacy protection as
                            those in the country where you reside or are a
                            citizen.
                        </p>
                        <p>
                            In connection with the operation of The Website,
                            EM does not intend to transfer
                            your personal information to
                            locations both inside the European
                            Economic Area.
                            The Website is complying with the
                            EU-U.S. Privacy Shield Framework
                            and the Swiss-U.S.
                            Privacy Shield Framework, as set forth by the U.S.
                            Department of Commerce
                            regarding the collection, use, and retention of
                            personal information
                            transferred from the European Union and/or
                            Switzerland, to the United
                            States. Under the Privacy Shield Framework, EM
                            is responsible for
                            the processing of personal data it receives and
                            subsequently transfers to
                            a third party acting as an agent on its behalf.
                            EM complies with
                            the Privacy Shield Principles for all onward
                            transfers of personal data
                            from the EU, including the onward transfer liability
                            provisions.

                            The Website is Certifying with the Department of
                            Commerce that it adheres to the Privacy
                            Shield Principles. If there is any conflict between
                            the terms in this
                            Notice and the Privacy Shield Principles, the
                            Privacy Shield Principles
                            shall govern. To learn more about the Privacy Shield
                            program, and to view
                            our certification, please visit <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>.
                        </p>
                        <p>
                            With respect to personal data received or
                            transferred pursuant to the
                            Privacy Shield Framework, The Website is subject to
                            the regulatory
                            enforcement powers of the U.S. Federal Trade
                            Commission.
                        </p>
                        <h3>
                            Dispute Resolution</h3>
                        <p>
                            For any complaints that cannot be resolved with
                            Us directly,
                            EM intends to utilize the <a
                            href="https://www.jamsadr.com/eu-us-privacy-shield">JAMS
                            Privacy Shield Program</a> in relation
                            to such unresolved complaints (as further described
                            in the Principles). As
                            further explained in the <a href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction">Privacy
                            Shield Framework Annex I</a>, a binding
                            arbitration option will also be made available to
                            you in order to address
                            residual complaints not resolved by any other means.

                            Please see our Terms for more information<Link
                            to={'terms'}>Terms of Service</Link>.
                        </p>
                        <h3>
                            Changes to this
                            Notice</h3>
                        <p>
                            It is our intention to keep all of our
                            Users aware of our privacy efforts.
                            However, changes to this policy may or may not spur
                            notifications to Users. It is the User's
                            responsibility to be aware of Our current policies.

                        </p>
                        <h3>Contacting Us</h3>
                        <p>
                            If you have any questions or comments about this
                            Notice, please contact us
                            at <a
                            href="mailto:support@experiencemaster.com">support@experiencemaster.com</a>.
                            For questions or comments outside of the
                            Privacy Notice, please contact us at <a
                            href="mailto:support@experiencemaster.com">support@experiencemaster.com</a>
                        </p>
                        <h1 className="title">Privacy Notice for California
                            Residents</h1>
                        <p>Effective date: December 31, 2019</p>
                        <p>
                            This Privacy Notice for California Residents
                            (“California Notice”)
                            supplements the Our Privacy Notice. It applies
                            solely to Our
                            Users, Invitees, and Viewers who live and work
                            in the State of California
                            (“California Residents”). We are expressing this
                            notice to comply with and promote the
                            California Consumer Privacy Act of 2019 and any
                            subsequent amendments
                            (“CCPA”).
                        </p>
                        <h3>Information
                            We May Collect Regarding California
                            Residents</h3>
                        <p>
                            We collect personal information through The Website
                            services. That information is:
                        </p>
                        <ul>
                            <li>A. Identifiers: real name; alias; email address;
                                internet protocol address.
                            </li>
                            <li>B. Personal information categories listed in
                                Cal. Civ. Code Sec. 1798.80(e): Real name; phone
                                number.
                            </li>
                            <li>C. Commercial information: Purchase records
                                regarding The Website.
                            </li>
                            <li>D. Internet or other similar network activity:
                                Data on User and Viewer interactions with The
                                Website.
                            </li>
                        </ul>
                        <p>
                            <strong>Our Sources are:</strong>
                        </p>
                        <ul>
                            <li>Directly Input from Users and Invitees.</li>
                            <li>Indirect analysis of
                                Users, Invitees, and Visitors.</li>
                            <li>From third parties that interact with us in
                                connection with the services we perform.
                            </li>
                        </ul>
                        <h3>
                            How California
                            Resident Personal Information May Be Used
                        </h3>
                        <p>We disclose Personal Information to:</p>
                        <ul>
                            <li>Fulfill its intended purpose.
                            </li>
                            <li>Provide Users and Invitees with
                                requested information.
                            </li>
                            <li>Provide alerts and other
                                notices concerning our services, or news.
                            </li>
                            <li>Enable financial transactions.
                            </li>
                            <li>To improve The Website.</li>
                            <li>For testing, research, analysis and service
                                development.
                            </li>
                            <li>As necessary or appropriate to protect
                                Ourselves and our Users.
                            </li>
                            <li>To respond to law enforcement requests and as
                                required by applicable law, court order, or
                                governmental regulations.
                            </li>
                            <li>In compliance with the CCPA.
                            </li>
                            <li>To evaluate or conduct a merger, divestiture,
                                restructuring, reorganization, dissolution, or
                                other sale or transfer of some or all of our
                                assets, whether as a going concern or as part of
                                bankruptcy, liquidation, or similar proceeding,
                                in which personal information held by Us
                                is among the assets transferred.
                            </li>
                            <li>The Website will not collect additional
                                categories of personal information and will
                                notify Users if there is an exception.
                            </li>
                        </ul>
                        <h3>Sharing
                            Personal Information</h3>
                        <p>
                            We use third-party processors which may require
                            User personal information to fulfill your contact
                            with Us.
                        </p>
                        <p>
                            We disclose your personal information for a business
                            purpose to the following categories of third
                            parties:
                        </p>
                        <ul>
                            <li>Service providers.</li>
                            <li>Third parties to whom you or your agents
                                authorize us to disclose your personal
                                information in connection with the services we
                                provide to you.
                            </li>
                            <li>Government representatives as required by law.
                            </li>
                        </ul>
                        <p>In the preceding twelve (12) months, we have not sold
                            any personal information.</p>
                        <h3>Access to
                            Specific Information and Data Portability Rights</h3>
                        <p>
                            You have the right to request that we disclose
                            certain information to you
                            about our collection and use of your personal
                            information over the past 12
                            months. Once we receive and confirm your verifiable
                            consumer request, we
                            will disclose to you:
                        </p>
                        <ul>
                            <li>The categories of personal information we
                                collected about you.
                            </li>
                            <li>The categories of sources for the personal
                                information we collected about you.
                            </li>
                            <li>Our business or commercial purpose for
                                collecting or selling that personal information.
                            </li>
                            <li>The categories of third parties with whom we
                                share that personal information.
                            </li>
                            <li>The specific pieces of personal information that
                                we have collected about you.
                            </li>
                        </ul>
                        <h3>Deletion Request
                            Rights</h3>
                        <p>
                            You have the right to request that we delete any of
                            your personal
                            information that we have collected from you and
                            retained, subject to
                            certain exceptions. Once we receive and confirm your
                            verifiable request,
                            we will delete (and direct our service providers to
                            delete) your personal
                            information from our records, unless an exception
                            applies.
                        </p>
                        <p>
                            We may deny your deletion request if retaining the
                            information is necessary for us or our service
                            providers to:
                        </p>
                        <ul>
                            <li>
                                Complete the transaction for which we collected
                                the personal
                                information, provide a service that you
                                requested, take actions
                                reasonably anticipated within the context of our
                                ongoing business
                                relationship with you, or otherwise perform our
                                contract with you.
                            </li>
                            <li>
                                Detect security incidents, protect against
                                malicious, deceptive,
                                fraudulent, or illegal activity, or prosecute
                                those responsible for such
                                activities.
                            </li>
                            <li>
                                Debug software to identify and repair errors
                                that impair existing intended functionality.
                            </li>
                            <li>
                                Comply with the California Electronic
                                Communications Privacy Act.
                            </li>
                            <li>
                                Enable solely internal uses that are reasonably
                                aligned with consumer
                                expectations based on your relationship with us.
                            </li>
                            <li>
                                Comply with a legal obligation.
                            </li>
                            <li>
                                Otherwise lawfully uses that information in
                                compatibility with the context in which you
                                provided it.
                            </li>
                        </ul>
                        <h3>Exercising Access,
                            Data Portability, and Deletion Rights</h3>
                        <p>
                            To exercise the access, data portability, and
                            deletion rights described
                            above, please submit a verifiable consumer request
                            to us by reaching out
                            to <a
                            href="mailto:support@experiencemaster.com">support@experiencemaster.com</a>.
                        </p>
                        <p>
                            Only you or a person registered with the California
                            Secretary of State
                            that you authorize to act on your behalf, may make a
                            verifiable consumer
                            request related to your personal information. You
                            may also make a
                            verifiable consumer request on behalf of your minor
                            child. You may only
                            make a verifiable California Resident request for
                            access or data
                            portability twice within a 12-month period.
                        </p>
                        <p>The request must:</p>
                        <ul>
                            <li>
                                Provide sufficiently detailed information to
                                allow EM to reasonably
                                verify that you are the person about whom we
                                collected personal
                                information or their authorized representative.
                            </li>
                            <li>
                                Include sufficient detail to allow us to
                                properly understand, evaluate, and respond to
                                it.
                            </li>
                            <li>
                                We cannot respond to your request or provide you
                                with personal information
                                if we cannot verify your identity or authority
                                to make the request and
                                confirm the personal information relates to you.
                            </li>
                            <li>
                                Making a verifiable consumer request does not
                                require you to create an account with us.
                            </li>
                            <li>
                                EM will only use personal information
                                provided in a verifiable
                                consumer request to verify the requestor's
                                identity or authority to make
                                the request.
                            </li>
                        </ul>
                        <h3>Response
                            Timing and Format</h3>
                        <p>
                            We will attempt to respond to California Resident
                            requests in as timely a
                            fashion as possible. In the event of delays over 60
                            days, we will inform
                            you of the reason and extension period in writing.
                            If you have an account
                            with us, we will deliver our written response to
                            that account. Any
                            disclosures we provide will only cover the 12-month
                            period preceding the
                            verifiable receipt of a California Resident’s
                            request. The response we
                            provide will explain the reasons we cannot comply
                            with a request, if
                            applicable.
                        </p>
                        <p>
                            We do not charge a fee to process or respond to your
                            verifiable consumer
                            request unless it is excessive, repetitive, or
                            manifestly unfounded. If
                            we determine that the request warrants a fee, we
                            will tell you why we made
                            that decision and provide you with a cost estimate
                            before fulfilling your
                            request.
                        </p>
                        <h3>Non-Discrimination</h3>
                        <p>
                            In accordance with the CCPA’s requirements, we will
                            not discriminate
                            against you for exercising any of your CCPA rights.
                        </p>
                        <h3>Changes to Our
                            Privacy Notice</h3>
                        <p>
                            We reserve the right to amend this privacy notice at
                            our discretion.
                        </p>
                        <h3>Contact
                            Information</h3>
                        <p>
                            If you have any questions or comments about this
                            notice, our Privacy
                            Statement, the ways in which we collect and use your
                            personal information,
                            your choices and rights regarding such use, or wish
                            to exercise your
                            rights under California law, please do not hesitate
                            to contact us at:
                            <a href="mailto:support@experiencemaster.com">support@experiencemaster.com</a>.
                        </p>
                    </div>
                </Container>


            </Container>

        </div>
    }
}

