import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";

class SearchResults extends Component {

    constructor(props) {
        super(props);
        this.state = {searchResults: []}
    }

    // componentDidMount() {
    //     this.setState({searchResults: this.props.searchResults})
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (this.state.searchResults !== this.props.seachResults) {
    //         this.setState({searchResults: this.props.searchResults})
    //     }
    // }

    render() {

        return (
            <div>
                <h1>Search Results</h1>
                <Container style={{textAlign: 'left', marginLeft:'15%'}}>
                    {this.props.searchResults.map((searchItem, index) => {
                        return <Card key={index} className={'skin-card'} style={{borderWidth: 0, marginTop: 0}}>
                            <Card.Body>
                                <Link to={'/contacts/contact/' + searchItem.contact.id} style={{fontSize: '1.5rem'}}>{searchItem.contact.name}</Link>
                                <div>{searchItem.type_name}</div>

                                <div dangerouslySetInnerHTML={{__html:searchItem.reference}} style={{width:'75%'}}/>
                                <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                                    <div style={{width: '33%', display: 'flex', flexDirection: 'column'}}>{searchItem.contact.origin_description}</div>
                                    <div style={{width: '35%', display: 'flex', flexDirection: 'column'}}>{searchItem.contact.profession_description}</div>
                                    <div style={{width: '33%', display: 'flex', flexDirection: 'column'}}>{searchItem.contact.industry_description}</div>
                                </div>
                            </Card.Body>
                            </Card>
                    })}
                {!this.props.searchResults.length > 0 && <div>No Results</div>}
                </Container>
            </div>
        )
    }
}

export default SearchResults;