import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";


export default class AddGuestModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            nameIsValid: false,
            nameValidationMessage: '',
            email: '',
            emailIsValid: false,
            emailValidationMessage: '',
            errors: {},
            submitted: false,
            isSaved: true
        };
        this.onNameChange = this.onNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.callback = this.callback.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onNameChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        this.setState({
            name: event.target.value,
            nameIsValid: isValid,
            nameValidationMessage: validationMessage,
            isSaved: false
        });
    }

    onFormItemChange(event) {
        let keyData = {};
        keyData[event.target.name] = event.target.value;
        this.setState(prevState => ({
            contact: {
                ...prevState.contact,
                ...keyData
            }
        }), () => {
            this.putContact()
        });
    }

    callback(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        this.props.callback({name: this.state.name, email: this.state.email})
    }

    onHide(event) {
        this.props.cancelCallback(event)
    }

    render() {
        return <Modal
            show={this.props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.onHide}

        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Contact
                </Modal.Title>
            </Modal.Header>
            <Modal.Body><Form onSubmit={this.callback}
                              validated={!this.state.isSaved}>
                <FormGroup>
                    <Form.Control type="text"
                                  id={'name'}
                                  name={'name'}
                                  placeholder="Guest Name Here"
                                  onChange={this.onNameChange}
                                  value={this.state.name}
                                  required
                                  isInvalid={!!this.state.errors.name}
                                  minLength={6}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.name}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.nameValidationMessage}
                    </Form.Control.Feedback>
                    <Form.Control type="email"
                                  id={'email'}
                                  name={'email'}
                                  placeholder="Your Email Address Here."
                                  value={this.state.email}
                                  onChange={this.onEmailChange}
                                  required
                                  isInvalid={!!this.state.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.email}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.emailValidationMessage}
                    </Form.Control.Feedback>
                </FormGroup>
                <Button type={'submit'}
                        disabled={this.state.isSaved}>Confirm</Button>
            </Form></Modal.Body>
        </Modal>
    }
}