import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import {Link} from "react-router-dom";
import GoogleLogins from "./GoogleLogins";


export default class FTUEModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,

            show: true,

            nameOne: '',
            nameTwo: '',
            nameThree: '',
            nameFour: '',
            nameFive: ''

        };
        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSubmitErrorCallback = this.onSubmitErrorCallback.bind(this);
        this.onDontShowAgain = this.onDontShowAgain.bind(this);
        this.isValid = this.isValid.bind(this);
    }

    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        // const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        // newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        // const isValidName = fieldName + 'IsValid';
        // newStates[isValidName] = isValid;
        // const validationMessageName = fieldName + 'ValidationMessage';
        // newStates[validationMessageName] = validationMessage;
        if (isValid) {
            this.setState(newStates, this.isValid);
        } else {
            this.setState(newStates);
        }
    }

    isValid() {
        const isValid = Boolean(this.state.nameOne && this.state.nameTwo && this.state.nameThree && this.state.nameFour && this.state.nameFive);
        this.setState({isValid: isValid})
    }


    onSubmitErrorCallback(data, status) {
        this.setState({
            submitted: false, isSaved: false, success: false, errors: data
        })
    }

    onSubmitCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState({
                show: false, success: true, isSaved: true, submitted: true
            },
            () => {
                this.onHide();
            })
    }

    onSubmit(event) {
        event.preventDefault();

        const saveFieldList = [
            this.state.nameOne,
            this.state.nameTwo,
            this.state.nameThree,
            this.state.nameFour,
            this.state.nameFive,
        ];
        saveFieldList.forEach((name) => {
            let body = {
                name: name,
                created_by: this.props.authenticator.account.user.id
            };
            this.props.authenticator.queuedPost(
                'contacts', body,
                this.onSubmitCallback,
                this.onSubmitErrorCallback)
        });
        this.setState({isSaved: true});
    }

    onHide() {
        this.props.authenticator.account.ftue = false;
        this.setState({show: false})
    }

    dontShowCallback(data) {

    }
    dontShowErrorCallback(data) {

    }

    onDontShowAgain(event) {
        const dontShow = event.currentTarget.checked;
        const accountId = this.props.authenticator.account.id;
        this.props.authenticator.queuedPut(
            'account',
            accountId,
            {id:accountId, ftue: dontShow === false},
            this.dontShowCallback,
            this.dontShowErrorCallback
        )
    }


    render() {
        return <Modal
            show={this.props.authenticator.account.ftue && this.state.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.onHide}
            className={'skin-modal'}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Welcome New User!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col align={'center'} md={12}>
                    <h4>Here are 3 options to help you get started.</h4>
                    </Col>
                </Row>
                <Row>
                    <Col align={'center'}>
                        <div>Quick Add 5 Contacts</div>
                        <Form onSubmit={this.onSubmit}
                              validated={!this.state.isSaved}>
                            <FormGroup>
                                <Form.Control type="text"
                                              placeholder={"Contact 1's Full Name"}
                                              id={'nameOne'}
                                              name={'nameOne'}
                                              onChange={this.onFieldChange}
                                              value={this.state.nameOne}
                                              required
                                              isInvalid={!!this.state.errors.nameOne}
                                              autoComplete="none"
                                />
                                <Form.Control type="text"
                                              placeholder={"Contact 2's Full Name"}
                                              id={'nameTwo'}
                                              name={'nameTwo'}
                                              onChange={this.onFieldChange}
                                              value={this.state.nameTwo}
                                              required
                                              isInvalid={!!this.state.errors.nameTwo}
                                              autoComplete="none"
                                />
                                <Form.Control type="text"
                                              placeholder={"Contact 3's Full Name"}
                                              id={'nameThree'}
                                              name={'nameThree'}
                                              onChange={this.onFieldChange}
                                              value={this.state.nameThree}
                                              required
                                              isInvalid={!!this.state.errors.nameThree}
                                              autoComplete="none"
                                />
                                <Form.Control type="text"
                                              placeholder={"Contact 4's Full Name"}
                                              id={'nameFour'}
                                              name={'nameFour'}
                                              onChange={this.onFieldChange}
                                              value={this.state.nameFour}
                                              required
                                              isInvalid={!!this.state.errors.nameFour}
                                              autoComplete="none"
                                />
                                <Form.Control type="text"
                                              placeholder={"Contact 5's Full Name"}
                                              id={'nameFive'}
                                              name={'nameFive'}
                                              onChange={this.onFieldChange}
                                              value={this.state.nameFive}
                                              required
                                              isInvalid={!!this.state.errors.nameFive}
                                              autoComplete="none"
                                />
                            </FormGroup>
                            {this.state.isValid &&
                            <Button type={'submit'}
                                    disabled={this.state.isSaved}>Save</Button>
                            }
                        </Form>
                    </Col>
                    <Col align={'center'}>
                        <p>Optional Bulk Import Methods</p>
                        <br/>
                        <p><Link to={'/import'}>Import from a CSV File</Link></p>
                        <br/>
                        <div>Sync with Google Contacts</div>
                        <GoogleLogins authenticator={this.props.authenticator}/>
                        <div>&nbsp;</div>
                        <div>
                            <Button as={'a'} href={'https://api-v1.experiencemaster.com/apple/step1/'}>Apple iCloud Contacts</Button>
                        </div>
                    </Col>
                    <Col align={'center'}>
                        <p>Helpful Videos</p>
                        <p><a href={'https://youtu.be/lcW4SBgYop4'}>Add A Contact</a></p>
                        <p><a href={'https://youtu.be/MLGadP-cYV4'}>Bulk Imports</a></p>
                        <p><a href={'https://youtu.be/3nNMsp7z_UU'}>Game Basics</a></p>
                        <p><a href={'https://youtu.be/SP_dhVHlWfE'}>Scores & Workflows</a></p>
                        <p><Link to={'/help'}>Click see more helpful videos.</Link></p>
                    </Col>

                </Row>


            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.onHide}>Close</Button>
                <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Don't Show Again." onChange={this.onDontShowAgain}/>
                </Form.Group>
            </Modal.Footer>
        </Modal>
    }
}