import React, {Component} from 'react'
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ContactStars from "./Stars";

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: null,
            saving: false
        };

        this.onFormItemChange = this.onFormItemChange.bind(this);
        this.putContact = this.putContact.bind(this);
        this.putContactCallback = this.putContactCallback.bind(this);
        this.autoSave = this.autoSave.bind(this);

    }

    componentDidMount() {
        this.setState({contact: this.props.contact})
    }


    isDirty(formValue, serverValue) {
        if (!formValue && !serverValue) {
            return false;
        }
        const cleaned = formValue.trim();
        return cleaned !== serverValue;
    }

    dirtyCheck(data) {
        // check for dirty but without trailing whitespace which DB removes.
        const contact = this.state.contact;

        let isDirty = false;
        let result = this.isDirty(contact.notes, data.notes);
        isDirty = isDirty || result;

        result = this.isDirty(contact.origin_description, data.origin_description);
        isDirty = isDirty || result;

        result = this.isDirty(contact.type_description, data.type_description);

        isDirty = isDirty || result;
        result = this.isDirty(contact.profession_description, data.profession_description);
        isDirty = isDirty || result;
        result = this.isDirty(contact.industry_description, data.industry_description);
        isDirty = isDirty || result;
        return isDirty
    }

    autoSave() {
        if (!this.state.saving) {
            this.setState({saving: true}, this.putContact);
        }
    }

    putContactCallback(data) {
        const isDirty = this.dirtyCheck(data);
        if (isDirty) {
            this.putContact()
        } else {
            this.setState({saving: false})
        }
    }

    putContact() {
        const contactId = this.state.contact.id;
        const contact = this.state.contact;
        const bodyDict = {
            name: contact.name,
            notes: contact.notes,
            status: contact.status,
            origin_description: contact.origin_description,
            type_description: contact.type_description,
            profession_description: contact.profession_description,
            industry_description: contact.industry_description,
            created_by: this.props.authenticator.account.user.id,
            modified_by: this.props.authenticator.account.user.id
        };
        this.props.authenticator.queuedPut('contacts', contactId,
            bodyDict, this.putContactCallback, null)
    }

    onFormItemChange(event) {
        let keyData = {};
        keyData[event.target.name] = event.target.value;
        this.setState(prevState => ({
            contact: {
                ...prevState.contact,
                ...keyData
            }
        }), () => {
            this.autoSave()
        });
    }

    render() {
        const contact = this.props.contact;
        return (<div className={'contact-form'}>
                {this.props.editMode &&
            <Form id={'contact-form-1'} name={'contact-form-1'}>
                {/*<Form.Row>*/}
                {/*    <Form.Label column>Status:</Form.Label>*/}
                {/*    <Col>*/}
                {/*        <SelectBox*/}
                {/*            itemList={library.status_types}*/}
                {/*            currentId={contact.status}*/}
                {/*            keyPrefix='status'*/}
                {/*            onChange={this.onFormItemChange}*/}
                {/*        />*/}
                {/*    </Col>*/}
                {/*</Form.Row>*/}

                <Form.Row>
                    <Form.Control as="textarea" rows="5"
                                  placeholder="Contact Notes"
                                  defaultValue={contact.notes}
                                  size="sm"
                                  name="notes"
                                  id="notes"
                                  onChange={this.onFormItemChange}
                    />
                </Form.Row>
                <Form.Row>
                    <Form.Label column>Origin:
                    </Form.Label>
                    <Form.Control
                        required
                        type="text"
                        id="origin_description"
                        name="origin_description"
                        placeholder="Origin Description"
                        defaultValue={contact.origin_description}
                        onChange={this.onFormItemChange}
                    />
                </Form.Row>
                <Form.Row>
                    <Form.Label column>Type:</Form.Label>

                    <Form.Control
                        required
                        type="text"
                        id="type_description"
                        name="type_description"
                        placeholder="Friend, Family, Intern, etc."
                        defaultValue={contact.type_description}
                        onChange={this.onFormItemChange}
                    />
                </Form.Row>
                <Form.Row as={Row}>
                    <Form.Label column>Profession:</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        id="profession_description"
                        name="profession_description"
                        placeholder="Programmer, CEO, Secretary, Coach, etc."
                        defaultValue={contact.profession_description}
                        onChange={this.onFormItemChange}
                    />
                </Form.Row>
                <Form.Row>
                    <Form.Label column>Industry:</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        id="industry_description"
                        name="industry_description"
                        placeholder="Entertainment, Manufacturing, HR, etc.."
                        defaultValue={contact.industry_description}
                        onChange={this.onFormItemChange}
                    /></Form.Row>
                {this.state.saving && <div>Saving...</div>}
            </Form>
                }
            {!this.props.editMode && this.state.contact &&
                <div align={"left"}>
                    <ContactStars contact={contact} experienceMax={this.state.contact.experience_max}/>
                    <div>{this.state.contact.notes}</div>
                    <div>{this.state.contact.origin_description}</div>
                    <div>{this.state.contact.type_description}</div>
                    <div>{this.state.contact.profession_description}</div>
                    <div>{this.state.contact.industry_description}</div>
                </div>
            }
            </div>
        )
    }

}

export default ContactForm;
