import React, {Component} from "react";
import {Redirect} from "react-router";
import "./advisors.css"


class VariableAdvisor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            advisor: null
        };
        // clickGroup
        this.clickCard = this.clickCard.bind(this);
        this.onClickSolButton = this.onClickSolButton.bind(this);
        this.onClickSolButtonCallback = this.onClickSolButtonCallback.bind(this);
        this.getAdvisor = this.getAdvisor.bind(this);
        this.getAdvisorCallback = this.getAdvisorCallback.bind(this);
    }

    componentDidMount() {
        this.getAdvisor();
    }


    getAdvisorCallback(data) {
        this.setState({advisor: data});
    }


    getAdvisor() {
        this.props.authenticator.queuedGet(
            'variable_advisor',
            this.getAdvisorCallback,
            () => {},
            this.props.advisorKey
        );
    }

    clickCard(event) {
        const contactId = event.currentTarget.dataset.contactId;
        this.setState({redirect: '/contacts/contact/' + contactId.toString()})
    }

    onClickSolButtonCallback(event) {
        this.getAdvisor();
        this.props.callback(event);
    }

    onClickSolButton(event) {
        const status = event.currentTarget.dataset.status;
        const contactId = event.currentTarget.dataset.contactId;
        this.props.authenticator.queuedPut(
            'experiencemaster',
            contactId,
            {
                id: contactId,
                status: status
            }, this.onClickSolButtonCallback);
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        if (!this.state.advisor) {
            return <div id={'no-advisor-data'}/>
        }

        if (!this.state.advisor.contact) {
            return <div id={'no-advisor-contact'}/>
        }
        const advisor = this.state.advisor;
        const advisorKey = advisor.advisor_key;
        const contact = advisor.contact;

        const advisorIconClassDict = {
            'brand_value': 'square',
            'personal_value': 'circle',
            'inclusion': 'triangle',
            'affinity': 'letter-x',
        };
        const advisorIconClass = advisorIconClassDict[advisorKey];

        // const advisorCardClassDict = {
        //     'brand_value': 'advisor-card brand-value-advisor',
        //     'personal_value': 'advisor-card personal-value-advisor',
        //     'inclusion': 'advisor-card inclusion-advisor',
        //     'affinity': 'advisor-card affinity-advisor',
        // };
        // const advisorCardClass = advisorCardClassDict[advisorKey];


        const statusClassDict = {
            3: 'never-faded',
            12: 'first-contact-faded',
            27: 'thank-you-faded',
            34: 'sooner-faded',
            11: 'later-faded',
            25: 'sleep-faded'
        };

        const statusClass = statusClassDict[contact.status];

        return <div
                    className={'advisor-contact-box'}
                    data-contact-id={contact.id}
                >
                    <div className={'advisor-button'}>
                        <div className={advisorIconClass}>&nbsp;</div>
                    </div>
                    <div data-contact-name={contact.name}
                         data-contact-id={contact.id}
                         onClick={this.onClickSolButton}
                         data-status={34}
                         className="advisor-button sooner"
                    >S
                    </div>
                    <div data-contact-name={contact.name}
                         data-contact-id={contact.id}
                         onClick={this.clickCard}
                         className={'advisor-main-button ' + statusClass}
                    >{contact.name}</div>
                    <div data-contact-name={contact.name}
                         data-contact-id={contact.id}
                         onClick={this.onClickSolButton}
                         data-status={11}
                         className="advisor-button later"
                    >L
                    </div>
                    <div className={'advisor-button'}>&nbsp;</div>
                </div>

    }

}

export default class VariableAdvisors extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: null
        };
        // clickGroup
        this.clickCard = this.clickCard.bind(this);
        this.onClickSolButton = this.onClickSolButton.bind(this);
        this.onClickSolButtonCallback = this.onClickSolButtonCallback.bind(this);
    }

    clickCard(event) {
        const contactId = event.currentTarget.dataset.contactId;
        this.setState({redirect: '/contacts/contact/' + contactId.toString()})
    }

    onClickSolButtonCallback(event) {
        this.props.callback(event)
    }

    onClickSolButton(event) {
        const status = event.currentTarget.dataset.status;
        const contactId = event.currentTarget.dataset.contactId;
        this.props.authenticator.queuedPut(
            'experiencemaster',
            contactId,
            {
                id: contactId,
                status: status
            }, this.onClickSolButtonCallback);
    }

    render() {
        return <div className={'skin-card'}>
            <div className={'skin-card-header'}>
                Advisors
            </div>
            {this.props.authenticator.account.contact_count < 6 ?
            <div>The AIs need to see at least 5 scored contacts before they will help.</div>
                :
                <>
                    <VariableAdvisor
                        authenticator={this.props.authenticator}
                        advisorKey={'brand_value'}
                        advisorName={'Brand Value'}
                    />
                    <VariableAdvisor
                        authenticator={this.props.authenticator}
                        advisorKey={'personal_value'}
                        advisorName={'Personal Value'}
                    />
                    <VariableAdvisor
                        authenticator={this.props.authenticator}
                        advisorKey={'inclusion'}
                        advisorName={'Inclusion'}
                    />
                    <VariableAdvisor
                        authenticator={this.props.authenticator}
                        advisorKey={'affinity'}
                        advisorName={'Affinity'}
                    />
                </>}
        </div>
    }

}