import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import DayPicker from 'react-day-picker';


function getDates(startDate, stopDate) {
    if (startDate && stopDate) {
        let dateArray = [];
        dateArray.push({from:startDate, to:stopDate});
        return dateArray;
    }
}

export default class EventTypeDateRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: null,
            indefinite: false,
            rollingDays: '',
            startDate: '',
            endDate: '',

            selectedDateRange: null,

            errors: {},
            submitted: false,

            showEditModal: false,
            selectedRange: null
        };
        this._onSubmit = this._onSubmit.bind(this);
        this._onIndefiniteChange = this._onIndefiniteChange.bind(this);
        this._onRollingDaysChange = this._onRollingDaysChange.bind(this);
        this._onStartDateChange = this._onStartDateChange.bind(this);
        this._onEndDateChange = this._onEndDateChange.bind(this);
        this._onStartDateSelect = this._onStartDateSelect.bind(this);
        this._onEndDateSelect = this._onEndDateSelect.bind(this);
        this._onSubmitCallback = this._onSubmitCallback.bind(this);
        this._onSubmitErrorCallback = this._onSubmitErrorCallback.bind(this);
        this._handleDayClick = this._handleDayClick.bind(this);
        this._selectedDays = this._selectedDays.bind(this);
        this._clickOpenEditModal = this._clickOpenEditModal.bind(this);
        this._closeEditModal = this._closeEditModal.bind(this);
        this._defaultSelected = this._defaultSelected.bind(this);
        this._onChange = this._onChange.bind(this);
        this._setDateRangeValues = this._setDateRangeValues.bind(this);
        this._reset = this._reset.bind(this);
    }

    componentDidMount() {
        if (!!this.props.eventType && !!this.props.eventType.eventtypedaterange_set) {
            const dateRange= this.props.eventType.eventtypedaterange_set[0];
            this.setState({
                    dateRange: dateRange,
                    indefinite: dateRange.indefinite,
                    rollingDays: dateRange.rolling_days,
                    startDate: dateRange.start_date ? new Date(dateRange.start_date) : null,
                    endDate: dateRange.end_date ? new Date(dateRange.end_date) : null,
                }, this._defaultSelected)
        }
    }

    _onSubmitCallback(data) {
        if (!!!data) {return;}
        this.setState({submitted: true, dateRange: data});
        this._closeEditModal();
        this.props.callback(data);
    }

    _onSubmitErrorCallback(data, status) {
        this.setState({submitted: false, errors: data})
    }

    _reset() {
        this.setState({
            indefinite: this.state.dateRange.indefinite,
            rollingDays: this.state.dateRange.rolling_days,
            startDate: this.state.dateRange.start_date ? new Date(this.state.dateRange.start_date) : null,
            endDate: this.state.dateRange.end_date ? new Date(this.state.dateRange.end_date) : null,
        })
    }

    _formatDate(datetime) {
        return new Date(datetime.toDateString()).toISOString().split('T')[0]
    }

    _setDateRangeValues() {
        // use case
        let dateRange;
        const selectedRange = parseInt(this.state.selectedRange);
        if (selectedRange === 1) {
            dateRange = {
                indefinite: true,
                rolling_days: null,
                start_date: null,
                end_date: null,
            };
        }
        if (selectedRange === 2) {
            dateRange = {
                indefinite: false,
                rolling_days: this.state.rollingDays,
                start_date: null,
                end_date: null,
            };
        }
        if (selectedRange === 3) {
            let startDate, endDate;
            if (this.state.startDate > this.state.endDate) {
                startDate = this.state.endDate;
                endDate = this.state.startDate;
            } else {
                startDate = this.state.startDate;
                endDate = this.state.endDate;
            }
            dateRange = {
                indefinite: false,
                rolling_days: null,
                start_date: this._formatDate(startDate),
                end_date: this._formatDate(endDate),
            };
        }
        this.setState({...dateRange})
        return dateRange
    }

    _onSubmit(event) {
        event.preventDefault();
        const dateRange = this._setDateRangeValues();
        let data = {
            ...dateRange,
            event_type: this.props.eventType.id
        };
        let accessPoint = 'event_type_date_ranges';
        data.id = this.state.dateRange.id;
        this.props.authenticator.queuedPut(
            accessPoint,
            data.id,
            data,
            this._onSubmitCallback,
            this._onSubmitErrorCallback)
    }

    _onIndefiniteChange(event) {
        this.setState({indefinite: event.target.value})
    }

    _onRollingDaysChange(event) {
        this.setState({rollingDays: event.target.value})
    }

    _onStartDateChange(event) {
        this.setState({startDate: event.target.value})
    }

    _onEndDateChange(event) {
        this.setState({endDate: event.target.value})
    }

    _onStartDateSelect(day) {
        this.setState({startDate: day})
    }

    _onEndDateSelect(day) {
        this.setState({endDate: day})
    }

    _handleDayClick(day) {
        if (this.state.startDate) {
            this.setState({endDate: day})
        } else {
            this.setState({startDate: day})
        }

        // const range = DateUtils.addDayToRange(day, this.state.selectedDateRange);
        // const tomorrow = day.setDate(day.getDate() + 1);
        // this.setState({selectedDateRange: [day, tomorrow]});
    }

    _selectedDays() {
        if (!this.state.startDate) {
            return []
        }
        if (!this.state.endDate) {
            return [this.state.startDate,]
        }
        const dates = getDates(this.state.startDate, this.state.endDate);
        return dates;
    }

    _clickOpenEditModal(event) {
        this.setState({showEditModal: true})
    }

    _closeEditModal(event) {
        this._reset()
        this.setState({showEditModal: false})
    }

    _defaultSelected() {
        let defaultValue;
        if (this.state.indefinite) {
            defaultValue = 1
        }
        if (this.state.rollingDays) {
            defaultValue = 2
        }
        if (this.state.startDate) {
            defaultValue = 3
        }
        this.setState({selectedRange: defaultValue});
        return defaultValue;
    }

    _onChange(event) {
        const value = parseInt(event.target.value);
        if (value === 2 && !this.state.rollingDays) {
            this.setState({rollingDays: 15});
        }
        this.setState({selectedRange: value});
    }

    render() {
        if (!this.state.dateRange) {
            return <div>Loading...</div>
        }

        const modifiers = { start: this.state.startDate,
                            end: this.state.endDate };

        return (
            <Container>
                {!this.state.showEditModal &&
            <p>
                {this.state.indefinite &&
                    <>Runs Indefinitely.</>
                }
                {this.state.rollingDays &&
                    <>Runs {this.state.rollingDays} Days Ahead.</>
                }
                {this.state.startDate && this.state.endDate &&
                    <>Runs from {this.state.startDate.toLocaleDateString()} to {this.state.endDate.toLocaleDateString()}.</>
                }
                &nbsp;
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={this._clickOpenEditModal}>
                    Change
                </Button>
            </p>}
                <Modal
                    show={this.state.showEditModal}
                    size="sm"
                    aria-labelledby="date-range-modal-title-vcenter"
                    centered
                    onHide={this._closeEditModal}
                    className={'skin-modal'}
                >
                    <Modal.Header closeButton>Event Type Date Range</Modal.Header>
                    <Modal.Body>
                        {this.state.errors && this.state.errors.detail &&
                        <Alert
                            variant={'danger'}>{this.state.errors.detail}</Alert>}
                        <Row>
                            <Form.Control as="select"
                                          value={this.state.selectedRange}
                                          onChange={this._onChange}
                            >
                                <option value={1}>
                                    Indefinitely.
                                </option>
                                <option value={2}>
                                    An ongoing number days ahead.
                                </option>
                                <option value={3}>
                                    Over a specific Date Range.
                                </option>
                            </Form.Control>
                        </Row>
                            <Row>
                                {parseInt(this.state.selectedRange) === 3 &&
                                <Col>
                                    <DayPicker
                                        numberOfMonths={1}
                                        onDayClick={this._handleDayClick}
                                        selectedDays={this._selectedDays()}
                                        modifiers={modifiers}
                                        todayButton="Go to Today"
                                        fixedWeeks
                                    />
                                </Col>
                                }
                                {parseInt(this.state.selectedRange) === 1 &&
                                    <Col>
                                        Will run Indefinitely.
                                    </Col>
                                }
                                {parseInt(this.state.selectedRange) === 2 &&
                                <Col>
                                    <Form>
                                        <FormGroup>

                                            <Form.Label column={'rollingDays'}>
                                                Event Type Duration
                                            </Form.Label>
                                            <Form.Control type="number"
                                                          id={'rollingDays'}
                                                          name={'rollingDays'}
                                                          placeholder="Rolling Days"
                                                          value={this.state.rollingDays}
                                                          onChange={this._onRollingDaysChange}
                                                          isInvalid={!!this.state.errors.rolling_days}
                                                          min="1" max="480"
                                                          step="1"
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">
                                                {this.state.errors.rolling_days}
                                            </Form.Control.Feedback>
                                        </FormGroup>
                                    </Form>
                                </Col>
                                }
                            </Row>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button type={'submit'} block onClick={this._onSubmit}>Submit</Button>
                        <Button variant="secondary" onClick={this._closeEditModal}>Cancel</Button>
                    </Modal.Footer>
                </Modal>


            </Container>
        )
    }
}