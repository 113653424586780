import PropTypes from "prop-types";
import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import {Redirect} from "react-router";
import {Link} from "react-router-dom";
import LoginForm from "./LoginForm";

class RegistrationResponse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            success: false,
            errorMessage: null,
            redirect: false
        };
        this.submitToken = this.submitToken.bind(this)
        this.submitTokenCallback = this.submitTokenCallback.bind(this)
        this.submitTokenErrorCallback = this.submitTokenErrorCallback.bind(this)
        this.onLogin = this.onLogin.bind(this)
    }

    componentDidMount() {
        const {token} = this.props.match.params;
        this.submitToken(token)
    }

    submitTokenErrorCallback(data, status) {
        this.setState({errorMessage: data.detail})
    }


    submitTokenCallback(data) {
        if (!!!data) {
            this.setState({success: false})
            return;
        }

        if (!!data && data.detail) {
            this.setState({success: false, errorMessage: data.detail})
            return;
        }
        this.setState({success: true})
    }

    submitToken(token) {
        this.props.authenticator.queuedGet(
            'user_registration',
            this.submitTokenCallback,
            this.submitTokenErrorCallback,
            token)
    }

    onLogin(event, loginFormState) {
        const username = loginFormState.username;
        const password = loginFormState.password;
        this.props.authenticator.login(username, password)
        this.setState({redirect: true})
    }

    render() {
        // TODO: this could redirect to a FTUE....
        if (this.state.redirect) {
            return <Redirect to={'/'} />
        }

        if (this.state.success) {
            return (
                <Container>
                <h1>Account Confirmed</h1>
                <p>Please Log In</p>
                    <LoginForm authenticator={this.props.authenticator}
                               onLogin={this.onLogin}/>
                </Container>
            )
        }
        return (<div>RegistrationFailure - {this.state.errorMessage}
            <Link to="/">Home</Link></div>
        )
    }
}

RegistrationResponse.propTypes = {
    authenticator: PropTypes.object.isRequired
};

export default RegistrationResponse;
