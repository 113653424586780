import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import RetailMenu from "./RetailMenu";

export default class About extends Component {

    render() {
        return <div style={{fontFamily: ['Dosis', 'serif']}}>
            <RetailMenu authenticator={this.props.authenticator}/>
            <Container>
                <h3>Experience Master the App</h3>
                <p>This app was created by a pair of Silicon Valley honed
                    Entrepreneurs to promote heathy relationship building,
                    and hopefully reduce loneliness.</p>
                <div>
                    <div>Experience Master is a division of Fossa Tracks, Inc., an American owned and operated company.</div>
                    <div>Fossa Tracks, Inc.</div>
                    <div>1925 Palomar Oaks Way, Suite 107</div>
                    <div>Carlsbad, CA 92008</div>
                    <div><a href={'mailto:michael@experiencemaster.com'}>michael@experiencemaster.com</a></div>
                </div>
                <p>Read our book, Experience Master the Book, on creating and maintaining Agile teams.<br/>
                    <a href={'https://read.amazon.com/kp/embed?asin=B06XHTNM18&preview=newtab&linkCode=kpe&ref_=cm_sw_r_kb_dp_b3GmEb57PRGG2'}>Experience Master the Book</a>
                </p>
            </Container>

        </div>
    }
}

