import moment from "moment-timezone";
import React, {Component} from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import {Link, Redirect} from "react-router-dom";
import {AssignedQuest} from "./AssignedQuest";
import CoachShareModal from "./CoachShareModal";
import ExperienceGraph from "./ExperienceGraph";
import {SubscriptionCheck} from "./Subscriptions";
import {CoachCard} from "./SuggestionCards";


export default class Learner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            learner: null,
            isCoach: false,
            currentNote: '',
            currentConnection: null,
            selectedContact: null,
            showShareModal: false,
            availableQuests: [],
            selectedQuest: null,
            daysToComplete: ''
        };
        this.learnerCallback = this.learnerCallback.bind(this);
        this.getLearner = this.getLearner.bind(this);
        this.addSharedNoteCallback = this.addSharedNoteCallback.bind(this);
        this.addSharedNote = this.addSharedNote.bind(this);
        this.clickShareNote = this.clickShareNote.bind(this);
        this.showShareModal = this.showShareModal.bind(this);
        this.hideShareModalCallback = this.hideShareModalCallback.bind(this);
        this.getAvailableQuests = this.getAvailableQuests.bind(this);
        this.getAvailableQuestsCallback = this.getAvailableQuestsCallback.bind(this);
        this.onDaysToComplete = this.onDaysToComplete.bind(this);
        this.postQuestSelectedCallback = this.postQuestSelectedCallback.bind(this);
        this.onQuestSelect = this.onQuestSelect.bind(this);
        this.onChangeQuestSubmit = this.onChangeQuestSubmit.bind(this);
    }

    componentDidMount() {
        this.getLearner();
        this.getAvailableQuests();
    }

    getLearner() {
        this.setState({showShareModal: false});
        const learnerId = this.props.match.params.learnerId;
        this.props.authenticator.queuedGet('learner', this.learnerCallback, null, learnerId)
    }

    learnerCallback(data) {
        const isCoach = data.coach.user_id === this.props.authenticator.account.user.id;
        this.setState({learner: data, isCoach: isCoach})
    }

    addSharedNoteCallback(data) {
        this.setState({currentNote: ''});
        this.getLearner();
    }

    addSharedNote() {
        if (!this.state.currentNote) {
            return
        }

        const body = {
            connection: this.state.currentConnection.id,
            note: this.state.currentNote
        };

        this.props.authenticator.queuedPost(
            'coach/shared/notes',
            body,
            this.addSharedNoteCallback
        );
        this.setState({note: null})
    }


    clickShareNote(event) {
        event.preventDefault();
        this.addSharedNote();
    }

    onContactSelect(row) {
        let contact;
        if (this.state.isCoach) {
            contact = row.coach_contact
        }
        else {
            contact = row.learner_contact.id
        }
        this.setState({selectedContact: contact});
    };


    getColumns() {
        return [{
            dataField: 'learner_contact.name',
            text: 'Name',
            sort: true,
            align: 'left',
            filter: textFilter(),
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.onContactSelect(row)
                }
            }
        }, {
            dataField: 'learner_contact.status',
            text: 'Status',
            sort: true,
            filter: textFilter(),
            formatter: (cell, row) => {
                return cell ? this.props.authenticator.library.status_type_dict[cell] : ''
            },
        }, {
            dataField: 'learner_contact.last_viewed',
            text: 'Last Viewed',
            sort: true,
            type: 'date',
            formatter: (cell, row) => {
                return cell ? moment(cell).fromNow() : 'Never'
            },
        }, {
            dataField: 'learner_contact.last_contact',
            text: 'Last Contact',
            sort: true,
            type: 'date',
            formatter: (cell, row) => {
                return cell ? moment(cell).fromNow() : 'Never'
            },
            editable: false
        }, {
            dataField: 'created',
            text: 'Shared',
            sort: true,
            type: 'date',
            formatter: (cell, row) => {
                return cell ? moment(cell).fromNow() : 'Never'
            },
            editable: false
        }, {
            dataField: 'sharedconnectionnote_set.length',
            text: 'Notes'
        }
        ];
    }

    expandRow(row) {
        return <div>

            <Table striped hover size="sm">
                <tbody>
                {row.sharedconnectionnote_set.map((note, index, original) => (
                    <tr key={index}>
                        <td>{note.created_by === this.props.authenticator.account.user.id ? 'You Said: ' : 'They Said: '}</td>
                        <td colSpan={2} className={'text-left'}>{note.note}</td>
                        <td>{moment(note.modified).fromNow()}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <Form>
                <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Add Note"
                    onChange={(event) => {
                        this.setState({currentConnection: row, currentNote: event.currentTarget.value})
                    }}
                    value={this.state.currentNote}
                />
                <Button type={'submit'}
                        onClick={this.clickShareNote}>Share Note</Button>
            </Form>

        </div>

    }

    showShareModal() {
        this.setState({showShareModal: true})
    }
    hideShareModalCallback() {
        this.setState({showShareModal: false})
    }

    getAvailableQuestsCallback(responseData) {
        this.setState({
            availableQuests: responseData,
            loading: false
        })
    }

    getAvailableQuests(event) {
        this.setState({loading: true});
        this.props.authenticator.queuedGet('quests',
            this.getAvailableQuestsCallback, null);
    }

    onDaysToComplete(event) {
        const days = event.currentTarget.value;
        this.setState({daysToComplete: days})
    }

    onQuestSelect(event) {
        const selectedQuest = event.currentTarget.value;
        this.setState({selectedQuest: parseInt(selectedQuest)})
    }

    postQuestSelectedCallback(data) {
        this.setState({learner: null}, this.getLearner);
    }

    onChangeQuestSubmit(event) {
        event.preventDefault();
        const data = {
            quest: this.state.selectedQuest,
            user: this.state.learner.user_id};
        if (this.state.daysToComplete && this.state.daysToComplete > 0) {
            data['days_to_complete'] = this.state.daysToComplete
        }
        this.props.authenticator.queuedPost(
            'quest',
            data,
            this.postQuestSelectedCallback
        )
    }

    render() {
        if (!this.state.learner) {
            return <>Loading...</>
        }
        if (this.state.selectedContact) {
            return <Redirect
                to={'/contacts/contact/' + this.state.selectedContact}/>
        }
        const learner = this.state.learner;
        const isCoach = this.state.isCoach;
        const coach = this.state.learner.coach;

        return <>
            <SubscriptionCheck authenticator={this.props.authenticator} minimum={'pro'}/>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <AssignedQuest authenticator={this.props.authenticator} learner={learner}/>
                        {/*    <QuestCard*/}
                        {/*    cardId={'main-quest'}*/}
                        {/*    questName={'Coach Challenge'}*/}
                        {/*    questTitle={'Add 5 Contacts'}*/}
                        {/*    questText={'You have added X Contacts.'}*/}
                        {/*    emojiSymbol={'📅'}*/}
                        {/*    authenticator={this.props.authenticator}*/}
                        {/*    callback={this.cardCallback}*/}
                        {/*/>*/}
                            <div>Select Current Quest</div>
                            <Form onSubmit={this.onChangeQuestSubmit}>
                                <Form.Control as="select" onChange={this.onQuestSelect}>
                                    <option></option>
                                    {this.state.availableQuests.map((quest, index) => (<option key={index} value={quest.id}>{quest.name}</option>))}
                                </Form.Control>
                                <Form.Control type="text"
                                     id={'days_to_complete'}
                                     name={'days_to_complete'}
                                     placeholder="(Optional) Days To Complete"
                                     value={this.state.daysToComplete}
                                     onChange={this.onDaysToComplete}
                                />
                                {this.state.selectedQuest && <Button type={'submit'}>Change Quest</Button>}
                            </Form>
                        </div>
                    </Col>
                    <Col>
                        <Card className={'experience-master-card'}>
                            <Card.Body>
                                <Card.Header>Learner</Card.Header>
                                {isCoach &&
                                <Card.Title
                                    onClick={this.clickCard}>
                                    {learner.learner_contact ?
                                    <Link
                                        to={'/contacts/contact/' + learner.learner_contact}>
                                        {learner.name}</Link> :
                                        <div>
                                            <div>{learner.name}</div>
                                            <a href={'mailto://' + learner.email}>{learner.email}</a>
                                        </div>}
                                </Card.Title>}
                                {!isCoach &&
                                <Card.Title
                                    onClick={this.clickCard}>
                                    {learner.name}
                                </Card.Title>}

                                <Card.Body>
                                    <h4>Level {learner.level}</h4>
                                    <div>Coach</div>
                                    {isCoach && <div>{coach.name}{isCoach &&
                                    <span> (You)</span>}</div>}
                                    {!isCoach && <div>
                                        <Link
                                            to={'/contacts/contact/' + learner.coach_contact}>{coach.name}</Link>
                                    </div>}

                                </Card.Body>
                                <Card.Footer as={'div'}
                                             className={'d-flex flex-column'}>

                                    {isCoach && learner.learner_contact &&
                                    <Button onClick={this.showShareModal}>Share A Contact</Button>}
                                </Card.Footer>
                            </Card.Body>
                        </Card></Col>
                    <Col><CoachCard
                        editMode={isCoach}
                        coachCard={learner.coach_card}
                        emojiSymbol={'📅'}
                        authenticator={this.props.authenticator}
                        callback={this.cardCallback}
                    />

                        <ExperienceGraph data={learner.experience} />
                    </Col>
                </Row>
                <h3>Shared Contacts</h3>
                <BootstrapTable keyField='id'
                                classes={'data-table-container'}
                                data={learner.sharedconnection_set}
                                columns={this.getColumns()}
                                striped={true}
                                hover={true}
                                bootstrap4={true}
                                filter={filterFactory()}
                                expandRow={{
                                    renderer: row => (this.expandRow(row))
                                    // showExpandColumn: true,
                                    // expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
                                    //     return expandable ? expanded ? <div>-</div> : <div>+</div> : <></>
                                    // },
                                    // expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                                    //     return isAnyExpands ? <div>-</div> : <div>+</div>
                                    // }
                                }}
                />


            </Container>
            {isCoach && this.state.showShareModal &&
            <CoachShareModal
                authenticator={this.props.authenticator}
                contact={learner.learner_contact}
                show={this.state.showShareModal}
                callback={this.getLearner}
                hideCallback={this.hideShareModalCallback}
            />}
        </>

    }
}
