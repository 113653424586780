import React, {Component} from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import './ScoreSlider.css'

class ScoreSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactId: 0,
            scoreId: 0,
            scoreEntry: {},
            fieldName: '',
            scoreItem: {},
            scoreItemType: {},
            min: 0,
            max: 10,
            fieldValue: -1,
            step: 1
        };
        this.changeEntry = this.changeEntry.bind(this);
        this.saveRecord = this.saveRecord.bind(this);
        this.saveRecordCallback = this.saveRecordCallback.bind(this);

        this.onPointerUp = this.onPointerUp.bind(this);
        this.getSliderClass = this.getSliderClass.bind(this);
    }

    changeEntry(event) {
        this.setState({fieldValue:event.target.value});
    }

    componentDidMount() {
        this.setState({
            fieldName: this.props.scoreEntry.item.name,
            fieldValue: this.props.scoreEntry.value,
            min: this.props.scoreEntry.item.type.min_value,
            max: this.props.scoreEntry.item.type.max_value,
            step: this.props.scoreEntry.item.type.step
        });
    }

    saveRecordCallback(data) {
        this.props.callBack(this.state.score, data)
    }

    saveRecord() {
        const body = {
            id: this.props.scoreEntry.id,
            contact: this.props.contact.id,
            item: this.props.scoreEntry.item.id,
            value: this.state.fieldValue,
            score: this.props.score.id,
        };
        this.props.authenticator.queuedPut(
            'score_entries',
            this.props.scoreEntry.id,
            body,
            this.saveRecordCallback
        );
    }

    onPointerUp(event) {
        this.saveRecord()
    }

    getSliderClass() {
        let output;
        switch (this.state.fieldName) {
            case 'Brand Value':
                output = 'score-slider brand-value';
                break;
            case 'Personal Value':
                output = 'score-slider personal-value';
                break;
            case 'Inclusion':
                output = 'score-slider inclusion';
                break;
            case 'Affinity':
                output = 'score-slider affinity';
                break;
            default :
                output = 'score-slider';

        }
        return output;
    }

    render() {

        const sliderClass = this.getSliderClass();

        return (
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{this.state.fieldValue}</Tooltip>}>
            <input
                id="typeinp"
                type="range"
                className={sliderClass}
                min={this.state.min}
                max={this.state.max}
                value={this.state.fieldValue}
                onChange={this.changeEntry}
                onPointerUp={this.onPointerUp}
                onTouchEnd={this.onPointerUp}
                step={this.state.step}/>
            </OverlayTrigger>
        )
    }
}

export default ScoreSlider;
