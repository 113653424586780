import React, {Component} from "react";

import {VictoryAxis, VictoryChart, VictoryLine} from 'victory';

//
// class AdvisorScorePie extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             brandValue: null,
//             personalValue: null,
//             inclusion: null,
//             affinity: null,
//         };
//         this.pieItem = this.pieItem.bind(this)
//     }
//
//     pieItem(scoreEntry, index, originalArray) {
//         let color = null;
//         switch (index) {
//             case 0:
//                 color = this.state.brandValue || '#525174';
//                 break;
//             case 1:
//                 color = this.state.personalValue || '#348AA7';
//                 break;
//             case 2:
//                 color = this.state.inclusion || '#5DD39E';
//                 break;
//             case 3:
//                 color = this.state.affinity || '#BCE784';
//                 break;
//             default:
//                 color = 'red'
//         }
//
//         const ringWidth = Math.round(100 / (originalArray.length + 1));
//         const centerAreaRadius = ringWidth;
//         const innerRadius = centerAreaRadius + ringWidth * index;
//         const radius = innerRadius + ringWidth;
//
//         const value = scoreEntry.value;
//
//         const data = [
//             {
//                 x: 1,
//                 y: parseInt(value)
//             },
//             {
//                 x: 2,
//                 y: 100 - parseInt(value)
//             }
//         ];
//         return (
//             <VictoryPie
//                 key={'pieItem-' + index}
//                 standalone={false}
//                 width={100} height={100}
//                 data={data}
//                 innerRadius={innerRadius}
//                 radius={radius}
//                 labelRadius={100}
//                 labels={() => null}
//                 style={{
//                     data: {
//                         fill: ({datum}) => {
//                             return datum.x === 1 ? color : "transparent"
//                         }
//                     }
//                 }}
//             />
//         )
//     }
//
//     getStyles = (ref) => {
//         if (!ref) {
//             return;
//         }
//         const computed = window.getComputedStyle(ref);
//         const brandValue = computed.getPropertyValue("--brand-value");
//         const personalValue = computed.getPropertyValue("--personal-value");
//         const inclusion = computed.getPropertyValue("--inclusion");
//         const affinity = computed.getPropertyValue("--affinity");
//         this.setState({
//             brandValue: brandValue,
//             personalValue: personalValue,
//             inclusion: inclusion,
//             affinity: affinity
//         })
//     };
//
//     render() {
//         if (!this.state.inclusion) {
//             return <div className={'inclusion'}
//                         ref={ref => this.getStyles(ref)}/>
//         }
//         return (<>
//                 <VictoryGroup
//                     containerComponent={<VictoryContainer
//                         style={{touchAction: 'auto', pointerEvents: 'auto'}}/>}
//                 >
//                     {this.props.scoreEntries.map(this.pieItem)}
//                     <VictoryLabel
//                         textAnchor="middle"
//                         verticalAnchor="middle"
//                         style={{fontSize: 30}}
//                         y={150}
//                         x={225}
//                         text={Math.round(this.props.score.value)}
//                     />
//                 </VictoryGroup>
//             </>
//         )
//     }
// }
//




export default class AdvisorGraphs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
        this.getData = this.getData.bind(this);
        this.getDataCallback = this.getDataCallback.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getDataCallback(data) {
        // const advisors = data.map((advisor) => {
        //     let trendData = [];
        //     let currentValue = 0;
        //     const steps = 10;
        //     let currentStep = 0;
        //     let newData = [...advisor.data];
        //     while (newData.length > 0) {
        //         currentStep++;
        //         const value = newData.shift();
        //         currentValue += value;
        //         if (currentStep === steps) {
        //             trendData.push(currentValue);
        //             currentValue = 0;
        //             currentStep = 0;
        //         }
        //     }
        //
        //     return {...advisor, trend: trendData}});

        this.setState({data: data})

    }

    getData() {
        this.props.authenticator.queuedGet(
            'advisors', this.getDataCallback, () => {})
    }


    render() {
        if (!this.state.data || (this.state.data && this.state.data.length === 0)) {
            return <span id={'no-advisor-graphs'}/>
        }

        if (this.props.authenticator.account.contact_count < 6) {
            return <div id={'inadiquate-contacts-for-graph'}/>
        }

        return <div className={'skin-card advisor-graphs-card'}>
            <div className={'skin-card-header'}>Variable Trends</div>
            {this.state.data && this.state.data.map((advisor, index) => {

                let advisorKey = advisor.name;
                // let contact = advisor.contact;

                const advisorIconClassDict = {
                    'brand_value': 'square',
                    'personal_value': 'circle',
                    'inclusion': 'triangle',
                    'affinity': 'letter-x',
                };
                const advisorIconClass = advisorIconClassDict[advisorKey];

                return <div className={'skin-row'} key={index}>

                    <div className={'advisor-button'}>
                        <div className={advisorIconClass}>&nbsp;</div>
                    </div>
                    <VictoryChart height={150}
                                  maxDomain={{x: 100, y:advisor.trend_max}}>
                        <VictoryLine
                            data={advisor.data}
                            x="value"
                            y="sum"
                        />
                        <VictoryLine
                            domain={{x: [0, 100], y: [0, advisor.trend_max]}}
                            data={advisor.trend_data}
                            interpolation="natural"
                            x="value"
                            y="sum"
                            style={{
                                data: { stroke: "#c43a31" },
                                parent: { border: "1px solid #ccc"}
                            }}
                        />
                        <VictoryAxis
                            dependentAxis crossAxis
                            tickValues={[0, advisor.trend_max]}
                        />
                        <VictoryAxis
                            crossAxis
                            tickValues={[0, 20, 40, 60, 80, 100]}
                        />
                    </VictoryChart>
                </div>
            })}

        </div>
    }
}
