import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {Link} from "react-router-dom";

export default class RetailMenu extends Component {

    forceLogout = () => {
        this.props.authenticator.logout();
    };

    render() {
        return <>
            <Navbar collapseOnSelect={true} expand="lg" fixed={'top'} bg={'light'} style={{zIndex:1000}}>
                <Navbar.Brand as={Link} to="/">
                    <img
                        src="/logo192.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        alt="Experience Master"
                    />&nbsp;Experience Master
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                        <Nav.Link href="register">Register</Nav.Link>
                        <Nav.Link href="pricing">Pricing</Nav.Link>
                        <Nav.Link href="about">About Us</Nav.Link>
                    {!this.props.authenticator.isAuthenticated && <Link to={'/login'}>Login</Link>}
                    {this.props.authenticator.isAuthenticated && <Button onClick={this.forceLogout}>Logout</Button>}


                </Navbar.Collapse>
            </Navbar>

            <div style={{marginTop: '3.6rem'}} />
        </>
    }
}

