import * as queryString from "query-string";
import React, {Component} from "react";

export default class GoogleCredentialsCallback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            googleTokenRecord: null
        };
        this.callback = this.callback.bind(this);
        this.putOrPost = this.putOrPost.bind(this);
    }

    componentDidMount() {
        const searchDict = queryString.parse(this.props.location.search);
        const responseDict = this.prepareDict(searchDict);
        this.putOrPost(responseDict);
        this.getToken(responseDict)
    }

    prepareDict(searchDict) {
        let responseDict = {};
        if (this.props.authenticator.account.google_token) {
            responseDict.id = this.props.authenticator.account.google_token.id
        }
        responseDict.user = this.props.authenticator.account.user.id;
        responseDict.authorization_code = searchDict.code;
        responseDict.calendar_read_only = null;
        if (searchDict.scope.indexOf('https://www.googleapis.com/auth/calendar.events') > -1) {
            responseDict.calendar_read_only = false
        }
        responseDict.contacts_read_only = null;
        if (searchDict.scope.indexOf('https://www.googleapis.com/auth/contacts') > -1) {
            responseDict.contacts_read_only = false
        }
        return responseDict;
    }

    callback(data) {
        this.setState({googleTokenRecord: data})
    }

    putOrPost(responseDict) {
        if (!!responseDict.id) {
            this.props.authenticator.queuedPut(
                'credentials/google',
                responseDict.id,
                responseDict,
                this.callback,
                null
            )
        } else {
            this.props.authenticator.queuedPost(
                'credentials/google',
                responseDict,
                this.callback,
                null
            )
        }
    }

    getTokenCallback(data) {
        console.log('callback', data)
    }

    getTokenError(data) {
        console.log('error', data)
    }

    getToken(responseDict) {

        const redirect_uri = encodeURIComponent('http://localhost:8000/credentials/google/callback/');
        const client_id = '1069555633989-jmf6a4igufdnort10fdqe1ji0prf5u2e.apps.googleusercontent.com';
        const client_secret = 'vkxkE34PvZ1drW72Ucu0sIgk';
        const body = JSON.stringify({
            'form': {
                'code': responseDict.authorization_code,
                'code_verifier': null,
                'client_id': client_id,
                'client_secret': client_secret,
                'redirect_uri': redirect_uri,
                'access_type': 'offline',
                'grant_type': 'authorization_code'
            }
        });
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        let request = {
            method: 'POST',
            headers: headers,
            body: body}
        ;
        const authUrl= 'https://oauth2.googleapis.com/token'
        // const url = 'https://oauth2.googleapis.com/token?code=4/wwEJxzPRLX6a9vHHE7HGONI8A2pOtdW-FUXWVW1cGeq3Y0QIkb0M_P5PMAdUyymvbaqqv7Ml6iJpzNVzlBjEfeE&redirect_uri=https%3A%2F%2Fdevelopers.google.com%2Foauthplayground&client_id=1069555633989-jmf6a4igufdnort10fdqe1ji0prf5u2e.apps.googleusercontent.com&client_secret=vkxkE34PvZ1drW72Ucu0sIgk&scope=&grant_type=authorization_code'
        // console.log(url)
        fetch(authUrl,
            request)
            .then(res => res.json())
            .then(this.getTokenCallback)

    }

    render() {
        if (!this.state.googleTokenRecord) {
            return <div>Loading</div>
        }
        return <div>
            {this.state.googleTokenRecord.calendar_read_only == null &&
            <div>No Calendar Access</div>}
            {this.state.googleTokenRecord.calendar_read_only !== null && this.state.googleTokenRecord.calendar_read_only &&
            <div>Calendar Read Only access</div>}
            {this.state.googleTokenRecord.calendar_read_only !== null && !this.state.googleTokenRecord.calendar_read_only &&
            <div>Full Calendar Access</div>}
            {this.state.googleTokenRecord.contacts_read_only == null &&
            <div>No Contacts Access</div>}
            {this.state.googleTokenRecord.contacts_read_only !== null && this.state.googleTokenRecord.contacts_read_only &&
            <div>Contacts Read Only access</div>}
            {this.state.googleTokenRecord.contacts_read_only !== null && !this.state.googleTokenRecord.contacts_read_only &&
            <div>Full Contacts Access</div>}
        </div>
    }

}
