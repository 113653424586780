import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import RetailMenu from "./RetailMenu";

export default class TermsOfService extends Component {

    render() {
        return <div>

            <RetailMenu authenticator={this.props.authenticator}/>
            <Container>
            <div className="sub-section">
                <div className="top-header">
                    <h1>Terms of Use</h1>
                </div>
            </div>
            <div className="sub-section narrow">
                <p>Effective January 1, 2020</p>
                <p>
                    Thanks for using ExperienceMaster. These Terms of Use
                    (“Terms”)
                    are a legal
                    agreement between you and Fossa Tracks, Inc
                    (dba “EM or ExperienceMaster.com”) and govern your use
                    of all the text, data, information, software, graphics,
                    photographs and
                    more that we and our affiliates may make available to you
                    (all
                    of which we
                    refer to as “Materials”), all software (the “Platform” and
                    together with the Materials, this “The Website”).
                    The terms "us" or "we" or "our"
                    refer to
                    EM, the owner of this Website. “Users” are the
                    people who
                    have created a EM account, and “Invitees” are
                    people who
                    have scheduled an activity with an EM User but may or may
                    not
                    have
                    registered. A “Viewer” is a person who
                    visits The Website but may or may not be a User or Invitee.
                </p>
                <p>
                    If you are entering into these Terms on behalf of a company
                    or
                    other
                    organization, you hereby represent and warrant that you are
                    authorized to
                    enter into this Terms on behalf of such company or other
                    organization.
                </p>
                <p>
                    READ THESE TERMS CAREFULLY BEFORE BROWSING THIS WEBSITE.
                    USING
                    THIS
                    WEBSITE INDICATES THAT YOU HAVE BOTH READ AND ACCEPT THESE
                    TERMS. YOU
                    CANNOT USE THIS WEBSITE IF YOU DO NOT ACCEPT THESE TERMS.
                    THESE
                    TERMS
                    CONTAIN A DISPUTE RESOLUTION AND ARBITRATION PROVISION,
                    INCLUDING A CLASS
                    ACTION WAIVER THAT AFFECTS YOUR RIGHTS UNDER THESE TERMS AND
                    WITH RESPECT
                    TO DISPUTES YOU MAY HAVE WITH EM.
                </p>
                <p>
                    IF YOU ARE AN EM USER, VIEWER, OR INVITEE LOCATED IN THE
                    EUROPEAN
                    ECONOMIC AREA, SWITZERLAND, OR UNITED KINGDOM, SEE THE <Link
                    to="privacy">DATA
                    PRIVACY SPECIFICATIONS</Link> WHICH
                    ACCOMPANIES THESE TERMS AND SETS FORTH OTHER TERMS OF OUR
                    AGREEMENT THAT APPLY SOLELY TO THE EXTENT ANY INFORMATION
                    YOU
                    PROVIDE TO
                    EM INCLUDES PERSONAL DATA OF INDIVIDUALS LOCATED IN THE
                    EUROPEAN
                    ECONOMIC AREA, SWITZERLAND, AND THE UNITED KINGDOM.
                </p>
                <h3>SCOPE</h3>
                <p>
                    The Website facilitates contact storage and online
                    scheduling of
                    activities. You may
                    access and use The Website in accordance with these Terms.
                    Please note
                    that these Terms govern your use of the Website, including,
                    but
                    not
                    limited to, the process through which your Invitees schedule
                    activities
                    with you.
                </p>
                <p>
                    When using the features of The Website you are subject to
                    any
                    agreements,
                    policies or guidelines applicable to such features that may
                    be
                    posted from
                    time to time. All such agreements, policies or guidelines,
                    including the
                    <Link to="privacy">Privacy Policy</Link> are included.
                </p>
                <h3>GENERAL USE</h3>
                <p>
                    Resale of The Website or the EM brand is restricted by
                    contract.
                </p>
                <p>
                    Subject to these Terms, we grant you a limited, personal,
                    non-exclusive
                    and non-transferable license to use and to display the
                    Materials
                    and a
                    limited, personal, non-exclusive and non-transferable right
                    to
                    access and
                    use The Website. You have no other rights in the Website and
                    you may
                    not
                    modify, edit, copy, reproduce, create derivative works of,
                    reverse
                    engineer, alter, enhance, or in any way exploit any portion
                    of
                    The Website
                    in any manner.
                </p>
                <p>
                    Our Website is not intended for and may not be used by
                    individuals under
                    16 years of age. By registering for a User account, you
                    state that you
                    are at least 18 years of age, and you are responsible for
                    ensuring that
                    all EM Users and Invitees are at least 16 years old.
                    Creating an
                    account with false information is a violation of our Terms,
                    including
                    creating an account on behalf of others or persons under the
                    age
                    of 18.
                </p>
                <h3 className="">ACCOUNT SETUP</h3>
                <p>
                    To use the Website, you need to set up a User account. Users
                    are allowed to have multiple paid accounts. Email address
                    for
                    each account must be unique.
                </p>
                <p>
                    Users agree to provide true, accurate, current, and
                    complete
                    information which is directly modifyable by the User. Users
                    are
                    entirely responsible for compliance.
                </p>
                <p>
                    Password complexity is enforce, but Users should change
                    passwords often.
                    Should you believe your password or security for this
                    website
                    has been
                    breached in any way, you must immediately notify us at
                    <a href="mailto:support@experiencemaster.com">support@experiencemaster.com</a>.
                </p>
                <p>
                    We or our billing processors collect your billing
                    information when
                    you register as a Paid Account. All information is
                    protected in accordance with our <Link to="privacy">Privacy
                    Policy</Link>.
                </p>
                <p>
                    Custom URLs are provided, EM reserves the right to change or
                    deactivate a custom URL without prior notice.
                </p>
                <p>
                    The person / entity which pays for The Website User Account,
                    the paying party has the right to control, manage, report
                    on,
                    delete the Account and all of the data within that account.
                </p>
                <h3>SUBSCRIPTION PERIOD AND SUBSCRIPTION
                    FEES</h3>
                <p>
                    You will pay all fees described in <Link
                    to={'pricing'}>Pricing.</Link>
                    You provide a payment method which authorizes us to charge
                    the fees as expressed as well as any taxes or other charges
                    incurred based on that subscription.
                </p>
                <p>
                    The subscription period for Paid Accounts can be
                    month-to-month
                    or yearly.
                </p>
                <p>
                    EM will automatically charge your
                    payment
                    method in the amount of the subscription fee you have
                    chosen,
                    plus applicable taxes.
                    All payments are non-refundable. To the
                    extent that
                    payment mechanisms are provided through third parties, you
                    agree
                    that
                    EM will have no liability to you arising out of the
                    acts
                    or
                    omissions of such third parties.
                </p>
                <p>
                    Failure to pay subscriptions results in account suspension.
                    Accounts suspended for 3 consecutive months will be purged
                    entirely, including from backups.
                </p>
                <p>
                    BY CREATING A PAID ACCOUNT, YOU CONSENT TO ALLOW EM TO
                    CHARGE YOUR
                    PAYMENT METHOD, FOR THE AMOUNT DUE FOR THAT AND SUBSEQUENT
                    SUBSCRIPTION PERIODS OR UNTIL YOUR ACCOUNT IS CANCELED OR
                    TERMINATED. PLEASE
                    PRINT OUT THESE TERMS AND CONDITIONS FOR YOUR RECORDS.
                </p>
                <h3>RENEWAL/CANCELLATION</h3>
                <p>
                    Paid Accounts will automatically renew for the same
                    Subscription
                    Period
                    unless you cancel the account by the end of the then-current
                    Subscription
                    Period. You can cancel your account at any time by
                    contacting
                    customer
                    service at <a
                    href="mailto:support@experiencemaster.com">support@experiencemaster.com</a>.
                    Cancellation will be effective
                    immediately. Please note that after you cancel your account,
                    all data will be purged and you will not be
                    entitled to a
                    refund of any fees that you've paid.
                </p>
                <h3>LEGAL COMPLIANCE</h3>
                <p>
                    You agree to comply with all applicable domestic and
                    international laws,
                    statutes, ordinances, and regulations regarding your use of
                    The
                    Website. See <Link to="privacy">Privacy
                    Policy</Link>.
                    You agree to promptly
                    notify EM if you are aware of any
                    unauthorized
                    or illegal use of the Website.
                </p>
                <h3>INTELLECTUAL PROPERTY</h3>
                <p>
                    Our Website may contain our service marks or trademarks as
                    well
                    as those
                    of our affiliates or other companies, in the form of words,
                    graphics, and
                    logos. Your use of The Website does not constitute any right
                    or
                    license or ownership
                    rights of
                    any kind toward The Website.
                </p>
                <h3>NOTICES AND MESSAGE</h3>
                <p>
                    By using the Website, you consent to receiving emails from
                    us.
                    SMS Services may be used as event reminders for Users.
                </p>
                <p>
                    You agree emails are legally binding and to keep your
                    contact information up to date and to
                    monitor your email settings to ensure that you receive
                    emails
                    from us.
                </p>
                <h3>LINKS TO OTHER WEBSITES</h3>
                <p>
                    Our Website may contain links to third party websites.
                    The inclusion of links within our Website does not
                    constitute
                    any endorsement, guarantee, warranty, or recommendation of
                    such
                    third
                    party websites. Access any third party
                    websites
                    at your own risk.
                </p>
                <h3>COMMUNITY FORUMS</h3>
                <p>
                    There are Community Forums which may permit you to submit
                    feedback. Submissions, to us can be used without any
                    restrictions or compensation to you.
                </p>
                <h3>UNAUTHORIZED ACTIVITIES</h3>
                <p>
                    The Website is to be used only for the stated purposes.</p>
                <p>
                    Unauthorized use may result in violation of
                    various United
                    States and international copyright laws, the deletion of
                    your
                    User
                    Account, or a block from the Website.
                </p>
                <p>You are not allowed to use The Website to:</p>
                <ul>
                    <li>Publicly display this website;</li>
                    <li>Do anything illegal;</li>
                    <li>Use bots. To be extra-clear NO BOTS.;</li>
                    <li>Impersonate any other person or entity;</li>
                    <li>Release any software of any kind;</li>
                    <li>Disrupt anything;</li>
                    <li>To stalk, harass, spam, or harm another individual,
                        organization, or business;
                    </li>
                    <li>To disclose information that you do not have permission
                        to
                        disclose;
                    </li>
                    <li>
                        Store Credit Card information of any kind.
                    </li>
                    <li>
                        To disclose anything you don't explicitly and wholly
                        own.
                    </li>
                </ul>
                <h3>INDEMNIFICATION</h3>
                <p>
                    EM is held harmess from any fees, claims, demands, losses,
                    or damages of or related to
                    processing of Personal Data given by you to us.
                </p>
                <h3>INTELLECTUAL PROPERTY INFRINGEMENT</h3>
                <p>
                    We respect the intellectual property rights, violators will
                    be
                    banned.
                </p>
                <p>
                    We enforce copyrights. If you believe your copyright or
                    other
                    intellectual
                    property
                    right is being infringed by a User, please provide
                    written notice
                    to us for notice of claims of infringement at <a
                    href="mailto:support@experiencemaster.com">support@experiencemaster.com</a>.
                </p>
                <p>
                    Violations will result in swift action. Counter
                    notifications
                    may be taken into consideration. Bans are a likely result.
                </p>
                <h3>WARRANTY DISCLAIMER</h3>
                <p>
                    THE WEBSITE AND ALL CONTENT ARE PROVIDED "AS IS" AND "WITH
                    ALL
                    FAULTS" AND
                    THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE IS WITH
                    YOU.
                    WE
                    EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND (EXPRESS,
                    IMPLIED
                    OR
                    STATUTORY) WHICH INCLUDES BUT IS NOT LIMITED TO, ANY IMPLIED
                    OR
                    STATUTORY
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE
                    OR
                    PURPOSE,
                    ACCURACY OF DATA, TITLE, AND NON-INFRINGEMENT OF
                    INTELLECTUAL
                    PROPERTY
                    RIGHTS.
                </p>
                <p>
                    We don't promise to be perfect. We are not even sure we will
                    fit your needs. We make no warranty and make no advice.
                </p>
                <h3>LIMITATION OF LIABILITY</h3>
                <p>
                    WE WILL NOT BE LIABLE TO YOU OR OTHERS FOR ANY DAMAGES
                    RESULTING
                    FROM YOUR USE OF THE WEBSITE OR YOUR DISPLAYING, COPYING, OR
                    DOWNLOADING
                    ANY MATERIALS TO OR FROM THE WEBSITE. IN NO EVENT WILL WE
                    BE LIABLE
                    TO YOU FOR ANY INDIRECT, EXTRAORDINARY, EXEMPLARY, PUNITIVE,
                    SPECIAL,
                    INCIDENTAL, OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF
                    DATA,
                    REVENUE,
                    PROFITS, OPPORTUNITIES, REPUTATION, USE, OR OTHER ECONOMIC
                    ADVANTAGE) ARISING, EVEN IF WE KNOW THERE
                    IS A POSSIBILITY OF SUCH
                    DAMAGE.
                </p>
                <p>
                    OUR MAXIMUM AGGREGATE LIABILITY TO YOU IN CONNECTION WITH
                    THESE
                    TERMS OR THE WEBSITE, REGARDLESS OF THE FORM OF ACTION
                    (WHETHER
                    SUCH
                    LIABILITY ARISES DUE TO NEGLIGENCE, BREACH OF CONTRACT,
                    MISREPRESENTATION,
                    OR FOR ANY OTHER REASON), WILL AT ALL TIMES BE LIMITED TO
                    THE
                    GREATER OF
                    (I) THE AMOUNT PAID, IF ANY, BY YOU TO EM IN CONNECTION
                    WITH THESE
                    TERMS IN THE TWELVE (12) MONTHS PRIOR TO THE ACTION GIVING
                    RISE
                    TO
                    LIABILITY OR (II) US $100.00.
                </p>
                <p>
                    THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE
                    AGREEMENT BETWEEN
                    YOU AND US AND SHALL APPLY TO ALL CLAIMS OF LIABILITY,
                    EVEN IF
                    WE HAVE BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE,
                    AND EVEN IF
                    THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.
                </p>
                <h3>LOCAL LAWS; EXPORT CONTROL</h3>
                <p>
                    We comply with the laws within the United States.
                    International
                    Users are solely responsible for
                    following
                    applicable local laws.
                </p>
                <h3>DISPUTE RESOLUTION AND ARBITRATION; CLASS
                    ACTION WAIVER.</h3>
                <p>
                    <strong>Please Read This Provision Carefully. It Affects
                        Your
                        Legal Rights.</strong>
                </p>
                <p>
                    This provision provides that all disputes between you and us
                    will be
                    resolved by binding arbitration because acceptance of these
                    Terms
                    constitutes a waiver of your right to litigate claims and
                    all
                    opportunity
                    to be heard by a judge or jury.
                    YOU
                    AND
                    EM AGREE THAT ANY AND ALL DISPUTES, AS DEFINED ABOVE,
                    WHETHER
                    PRESENTLY IN EXISTENCE OR BASED ON ACTS OR OMISSIONS IN THE
                    PAST
                    OR IN THE
                    FUTURE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING
                    ARBITRATION
                    RATHER THAN IN COURT IN ACCORDANCE WITH THIS PROVISION.
                </p>
                <p>Pre-Arbitration Claim Resolution</p>
                <p>
                    We reserve the right to attempt resolution before
                    escalation.
                    If we do not resolve the dispute within 45 days after
                    receiving
                    your notification, then you may pursue your dispute in
                    arbitration.
                </p>
                <h3>GENERAL TERMS</h3>
                <p>
                    Term violations may require immediate action, and may result
                    in immediate termination.
                </p>
                <p>
                    Unless you have specifically notified EM to the contrary
                    in writing
                    (including via email to support@experiencemaster.com), EM
                    may
                    disclose your
                    company as a customer of EM (if you use EM for
                    business
                    purposes and/or use a business email address as your EM
                    User email
                    address), and may use your company’s name and logo on the
                    Website and in
                    EM’s promotional materials. EM will request your
                    prior written
                    consent for any other uses.
                </p>
                <h3>CHANGES</h3>
                <p>
                    We may amend our Terms at any time by publishing revised
                    Terms
                    on our
                    Website and/or by sending an email to the address you gave
                    us.
                    These Terms
                    are current as of the Effective Date set forth above. EM
                    reserves the right
                    to change
                    any and all features of our Website, at any time, without
                    notice.
                </p>
                <h3>CONTACT US</h3>
                <p>
                    If you have any questions about these Terms or otherwise
                    need to
                    contact us for any reason, you can reach us at <a
                    href="mailto:support@experiencemaster.com">support@experiencemaster.com</a>.
                </p>
            </div>
            </Container>
        </div>
            }
            }