import React, {Component} from "react";
import {Link} from "react-router-dom";
import ContactStars from "./Stars";


export default class TopExperience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            topExperiences: []
        };
        this.getEvents = this.getEvents.bind(this);
        this.getEventsCallback = this.getEventsCallback.bind(this);
    }

    componentDidMount() {
        this.getEvents();
    }

    getEventsCallback(data) {
        this.setState({topExperiences: data})
    }

    getEvents() {
        this.props.authenticator.queuedGet('contacts/experience', this.getEventsCallback, () => {
        })
    }


    render() {
        if (!this.state.topExperiences || (!!this.state.topExperiences && this.state.topExperiences.length === 0)) {
            return <span id={'no-experiences'}/>
        }

        if (!!this.state.topExperiences.results && this.state.topExperiences.results.length === 0) {
            return <span id={'no-experiences'}/>
        }
        const experienceMax = this.state.topExperiences.results[0].experience_sum;
        return <div className={'skin-card top-experience-card-1'}>
                <div className={'skin-card-header'}>Top 10</div>
                <div className={'skin-column'} style={{padding: '1rem'}}>
                {this.state.topExperiences.results && this.state.topExperiences.results.map((contact, index) => {
                    return <div className={'skin-row'} style={{borderBottom: '.5px solid grey'}} key={index}>
                        <div style={{textAlign: 'left', flexGrow: 1, flexShrink: 1, maxWidth: '50%'}}><Link to={'/contacts/contact/' + contact.id}>{contact.name}</Link></div>
                        <div style={{textAlign: 'left', flexGrow: 0, flexShrink: 0}}><ContactStars contact={contact} experienceMax={experienceMax}/></div>

                </div>})}
                </div>

            </div>
    }
}
