import React, {Component} from 'react';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import GoogleContactsSync from "./GoogleContactsSync";
import GoogleRequest from "./GoogleRequest";

export default class GoogleRequests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            googleRequests: [],
        };
        this.onClick = this.onClick.bind(this);
        this.getGoogleRequests = this.getGoogleRequests.bind(this);
        this.getGoogleRequestsCallback = this.getGoogleRequestsCallback.bind(this);
    }


    getGoogleRequestsCallback(data) {
        this.setState({googleRequests: data})
    }

    getGoogleRequests() {
        this.props.authenticator.queuedGet(
            'google/requests',
            this.getGoogleRequestsCallback, ()=>{});
    }


    onClick(event) {
        if (this.state.googleRequests.length === 0) {
            this.getGoogleRequests()
        }
    }


    render() {
        return (
            <Container>
                {this.state.googleRequests &&
                <Accordion
                    as={Card}
                    border={'primary'}
                    key={'googleRequests'}
                >
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey={'googleRequests'}
                        className="text-center"
                        onClick={this.onClick}
                    >
                        Google Requests
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={'googleRequests'}>
                        <Card.Body>
                            <GoogleContactsSync
                                callback={this.getGoogleRequests}
                                authenticator={this.props.authenticator}/>
                            <br/>
                            {this.state.googleRequests.map(
                                (googleRequest, index, original) => {
                                    return <GoogleRequest
                                            key={index}
                                            googleRequest={googleRequest}
                                            authenticator={this.props.authenticator} />
                                }
                            )}

                        </Card.Body>
                    </Accordion.Collapse>
                </Accordion>
                }

            </Container>
        )
    }
}

