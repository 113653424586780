import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export default class DigestFrequency extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recurringDays: []
        };

        this.chooseRecurring = this.chooseRecurring.bind(this);
        this.recurringColors = this.recurringColors.bind(this);
        this.updateDigestFrequencyErrorCallback = this.updateDigestFrequencyErrorCallback.bind(this);
        this.updateDigestFrequencyCallback = this.updateDigestFrequencyCallback.bind(this);
    }

    componentDidMount() {
        this.setState({recurringDays: this.props.authenticator.account.digest_frequency})
    }

    updateDigestFrequencyErrorCallback(data) {

    }

    updateDigestFrequencyCallback(data) {

    }

    updateDigestFrequency() {
        const accessPoint = 'account';
        const accountId = this.props.authenticator.account.id;
        const data = {
            id: accountId,
            digest_frequency: this.state.recurringDays
        };
        this.props.authenticator.queuedPut(
            accessPoint, accountId, data,
            this.updateDigestFrequencyCallback,
            this.updateDigestFrequencyErrorCallback)
    }


    chooseRecurring(event) {
        event.preventDefault();
        const weekday = parseInt(event.target.value);
        let recurring = [...this.state.recurringDays];
        let index = recurring.indexOf(weekday);
        if (index < 0) {
            recurring.push(weekday)
        } else {
            recurring = recurring.filter(function (value) {
                return value !== weekday
            });
        }
        recurring.sort();
        this.setState({recurringDays: recurring},
            this.updateDigestFrequency);
    }


    recurringColors() {
        return {
            0: this.state.recurringDays.includes(0) ? 'success' : 'outline-primary',
            1: this.state.recurringDays.includes(1) ? 'success' : 'outline-primary',
            2: this.state.recurringDays.includes(2) ? 'success' : 'outline-primary',
            3: this.state.recurringDays.includes(3) ? 'success' : 'outline-primary',
            4: this.state.recurringDays.includes(4) ? 'success' : 'outline-primary',
            5: this.state.recurringDays.includes(5) ? 'success' : 'outline-primary',
            6: this.state.recurringDays.includes(6) ? 'success' : 'outline-primary',
        }
    }

    render() {
        console.log(this.state.recurringDays)
        const weekends = this.state.recurringDays.length === 2 && this.state.recurringDays[0] === 5 && this.state.recurringDays[1] === 6;
        const recurringColors = this.recurringColors();

        return (<>

                <ButtonGroup aria-label="days-of-the-week">
                    <Button name={'Sunday'}
                            value={6}
                            variant={recurringColors['6']}
                            onClick={this.chooseRecurring}>U</Button>
                    <Button name={'Monday'}
                            value={0}
                            variant={recurringColors['0']}
                            onClick={this.chooseRecurring}>M</Button>
                    <Button name={'Tuesday'}
                            value={1}
                            variant={recurringColors['1']}
                            onClick={this.chooseRecurring}>T</Button>
                    <Button name={'Wednesday'}
                            value={2}
                            variant={recurringColors['2']}
                            onClick={this.chooseRecurring}>W</Button>
                    <Button name={'Thursday'}
                            value={3}
                            variant={recurringColors['3']}
                            onClick={this.chooseRecurring}>R</Button>
                    <Button name={'Friday'}
                            value={4}
                            variant={recurringColors['4']}
                            onClick={this.chooseRecurring}>F</Button>
                    <Button name={'Saturday'}
                            value={5}
                            variant={recurringColors['5']}
                            onClick={this.chooseRecurring}>S</Button>
                </ButtonGroup>
                <br/>
                {this.state.recurringDays && this.state.recurringDays.length > 0 && this.state.recurringDays.length < 7 && !weekends &&
                <span style={{fontSize: '0.9rem'}}>Every {
                    this.state.recurringDays.map(
                        (recurringWeekday, index, original) => (
                            <div key={index} style={{display: 'inline'}}>
                                {original.length > 2 && index + 1 === original.length &&
                                <span>and&nbsp;</span>}
                                <span>
                                            {new Date(2001, 0, 1 + recurringWeekday).toLocaleString(navigator.language, {weekday: 'short'})}
                                    {original.length > 2 && index + 1 !== original.length && <>,&nbsp;</>}
                                        </span>
                                {original.length === 2 && index === 0 &&
                                <span>&nbsp;and&nbsp;</span>}
                            </div>
                        ))}
                            </span>
                }
                {this.state.recurringDays.length === 7 &&
                <span style={{fontSize: '0.9rem'}}>Every Day</span>}
                {weekends && <span style={{fontSize: '0.9rem'}}>Weekends</span>}
                {this.state.recurringDays && this.state.recurringDays.length === 0 &&
                <span style={{fontSize: '0.9rem'}}>Never</span>}
            </>
        )
    }
}
