import moment from "moment-timezone";
import {EDNA_API_URL} from "../Settings";


class EdnaApiAuthenticator {

    TIMEOUT=6000;

    constructor(lostAuthenticationCallback, isAuthenticatedCallback,
                setExperience) {

        this.lostAuthenticationCallback=lostAuthenticationCallback;
        this.isAuthenticatedCallback=isAuthenticatedCallback;
        this.setExperience = setExperience;
        this.experience = null;

        this.authenticating = true;
        this.access = null;
        this.refresh = null;
        this.baseHeaders = null;
        this.isAuthenticated = false;
        this.authenticationTimer = null;

        this.fetchQueue = {};


        this.timeZoneName = moment.tz.guess(true);
        this.timeZone = moment.tz.zone(this.timeZoneName);
        this.timeZoneAbbreviation = this.timeZone.abbr(moment());


        this.createBaseHeaders = this.createBaseHeaders.bind(this);
        this.errorCallback = this.errorCallback.bind(this);
        this.responseProcessor = this.responseProcessor.bind(this);
        this.refreshCredentials = this.refreshCredentials.bind(this);

        this.refreshCredentialsCallback = this.refreshCredentialsCallback.bind(this);
        this.setAccessAuthenticated = this.setAccessAuthenticated.bind(this);

        this.getStoredCredentials = this.getStoredCredentials.bind(this);
        this.validateCredentials = this.validateCredentials.bind(this);

        this.clearAuthentication = this.clearAuthentication.bind(this);
        this.setIsAuthenticated = this.setIsAuthenticated.bind(this);
        this.setAuthenticationTimer = this.setAuthenticationTimer.bind(this);
        this.clearAuthenticationTimer = this.clearAuthenticationTimer.bind(this);
        this.timerTask = this.timerTask.bind(this);

        this.tryAgain = this.tryAgain.bind(this);
        this.processQueue = this.processQueue.bind(this);

        this.login = this.login.bind(this)
        this.loginWithCallback = this.loginWithCallback.bind(this)
        this.postLogin = this.postLogin.bind(this)
        this.postLoginCallback = this.postLoginCallback.bind(this)
        this.logout = this.logout.bind(this)

        this.getLibrary = this.getLibrary.bind(this)
        this.getLibraryCallback = this.getLibraryCallback.bind(this)
        this.getAccountCallback = this.getAccountCallback.bind(this)

        this.loadAssetsAndSetIsAuthenticated = this.loadAssetsAndSetIsAuthenticated.bind(this)

        this.experienceProcessor = this.experienceProcessor.bind(this);

        this.validateCredentials();
    }

    validateCredentials() {
        this.authenticating = true;
        if (!this.access) {
            this.getStoredCredentials();
        }
        if (!this.access) {
            this.clearAuthenticationTimer();
            this.clearAuthentication();
        } else {
            this.loadAssetsAndSetIsAuthenticated();
        }
    }



    getLibraryCallback(data) {
        if (!!!data || data==={}) {
            console.warn('Library will need to retry.');
            return;
        }
        this.library = data;
        this.getAccount();
    }

    getLibraryCallbackFailure(data) {
    }

    getLibrary() {
        this.queuedGet('type_library',
            this.getLibraryCallback, this.getLibraryCallbackFailure)
    }

    getAccountCallback(data) {
        if (!!!data || data==={}) {
            console.warn('Account will need to retry.');
            return;
        }
        this.account = data[0];
        this.setIsAuthenticated();
    }

    getAccountCallbackFailure(data) {
    }

    getAccount() {
        this.queuedGet('account',
            this.getAccountCallback, this.getAccountCallbackFailure)
    }

    loadAssetsAndSetIsAuthenticated() {
        this.getLibrary();
    }



    logout() {
        this.clearAuthenticationTimer();
        this.clearAuthentication();
        window.location.reload();
    }

    start() {
        this.clearAuthenticationTimer();
        this.setAuthenticationTimer();
    }

    postLoginCallback(data) {
        if (data) {
            localStorage.setItem('access', data.access);
            localStorage.setItem('refresh', data.refresh);
            this.access = data.access;
            this.refresh = data.refresh;
            this.createBaseHeaders();
            // this.setAuthenticationTimer();
            this.loadAssetsAndSetIsAuthenticated()
        } else {
            console.warn('login failure')
            this.clearAuthentication();
        }
    }

    postLogin(username, password) {
        const body = {
            username: username,
            password: password};
        this.post('token',
            body, this.postLoginCallback, this.errorCallback)
    }

    login(username, password) {
        this.postLogin(username, password)
    }

    loginWithCallback(username, password, successCallback, failureCallback) {
        const body = {
            username: username,
            password: password};
        this.queuedPost(
            'token',
            body,
            (data) => {
                this.postLoginCallback(data);
                successCallback(data);
            },
            (data) => {
                failureCallback(data);
                this.errorCallback(data);
            })
    }

    clearAuthentication() {
        this.isAuthenticated = false;
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        if (this.lostAuthenticationCallback) {
            this.lostAuthenticationCallback();
        }
        this.authenticating = false;
        this.account = null;
    }

    setIsAuthenticated() {
        if (this.library && this.account) {
            this.isAuthenticatedCallback();
        }

        this.isAuthenticated = Date.now();
        this.authenticating = false;
        this.processQueue();
    }

    processQueue() {
        for (const key in this.fetchQueue) {
            if (this.fetchQueue[key].status === 'waiting-for-retry') {
                this.tryAgain(key);
            }
        }
    }

    createBaseHeaders() {
        this.baseHeaders = {
            Authorization: 'Bearer ' + this.access
        };
    }

    getStoredCredentials() {
        this.access = localStorage.getItem('access');
        this.refresh = localStorage.getItem('refresh');
        this.createBaseHeaders();
    }

    refreshCredentialsCallback(data) {
        if (!!data && !!data.error) {
            console.warn('refreshCredentialsCallback ERROR', data)
            this.authenticating = false;
            this.clearAuthenticationTimer();
            this.clearAuthentication();
            return;
        }
        if (!!data) {
            this.setAccessAuthenticated(data)
        }
    }


    refreshCredentials() {
        if (!this.refresh || this.refresh === 'undefined') {
            this.authenticating = false;
            this.clearAuthenticationTimer();
            this.clearAuthentication();
            return;
        }
        let body = {refresh: this.refresh};

        body = JSON.stringify(body);
        const url = this.getUrl('refresh');
        const request = this.buildRequest('post',null, body);
        fetch(url, request)
            .then((response) => {
                return this.responseProcessor(
                    response, null, true)}
            )
            .then(this.refreshCredentialsCallback);
    }

    setAccessAuthenticated(data) {
        if (data && 'access' in data) {
            this.access=data.access;
            localStorage.setItem('access', data.access);
            // localStorage.setItem('refresh', data.refresh);
            this.setIsAuthenticated();
        } else {
            this.authenticating = false;
            this.clearAuthentication();
            this.clearAuthenticationTimer();
        }
    }



    timerTask() {
        this.validateCredentials()
    }

    setAuthenticationTimer() {
        return;
    }

    clearAuthenticationTimer() {
        if (!!this.authenticationTimer) {
            clearInterval(this.authenticationTimer);
        }
        this.authenticationTimer = null;
    }


    responseToJson(response) {
        return response.text().then(function (text) {
            if (text) {
                try {
                    return JSON.parse(text)
                } catch (error) {
                    console.warn('not JSON', error);
                    return {}
                }
            } else {
                return {}
            }
        });
    }

    errorHandler(response, errorCallback) {
        const jsonResponse = this.responseToJson(response)
        if (errorCallback) {
            jsonResponse.then((data) => {
                errorCallback(data, response.status)})
        }
        return null
    }

    responseProcessor(response, key=null, clearAuthentication=false, errorCallback=null) {
        if (response.ok) {
                if (!!key) {
                    delete this.fetchQueue[key]
                }
            return this.responseToJson(response)
        }
        if (response.status === 401) {
            if (clearAuthentication) {
                this.clearAuthentication();
            } else {
                if (!!key) {
                    this.fetchQueue[key].status = 'waiting-for-retry';
                }
                this.refreshCredentials();
            }
            return this.errorHandler(response, errorCallback)
        }
        return this.errorHandler(response, errorCallback)
        }

    tryAgain(key) {
        this.fetchQueue[key].status = 'in-progress';
        const queuedItem = this.fetchQueue[key];
        const url = queuedItem.url;
        const method = queuedItem.method;
        let headers = queuedItem.headers;
        this.createBaseHeaders();
        headers = {...headers, ...this.baseHeaders};

        const callBack = queuedItem.callBack;
        const errorCallback = queuedItem.errorCallback;
        const body = queuedItem.body;
        this.queuedFetch(
            url, method, headers, callBack, errorCallback, body, key)
    }

    errorCallback(event) {
        if (!!event.error) {
            if (event.error === '401 - Unauthorized') {
            }
        }
    }

    getUrl(accessPoint, recordId=null, args=null) {

        let url = EDNA_API_URL;
        if (accessPoint) {
            url += accessPoint + '/';
        }
        if (recordId) {
            url += (recordId).toString() + '/';
        }
        // TODO: so args is incorrect, it is kwargs, very disappointed.
        if (args) {
            url += '?'
            for (let [key, value] of Object.entries(args)) {
                url += `${key}=${value}&`
            }
            if (url.endsWith('&')) {
                url = url.slice(0, -1);
            }
        }

        return url;
    }

    experienceProcessor(data) {
        this.setExperience(data);
    };

    queuedFetch = (
            url, method, headers,
            callBack, errorCallback,
            body=null, key=null) => {
        // console.log('queuedFetch', url, method, headers);
        const request = this.buildRequest(method, headers, body);
        if (!key) {
            key = Date.now();
            this.fetchQueue[key] = {
                url: url,
                request: request,
                method: method,
                headers: headers,
                callBack: callBack,
                errorCallback: errorCallback,
                body: body,
                status: 'fetching',
                retryCount: 0};
        } else {
            this.fetchQueue[key].retryCount += 1;
            if (this.fetchQueue[key].retryCount > 5) {
                console.warn('EdnaApiAuthenticator.queuedFetch - retry limit reached - clearing auth')
                this.clearAuthentication()
                return;
            }
        }

        fetch(url, request)
            .then((response) => {return this.responseProcessor(response, key, false, errorCallback)})
            .then((data) => {
                if (!!data && (!!data.error || !!data.detail)) {
                    errorCallback(data);
                } else {
                    if (!!data && !!data._experience) {
                        this.experienceProcessor(data._experience);
                    }
                    if (!!callBack) {callBack(data)} else {debugger;}
                }
            })
            .catch(errorCallback);
    };


    queuedGet = (accessPoint, callBack, errorCallback=null, recordId=null, args=null) => {
        const url = this.getUrl(accessPoint, recordId, args);
        if (!!!errorCallback) {
            errorCallback = this.errorCallback;
        }
        this.queuedFetch(url, 'get',
            this.baseHeaders, callBack, errorCallback);
    };

    queuedPut = (accessPoint, recordId, body, callBack, errorCallback=null, args=null) => {
        body = JSON.stringify(body);
        const url = this.getUrl(accessPoint, recordId, args=null);
        if (!!!errorCallback) {
            errorCallback = this.errorCallback;
        }
        this.queuedFetch(url, 'put',
            this.baseHeaders, callBack, errorCallback, body);
    };

    queuedPatch = (accessPoint, recordId, body, callBack, errorCallback=null, args=null) => {
        body = JSON.stringify(body);
        const url = this.getUrl(accessPoint, recordId, args=null);
        if (!!!errorCallback) {
            errorCallback = this.errorCallback;
        }
        this.queuedFetch(url, 'patch',
            this.baseHeaders, callBack, errorCallback, body);
    };

    queuedPost = (accessPoint, body, callBack, errorCallback=null) => {
        body = JSON.stringify(body);
        const url = this.getUrl(accessPoint);
        if (!!!errorCallback) {
            errorCallback = this.errorCallback;
        }
        this.queuedFetch(url, 'post',
            this.baseHeaders, callBack, errorCallback, body);
    };

    queuedDelete = (accessPoint, recordId, callBack, errorCallback=null,
              args=null) => {
        const url = this.getUrl(accessPoint, recordId, args);
        if (!!!errorCallback) {
            errorCallback = this.errorCallback;
        }
        this.queuedFetch(url, 'delete',
            this.baseHeaders, callBack, errorCallback);
    };


    buildRequest(method, headers, body) {
        let request = {
            method: method,
            headers: headers,
        };

        if (!!body) {
            request.body = body;
            const jsonHeader = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'};
            request.headers = Object.assign({}, headers, jsonHeader);
        }
        return request
    }

    fetch = (url, method, headers, callBack, errorCallback, body=null) => {
        console.log('xfetch', url, method, headers, callBack, errorCallback, body)
        const request = this.buildRequest(method,headers,body);
        fetch(url, request)
            .then((response) => {
                return this.responseProcessor(
                    response, null)}
                    )
            .then((data) => {
                if (!!data && !!data.error) {
                    this.errorCallback(data);
                } else {
                    callBack(data);
                }
            })
            .catch(errorCallback);
    };

    get = (accessPoint, callBack, errorCallback=null, recordId=null, args=null) => {
        const url = this.getUrl(accessPoint, recordId, args);
        if (!!errorCallback) {
            errorCallback = this.errorCallback;
        }
        this.fetch(url, 'get',
            this.baseHeaders, callBack, errorCallback);
    };

    put = (accessPoint, recordId, body, callBack, errorCallback=null) => {
        body = JSON.stringify(body);
        const url = this.getUrl(accessPoint, recordId);
        if (!!errorCallback) {
            errorCallback = this.errorCallback;
        }
        this.fetch(url, 'put',
            this.baseHeaders, callBack, errorCallback, body);
    };

    post = (accessPoint, body, callBack, errorCallback=null) => {
        body = JSON.stringify(body);
        const url = this.getUrl(accessPoint);
        if (!!errorCallback) {
            errorCallback = this.errorCallback;
        }
        this.fetch(url, 'post',
            this.baseHeaders, callBack, errorCallback, body);
    };

    delete = (accessPoint, recordId, callBack, errorCallback=null,
              args=null) => {
        const url = this.getUrl(accessPoint, recordId, args);
        if (!!errorCallback) {
            errorCallback = this.errorCallback;
        }
        this.fetch(url, 'delete',
            this.baseHeaders, callBack, errorCallback);
    };

}

export default EdnaApiAuthenticator;
