import moment from "moment-timezone";
import React, {Component} from 'react';
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import {Link} from "react-router-dom";

export default class AppleRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appleRequest: null,
            timer: null
        };
        this.getAppleRequest = this.getAppleRequest.bind(this);
        this.getAppleRequestCallback = this.getAppleRequestCallback.bind(this);
    }

    componentDidMount() {
        const request = this.props.appleRequest;
        this.setState({appleRequest: request});
        if (request.status.name !== 'DONE' && request.status.name !== 'ERROR') {
            this.setTimer();
        }
    }

    componentWillUnmount() {
        this.clearTimer()
    }

    setTimer() {
        this.setState({
            timer: setInterval(
                this.getAppleRequest, 500)
        })
    }

    clearTimer() {
        clearInterval(this.state.timer)
    }

    getAppleRequestCallback(data) {
        this.setState({appleRequest: data})
    }

    getAppleRequest() {
        this.props.authenticator.queuedGet(
            'apple/requests',
            this.getAppleRequestCallback,
            () => {
            },
            this.state.appleRequest.id);
    }

    sortByName(a, b) {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
    }

    render() {
        const request = this.state.appleRequest;
        return (
            <Container>
                {request &&
                <Accordion
                    as={Card}
                    border={'primary'}
                    key={'appleRequests'}
                >
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey={'appleRequests'}
                        className="text-center"
                    >
                        Apple Request: {request.id} | {request.status.name}
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={'appleRequests'}>
                        <Card.Body>
                            <Row>Account {request.author_email}</Row>
                            <Row>Scope {request.scope}</Row>
                            <Row>Created {moment(request.created).fromNow()}</Row>
                            {request.completed_on ? <Row>Completed
                                    On {moment(request.completed_on).fromNow()}</Row> :
                                <Row>{request.status.name}</Row>}
                            {(request.status.name !== 'DONE' && request.status.name !== 'ERROR') && request.pages > 0 &&
                            <div>
                                <div>
                                    Apple Request
                                    ETA: {moment().add((request.pages - request.current_page) * request.average_speed, 's').fromNow()}
                                </div>
                                <ProgressBar
                                    label={'Apple Pages: ' + request.current_page + '/' + request.pages}
                                    now={request.current_page / request.pages * 100}/>
                            </div>
                            }
                            {(request.status.name !== 'DONE' && request.status.name !== 'ERROR') && request.record_count &&
                            <div>
                                <div>
                                    Experience Master Sync
                                    ETA: {moment().add((request.record_count - request.current_record) * request.average_speed, 's').fromNow()}
                                </div>
                                <ProgressBar
                                    label={'Experience Master Records: ' + request.current_record + '/' + request.record_count}
                                    now={request.current_record / request.record_count * 100}/>
                            </div>
                            }

                            {request.created_contacts && request.created_contacts.length > 0 &&
                            <div>
                                <Table striped style={{textAlign: 'left'}}>
                                    <thead>
                                    <tr>
                                        <th colSpan={3}>{request.created_contacts.length} Created
                                            Contacts
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {request.created_contacts.sort(this.sortByName).map((contact, index, original) => {
                                        return <tr key={index}>
                                            <td width={'75%'}
                                                style={{textAlign: 'left'}}>
                                                <div>
                                                    <Link
                                                        to={'contacts/contact/' + contact.id + '/'}>{contact.name}</Link>
                                                </div>
                                            </td>
                                            <td><Button variant={'danger'}
                                                        size={'sm'}
                                                        data-contact-id={contact.id}
                                                        onClick={this.deleteButton}
                                            >X</Button></td>
                                        </tr>
                                    })}
                                    </tbody>
                                </Table></div>}

                            {request.updated_contacts && request.updated_contacts.length > 0 &&
                            <div>
                                <Table striped style={{textAlign: 'left'}}>
                                    <thead>
                                    <tr>
                                        <th colSpan={3}>{request.updated_contacts.length} Updated
                                            Contacts
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {request.updated_contacts.sort(this.sortByName).map((contact, index, original) => {
                                        return <tr key={index}>
                                            <td width={'75%'}
                                                style={{textAlign: 'left'}}>
                                                <div>
                                                    <Link
                                                        to={'contacts/contact/' + contact.id + '/'}>{contact.name}</Link>
                                                </div>
                                            </td>
                                            <td><Button variant={'danger'}
                                                        size={'sm'}
                                                        data-contact-id={contact.id}
                                                        onClick={this.deleteButton}
                                            >X</Button></td>
                                        </tr>
                                    })}
                                    </tbody>
                                </Table></div>}


                        </Card.Body>
                    </Accordion.Collapse>
                </Accordion>
                }

            </Container>
        )
    }
}

