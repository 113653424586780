import moment from "moment-timezone";
import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import {Link} from "react-router-dom";


export default class TodaysEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            todaysEvents: []
        };
        this.getEvents = this.getEvents.bind(this);
        this.getEventsCallback = this.getEventsCallback.bind(this);
    }

    componentDidMount() {
        this.getEvents();
    }

    getEventsCallback(data) {
        this.setState({todaysEvents: data})
    }

    getEvents() {
        this.props.authenticator.queuedGet('scheduled_events', this.getEventsCallback, () => {
        })
    }


    render() {
        if (!this.state.todaysEvents || (this.state.todaysEvents && this.state.todaysEvents.length === 0)) {
            return <span id={'no-events'}/>
        }
        return <>
            <Card className={'todays-events-card'}>
                <Card.Body>
                    <Card.Header>Today's Events</Card.Header>
                    <Card.Body className={'todays-events-body'}>
                        <Table className={'todays-events-table'}>
                            <tbody>
                            {this.state.todaysEvents && this.state.todaysEvents.map((event, index) => {
                                return <tr key={index}>
                                    <td align={'left'} className={'todays-events-cell'}><a
                                        href={event.link}>{event.summary.substring(0,6)}</a>
                                    </td>
                                    {!event.start &&
                                    <td colSpan={2} className={'todays-events-cell'}>All Day</td>}
                                    {event.start &&
                                        <td className={'todays-events-cell'}>{moment(event.start).format('LT')}</td>}
                                    {event.start &&
                                        <td className={'todays-events-cell'}>{moment(event.start).fromNow()}</td>}
                                    {event.guest_contact_id ?
                                        <td className={'todays-events-cell'}>
                                            <Link
                                                to={'/contacts/contact/' + event.guest_contact_id.toString()}>{event.guest_name}</Link>
                                        </td>
                                        :
                                        <td className={'todays-events-cell'}>
                                            {event.guest_name}
                                        </td>
                                    }
                                </tr>
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card.Body>
            </Card>
            <div style={{marginBottom: '1rem'}}/>
        </>
    }
}
