import moment from "moment-timezone";
import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import zipcode_to_timezone from "zipcode-to-timezone";


export default class AddressForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,

            id: null,

            street: '',
            streetIsValid: false,
            streetValidationmessage: '',
            extendedaddress: '',
            extendedaddressIsValid: false,
            extendedaddressValidationmessage: '',
            city: '',
            cityIsValid: false,
            cityValidationmessage: '',
            region: '',
            regionIsValid: false,
            regionValidationmessage: '',
            postalcode: '',
            postalcodeIsValid: false,
            postalcodeValidationmessage: '',
            country: '',
            countryIsValid: false,
            countryValidationmessage: '',
            timezone: '',
            timezoneIsValid: false,
            timezoneValidationmessage: '',
            addresstype: '',
            addresstypeIsValid: false,
            addresstypeValidationmessage: '',
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitErrorCallback = this.onSubmitErrorCallback.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
        this.suggestTimezone = this.suggestTimezone.bind(this);
    }

    componentDidMount() {
        const address = this.props.address;
        if (address) {
            this.setState({
                id: address.id,
                street: address.street  || '',
                extendedaddress: address.extended_address  || '',
                city: address.city  || '',
                region: address.region  || '',
                postalcode: address.postal_code  || '',
                country: address.country  || '',
                timezone: address.timezone,
                addresstype: address.address_type  || ''
            });
        }
    }

    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates, this.suggestTimezone);

    }

    suggestTimezone() {
        if (!this.state.timezone && this.state.postalcode) {
            const suggestedTimezone = zipcode_to_timezone.lookup(
                this.state.postalcode);
            if (suggestedTimezone) {
                this.setState({timezone:suggestedTimezone})
            }
        }
    }

    onSubmitErrorCallback(data, status) {
        this.setState({isSaved: false, submitted: false, errors: data})
    }

    onSubmitCallback(data) {
        if (!!!data) {return;}
        this.setState({show: false, success: true, isSaved: true});
        this.props.callback(data);
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        let data = {
            contact: this.props.contact.id,
            street: this.state.street,
            extended_address: this.state.extendedaddress,
            city: this.state.city,
            region: this.state.region,
            postal_code: this.state.postalcode,
            country: this.state.country,
            timezone: this.state.timezone,
            address_type: this.state.addresstype
        };
        if (this.state.id) {
            data.id = this.state.id
        }

        if (this.state.id) {
            this.props.authenticator.queuedPut(
                'locations',
                this.state.id,
                data,
                this.onSubmitCallback,
                this.onSubmitErrorCallback
            )
        } else {
            this.props.authenticator.queuedPost(
                'locations',
                data,
                this.onSubmitCallback,
                this.onSubmitErrorCallback
            )
        }
    }

    timezoneList() {
        const timeZones = moment.tz.names().map((tz) => {
            return tz
        });
        return timeZones
    }

    timezoneOptionList() {
        const timezones = this.timezoneList();
        let optionList = [];
        optionList.push(
            <option key={'-null'}
                    value=''
            >None Selected</option>
        );
        // eslint-disable-next-line no-unused-vars
        timezones.map((value, index) => {
            optionList.push(
                <option key={index}
                        value={value}
                >{value}</option>
            );
            return ''
        });
        return optionList;
    }

    render() {
        return <Container>
            <Form onSubmit={this.onSubmit}
                  validated={!this.state.isSaved}>
                {this.state.errors && this.state.errors.detail &&
                <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                {this.state.errors && this.state.errors.non_field_errors &&
                <Alert
                    variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                <FormGroup>
                    <Form.Label column={'street'}>
                        Street
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'street'}
                                  name={'street'}
                                  autoComplete="street"
                                  onChange={this.onFieldChange}
                                  value={this.state.street}
                                  isInvalid={!!this.state.errors.street}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.street}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.streetValidationmessage}
                    </Form.Control.Feedback>
                    <Form.Label column={'extendedaddress'}>
                        Extended Address
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'extendedaddress'}
                                  name={'extendedaddress'}
                                  autoComplete="extendedaddress"
                                  onChange={this.onFieldChange}
                                  value={this.state.extendedaddress}
                                  isInvalid={!!this.state.errors.extendedaddress}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.extended_address}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.extendedaddressValidationmessage}
                    </Form.Control.Feedback>
                    
                    <Form.Label column={'city'}>
                        City
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'city'}
                                  name={'city'}
                                  onChange={this.onFieldChange}
                                  value={this.state.city}
                                  isInvalid={!!this.state.errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.city}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.cityValidationmessage}
                    </Form.Control.Feedback>
                    
                    <Form.Label column={'region'}>
                        Region
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'region'}
                                  name={'region'}
                                  onChange={this.onFieldChange}
                                  value={this.state.region}
                                  isInvalid={!!this.state.errors.region}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.region}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.regionValidationmessage}
                    </Form.Control.Feedback>
                    
                    <Form.Label column={'postalcode'}>
                        Postal Code
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'postalcode'}
                                  name={'postalcode'}
                                  onChange={this.onFieldChange}
                                  value={this.state.postalcode}
                                  isInvalid={!!this.state.errors.postalcode}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.postal_code}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.postalcodeValidationmessage}
                    </Form.Control.Feedback>

                    <Form.Label column={'country'}>
                        Country
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'country'}
                                  name={'country'}
                                  onChange={this.onFieldChange}
                                  value={this.state.country}
                                  isInvalid={!!this.state.errors.country}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.country}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.countryValidationmessage}
                    </Form.Control.Feedback>

                    <Form.Label column={'timezone'}>
                        Timezone
                    </Form.Label>
                    <Form.Control as="select"
                                  id={'timezone'}
                                  name={'timezone'}
                                  value={this.state.timezone || ''}
                                  onChange={this.onFieldChange}
                                  isInvalid={!!this.state.errors.timezone}
                    >
                        {this.timezoneOptionList()}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.timezone}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.timezoneValidationmessage}
                    </Form.Control.Feedback>

                    <Form.Label column={'addresstype'}>
                        Address Type (optional)
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'addresstype'}
                                  name={'addresstype'}
                                  onChange={this.onFieldChange}
                                  value={this.state.addresstype}
                                  isInvalid={!!this.state.errors.address_type}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.address_type}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.addresstypeValidationmessage}
                    </Form.Control.Feedback>
                </FormGroup>
                <Button type={'submit'}
                        disabled={this.state.isSaved}>Submit</Button>
            </Form>

        </Container>
    }
}