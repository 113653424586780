import moment from "moment-timezone";
import React, {Component} from "react";
import Spinner from "react-bootstrap/Spinner";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {Link} from "react-router-dom";
import {SubscriptionCheck} from "./Subscriptions";


export default class TransactionStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: [],
            loading: true,
            requestedMoment: moment(),
            startingMoment: null,
            startDateString: '',
            endingMoment: null,
            endDateString: '',
            weekOfMoments: [],
            dictOfMoments: []
        };
        this.getStats = this.getStats.bind(this);
        this.processStats = this.processStats.bind(this);
        this.dayPicked = this.dayPicked.bind(this);
        this.lastWeek = this.lastWeek.bind(this);
        this.nextWeek = this.nextWeek.bind(this);
        this.addStats = this.addStats.bind(this);
        this.getData = this.getData.bind(this);
        this.getTimings = this.getTimings.bind(this);
        this.getBirthdays = this.getBirthdays.bind(this);
    }

    componentDidMount() {
        this.setDates();
    }

    processStats(data) {
        let prepped = data.map((record) => {
            let result = record.result.map((resultRecord) => (
                {
                    id: resultRecord.id,
                    type: resultRecord.type,
                    contact: resultRecord.contact,
                    created: moment(resultRecord.created),
                    link: resultRecord.link
                }
            ));
            return {day: moment(record.day), result: result};
        });

        this.addStats(prepped);
    }

    addStats(newStats) {
        let newDictOfMoments = this.state.dictOfMoments;
        newStats.forEach((record) => {
            const day = record.day;
            const result = record.result;
            const weekDay = this.state.weekOfMoments.find(
                (weekDay) => {
                    return weekDay.isSame(day, 'day')
                });
            let newList = newDictOfMoments[weekDay].concat(result);
            newList.sort((a, b) => {
                if (a.created < b.created) {
                    return -1;
                }
                if (a.created > b.created) {
                    return 1;
                }
                return 0;
            });
            newDictOfMoments[weekDay] = newList;
        });

        this.setState({dictOfMoments: newDictOfMoments, loading: false})
    }

    getStats() {
        this.props.authenticator.queuedGet('transcript_stats',
            this.processStats,
            () => {},
            null,
            {start_date: this.state.startDateString,
             end_date: this.state.endDateString,
             tzinfo: this.props.authenticator.timeZoneName}
        )
    }

    getEventStats() {
        this.props.authenticator.queuedGet('event_stats',
            this.processStats,
            () => {},
            null,
            {start_date: this.state.startDateString,
             end_date: this.state.endDateString,
             tzinfo: this.props.authenticator.timeZoneName}
        )
    }

    getTimings() {
        this.props.authenticator.queuedGet('todays_timings',
            this.processStats,
            () => {},
            null,
            {start_date: this.state.startDateString,
             end_date: this.state.endDateString,
             tzinfo: this.props.authenticator.timeZoneName}
        )
    }

    getBirthdays() {
        this.props.authenticator.queuedGet('todays_birthdays',
            this.processStats,
            () => {},
            null,
            {start_date: this.state.startDateString,
             end_date: this.state.endDateString,
             tzinfo: this.props.authenticator.timeZoneName}
        )
    }

    addNow() {
        const now = moment();
        if (this.state.startingMoment < now && now < this.state.endingMoment) {
            const weekDay = this.state.weekOfMoments.find(
                (weekDay) => {
                    return weekDay.isSame(now, 'day')
                });
            if (!weekDay) {
                debugger;
            }
            const nowData = {day: weekDay, result: {
                id: null,
                type: null,
                contact: {name: 'NOW'},
                created: moment()
            }};
            this.addStats([nowData]);
        }
    }

    getData() {
        this.addNow();
        this.getBirthdays();
        this.getStats();
        this.getEventStats();
    }

    setDates() {
        const requestedMoment = this.state.requestedMoment;
        const thisSaturday = requestedMoment.clone().weekday(6);
        let year = thisSaturday.year();
        let correctMonth = thisSaturday.month() + 1;
        let dayDate = thisSaturday.date();
        const end = year + '-' + correctMonth + '-' + dayDate;

        const lastSunday = requestedMoment.clone().weekday(0);
        year = lastSunday.year();
        correctMonth = lastSunday.month() + 1;
        const start = year + '-' + correctMonth + '-' + lastSunday.date();

        // const tzinfo = this.props.authenticator.timeZoneName;

        const weekOfMoments = [0,1,2,3,4,5,6].map((dayNumber) => {return lastSunday.clone().add(dayNumber, 'days')});
        const dictOfMoments = {};
        weekOfMoments.forEach((dayMoment) => (dictOfMoments[dayMoment] = []));

        this.setState({
            startingMoment: lastSunday,
            startDateString: start,
            endingMoment: thisSaturday,
            endDateString: end,
            weekOfMoments: weekOfMoments,
            dictOfMoments: dictOfMoments}, this.getData);
        // return {start: start, end: end, tzinfo: tzinfo};
    }

    dayPicked(day) {
        const newMoment = moment(day);
        this.setState({requestedMoment: newMoment}, this.setDates);
    }

    lastWeek() {
        const newMoment = this.state.requestedMoment.subtract(7, 'days')
        this.setState({requestedMoment: newMoment}, this.setDates);
    }

    nextWeek() {
        const newMoment = this.state.requestedMoment.add(7, 'days')
        this.setState({requestedMoment: newMoment}, this.setDates);
    }

    typeIcon(typeDict) {
        if (!typeDict) {
            return <span
                role="img"
                aria-label={'Now'}
                aria-hidden={'false'}>
                ⌚
            </span>
        }

        if (!typeDict.name) {
            return <span
                role="img"
                aria-label={'Note'}
                aria-hidden={'false'}>
                📝
            </span>
        }
        if (typeDict.id === 20) {
            return <span
                role="img"
                aria-label={'Email'}
                aria-hidden={'false'}>📧</span>
        }
        if (typeDict.id === 24) {
            return <span
                role="img"
                aria-label={'View'}
                aria-hidden={'false'}>👀</span>
        }

        if (typeDict.name === 'scheduled_event') {
            return <span
                role="img"
                aria-label={'Event'}
                aria-hidden={'false'}>
                📅
            </span>
        }

        if (typeDict.name === 'birthday') {
            return <span
                role="img"
                aria-label={'Birthday'}
                aria-hidden={'false'}>
                🎁
            </span>
        }
        if (typeDict.name === 'timing') {
            return <span
                role="img"
                aria-label={'Timing'}
                aria-hidden={'false'}>
                ⏲️
            </span>
        }
    }

    entryNameTag(entry) {
        if (entry.link) {
            return <Link to={entry.link}>{entry.contact.name}</Link>
        }
        if (entry.contact) {
            if (entry.contact.id) {
                return <Link to={'/contacts/contact/' + entry.contact.id}>
                    {entry.contact.name}
                </Link>
            }
            if (entry.contact.name) {
                return <span>{entry.contact.name}</span>
            }
        }

    }

    entryTime(entry) {
        const entryTime = moment(entry.created);
        var twelveOOne = entryTime.clone().set({h:0, m:1});
        var isBefore = entryTime.isBefore(twelveOOne);
        if (isBefore) {
            return <span />
        }

        var ElevenFiftyNine = entryTime.clone().set({h:23, m:58});
        var isAfter = entryTime.isAfter(ElevenFiftyNine);
        if (isAfter) {
            return <span />
        }

        return entry.created ? entryTime.format('LT') : moment().format('LT')
    }

    entryIndexStyle(entry) {
        let outputString = 'week-day-item-index ';
        if (entry.type && entry.type.name === 'timing') {
            switch (entry.contact.status) {
                case 3:
                    outputString += 'never';
                    break;
                case 12:
                    outputString += 'first-contact';
                    break;
                case 27:
                    outputString += 'thank-you';
                    break;
                case 34:
                    outputString += 'sooner';
                    break;
                case 11:
                    outputString += 'later';
                    break;
                case 25:
                    outputString += 'sleep';
                    break;
                default:
            }
        }
        return outputString
    }


    render() {
        if (this.state.loading) {
            return <Spinner animation="border"
                            role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        }
        console.log('TransactionStats.render');

        return <>
            <SubscriptionCheck authenticator={this.props.authenticator}/>
            <div style={{
                paddingLeft: '2rem',
                paddingRight: '2rem',
                textAlign: 'left',
                height: '90vh'
            }}>
                <div className={'skin-row'}>
                    <div className={'skin-column'}>
                        <button className={'skin-button'}
                                onClick={this.lastWeek}>&lt;--- Previous Week
                        </button>
                    </div>
                    <div className={'skin-column'}
                         style={{textAlign: 'center'}}>

                        <DayPickerInput
                            value={this.state.requestedMoment.toDate()}
                            onDayChange={this.dayPicked}/>

                    </div>
                    <div className={'skin-column'}>
                        <button className={'skin-button'}
                                onClick={this.nextWeek}>Next Week
                            ---&gt;</button>
                    </div>
                </div>

                <div className={'skin-row'}>
                    <div className={'skin-column'}>
                    <div className={'week-month'}>
                        {this.state.startingMoment && <span>{this.state.startingMoment.format('MMMM YYYY')} </span>}
                    </div>
                    </div>
                    <div className={'skin-column'} style={{flexGrow: 0}}>
                    <button className={'skin-button'}
                            onClick={this.getTimings}>Timings
                    </button>
                    </div>
                </div>
                <div className={'week-table'} style={{height: '100%'}}>
                    {this.state.weekOfMoments.map((day, index, array) => {
                        return <div key={index} className={'week-column'}>
                                <div className={'week-day-header'}>
                                    <div
                                        className={(day.isSame(moment(), 'day')) ? 'week-day-date-box week-day-today-circle' : 'week-day-date-box'}>
                                        {day.toDate().toLocaleString(navigator.language, {weekday: 'short'})}<br/>{day.date()}
                                    </div>
                                </div>
                                <div className={'week-day-body'}>
                                    {this.state.dictOfMoments[day].length > 0 && this.state.dictOfMoments[day].map((entry, itemIndex) => {
                                        return <div key={itemIndex}
                                                    className={'week-day-item'}>

                                            <div className={'week-day-item-inline'}>
                                                <div className={this.entryIndexStyle(entry)}>
                                                    {this.typeIcon(entry.type)}
                                                </div>
                                                <div className={'week-day-item-body'}>
                                                    <div className={'week-day-item-name'}>
                                                        {this.entryNameTag(entry)}
                                                    </div>
                                                    <div className={'week-day-item-time'}>
                                                        {this.entryTime(entry)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                    })}
                </div>

                <div style={{marginBottom: '3.6rem'}}/>
            </div>
        </>
    }


}
