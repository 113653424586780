import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment-timezone";
import React, {Component} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';


import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import {Redirect} from "react-router-dom";
import './MainList.css';
import {SubscriptionCheck} from "./Subscriptions";


class MergeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onMergeCallback = this.onMergeCallback.bind(this);
    }

    onMergeCallback(data) {
        this.setState({redirect: data.id})
    }

    onButtonClick(event) {
        const data = {
            ids: this.props.selectedContacts.map((contact)=>(contact.id)),
            bulk_action: 'merge'
        };

        this.props.authenticator.queuedPut(
            'contacts/bulk',
            0,
            data,
            this.onMergeCallback,
            () => {})
    }

    render() {
        let contacts = [...this.props.selectedContacts];

        const firstContact=contacts.shift();

        if (contacts.length === 0) {
            return <></>
        }

        if (this.state.redirect) {
            return <Redirect to={'/contacts/contact/' + this.state.redirect + '/'} />
        }

        return <Modal
            show={this.props.show}
            size="lg"
            centered
            onHide={this.props.hide}
            className={'skin-modal'}
        >
            <Modal.Header closeButton>
                <Modal.Title>Merge Contacts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You are about to merge "primary" Contact ID: {firstContact.id} - Name : {firstContact.name} - Score : {firstContact.score}.</p>
                <p>The "primary" contact will be saved. If this is not the right one, "cancel" and click the correct one first, before the "secondary" ones.</p>
                <p>These {contacts.length} "secondary" Contacts will be merged and deleted:</p>
                {contacts.map((contact, index) => {
                    return <div key={index}>{contact.id} - {contact.name}</div>
                })}
                <p>The "secondary" contact data, except Score, is moved to the first, then all "secondary" contacts are deleted.</p>
                <p><strong>There is no UNDO at this time.</strong></p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.onButtonClick}>Click to Merge {1+contacts.length} Contacts.</Button>
            </Modal.Footer>
        </Modal>
    }

}



class BulkActionButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMergeModal: false,
        };
        this.onClick = this.onClick.bind(this);
        this.bulkDelete = this.bulkDelete.bind(this);
        this.bulkChangeStatus = this.bulkChangeStatus.bind(this);
        this.bulkCallback = this.bulkCallback.bind(this);
        this.merge = this.merge.bind(this);
        this.merge = this.merge.bind(this);
        this.showMergeModal = this.showMergeModal.bind(this);
        this.hideMergeModal = this.hideMergeModal.bind(this);
    }

    showMergeModal() {

        this.setState({showMergeModal: true})
    }
    hideMergeModal() {

        this.setState({showMergeModal: false})
    }

    bulkCallback(data) {
        this.hideMergeModal();
        this.props.callback();
    }

    bulkChangeStatus(status) {
        const data = {
            ids: this.props.selectedContacts.map((contact)=>(contact.id)),
            status: status,
            bulk_action: 'status'
        };
        this.props.authenticator.queuedPut(
            'contacts/bulk',
            0,
            data,
            this.bulkCallback,
            () => {})
    }

    bulkDelete() {
        const data = {
            ids: this.props.selectedContacts.map((contact)=>(contact.id)),
            bulk_action: 'delete'
        };
        this.props.authenticator.queuedPut(
            'contacts/bulk',
            0,
            data,
            this.bulkCallback,
            () => {})
    }

    merge() {
        this.showMergeModal();
    }

    onClick(event) {
        const value = event.currentTarget.dataset.value;
        const validStatusValues = ['3', '12', '27', '34', '11', '25']
        const isStatus = validStatusValues.includes(value);
        if (isStatus) {
            return this.bulkChangeStatus(value)
        }
        if (value === 'Delete') {
            return this.bulkDelete();
        }
        return this.merge();
    }

    render() {

        const selectorList = [
            {value: 3, label: 'Never'},
            {value: 12, label: 'First-Contact'},
            {value: 27, label: 'Thank You'},
            {value: 34, label: 'Sooner'},
            {value: 11, label: 'Later'},
            {value: 25, label: 'Sleep'},
        ];

        const actionList = ['Delete', 'Merge'];

        return <>
            <MergeModal
                show={this.state.showMergeModal}
                hide={this.hideMergeModal}
                authenticator={this.props.authenticator}
                selectedContacts={this.props.selectedContacts}
                preparedContacts={this.props.preparedContacts}/>
            <Row style={{height: '3rem'}}>
                {this.props.selectedContacts.length > 0 && <>
                <Col md={2}>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Select Bulk Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>Statuses</Dropdown.Header>
                            {selectorList.map(
                                (item, index) => {
                                    return <Dropdown.Item key={index} data-value={item.value} onClick={this.onClick}>{item.label}</Dropdown.Item>
                                })
                                }
                            <Dropdown.Divider />
                            <Dropdown.Header>Actions</Dropdown.Header>
                            {actionList.map(
                                (action, index) => {
                                    return <Dropdown.Item key={index} data-value={action} onClick={this.onClick}>{action}</Dropdown.Item>
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col md={2}>
                    {this.props.selectedContacts.length} of {this.props.preparedContacts.length} selected.
                </Col>
                    </>}
            </Row>
        </>
    }

}


class MainContactList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: null,
            selectedContact: null,
            selectedContacts: [],
            preparedContacts: []
        };
        this.getContacts = this.getContacts.bind(this);
        this.getContactsCallback = this.getContactsCallback.bind(this);
        this.getData = this.getData.bind(this);
        this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
        this.callbackAfterStatusUpdate = this.callbackAfterStatusUpdate.bind(this);
        this.onSelectRow = this.onSelectRow.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
    }

    componentDidMount() {
        this.getContacts();
    }

    getContactsCallback(data) {
        this.setState({contacts: data}, this.getData)
    }


    getContacts() {
        this.props.authenticator.queuedGet(
            'contacts/contact_main_list', this.getContactsCallback)
    }


    onContactSelect = (contactId) => {
        this.setState({selectedContact: contactId});
        // this.props.handleContactChange(contact);
        // window.location = '/contacts/contact/' + contactId;
    };


    getData() {
        // const statusTypeDict = this.props.authenticator.library.status_type_dict;
        this.setState({
            preparedContacts: this.state.contacts.map(contact => {
                return {
                    id: contact.id,
                    name: contact.name,
                    score: contact.score,
                    last_contact: contact.last_contact,
                    last_viewed: contact.last_viewed,
                    next_ping: contact.next_ping,
                    status: contact.status, // && statusTypeDict[contact.status],
                    profession_description: contact.profession_description,
                    industry_description: contact.industry_description
                }
            })
        })
    }

    getColumns() {
        const selectorList = [
            {value: 3, label: 'Never'},
            {value: 12, label: 'First-Contact'},
            {value: 27, label: 'Thank You'},
            {value: 34, label: 'Sooner'},
            {value: 11, label: 'Later'},
            {value: 25, label: 'Sleep'},
        ];
        const statusTypeDict = this.props.authenticator.library.status_type_dict;

        return [{
            dataField: 'name',
            text: 'Name',
            sort: true,
            filter: textFilter(),
            editable: false,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    this.onContactSelect(row.id)
                }
            }
        }, {
            dataField: 'score',
            text: 'Score',
            sort: true,
            formatter: (cell, row) => {
                return Math.trunc(cell)
            },
            style: (cell, row, rowIndex, colIndex) => {
                if (cell >= 50) {
                    return {
                        backgroundColor: '#81c784'
                    };
                }
            },
            editable: false
        }, {
            dataField: 'last_contact',
            text: 'Last Contact',
            sort: true,
            type: 'date',
            formatter: (cell, row) => {
                return cell ? moment(cell).fromNow() : 'Never'
            },
            editable: false
        }, {
            dataField: 'last_viewed',
            text: 'Last Viewed',
            sort: true,
            type: 'date',
            formatter: (cell, row) => {
                return cell ? moment(cell).fromNow() : 'Never'
            },
            classes: 'desktop-only',
            headerClasses: 'desktop-only',
            editable: false
        }, {
            dataField: 'next_ping',
            text: 'Reminder',
            sort: true,
            type: 'date',
            formatter: (cell, row) => {
                return cell ? moment(cell).fromNow() : 'Never'
            },
            classes: 'desktop-only',
            headerClasses: 'desktop-only',
            editable: false
        }, {
            dataField: 'status',
            text: 'Timing',
            sort: true,
            filter: textFilter(),
            filterValue: (cell, row) => statusTypeDict[cell],
            editor: {
                type: Type.SELECT,
                options: selectorList
            },
            formatter: (cell, row) => {
                return cell ? statusTypeDict[cell] : ''
            },
        }, {
            dataField: 'profession_description',
            text: 'Profession',
            sort: true,
            filter: textFilter(),
            classes: 'desktop-only',
            headerClasses: 'desktop-only',
            editable: false
        }, {
            dataField: 'industry_description',
            text: 'Industry',
            sort: true,
            filter: textFilter(),
            classes: 'desktop-only',
            headerClasses: 'desktop-only',
            editable: false
        }
        ];
    }

    getRowEvents() {
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.onContactSelect(row.id)
            }
        };
        return rowEvents;
    }

    getDefaultSorted() {
        return [{
            dataField: 'last_contact', // if dataField is not match to any column you defined, it will be ignored.
            order: 'desc' // desc or asc
        }];
    }


    onSelectAll(isSelect, rows, e) {
        if (isSelect) {
            this.setState({
                selectedContacts: rows
            });
        } else {
            this.setState({
                selectedContacts: []
            });
        }
    }

    onSelectRow(row, isSelect, rowIndex, e) {
        const selectedContact = row;
        const index = this.state.contacts.findIndex(contact => contact.id === selectedContact.id);
        if (isSelect) {
            if (index > -1) {
                this.setState({
                    selectedContacts: [...this.state.selectedContacts, selectedContact]
                });
            }
        } else {
            if (this.state.selectedContacts.length === 1) {
                this.setState({selectedContacts: []});
                return
            }
            if (index > -1) {
                let newContacts = [...this.state.selectedContacts];
                newContacts.splice(index, 1);
                this.setState({
                    selectedContacts: newContacts
                });
            }
        }
    }

    callbackAfterStatusUpdate(contact) {
        let newContacts = [...this.state.contacts];
        // eslint-disable-next-line array-callback-return
        newContacts.find((o, i) => {
            if (o.id === contact.id) {
                newContacts[i] = contact;
                return true;
            }
        });
        this.getContactsCallback(newContacts);
    }

    onAfterSaveCell(oldValue, newValue, row, column) {
        const contact = row;
        this.props.authenticator.queuedPut(
            'contacts',
            contact.id,
            {id: contact.id, status: newValue},
            this.callbackAfterStatusUpdate,
            () => {
            },
        )
    }

    cellEdit = () => {
        return cellEditFactory({
            mode: 'click',
            blurToSave: true,
            // onStartEdit: (row, column, rowIndex, columnIndex) => { console.log('start to edit!!!', row, column, rowIndex, columnIndex); },
            // beforeSaveCell: (oldValue, newValue, row, column) => { console.log('Before Saving Cell!!', oldValue, newValue, row, column); },
            afterSaveCell: (oldValue, newValue, row, column) => {
                this.onAfterSaveCell(oldValue, parseInt(newValue), row, column);
            }
        })
    };

    render() {
        if (!this.props.authenticator) {
            return <div>NO AUTH</div>
        }
        if (this.state.selectedContact) {
            return <Redirect
                to={'/contacts/contact/' + this.state.selectedContact}/>
        }

        if (!!!this.state.contacts) {
            return <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        }


        // const selectorList = [
        //     {value: 3, label: 'Never'},
        //     {value: 12, label: 'First-Contact'},
        //     {value: 27, label: 'Thank You'},
        //     {value: 34, label: 'Sooner'},
        //     {value: 11, label: 'Later'},
        //     {value: 25, label: 'Sleep'},
        // ];
        // const statusTypeDict = this.props.authenticator.library.status_type_dict;


        return <>
            <SubscriptionCheck authenticator={this.props.authenticator}/>
            <Container>
                <BulkActionButton
                    authenticator={this.props.authenticator}
                    selectedContacts={this.state.selectedContacts}
                    preparedContacts={this.state.preparedContacts}
                    callback={this.getContacts}
                />
                <Row>
                    <BootstrapTable keyField='id'
                                    classes={'data-table-container'}
                                    data={this.state.preparedContacts}
                                    columns={this.getColumns()}
                                    striped={true}
                                    hover={true}
                                    bootstrap4={true}
                        // rowEvents={this.getRowEvents()}
                                    defaultSorted={this.getDefaultSorted()}
                                    filter={filterFactory()}
                                    selectRow={{
                                        mode: 'checkbox',
                                        onSelect: this.onSelectRow,
                                        onSelectAll: this.onSelectAll
                                    }}
                                    cellEdit={this.cellEdit()}
                    />
                </Row>
                <div style={{marginBottom: '3.6rem'}}/>
            </Container>
        </>
    }

}

export default MainContactList;
