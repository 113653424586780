import moment from "moment-timezone";
import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import {Redirect} from "react-router";
import AddGuestModal from "./AddGuestModal";

export default class TokenedEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            scheduledEvent: null,
            datetime: null,
            showAddGuestModal: false,
            guests: [],
            deleted: false,
            close: false
        };
        this.getRecordCallback = this.getRecordCallback.bind(this);
        this.addGuest = this.addGuest.bind(this);
        this.clickAddGuest = this.clickAddGuest.bind(this);
        this.cancelAddGuest = this.cancelAddGuest.bind(this);
        this.saveAndNotify = this.saveAndNotify.bind(this);
        this.putRecord = this.putRecord.bind(this);
        this.clickCancelEvent = this.clickCancelEvent.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
        this.putRecordCallback = this.putRecordCallback.bind(this);
        this.closeButton = this.closeButton.bind(this);
    }

    componentDidMount() {
        this.getRecord()
    }

    getRecordCallback(data) {
        const datetime = moment(data.datetime);
        this.setState({
            scheduledEvent: data,
            datetime: datetime,
            guests: data.scheduledeventguest_set
        });
    }

    getRecord() {
        this.props.authenticator.queuedGet(
            'tokened_event',
            this.getRecordCallback,
            null,
            this.props.token
        )
    }

    saveAndNotify() {
        this.putRecord()
    }

    putRecordCallback(data) {
        const datetime = moment(data.datetime);
        this.setState({
            scheduledEvent: data,
            datetime: datetime,
            guests: data.scheduledeventguest_set,
            close: true
        }, this.props.callback);
    }


    putRecord() {
        const scheduledEvent = this.state.scheduledEvent;
        const data = {
            id: scheduledEvent.id,
            status: scheduledEvent.status,
            event_type: scheduledEvent.event_type.id,
            datetime: scheduledEvent.datetime,
            end_datetime: scheduledEvent.end_datetime,
            request: scheduledEvent.request,
            event_url: scheduledEvent.event_url,
            token: scheduledEvent.token,
            scheduledeventguest_set: this.state.guests
        };

        this.props.authenticator.queuedPut(
            'tokened_event',
            this.props.token,
            data,
            this.putRecordCallback,
            null
        )
    }

    addGuest(guest) {
        this.setState({guests: [...this.state.guests, guest],
            showAddGuestModal: false
        })
    }

    clickAddGuest(event) {
        event.preventDefault();
        this.setState({showAddGuestModal: true});
    }

    cancelAddGuest() {
        this.setState({showAddGuestModal: false});
    }

    deleteCallback() {
        this.setState({deleted: true}, this.props.callback);
    }

    clickCancelEvent(event) {
        event.preventDefault();
        const scheduledEvent = this.state.scheduledEvent;
        const data = {
            id: scheduledEvent.id
        };

        this.props.authenticator.queuedDelete(
            'tokened_event',
            this.props.token,
            data,
            this.deleteCallback
        )
    }

    closeButton(event) {
        this.setState({close: true}, this.props.callback);
    }

    render() {

        if (!this.state.scheduledEvent) {
            return <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        }

        if (this.state.deleted || this.state.close) {
            return <Redirect to={'/' + this.props.publicName} />
        }

        return <Container>
            {!this.state.scheduledEvent && <div>Loading...</div>}
            {this.state.scheduledEvent && this.state.showAddGuestModal &&
            <AddGuestModal
                authenticator={this.props.authenticator}
                scheduledEvent={this.state.scheduledEvent}
                show={this.state.showAddGuestModal}
                callback={this.addGuest}
                cancelCallback={this.cancelAddGuest}
                />
            }
            <Card style={{textAlign: 'center'}}>
                <Card.Header>
                    <h3>
                        {this.state.scheduledEvent && this.state.scheduledEvent.event_type.name}
                    </h3>
                </Card.Header>
                <Card.Body>
                    <h4>
                        Meeting With
                    </h4>
                    <h3>
                        {this.state.scheduledEvent &&
                        this.state.scheduledEvent.event_type.account_name}
                    </h3>
                    <div>
                        {this.state.datetime.format('dddd LL LT')}
                        &nbsp;{this.props.authenticator.timeZoneAbbreviation}&nbsp;
                    </div>
                    <div>
                        {this.state.scheduledEvent.event_type.duration} Minutes
                    </div>
                    <br/>
                    <h3 style={{textAlign: 'center'}}>
                        Guests
                    </h3>
                    <div>
                        {this.state.guests.map(
                            (guest, index) => (
                                <Row key={index}>
                                    <Col>{guest.name}</Col>
                                    <Col>{guest.email}</Col>
                                </Row>
                            ))}
                    </div>
                    {this.state.scheduledEvent.scheduledeventanswer_set.length > 0 && <>
                    <h3 style={{textAlign: 'center'}}>
                        Questions and Answers
                    </h3>
                    <div>
                        {this.state.scheduledEvent.scheduledeventanswer_set.map(
                            (answer, index) => (
                                <Row key={index}>
                                    <Col>Put Question Here?</Col>
                                    <Col>{answer.answer}</Col>
                                </Row>
                            ))}
                    </div>
                    </>}
                </Card.Body>
                <Card.Footer>
                    <Button block onClick={this.saveAndNotify}>Save & Notify</Button>
                    <Button block variant={'secondary'} onClick={this.closeButton}>Close</Button>
                    {/*<Button block onClick={this.clickAddGuest} variant={'info'}>Add Guest</Button>*/}
                    {/*<Button block>Update Answers</Button>*/}
                    <Button block variant={'warning'} onClick={this.clickCancelEvent}>Cancel Event</Button>
                </Card.Footer>
            </Card>




        </Container>
    }

}