import _ from 'lodash';
import moment from "moment-timezone";
import Papa from "papaparse"
import React, {Component} from "react";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";


const PARSER_CONFIG = {
    delimiter: "",	// auto-detect
    newline: "",	// auto-detect
    quoteChar: '"',
    escapeChar: '"',
    header: false,
    transformHeader: undefined,
    dynamicTyping: false,
    preview: 0,
    encoding: "",
    worker: false,
    comments: false,
    step: undefined,
    complete: undefined,
    error: undefined,
    download: false,
    downloadRequestHeaders: undefined,
    skipEmptyLines: true,
    chunk: undefined,
    fastMode: undefined,
    beforeFirstChunk: undefined,
    withCredentials: undefined,
    transform: undefined,
    delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
};

const SINGLE_FIELD_NAMES = [
    'given_name',
    'family_name',
    'additional_name',
    'name_prefix',
    'name_suffix',
    'nickname',
    'name',
    'birthday',
    'notes',
    'gender',
    'created',
    'type_description',
    'industry_description',
    'profession_description',
];

const REPEATING_FIELD_NAMES = [
    'email',
    'email_type',
    'phone',
    'phone_type',
    'url',
    'url_type',
    'address_type',
    'extended_address',
    'city',
    'region',
    'postal_code',
    'country',
    'po_box',
    'street'
];

const ALL_FIELD_NAMES = [...SINGLE_FIELD_NAMES, ...REPEATING_FIELD_NAMES];


const EMAIL_FIELDS = ['email',
    'email_type'];

const PHONE_FIELDS = ['phone',
    'phone_type'];

const URL_FIELDS = ['url',
    'url_type'];

const ADDRESS_FIELDS = [
    'address_type',
    'extended_address',
    'city',
    'region',
    'postal_code',
    'country',
    'street'
];


const ALTERNATIVE_FIELD_NAMES = {
    'name': ['name', 'full_name', 'complete_name', 'display_name'],
    'given_name': ['given_name', 'first_name', 'christian_name', 'firstname'],
    'family_name': ['family_name', 'last_name', 'second_name', 'lastname'],
    'additional_name': ['additional_name', 'middle_name', 'other_name'],
    'name_prefix': ['name_prefix', 'greeting', 'honorific', 'formaltitle', 'formal_title', 'academic_title', 'academictitle'],
    'name_suffix': ['name_suffix', 'credential', 'post_nominal_initial', 'post_nominal_title', 'designatory_letter', 'designatory'],
    'nickname': ['nickname', 'alias', 'affection_name', 'handle'],
    'birthday': ['birthday', 'birthdate', 'birth_date'],
    'notes': ['note', 'notes', 'comments', 'comment', 'transcript', 'transcripts'],
    'type_description': ['type', 'contact_type', 'group', 'contacttype', 'source'],
    'industry_description': ['industry', 'industry_type', 'industrytype'],
    'profession_description': ['profession', 'profession_type', 'professiontype', 'title'],
    'email': ['email', 'e_mail'],
    'email_type': ['email_type', 'e_mail_type'],
    'phone': ['phone', 'phone_number', 'contact', 'contact_number', 'phonenumber'],
    'phone_type': ['phone_type', 'phone_number_type', 'contact_number_type'],
    'url': ['url', 'website', 'site', 'web', 'www', 'sites'],
    'url_type': ['url_type', 'website_type', 'site_type', 'web_type', 'www_type'],
    'address_type': ['address_type', 'street_type', 'road_type'],
    'street': ['street', 'road', 'address', 'addresses', 'location', 'locations'],
    'extended_address': ['extended_address', 'other_address', 'additional_address'],
    'city': ['city'],
    'region': ['region', 'state', 'province', 'jurisdiction'],
    'postal_code': ['postal_code', 'zip_code', 'zip'],
    'country': ['country', 'union'],
    'po_box': ['po_box', 'p.o._box', 'p_o_box', 'box'],
    'gender': ['gender', 'sex'],
    'created': ['created', 'createdat', 'createdon']
};

const CONTACT_TEMPLATE = {
    'name': '',
    'given_name': '',
    'family_name': '',
    'additional_name': '',
    'name_prefix': '',
    'name_suffix': '',
    'nickname': '',
    'birthday': '',
    'notes': '',
    'type_description': '',
    'industry_description': '',
    'profession_description': '',
    'gender': '',
    'created': '',
    'emailaddress_set': [],
    'phonenumber_set': [],
    'contacturl_set': [],
    'contactlocation_set': []
};

function truncateString(str, num) {
    if (str.length <= num) {
        return str
    }
    return str.slice(0, num) + '...'
}

class FieldMatcher extends Component {

    render() {
        return <div key={this.props.index + 'c' + this.props.column}>
            <Form.Row>
                <Col>{this.props.column}</Col>
                <Form.Group as={Col}>
                    <Form.Control as="select"
                                  onChange={this.props.onFieldSelect}
                                  value={this.props.fieldName}
                                  required
                                  data-column={this.props.column}
                                  data-header-name={this.props.headerName}
                                  data-field-name={this.props.fieldName}
                    >
                        {this.props.fieldNameOptionList}
                    </Form.Control>
                </Form.Group>
                <Col style={{textAlign: 'left'}}>
                    {this.props.headerName}
                </Col>
                <Col style={{textAlign: 'left'}}>
                    {truncateString(this.props.rowData[this.props.column], 15)}
                </Col>
            </Form.Row>
        </div>
    }
}

// everything might have an s at the end
// the word value might be included in any field
// available types from type_library
// numbers in repeating fields


export default class ImporterEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            originalData: null,
            originalHeaders: [],
            matchedHeaderNames: [],
            matchedFieldNames: [],
            unMatchedSingleFieldNames: [],
            unMatchedHeaderNames: [],
            matchedColumns: [],
            allColumns: [],
            originalRows: [],
            newRows: [],
            fieldNameColumns: [],
            preview: [],
            submitting: false,
            submitted: false,
            reading: true,
            parsing: false,
            previewing: false,
            deletingId: null,
            errorCount: 0,
            warningCount: 0,
            importRequest: null
        };
        this.parseFile = this.parseFile.bind(this);
        this.parseFileCallback = this.parseFileCallback.bind(this);
        this.headerNameOptionList = this.headerNameOptionList.bind(this);
        this.findFieldName = this.findFieldName.bind(this);
        this.matchHeaders = this.matchHeaders.bind(this);
        this.onFieldSelect = this.onFieldSelect.bind(this);
        this.preview = this.preview.bind(this);
        this.buildContacts = this.buildContacts.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.postContacts = this.postContacts.bind(this);
        this.postContactsCallback = this.postContactsCallback.bind(this);
        this.postContactsErrorCallback = this.postContactsErrorCallback.bind(this);
    }

    componentDidMount() {
        this.parseFile();
    }

    fieldNameMatch(headerName, fieldName) {
        // if (fieldName === 'type_description') {
        //     return false
        // }

        let matches;
        const lower = headerName.toLowerCase();
        matches = fieldName === lower;
        if (matches) {
            return true
        }
        const snake = _.snakeCase(lower);
        matches = fieldName === snake;
        if (matches) {
            return true
        }
        const snakeList = snake.split('_');

        let possibleNames;
        let fieldNameIsInHeader;

        const isType = snake.match(/type/g)
        if (isType) {
            if (fieldName.match(/type/g)) {
                let typeName = fieldName.slice(0, -5);
                if (snake.includes(typeName)) {
                    return true;
                }
                possibleNames = ALTERNATIVE_FIELD_NAMES[typeName];
                if (!possibleNames) {
                    let splitType = typeName.split('_');
                    typeName = splitType[0];
                    possibleNames = ALTERNATIVE_FIELD_NAMES[typeName];
                    if (!possibleNames) {
                        typeName = splitType[-1];
                        possibleNames = ALTERNATIVE_FIELD_NAMES[typeName];
                    }
                }
                if (possibleNames) {
                    for (const possibleName of possibleNames) {
                        fieldNameIsInHeader = snake.includes(possibleName);
                        if (fieldNameIsInHeader) {
                            return true
                        }
                    }
                }
            } else {
                return false
            }
        }

        possibleNames = ALTERNATIVE_FIELD_NAMES[fieldName];

        if (possibleNames) {
            for (const possibleName of possibleNames) {
                if (possibleName === snake) {
                    return true
                }
                let fieldNameStartsHeader = snake.startsWith(possibleName);
                if (fieldNameStartsHeader) {
                    return true
                }
                let fieldNameEndsHeader = snake.endsWith(possibleName);
                if (fieldNameEndsHeader) {
                    return true
                }
            }
        }

        matches = snakeList[0] === fieldName;

        if (matches) {
            return true
        }

        return matches;
    }

    findFieldName(headerName, unMatchedSingleFieldNames) {
        let fieldName, match;
        for (fieldName of unMatchedSingleFieldNames) {
            match = this.fieldNameMatch(headerName, fieldName);
            if (match) {
                return fieldName
            }
        }
        for (fieldName of REPEATING_FIELD_NAMES) {
            match = this.fieldNameMatch(headerName, fieldName);
            // remove the "Address" references
            if (fieldName === 'street') {
                if (headerName.toLowerCase().includes('formatted')) {
                    match = false
                }
            }

            if (match) {
                return fieldName
            }
        }
    }

    matchHeaders() {
        this.setState({parsing: true}, () => {

            let matchedSingleFieldNames = [];
            let unMatchedSingleFieldNames = [];
            let unMatchedHeaderNames = [];
            let fieldName;
            let columns = {};
            let fieldNameColumns = {};
            this.state.originalHeaders.forEach(
                (headerName, index, original) => {
                    unMatchedSingleFieldNames = SINGLE_FIELD_NAMES.filter(
                        value => !matchedSingleFieldNames.includes(value));
                    fieldName = this.findFieldName(
                        headerName, unMatchedSingleFieldNames);
                    if (fieldName) {
                        if (SINGLE_FIELD_NAMES.includes(fieldName)) {
                            matchedSingleFieldNames.push(fieldName);
                        }
                        columns[index] = {
                            column: index,
                            headerName: headerName,
                            fieldName: fieldName
                        };
                        fieldNameColumns[fieldName] = index;
                    } else {
                        columns[index] = {
                            column: index,
                            headerName: headerName,
                            fieldName: ''
                        };
                        unMatchedHeaderNames[headerName] = index;
                    }
                }
            );
            this.setState({
                allColumns: columns,
                fieldNameColumns: fieldNameColumns,
                unMatchedSingleFieldNames: unMatchedSingleFieldNames,
                parsing: false
            }, this.preview)
        });
    }

    parseFileCallback(event) {
        let csvData = event.target.result;
        let data = Papa.parse(csvData, PARSER_CONFIG);
        const headerRow = data.data.slice(0, 1)[0];
        const rows = data.data.slice(1);
        this.setState({
            originalData: data,
            originalHeaders: headerRow,
            originalRows: rows,
            reading: false
        }, this.matchHeaders);
    }

    parseFile() {
        this.setState({reading: true}, () => {

            let reader = new FileReader();
            reader.readAsText(this.props.file);
            reader.onload = this.parseFileCallback;
            reader.onerror = function () {
                alert('Unable to read ' + this.props.file.name);
            };
        })
    }

    fieldNameOptionList(column, headerName, fieldName) {
        let optionList = [];
        if (fieldName) {
            optionList.push(
                <option key={column + '-' + fieldName}
                        value={fieldName}
                >{fieldName}</option>
            );
        }
        optionList.push(
            <option key={column + '-key'}
                    value={''}
            />
        );
        ALL_FIELD_NAMES.slice().sort().forEach((value, index) => {
            if (value !== fieldName) {
                optionList.push(
                    <option key={column + '-key' + index}
                            value={value}
                    >{value}</option>
                );
            }
        });
        return optionList;
    }

    headerNameOptionList(column, headerName, fieldName) {
        let optionList = [];
        optionList.push(
            <option key={column + '-key'}
                    value={headerName}
            >{headerName}</option>
        );
        this.state.originalHeaders.forEach(
            (originalHeaderName, index, original) => {
                if (originalHeaderName !== headerName) {
                    let headerName = originalHeaderName;
                    let column = index;
                    optionList.push(
                        <option key={column + '-key' + index}
                                name={column + '-key' + index}
                                value={column}
                        >{headerName}</option>
                    );
                }
            });

        return optionList;
    }

    onFieldSelect(event) {
        this.setState({parsing: true});
        const dataset = event.currentTarget.dataset;
        const column = dataset.column;
        const newFieldName = event.currentTarget.value;
        let newAllColumns = {...this.state.allColumns};
        let selectedColumn = newAllColumns[column];
        const selectedColumnFieldNameWas = selectedColumn.fieldName;
        selectedColumn.fieldName = newFieldName;
        newAllColumns[column] = selectedColumn;

        let newFieldNameColumns = {...this.state.fieldNameColumns};
        let newUnMatchedSingleFieldNames = [...this.state.unMatchedSingleFieldNames];

        if (newFieldName) {
            // if it's a nonrepeating
            if (SINGLE_FIELD_NAMES.includes(newFieldName)) {
                const existingColumnNumber = newFieldNameColumns[newFieldName];
                // const existingColumnFieldNameWas = newAllColumns[existingColumnNumber].fieldName;
                if (existingColumnNumber) {
                    newAllColumns[existingColumnNumber].fieldName = '';
                }
                if (newUnMatchedSingleFieldNames.includes(newFieldName)) {
                    const fieldNameLocation = newUnMatchedSingleFieldNames.indexOf(newFieldName)
                    newUnMatchedSingleFieldNames.splice(fieldNameLocation, 1)
                }
            }
            newFieldNameColumns[newFieldName] = column;
        } else {
            delete newFieldNameColumns[selectedColumnFieldNameWas];
            if (SINGLE_FIELD_NAMES.includes(selectedColumnFieldNameWas)) {
                newUnMatchedSingleFieldNames.push(selectedColumnFieldNameWas)
            }
        }
        this.setState({
            allColumns: newAllColumns,
            fieldNameColumns: newFieldNameColumns,
            unMatchedSingleFieldNames: newUnMatchedSingleFieldNames,
            parsing: false
        }, this.preview)
    }

    repeatingObjectType(repeatingObject) {
        const repeatingObjectKeys = Object.keys(repeatingObject);
        if (repeatingObjectKeys.length > 0) {
            if (EMAIL_FIELDS.filter(it => repeatingObjectKeys.includes(it)).length > 0) {
                return 'emailaddress_set'
            }
            if (PHONE_FIELDS.filter(it => repeatingObjectKeys.includes(it)).length > 0) {
                return 'phonenumber_set'
            }
            if (URL_FIELDS.filter(it => repeatingObjectKeys.includes(it)).length > 0) {
                return 'contacturl_set'
            }
            if (ADDRESS_FIELDS.filter(it => repeatingObjectKeys.includes(it)).length > 0) {
                return 'contactlocation_set'
            }
        }
    }

    repeatingFieldNameType(fieldName) {
        if (EMAIL_FIELDS.includes(fieldName)) {
            return 'emailaddress_set'
        }
        if (PHONE_FIELDS.includes(fieldName)) {
            return 'phonenumber_set'
        }
        if (URL_FIELDS.includes(fieldName)) {
            return 'contacturl_set'
        }
        if (ADDRESS_FIELDS.includes(fieldName)) {
            return 'contactlocation_set'
        }
    }

    buildContacts() {
        const allColumns = this.state.allColumns;
        let repeatingObject;
        let repeatingObjectType;
        let repeatingFieldNameType;
        if (allColumns.length === 0) {
            return []
        }
        let errorCount = 0;
        let warningCount = 0;
        const jsonOut = this.state.originalRows.map((row, index, original) => {
            let record = JSON.parse(JSON.stringify(CONTACT_TEMPLATE));
            // record.created_by = this.props.authenticator.account.user.id;
            if (!repeatingObject) {
                repeatingObject = {};
                repeatingObjectType = null;
            }
            let errors = [];
            let warnings = [];
            Object.keys(allColumns).forEach((key, index, original) => {
                const column = allColumns[key];
                const columnNumber = column.column;
                let value = _.trim(row[columnNumber]);
                if (value) {
                    const fieldName = column.fieldName;
                    if (fieldName) {
                        if (REPEATING_FIELD_NAMES.includes(fieldName)) {
                            repeatingFieldNameType = this.repeatingFieldNameType(fieldName);
                            repeatingObjectType = this.repeatingObjectType(repeatingObject);
                            if (fieldName in repeatingObject || (!!repeatingObjectType && repeatingObjectType !== repeatingFieldNameType)) {
                                record[repeatingObjectType].push(repeatingObject);
                                repeatingObject = {};
                                repeatingObjectType = null;
                            }
                            if (value) {
                                repeatingObject[fieldName] = value;
                            }
                        } else {
                            if (value) {
                                if (fieldName === 'birthday' || fieldName === 'created') {
                                    if (fieldName === 'birthday') {
                                        const parsedDate = moment(new Date(value)).format('YYYY-MM-DD');
                                        if (parsedDate === 'Invalid date') {
                                            // this.setState({warnings: ['Warning: One or more ' + fieldName +' fields has a bad value, those fields will not import. Dates should be YYYY-MM-DD.']});
                                            warnings.push({
                                                fieldName: fieldName,
                                                value: 'Skipped because Dates should be YYYY-MM-DD.'
                                            });
                                            ++warningCount;
                                            delete record[fieldName]
                                        } else {
                                            record[fieldName] = parsedDate;
                                        }
                                    }
                                    if (fieldName === 'created') {
                                        const parsedDate = moment(new Date(value)).toISOString();
                                        if (parsedDate === 'Invalid date') {
                                            warnings.push({
                                                fieldName: fieldName,
                                                value: 'Skipped because Date-Times should be in ISO format YYYY-MM-DDThh:mm:ss.sTZD.'
                                            });
                                            ++warningCount;
                                            delete record[fieldName]
                                        } else {
                                            record[fieldName] = parsedDate;
                                        }
                                    }
                                } else {
                                    record[fieldName] = value;
                                }
                            } else {
                                delete record[fieldName]
                            }
                            if (Object.keys(repeatingObject).length > 0) {
                                repeatingObjectType = this.repeatingObjectType(repeatingObject);
                                record[repeatingObjectType].push(repeatingObject);
                                repeatingObject = {};
                                repeatingObjectType = null;
                            }
                        }
                    }
                }
            });

            if (Object.keys(repeatingObject).length > 0) {
                repeatingObjectType = this.repeatingObjectType(repeatingObject);
                record[repeatingObjectType].push(repeatingObject);
                repeatingObject = {};
                repeatingObjectType = null;
            }

            SINGLE_FIELD_NAMES.forEach((fieldName, index, original) => {
                if (fieldName in record) {
                    if (!record[fieldName]) {
                        delete record[fieldName]
                    }
                }
            });

            if (record.emailaddress_set.length === 0) {
                delete record.emailaddress_set
            }
            if (record.phonenumber_set.length === 0) {
                delete record.phonenumber_set
            }
            if (record.contacturl_set.length === 0) {
                delete record.contacturl_set
            }
            if (record.contactlocation_set.length === 0) {
                delete record.contactlocation_set
            }

            if (!record.name && record.given_name && record.family_name) {
                record.name = record.given_name + ' ' + record.family_name
            }

            if (!record.name) {
                errors.push({
                    fieldName: 'name',
                    value: 'Unable to determine a Name.'
                });
                ++errorCount;
                return {errors: errors};
            }

            if (!record || Object.keys(record).length > 0) {
                if (errors.length) {
                    record.errors = errors;
                }
                if (warnings.length) {
                    record.warnings = warnings;
                }
                return record;
            }

            errors.push({fieldName: 'ALL', value: 'Record has no values.'});
            ++errorCount;
            return {errors: errors};
        });

        // const cleanedJsonOut = jsonOut.filter((value, index, original) => (!!value && Object.keys(value).length > 0));

        this.setState(
            {errorCount: errorCount, warningCount: warningCount});
        return jsonOut;
    }

    preview() {
        this.setState({previewing: true}, () => {
            const contacts = this.buildContacts();
            this.setState({preview: contacts, previewing: false})
        });
    }

    postContactsErrorCallback(data) {
        this.setState({submitting: false, submitted: false, errors: data})
    }

    postContactsCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState({
            submitting: false, submitted: true,
            importRequest: data,
        }, this.props.callback)
    }

    postContacts() {
        const errorFreeContacts = this.state.preview.filter((record) => (!record.errors));
        const jsonString = JSON.stringify(errorFreeContacts);
        this.props.authenticator.queuedPost(
            'import', jsonString,
            this.postContactsCallback, this.postContactsErrorCallback)

    }

    onSubmit() {
        this.setState({submitting: true}, this.postContacts)
    }



    render() {
        if (this.state.reading) {
            return <><Spinner animation="border"
                              role="status"/>
                <span>Reading...</span></>
        }

        if (this.state.parsing) {
            return <><Spinner animation="border"
                              role="status"/>
                <span>Parsing...</span></>
        }

        if (this.state.previewing) {
            return <><Spinner animation="border"
                              role="status"/>
                <span>Previewing...</span></>
        }

        const originalRowZero = this.state.originalRows[0];
        const matchedCount = Object.keys(this.state.fieldNameColumns).length;
        return <Container>


            {this.state.errors && this.state.errors.detail &&
            <Alert
                variant={'danger'}>{this.state.errors.detail}</Alert>}
            {this.state.warnings && this.state.warnings &&
            <Alert
                variant={'warning'}>{this.state.warnings}</Alert>}
            {this.state.errors && this.state.errors.non_field_errors &&
            <Alert
                variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}

            {

                (!this.state.submitting && !this.state.submitted) &&
                <div>
                    <Alert variant={'success'}>{this.props.file.name} is
                        processed and ready for your review.</Alert>
                    <Accordion
                        as={Card}
                        border={'primary'}
                        key={'headers-matched' + this.props.index}
                    >
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey={'headers-matched' + this.props.index}>
                            Click to Review {matchedCount} Matched Headers
                        </Accordion.Toggle>
                        <Accordion.Collapse
                            eventKey={'headers-matched' + this.props.index}>
                            <Card.Body>
                                <Form>
                                    <Row>
                                        <Col>Column</Col>
                                        <Col>Our Contact Field</Col>
                                        <Col>Your CSV Header Field</Col>
                                        <Col>Example</Col>
                                    </Row>
                                    <div>
                                        {Object.keys(this.state.allColumns).map(
                                            (key, index, original) => {
                                                let column = key;
                                                let headerName = this.state.allColumns[column].headerName;
                                                let fieldName = this.state.allColumns[column].fieldName;
                                                return <FieldMatcher
                                                    column={column}
                                                    headerName={headerName}
                                                    fieldName={fieldName}
                                                    index={index}
                                                    key={index}
                                                    onFieldSelect={this.onFieldSelect}
                                                    fieldNameOptionList={this.fieldNameOptionList(column, headerName, fieldName)}
                                                    rowData={originalRowZero}
                                                />
                                            })
                                        }
                                    </div>
                                </Form>
                                <Container>
                                    <Row align={'left'}>
                                        Pro Tips:
                                        <ul>
                                            <li>Import in small groups of 100 or
                                                less contacts, it will import
                                                thousands at a time, but smaller
                                                imports are easier to review for
                                                issues before importing.
                                            </li>
                                            <li>first_name = given_name</li>
                                            <li>last_name = family_name</li>
                                            <li>name = unique display name</li>
                                            <li>The non-repeatable notes field
                                                is very useful for fields with
                                                'random data.'
                                            </li>
                                            <li>street = very large and
                                                repeatable field (you can have
                                                any number of them), use it for
                                                larger unparsed/formatted
                                                address fields, as well as just
                                                the street address. Note: Every
                                                'street' entry you have, creates
                                                a new address record.
                                            </li>
                                        </ul>
                                    </Row>
                                </Container>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                    <Accordion
                        as={Card}
                        border={'primary'}
                        key={'preview' + this.props.index}
                    >
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey={'preview' + this.props.index}>
                            <div>Click to
                                Preview {this.state.preview.length - this.state.errorCount} Ready
                                Contacts before you import (Important!)
                            </div>
                            {(this.state.errorCount || this.state.warningCount) &&
                            <div>Out of {this.state.preview.length} Records
                                there were {this.state.errorCount &&
                                <span>{this.state.errorCount} errors </span>}{(this.state.errorCount && this.state.warningCount &&
                                    <span> and </span>)}{this.state.warningCount &&
                                <span>{this.state.warningCount} warnings</span>}.</div>}
                        </Accordion.Toggle>
                        <Accordion.Collapse
                            eventKey={'preview' + this.props.index}>
                            <Card.Body className='text-left'>
                                <div>Error rows (in red) will not import.
                                    Warning rows (in yellow) will import.
                                </div>
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Row</th>
                                        <th>Name</th>
                                        <th>Warnings</th>
                                        <th>Errors</th>
                                        <th>Record</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.preview.map((contact, index, original) => {
                                        return <tr key={index} align={'left'}
                                                   bgcolor={contact.errors ? 'red' : contact.warnings ? 'yellow' : 'white'}>
                                            <td>{index + 1}</td>
                                            <td>{contact.name}</td>
                                            <td>{contact.warnings ? contact.warnings.length : 0}</td>
                                            <td>{contact.errors ? contact.errors.length : 0}</td>
                                            <td>{
                                                <pre>{JSON.stringify(contact, null, 2)}</pre>}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Accordion>
                    {(this.state.unMatchedSingleFieldNames.includes('name') && !this.state.unMatchedSingleFieldNames.includes('given_name')) ?
                        <Alert variant={'danger'}>Missing Name Field
                            Header</Alert> : <div>
                            First Click Review Headers and verify, then Click
                            Preview, only after doing those should you
                            click: <Button
                            onClick={this.onSubmit}>Import {this.state.preview.length - this.state.errorCount} Contacts</Button>
                        </div>}

                </div>}
            {this.state.submitting && <div><Spinner animation="border"
                                                    role="status"/>
                <span>Submitting...</span>
            </div>}

            {/*{this.state.submitted && <Redirect to={'/import'} />}*/}

                <div style={{marginBottom: '3.6rem'}}/>
                </Container>
                }

                }
