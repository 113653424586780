import React, {Component} from 'react';
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import AppleRequest from "./AppleRequest";

export default class AppleRequests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appleRequests: [],
        };
        this.onClick = this.onClick.bind(this);
        this.getAppleRequests = this.getAppleRequests.bind(this);
        this.getAppleRequestsCallback = this.getAppleRequestsCallback.bind(this);
    }


    getAppleRequestsCallback(data) {
        this.setState({appleRequests: data})
    }

    getAppleRequests() {
        this.props.authenticator.queuedGet(
            'apple/requests',
            this.getAppleRequestsCallback, ()=>{});
    }


    onClick(event) {
        if (this.state.appleRequests.length === 0) {
            this.getAppleRequests()
        }
    }


    render() {
        return (
            <Container>
                {this.state.appleRequests &&
                <Accordion
                    as={Card}
                    border={'primary'}
                    key={'appleRequests'}
                >
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey={'appleRequests'}
                        className="text-center"
                        onClick={this.onClick}
                    >
                        Apple Requests
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={'appleRequests'}>
                        <Card.Body>

                            {this.state.appleRequests.map(
                                (appleRequest, index, original) => {
                                    return <AppleRequest
                                            key={index}
                                            appleRequest={appleRequest}
                                            authenticator={this.props.authenticator} />
                                }
                            )}

                        </Card.Body>
                    </Accordion.Collapse>
                </Accordion>
                }

            </Container>
        )
    }
}

