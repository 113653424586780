import moment from "moment-timezone";
import React, {Component} from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import './Transcripts.css'

export default class Transcripts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transcripts: [],
            newTranscriptOpen: false,
            resetNewTranscript: false
        };
        this.newTranscriptCallback = this.newTranscriptCallback.bind(this);
        this.getTranscripts = this.getTranscripts.bind(this);
        this.getTranscriptsCallback = this.getTranscriptsCallback.bind(this);
        this.search = this.search.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    componentDidMount() {
        this.getTranscripts()
    }

    getTranscriptsCallback(data) {
        this.setState({transcripts: data})
    }

    getTranscripts() {
        this.props.authenticator.queuedGet(
            'message_transcripts',
            this.getTranscriptsCallback,
            null,
            null,
            {contact: this.props.contact.id});
    }

    newTranscriptCallback(newRecord) {
        this.setState({resetNewTranscript: true}, () => (
            this.setState({resetNewTranscript: false})));
        this.setState(prevState => ({
            transcripts: [
                ...prevState.transcripts,
                newRecord
            ]
        }));
        this.setState(prevState => (
            {newTranscriptOpen: false}));
    }


    search() {
        if (this.state.search.length < 2) {
            if (this.state.search.length === 0) {
               this.getTranscripts();
            }
            return;
        }
        this.props.authenticator.queuedGet(
            'message_transcripts',
            this.getTranscriptsCallback,
            null,
            null,
            {
                contact: this.props.contact.id,
                search: this.state.search
            });
    }

    onSearch(event) {
        this.setState({search: event.currentTarget.value}, this.search)
    }

    render() {
        return (
            <div className={'contact-transcripts'}>
                <div className={'skin-row'} style={{marginTop: '2rem'}}>
                    <input placeholder={'Search Messages'}
                           className={'transcript-search'}
                           value={this.state.search}
                           onChange={this.onSearch}
                           width={'100%'} />
                </div>
                {this.state.transcripts.map(
                    (transcript, index, original) => {
                        return <div key={index} className={'transcript-container'}>
                            <div className={'transcript-description'}>
                                <div className={'transcript-description-left'}>
                                    {transcript.subject &&
                                    <div className={'skin-row'}>{transcript.subject}</div>}
                                </div>
                                <div className={'transcript-description-right'}>
                                    <div>{moment(new Date(transcript.created)).format('dddd LL LT')} : {moment(new Date(transcript.created)).fromNow()}</div>
                                    <div>
                                        {transcript.type && transcript.type !== 20 &&
                                        <span>{this.props.authenticator.library.transcript_type_dict[transcript.type]}</span>}
                                        {transcript.type === 20 && (transcript.transcript_set.length > 0 ?
                                            <span>
                                                <OverlayTrigger overlay={
                                                    <Popover id="tooltip-disabled">
                                                        <Popover.Title>
                                                            Message Views
                                                        </Popover.Title>
                                                        <Popover.Content>
                                                            <ul>
                                                                {transcript.transcript_set.map((transcript, indexI) => (<div key={indexI}>
                                                                    <li>{moment(transcript.created).format('dddd LL LT')}</li>
                                                                    <div>{moment(transcript.created).fromNow()}</div>
                                                                </div>))}
                                                            </ul>
                                                        </Popover.Content>
                                                    </Popover>}
                                                >
                                                    <span
                                                        className={'transcript-read skin-badge'}>Read</span>
                                                </OverlayTrigger>
                                            </span> :
                                            <span
                                                className={'transcript-unread skin-badge'}>Unread</span>)}
                                    </div>
                                    {transcript.type !== 20 && !transcript.transcript_set.length > 0 &&
                                    <div>{moment.duration(moment(transcript.end_time || transcript.modified).diff(moment(transcript.start_time || transcript.created))).humanize()} long</div>
                                    }
                                </div>
                            </div>
                            <div className={'transcript-body'}>
                                <div
                                    dangerouslySetInnerHTML={{__html: transcript.data}}/>
                            </div>
                            <div className="border-top my-2"/>
                        </div>
                    }
                )}
            </div>
        )
    }
}

