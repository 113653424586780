import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import {Redirect} from "react-router";
import {Link} from "react-router-dom";
import RetailMenu from "./RetailMenu";

// https://medium.com/@dakota.lillie/django-react-jwt-authentication-5015ee00ef9a

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            success: false,

            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,
            eventType: null,
            eventTypeFrequency: {},

            id: null,

            username: '',
            usernameIsValid: false,
            usernameValidationmessage: '',
            password: '',
            passwordIsValid: false,
            passwordValidationmessage: '',

        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.submitSuccess = this.submitSuccess.bind(this);
        this.submitFailure = this.submitFailure.bind(this);
    }

    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates);
    }

    submitFailure(data) {
        this.setState({errors: data})
    }


    clickLoginCallback(data) {
    }

    submitSuccess(data) {
        if (!!!data) {return;}
        this.setState({success: true});
        this.props.authenticator.queuedPost('click_login', {}, this.clickLoginCallback, ()=>{});
    }

    onSubmit(event) {
        event.preventDefault();
        this.props.authenticator.loginWithCallback(
            this.state.username,
            this.state.password,
            this.submitSuccess,
            this.submitFailure
            )
    }

    render() {

        if (this.state.success) {
            if (this.props.next && this.props.next !== '/login') {
                return <Redirect to={this.props.next}/>
            }
            return <Redirect to={'/advisors'}/>
        }

        return (
            <>
            <RetailMenu authenticator={this.props.authenticator}/>
            <Container>
                <Row>
                    <Col>
                        <Form
                            name={'user-registration-form'}
                            onSubmit={this.onSubmit}>
                            <h4>EM Log In</h4>
                            {this.state.errors && this.state.errors.detail &&
                            <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                            {this.state.errors && this.state.errors.non_field_errors &&
                            <Alert
                                variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                            <FormGroup>
                                <Form.Label column={'username'}>
                                    Account Username
                                </Form.Label>
                                <Form.Control type="text"
                                              id={'username'}
                                              name={'username'}
                                              autoComplete="username"
                                              onChange={this.onFieldChange}
                                              value={this.state.username}
                                              isInvalid={!!this.state.errors.username}
                                              placeholder={'Username'}
                                              required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {this.state.errors.username}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {this.state.usernameValidationmessage}
                                </Form.Control.Feedback>
                                <Form.Label column={'password'}>
                                    Password
                                </Form.Label>
                                <Form.Control type="password"
                                              id={'password'}
                                              name={'password'}
                                              autoComplete="current-password"
                                              onChange={this.onFieldChange}
                                              value={this.state.password}
                                              isInvalid={this.state.errors.user && !!this.state.errors.user.password}
                                              placeholder={'Password'}
                                              minLength={9}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {this.state.errors.user && this.state.errors.user.password}
                                </Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {this.state.apasswordValidationmessage}
                                </Form.Control.Feedback>
                            <Button variant="primary" type="submit">Agree &
                                Login</Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row><Col><Link to={'/register'}>New User Registration</Link></Col></Row>
                <Row><Col><Link to={'/reset-password'}>Forgot your password?</Link></Col></Row>
                <Row><Col>
                    <Link to="/">Return to EM Homepage</Link></Col></Row>
            </Container></>
        );
    }
}


LoginForm.propTypes = {
    onLogin: PropTypes.func.isRequired,
    authenticator: PropTypes.object.isRequired
};


//export default withRouter(LoginForm);
export default LoginForm;