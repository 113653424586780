import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {EDNA_API_URL} from "../Settings";
import DragAndDrop from "./DragAndDrop";
import ImporterEditor from "./ImporterEditor";
import ImportRequest from "./ImportRequest";

class Importer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            currentFile: null,
            importRequests: []
        };
        this.handleDrop = this.handleDrop.bind(this);
        this.getExistingImportRequests = this.getExistingImportRequests.bind(this);
        this.getExistingImportRequestsCallback = this.getExistingImportRequestsCallback.bind(this);
    }

    componentDidMount() {
        this.getExistingImportRequests()
    }

    handleDrop(fileDict) {
        let files = [];
        Object.entries(fileDict).forEach((file, index, original) => {
            files.push(file[1]);
        });
        this.setState({files: files})
    }

    oldHandleDrop = (files) => {
        let fileList = this.state.files
        for (var i = 0; i < files.length; i++) {
            if (!files[i].name) return
            fileList.push(files[i].name)
            this.postFile(files[i])
        }
        this.setState({files: fileList})
    };

    postFile = (file) => {
        this.setState({result: 'Processing File....'})
        let createUrl = EDNA_API_URL + 'importers/';
        let headers = {
            Authorization: 'Bearer ' + this.props.access
        };
        const formData = new FormData();
        formData.append('file', file);
        const body = formData;

        fetch(createUrl, {
            method: 'post',
            headers: headers,
            body: body
        })
            .then(function (response) {
                return response.json();
            })
            .then((data) => {
                this.setState({result: data.result});
            })
            .catch(function (err) {
                console.log(err);
            });
    }


    getExistingImportRequestsErrorCallback(data) {
    }

    getExistingImportRequestsCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState({importRequests: data})
    }

    getExistingImportRequests() {
        this.props.authenticator.queuedGet(
            'import_request',
            this.getExistingImportRequestsCallback,
            this.getExistingImportRequestsErrorCallback)
    }

    render() {
        return (
            <Container>
                {this.state.files.length > 0 &&
                <>
                    {this.state.files.map(
                        (file, index, original) => (
                            <ImporterEditor
                                authenticator={this.props.authenticator}
                                file={file}
                                index={index}
                                key={index}
                                callback={this.getExistingImportRequests}
                            />
                        )
                    )
                    }
                </>
                }

                {this.state.files.length === 0 &&<>
                <Row className="align-items-center">
                    <Col lg={6}>
                        <DragAndDrop handleDrop={this.handleDrop}>
                            <div style={{
                                height: 300, width: 250,
                                border: '30px solid green'
                            }}>
                                <div>Drop CSV w/ Headers Here</div>
                            </div>
                        </DragAndDrop>
                    </Col>
                </Row>
                </>}
                {this.state.importRequests && this.state.importRequests.length > 0 &&
                <Row id={'exist-requests'}>
                    <h3 align={'center'} style={{width: '100%'}}>Previous Import Requests</h3>
                    {this.state.importRequests.map(
                        (importRequest, index, original) => (
                            <Container key={index}>
                                <ImportRequest
                                    importRequest={importRequest}
                                    authenticator={this.props.authenticator}
                                />
                            </Container>
                        ))}
                </Row>
                    }
            </Container>
        )
    }
}

export default Importer;
