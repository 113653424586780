import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import RetailMenu from "./RetailMenu";


export default class PublicHelpPage extends Component {

    render() {
        return <>
            <RetailMenu authenticator={this.props.authenticator}/>
            <Container>
                <h1>Experience Master Help</h1>
                <Row>
                    <Col>A Message from the Developers:</Col>
                    <Col align={'left'}>The quickest way to get into the game is to Score and Workflow around 100 Contacts.
                        You can import a large number of contacts, but it is just as viable to
                        manually enter a bunch of Contacts, one at a time, giving them a Score
                        and Workflow as you go. Reference your emails, Social Media, highschool yearbooks, etc.
                        Once you have a significant number of
                        Contacts, use the Advisors to view them, and hopefully
                        reach out to them, possibly using a template. The Event Scheduler
                        is also available to you to help everyone know when you are available.
                        Soon your AI Advisors will learn who you want to keep in touch with,
                        and help you not forget to.
                    </Col>
                </Row>
                <Table>
                    <thead>
                    <tr>
                        <th>Video</th><th>Description</th>
                    </tr>
                    </thead>
                    <tbody className="text-left">
                    <tr>
                        <td><a href={'https://youtu.be/lcW4SBgYop4'}>Add Contact</a></td>
                        <td>Experience Master makes creating a Contact simple.</td>
                    </tr>
                    <tr>
                        <td><a href={'https://youtu.be/MLGadP-cYV4'}>Bulk Import Contact</a></td>
                        <td>A demonstration of our bulk Contact importer. Make sure you can score everyone you know.</td>
                    </tr>
                    <tr>
                        <td><a href={'https://youtu.be/3nNMsp7z_UU'}>Game Basics</a></td>
                        <td>Learn the simple way to earn XP, Levels, and Loot.</td>
                    </tr>

                    <tr>
                        <td><a href={'https://youtu.be/SP_dhVHlWfE'}>Scores & Workflows</a></td>
                        <td>A starting perspective on how to Score and assign Workflow.</td>
                    </tr>

                    <tr>
                        <td><a href={'https://youtu.be/rgm0YTH14NE'}>Privacy Mode</a></td>
                        <td>The Method and Reasoning behind Privacy Mode.</td>
                    </tr>

                    <tr>
                        <td><a href={'https://youtu.be/eNFxtM95q0o'}>Message Templates</a></td>
                        <td>A Short demonstration about our customizable templates.</td>
                    </tr>
                    <tr>
                        <td><a href={'https://youtu.be/ONr3d3-A7M0'}>Relationships</a></td>
                        <td>Quick overview of the Add Relationship feature.</td>
                    </tr>
                    <tr>
                        <td><a href={'https://youtu.be/t3XNLMf343Q'}>Scheduling</a></td>
                        <td>The simple approach to telling people when you are available.</td>
                    </tr>
                    <tr>
                        <td><a href={'https://youtu.be/B03bt4GB8F8'}>Being a Learner</a></td>
                        <td>Understanding of how Coaches can help Learners grow their network.</td>
                    </tr>
                    <tr>
                        <td><a href={'https://youtu.be/KOvBvQoGSR4'}>Being a Coach</a></td>
                        <td>See how Experience Master helps with Learner Accountability.</td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        </>

    }
}

