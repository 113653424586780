import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import EventTypes from "./EventTypes";
import TokenedEvent from "./TokenedEvent";


class PublicProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            publicName: null,
            accountName: '',
            isCoach: false,
            displayEvents: false,
            anonEvents: null,
            accountEvents: null,
            isOwner: false,
            typeSlug: null,
            token: null,
            eventType: null,
            notFound: false,
            rendering: true,
            requestingUser: null,
            isLearnerOf: null,
            callSignup: false
        };
        this.getAnonEvents = this.getAnonEvents.bind(this);
        this.getAccountEventTypesAndScheduledEvents = this.getAccountEventTypesAndScheduledEvents.bind(this);
        this.callbackGetAnonEvents = this.callbackGetAnonEvents.bind(this);
        this._callbackGetAccountEvents = this._callbackGetAccountEvents.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.eventTypesCallback = this.eventTypesCallback.bind(this);
        this.matchTokenToEventType = this.matchTokenToEventType.bind(this);
        this.tokenCallback = this.tokenCallback.bind(this);
        this.callbackGetAnonEventsError = this.callbackGetAnonEventsError.bind(this);
        this.callbackGetScheduledEvents = this.callbackGetScheduledEvents.bind(this);
        this.eventTypeSelectedCallback = this.eventTypeSelectedCallback.bind(this);
        this.redirectToTokened = this.redirectToTokened.bind(this);
        this.becomeALearner = this.becomeALearner.bind(this);
        this.becomeALearnerCallback = this.becomeALearnerCallback.bind(this);
        this.signUp = this.signUp.bind(this);
    }

    componentDidMount() {
        this.getEvents();
    }

    getEvents() {
        const publicName = this.props.match.params.publicName;
        const typeSlug = this.props.match.params.typeSlug;
        const token = this.props.match.params.token;
        let isOwner = false;
        this.setState(
            {
                publicName: publicName,
                typeSlug: typeSlug,
                token: token
            },
            () => {
                if (!!this.props.authenticator.account) {
                    const profileName = this.props.authenticator.account.accountprofilename.public_name;
                    isOwner = (profileName === publicName);
                    if (isOwner) {
                        this.getAccountEventTypesAndScheduledEvents()
                    }
                }
                if (!isOwner) {
                    this.getAnonEvents();
                }
                this.setState({isOwner: isOwner})
            })
    }

    matchTokenToEventType() {
        const types = this.state.accountEvents || this.state.anonEvents;
        if (!types) {
            this.setState({rendering: false});
            return;
        }

        // eslint-disable-next-line no-unused-vars
        for (const [index, eventType] of types.entries()) {
            if (eventType.name_slug === this.state.typeSlug) {
                this.setState({eventType: eventType, rendering: false});
                break;
            }
        }
        this.setState({rendering: false})
        // what if it doesn't match a type
    }


    callbackGetAnonEvents(data) {
        if (!!!data) {
            return;
        }
        this.setState({
                anonEvents: data.event_types,
                accountName: data.account_name,
                accountEmail: data.account_email,
                isCoach: data.is_coach,
                requestingUser: data.requesting_user,
                requestingContact: data.requesting_contact,
                isLearnerOf: data.is_learner_of,
                isCoachOf: data.is_coach_of,
                userId: data.user_id
            },
            this.matchTokenToEventType)
    }

    callbackGetAnonEventsError(data) {
        this.setState({notFound: true, rendering: false})
    }

    getAnonEvents() {
        this.props.authenticator.queuedGet(
            'anon_events',
            this.callbackGetAnonEvents,
            this.callbackGetAnonEventsError,
            this.state.publicName)
    }

    _callbackGetAccountEvents(data) {
        this.setState({
                accountEvents: data,
                accountName: this.props.authenticator.account.user.first_name + ' ' +
                    this.props.authenticator.account.user.last_name,
                accountEmail: this.props.authenticator.account.user.email,
                rendering: false},
            this.matchTokenToEventType)
    }

    callbackGetScheduledEvents(data) {
        this.setState({
            scheduledEvents: data,
            rendering: false})
    }

    getAccountEventTypesAndScheduledEvents() {
        if (!this.state.typeSlug) {
            this.setState({displayEvents: true});
        }
        this.props.authenticator.queuedGet(
            'event_types',
            this._callbackGetAccountEvents,
            null);

        // this.props.authenticator.queuedGet(
        //     'scheduled_events',
        //     this.callbackGetScheduledEvents,
        //     null);
    }

    eventTypesCallback() {
        this.setState({
            anonEvents: null, accountEvents: null, eventType: null,
            scheduledEvents: null, rendering: true
        }, this.getEvents);
    }

    eventTypeSelectedCallback(eventType) {
        this.setState({eventType: eventType, displayEvents: false})
    }

    tokenCallback() {
        this.setState({
            anonEvents: null, accountEvents: null, eventType: null,
            scheduledEvents: null, token: null
        }, this.getEvents);
    }

    redirectToTokened(event) {
        const token = event.currentTarget.dataset.token;
        const typeSlug = event.currentTarget.dataset.typeslug;
        this.setState({
            token: token,
            typeSlug: typeSlug
        })
    }

    signUp() {
        this.setState({callSignup: true})
    }

    becomeALearnerCallback(data) {
        window.location.reload();
    }

    becomeALearner() {
        const data = {
            coach: this.state.isCoach,
            user: this.props.authenticator.account.user.id
        };
        this.props.authenticator.queuedPost(
            'learner',
            data,
            this.becomeALearnerCallback,
            () => {})
    }


    render() {
        if (this.state.callSignup) {
            return <Redirect to={'/register?ref=' + this.state.publicName}/>
        }

        if (this.state.rendering) {
            return <div>Rendering</div>
        }
        if (this.state.notFound) {
            return <div>Public Profile - 404 - Not Found -- <Link to="/">Home</Link></div>
        }
        return <>
        {/*{!this.state.isOwner && <RetailMenu authenticator={this.props.authenticator}/>}*/}
            <div className={'public-container'}>
            <div className={'skin-row'} style={{width: '100%'}}><img className={'public-container-logo'} src={'https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/logo192.png'}  alt={'em-logo'}/></div>
            <div className={'skin-row'}>
                {this.state.isOwner && 'This is Your Public Profile'}
            </div>
            <div className={'skin-row'}>
                <h1>
                    {this.state.requestingContact ? <Link to={'/contacts/contact/' + this.state.requestingContact.id}>{this.state.requestingContact.name}</Link> : this.state.accountName}
                </h1>
            </div>
            {this.state.isLearnerOf && <div className={'skin-row'}>Your Learner</div>}
            {this.state.isCoachOf && <div className={'skin-row'}>Your Coach</div>}
            <div className={'skin-row'}>
                <a href={'mailto://' + this.state.accountEmail}>{this.state.accountEmail}</a>
            </div>
            <div className={'skin-row'}>&nbsp;</div>
            {!this.state.token &&
            <div className={'skin-column'} style={{ width: '100%'}}>
                {(this.state.anonEvents || this.state.accountEvents) &&
                <EventTypes authenticator={this.props.authenticator}
                            publicName={this.state.publicName}
                            typeSlug={this.state.typeSlug}
                            anonEvents={this.state.anonEvents}
                            accountEvents={this.state.accountEvents}
                            callback={this.eventTypesCallback}
                            selectedCallback={this.eventTypeSelectedCallback}
                            isOwner={this.state.isOwner}
                            eventType={this.state.eventType}
                />}
                {!this.state.isCoachOf && this.state.isCoach && this.state.requestingUser && <div className={'skin-row'} style={{justifyContent: "flex-end"}}>
                    <button className={'skin-button'} onClick={this.becomeALearner}>Become A Learner</button>
                </div>
                }

                {!this.props.authenticator.account && !this.state.requestingUser && <div className={'skin-row'} style={{justifyContent: "flex-end"}}>
                    <button className={'skin-button'} onClick={this.signUp}>Sign-Up</button>
                </div>
                }

                <div className={'skin-row'} style={{justifyContent: "flex-end"}}>
                    Powered by:&nbsp;<Link to={'/'}>Experience Master</Link>
                </div>
            </div>

            }
            {this.state.token &&
                <>
            <Redirect to={'/' + this.state.publicName + '/' + this.state.typeSlug + '/' + this.state.token + '/'} />
            <TokenedEvent
                authenticator={this.props.authenticator}
                publicName={this.state.publicName}
                typeSlug={this.state.typeSlug}
                token={this.state.token}
                callback={this.tokenCallback}
            />
            </>
            }

        </div></>

    }
}

export default PublicProfile;
