import moment from "moment-timezone";
import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Spinner from "react-bootstrap/Spinner";
import DayPicker from 'react-day-picker';
import {Redirect} from "react-router";

class EventRequestForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            nameIsValid: false,
            nameValidationMessage: '',
            email: '',
            emailIsValid: false,
            emailValidationMessage: '',
            nameSlug: null,
            errors: {},
            submitted: false,
            selectedDay: undefined,
            selectedMonth: null,
            selectedYear: null,
            selectedDatetime: null,
            availableTimes: null,
            dateBoundaries: null,
            unavailableDates: null,
            isSaved: true,
            token: null
        };
        this._handleDayClick = this._handleDayClick.bind(this);

        this.onEventRequestSubmit = this.onEventRequestSubmit.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
        this._onSubmitErrorCallback = this._onSubmitErrorCallback.bind(this);
        this.getTimesForDate = this.getTimesForDate.bind(this);
        this.getTimesCallback = this.getTimesCallback.bind(this);
        this.getUnavailableCallback = this.getUnavailableCallback.bind(this);
        this.getUnavailable = this.getUnavailable.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.resetDate = this.resetDate.bind(this);
        this.onTimeSelect = this.onTimeSelect.bind(this);
    }

    componentDidMount() {
        this.getUnavailable()
        if (this.props.authenticator.account) {
            this.setState({
                name: this.props.authenticator.account.user.first_name + ' ' +
                    this.props.authenticator.account.user.last_name,
                nameIsValid: true,
                email: this.props.authenticator.account.user.email,
                emailIsValid: true
            })
        }


    }

    resetDate() {
        this.setState({
            selectedDay: undefined,
            availableTimes: null, selectedDatetime: null
        })
    }

    getUnavailableCallback(data) {
        let unavailableDates = data.unavailable_dates.map(
            date => moment(date).toDate());
        const yesterday = moment().subtract(1, 'days').toDate();
        unavailableDates.push(
            {before: yesterday});
        this.setState({
            unavailableDates: unavailableDates,
            dateBoundaries: data.date_boundaries
        })
    }

    /**
     * Based on the requested month (+1 because 0 is not a month, sheesh).
     */
    getUnavailable() {
        this.setState({unavailableDates: null})
        let selectedMonth;
        let selectedYear;
        if (!this.state.selectedMonth) {
            selectedMonth = moment().month();
            selectedYear = moment().year();
            this.setState({
                selectedMonth: selectedMonth,
                selectedYear: selectedYear
            })
        } else {
            selectedMonth = this.state.selectedMonth;
            selectedYear = this.state.selectedYear;
        }
        let accessPoint = 'anon_events';
        this.props.authenticator.queuedGet(
            accessPoint,
            this.getUnavailableCallback,
            null,
            this.props.publicName,
            {
                type: this.props.eventType.name_slug,
                tzinfo: this.props.authenticator.timeZoneName,
                month: selectedMonth + 1,
                year: selectedYear
            },
        )
    }

    _handleDayClick(day) {
        this.setState({selectedDay: day});
        this.getTimesForDate(day)
    }

    getTimesCallback(data) {
        this.setState({
            availableTimes: data.available_times,
            dateBoundaries: data.date_boundaries
        })
    }

    getTimesForDate(day) {
        let accessPoint = 'anon_events';
        const year = day.getFullYear()
        const correctMonth = day.getMonth() + 1;
        const dayDate = day.getDate();
        const dateString = year + '-' + correctMonth + '-' + dayDate;
        this.props.authenticator.queuedGet(
            accessPoint,
            this.getTimesCallback,
            null,
            this.props.publicName,
            {
                type: this.props.eventType.name_slug,
                date: dateString,
                tzinfo: this.props.authenticator.timeZoneName
            },
        )
    }

    onNameChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        this.setState({
            name: event.target.value,
            nameIsValid: isValid,
            nameValidationMessage: validationMessage,
            isSaved: false
        });
    }

    onEmailChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        this.setState({
            email: event.target.value,
            emailIsValid: isValid,
            emailValidationMessage: validationMessage,
            isSaved: false
        });
    }

    onSubmitCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState({submitted: true, token: data.token});
        this.props.callback(data);
    }

    _onSubmitErrorCallback(data, status) {
        this.setState({submitted: false, errors: data})
    }

    onEventRequestSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        let data = {
            name: this.state.name,
            email: this.state.email,
            event_type: this.props.eventType.id,
            datetime: this.state.selectedDatetime,
        };
        let accessPoint = 'anon_events';
        this.props.authenticator.queuedPut(
            accessPoint,
            this.props.publicName,
            data,
            this.onSubmitCallback,
            this._onSubmitErrorCallback)
    }

    onMonthChange(dateTime) {
        let month = moment(dateTime).month();
        let year = moment(dateTime).year();
        this.setState({selectedMonth: month, selectedYear: year},
            () => {
                this.getUnavailable()
            });
    }

    onTimeSelect(event) {
        const selectedDatetime = event.target.value;
        this.setState({selectedDatetime: selectedDatetime})
    }

    render() {
        return <>
            {/*<RetailMenu authenticator={this.props.authenticator}/>*/}
            <div className={'public-type-container'}>
            <div className={'skin-row'}>{this.props.eventType.name}</div>
            <Form onSubmit={this.onEventRequestSubmit}
                  validated={!this.state.isSaved}>
                <FormGroup>
                    <Form.Label column={'name'}>
                        Your Name
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'name'}
                                  name={'name'}
                                  placeholder="Your Name Here"
                                  onChange={this.onNameChange}
                                  value={this.state.name}
                                  required
                                  isInvalid={!!this.state.errors.name}
                                  minLength={6}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.name}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.nameValidationMessage}
                    </Form.Control.Feedback>
                    <Form.Label column={'email'}>
                        Your Email Address
                    </Form.Label>
                    <Form.Control type="email"
                                  id={'email'}
                                  name={'email'}
                                  placeholder="Your Email Address Here."
                                  value={this.state.email}
                                  onChange={this.onEmailChange}
                                  required
                                  isInvalid={!!this.state.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.email}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.emailValidationMessage}
                    </Form.Control.Feedback>
                    {!this.state.unavailableDates &&
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>}
                    {this.state.unavailableDates && !this.state.selectedDay && this.state.nameIsValid && this.state.emailIsValid &&
                    <DayPicker
                        onDayClick={this._handleDayClick}
                        selectedDays={this.state.selectedDay}
                        disabledDays={this.state.unavailableDates}
                        onMonthChange={this.onMonthChange}
                        month={new Date(this.state.selectedYear, this.state.selectedMonth)}
                    />
                    }
                    {this.state.unavailableDates && this.state.selectedDay && (
                        <div>
                            <span>
                                <div>You Selected:</div>
                                {
                                moment(this.state.selectedDay).format('dddd LL')}
                                &nbsp;{this.props.authenticator.timeZoneAbbreviation}&nbsp;
                                <Button size={'sm'} variant={'outline-primary'}
                                        onClick={this.resetDate}
                                >
                                Change Date</Button>
                            </span>
                        </div>
                    )}
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.datetime}
                    </Form.Control.Feedback>
                </FormGroup>

                {this.state.selectedDatetime && this.state.nameIsValid && this.state.emailIsValid &&
                <Button type={'submit'}
                        disabled={this.state.isSaved}>Confirm {moment(this.state.selectedDatetime).format('dddd LL LT')}</Button>}
            </Form>
            <ButtonGroup aria-label="selectedDatetime" vertical>
                {!!!this.state.selectedDatetime && !!this.state.availableTimes && this.state.availableTimes.map(
                    (availableTime, index, original) => (

                        <Button
                            key={index}
                            block
                            onClick={this.onTimeSelect}
                            value={availableTime}
                        >
                            {moment(availableTime).format('LL LT')}
                            &nbsp;
                            {this.props.authenticator.timeZoneAbbreviation}
                        </Button>
                    )
                )}
            </ButtonGroup>
            {this.state.token && <Redirect to={'/' + this.props.publicName + '/' + this.props.eventType.name_slug + '/' + this.state.token + '/'} />}

        </div></>
    }
}


export default EventRequestForm;
