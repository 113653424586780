import React, {Component} from 'react'
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {Redirect} from "react-router";

import Nav from "react-bootstrap/Nav";

class AddContactButton extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            showAddContactModal: false,
            newContact: null
        }
        this.clickShowAddContactModal = this.clickShowAddContactModal.bind(this);
        this.clickHideAddContactModal = this.clickHideAddContactModal.bind(this);
        this.onNewContact = this.onNewContact.bind(this);
    }

    clickShowAddContactModal(event) {
        this.setState({showAddContactModal: true})
    }

    clickHideAddContactModal(event) {
        this.setState({showAddContactModal: false})
    }

    onNewContact(data) {
        this.setState({newContact: data, showAddContactModal: false}, () => {
            this.setState({newContact: null})
        })
    }

    render() {
        if (this.state.newContact) {
            return <Redirect
                to={'/contacts/contact/' + this.state.newContact.id + '/#edit'}/>
        }

        return (
            <Form inline className={'mx-auto'}>

                <Nav.Link
                    style={{
                        backgroundColor: 'rgb(52, 138, 167)',
                        textColor: 'white'
                    }}
                    eventKey={13}
                    as={Button}
                    onClick={this.clickShowAddContactModal}>Add Contact
                </Nav.Link>
                <AddContactModal
                    show={this.state.showAddContactModal}
                    callback={this.onNewContact}
                    hideCallback={this.clickHideAddContactModal}
                    authenticator={this.props.authenticator}
                />

            </Form>
        )
    }


}


class AddContactModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,

            id: null,

            name: '',
            nameIsValid: false,
            nameValidationmessage: '',
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitErrorCallback = this.onSubmitErrorCallback.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
    }


    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates);
    }

    onSubmitErrorCallback(data, status) {
        let newData = {...data};
        if (!!data.non_field_errors && data.non_field_errors[0] === 'The fields name, created_by must make a unique set.') {
            newData.non_field_errors = ['You already have a contact with that Display Name (make it unique to add).']
        }

        this.setState({isSaved: false, submitted: false, errors: newData})
    }

    onSubmitCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState({submitted: true});
        this.props.callback(data)
    }

    onSubmit(event) {
        event.preventDefault();
        const body = {
            name: this.state.name,
            created_by: this.props.authenticator.account.user.id
        };
        this.props.authenticator.queuedPost(
            'contacts', body,
            this.onSubmitCallback,
            this.onSubmitErrorCallback)

    }


    render() {
        return (

            <Modal
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={this.props.hideCallback}
                className={'skin-modal'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Contact
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {this.state.errors && this.state.errors.detail &&
                        <Alert
                            variant={'danger'}>{this.state.errors.detail}</Alert>}
                        {this.state.errors && this.state.errors.non_field_errors &&
                        <Alert
                            variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                        <Form.Label column={'name'}>
                            Display Name
                        </Form.Label>
                        <Form.Control type="text"
                                      id={'name'}
                                      name={'name'}
                                      onChange={this.onFieldChange}
                                      value={this.state.name}
                                      isInvalid={!!this.state.errors.name}
                                      required
                                      placeholder={'Unique Display Name'}
                        />
                        <Form.Text className="text-muted">
                            A Unique Display Name for your Contact.
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.name}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.nameValidationmessage}
                        </Form.Control.Feedback>
                        <br/>
                        <Button variant="primary" onClick={this.onSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        )
    }

}

export default AddContactModal;
export {AddContactButton};
