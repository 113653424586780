import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import {VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip} from 'victory';

import moment from "moment-timezone";

export default class ExperienceGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
            dailyTranscriptStats: null,
            zoomDomain: {x: [new Date(2019, 1, 1), new Date(2021, 1, 1)]}
        };
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    handleZoom(domain) {
        this.setState({zoomDomain: domain});
    }

    onMouseEnter(event) {
    }

    onMouseLeave(event) {
    }

    //
    // getDailyTranscriptStats(contact) {
    //     const url = EDNA_API_URL + 'transcript_stats/';
    //     const key = 'dailyTranscriptStats';
    //     // const fetchUrl = url + '?contact=' + contact.id;
    //     const headers = {
    //         Authorization: 'Bearer ' + this.props.access
    //     };
    //     fetch(url, {
    //         method: 'get',
    //         headers: headers
    //     })
    //         .then(res => res.json())
    //         .then((data) => {
    //
    //             let groomedData = data.map((item) => {
    //                 return {
    //                     'count': item.count,
    //                     'day': new Date(item.day)
    //                 }
    //             });
    //
    //             let keyData = {};
    //             keyData[key] = groomedData;
    //             this.setState(keyData);
    //         })
    //         .catch(console.log)
    // }
    //
    // componentDidMount() {
    //     this.getDailyTranscriptStats()
    // }

    render() {
        const data = this.props.data.map((item) => {
            return {
                'experience': item.experience,
                'day': new Date(item.day)
            }
        });

        return (
            <Container className={'experience-graph-container'}>
                {this.props.data.length > 0 ?
                    <VictoryChart scale={{x: "time"}}>
                        <VictoryBar
                            data={data}
                            x="day"
                            y="experience"
                            labels={({datum}) => moment(datum.day).format('dddd LL') + ':' + datum.experience}
                            labelComponent={<VictoryTooltip
                                constrainToVisibleArea
                                center={{x: 225, y: 30}}
                                pointerOrientation="bottom"
                                flyoutWidth={400}
                                flyoutHeight={24}
                                pointerWidth={150}
                                cornerRadius={0}
                            />}
                            style={{
                                data: { fill: "rgb(188, 231, 132)" },
                                parent: {
                                    border: "1px solid #ccc"
                                },
                                labels: {
                                    fontSize: 25, fill: "#c43a31"
                                }
                            }}
                        />
                        <VictoryAxis
                            label="Date"
                            style={{
                                axisLabel: {fontSize: 20, padding: 17},
                                tickLabels: {fontSize: 20, padding: 2}
                            }}
                        />
                        <VictoryAxis dependentAxis
                                     label="Activity"
                                     style={{
                                         axisLabel: {fontSize: 22, padding: 30},
                                         tickLabels: {fontSize: 14, padding: 10},
                                         grid: {stroke: "grey"}
                                     }}
                                     tickFormat={(t) => `${Math.round(t/1000)}k`}
                                     orientation={'left'}
                                     tickCount={10}
                        />
                    </VictoryChart>

                    : <div></div>}
            </Container>
        )
    }


}

