import React, {Component} from 'react'
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";

class AddPhoneNumberModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,

            success: false,

            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,
            eventType: null,
            eventTypeFrequency: {},

            id: null,

            number: '',
            numberIsValid: false,
            numberValidationmessage: '',
            phonetype: '',
            phonetypeIsValid: false,
            phonetypeValidationmessage: '',
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.submitSuccess = this.submitSuccess.bind(this);
        this.submitFailure = this.submitFailure.bind(this);
    }

    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates);
    }

    submitFailure(data) {
        this.setState({isSaved: false, submitted: false, errors: data})
    }

    submitSuccess(data) {
        if (!!!data) {return;}
        this.setState({show: false, success: true, isSaved: true});
        this.props.callback(data);
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        let body = {
            number:this.state.number,
            phone_type:this.state.phonetype,
            contact:this.props.contactId
        };
        this.props.authenticator.queuedPost(
            'phonenumbers',
            body,
            this.submitSuccess,
            this.submitFailure)
    }


    render() {
        return (

            <Modal
                show={this.props.show}
                size="lg"
                aria-labelledby="phonenumber-modal-title-vcenter"
                centered
                onHide={this.props.hideCallback}
                className={'skin-modal'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="phonenumber-modal-title-vcenter">
                        Add Phone Number
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.errors && this.state.errors.detail &&
                    <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                    {this.state.errors && this.state.errors.non_field_errors &&
                    <Alert
                        variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}

                    <Form id={'phonenumber-form'}
                          name={'phonenumber-form'}
                          onSubmit={this.onSubmit}
                          validated={!this.state.isSaved}>
                        <FormGroup>
                            <Form.Label column={'number'}>
                                Phone Number
                            </Form.Label>
                            <Form.Control type="text"
                                          id={'number'}
                                          name={'number'}
                                          minLength={10}
                                          onChange={this.onFieldChange}
                                          value={this.state.number}
                                          isInvalid={!!this.state.errors.number}
                                          required
                            />
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.number}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {this.state.numberValidationmessage}
                            </Form.Control.Feedback>
                            <Form.Label column={'phonetype'}>
                                Phone Number Type (optional)
                            </Form.Label>
                            <Form.Control type="text"
                                          id={'phonetype'}
                                          name={'phonetype'}
                                          onChange={this.onFieldChange}
                                          value={this.state.phonetype}
                                          isInvalid={!!this.state.errors.phone_type}
                                          placeholder={'work, personal, cell, etc.'}
                            />
                            <Form.Control.Feedback type="invalid">
                                {this.state.errors.phone_type}
                            </Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {this.state.phonetypeValidationmessage}
                            </Form.Control.Feedback>
                        </FormGroup>
                        <Button type={'submit'}
                                disabled={this.state.isSaved}>Submit</Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        )
    }

}

export default AddPhoneNumberModal;
