import React, {Component} from "react";
import {Redirect} from "react-router";
import EventRequestForm from "./EventRequest";
import EventTypeForm, {EventTypeCard} from "./EventType";


class EventTypes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventTypes: null,
            eventType: null,
            reroute: false,
            newType: false
        };
        this.buildEventTypeCard = this.buildEventTypeCard.bind(this);
        this.clickEventTypeCard = this.clickEventTypeCard.bind(this);
        this.eventTypeFormCallback = this.eventTypeFormCallback.bind(this);
        this.eventRequestFormCallback = this.eventRequestFormCallback.bind(this);
    }

    // componentDidMount() {
    //     this.setState({eventType: this.props.eventType});
    // }

    clickEventTypeCard(eventType) {
        if (!!eventType) {
            this.setState({eventType: eventType, reroute: true})
            this.props.selectedCallback(eventType);
        } else {
            this.setState({newType: true})
            this.props.selectedCallback();
        }
    }

    eventTypeFormCallback(eventType) {
        this.setState({eventType: null, reroute: true},
            this.props.callback);
    }

    eventRequestFormCallback(eventType) {
        this.setState({eventType: null});
        this.props.callback(eventType)
    }

    buildEventTypeCard(eventType, index) {
        return <EventTypeCard authenticator={this.props.authenticator}
                              eventType={eventType}
                              publicName={this.props.publicName}
                              callback={this.clickEventTypeCard}
                              key={index}/>
    }

    render() {
        const eventType = this.state.eventType || this.props.eventType;
        return (<>
            {(!!eventType || this.state.newType) && this.props.isOwner &&
            <EventTypeForm authenticator={this.props.authenticator}
                           eventType={eventType}
                           publicName={this.props.publicName}
                           callback={this.eventTypeFormCallback}
            />
            }
            {!!eventType && !this.props.isOwner &&
            <EventRequestForm authenticator={this.props.authenticator}
                              eventType={eventType}
                              publicName={this.props.publicName}
                              callback={this.eventRequestFormCallback}
            />
            }
            {!eventType && !this.state.newType && <>
            <div className={'skin-row'} style={{fontSize:'0.75rem', justifyContent: 'center'}}>
                Choose a Meeting Type
            </div>
            <div className={'public-type-container'}>
                {!!this.props.anonEvents && this.props.anonEvents.map(this.buildEventTypeCard)}
                {!!this.props.accountEvents && this.props.accountEvents.map(this.buildEventTypeCard)}
                {!!this.props.accountEvents &&
                <EventTypeCard authenticator={this.props.authenticator}
                               publicName={this.props.publicName}
                               callback={this.clickEventTypeCard}/>
                }
            </div></>}
            {!!eventType && this.state.reroute && <Redirect to={'/' + this.props.publicName + '/' + eventType.name_slug + '/'} />}
            {!!!this.state.eventType && this.state.reroute && <Redirect to={'/' + this.props.publicName + '/'} />}

        </>

        )}
}

export default EventTypes;
