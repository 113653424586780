import React, {Component} from "react";
import ForceGraph3D from 'react-force-graph-3d';
import {Redirect} from "react-router";
import SpriteText from "three-spritetext";
import {SubscriptionCheck} from "./Subscriptions";

export default class RelationshipForce extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            nodes: [],
            links: []
        };
        this.getRelationships = this.getRelationships.bind(this);
        this.getRelationshipsCallback = this.getRelationshipsCallback.bind(this);
        this.onNodeClick = this.onNodeClick.bind(this);
    }

    // componentDidMount() {
    //     this.setState({
    //         data: {
    //             nodes: [
    //                 {
    //                     id: "id1",
    //                     name: "name1",
    //                     val: 1,
    //                     color: "red"
    //                 },
    //                 {
    //                     id: "id2",
    //                     name: "name2",
    //                     val: 10,
    //                     color: "green"
    //                 },
    //             ],
    //             links:
    //                 [
    //                     {
    //                         source: "id1",
    //                         target: "id2"
    //                     },
    //                 ]
    //         }
    //     })
    // }

    componentDidMount() {
        this.getRelationships();
    }

    cleanData(contact) {
        let color = contact.score > 50 ? 'green' : 'olive';
        if (contact.score > 75) {color = 'lime'}

        return {
            id: contact.id,
            name: contact.name,
            val: contact.score,
            color: color,
            // relative: relative ? relative.id.toString() : null
        }
    }

    getRelationshipsCallback(data) {

        let contacts = {};
        let dataNodes = [];
        let dataLinks = [];
        data.forEach((relationship) => {
            let contact = relationship.contact;
            let relative = relationship.relative;
            if (relative) {
                contacts[contact.id] = this.cleanData(contact);
                contacts[relative.id] = this.cleanData(relative);
                dataLinks.push({source: contact.id,
                                target: relative.id,
                                type: relationship.type
                                })
            }
        });

        // const dataNodes = contacts.map((item) => {return item});
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(contacts)) {
            dataNodes.push(value);
        }


        // dataLinks = []
        // debugger;
        // let dataNodes = data.map((item) => {
        //     return {
        //         id: item.contact.id.toString(),
        //         name: item.contact.name,
        //         val: item.contact.score,
        //         color: 'red',
        //         relative: item.relative ? item.relative.id.toString() : null
        //     }
        // });

        // dataNodes = [dataNodes[1], dataNodes[3]];
        // const source = dataNodes[0].id;
        // const target = dataNodes[1].id;
        // const dataLinks = [{source:source, target: target}];

        //const dataLinks = [];

        // const dataLinks = data.flatMap((item) => (item.relative) ?
        //         {source: item.id.toString(), target: item.id.toString()} : []
        // );

        // console.log(dataNodes)
        // console.log(dataLinks)

        this.setState({nodes: dataNodes, links: dataLinks, data:data});
    }


    getRelationships() {
        this.props.authenticator.queuedGet(
            'relationships',
            this.getRelationshipsCallback)
    }

    onNodeClick(node) {
        this.setState({contact: node});
    }

    render() {
        if (this.state.contact) {
            return <Redirect to={'/contacts/contact/' + this.state.contact.id} />
        }

        const colors = {
            21: {
                "name": "Child of",
                "reverse_name": "Parent of",
                "color": 'orange'
            },
            20: {
                "name": "Classmate of",
                "reverse_name": "Classmate of",
                "color": 'green'
            },
            18: {
                "name": "EM Coach of",
                "reverse_name": "Only of Myself",
                "color": 'red'
            },
            4: {
                "name": "Existing Friend Of",
                "reverse_name": "Existing Friend Of",
                "color": 'green'
            },
            22: {
                "name": "Family of",
                "reverse_name": "Family of",
                "color": 'orange'
            },
            6: {
                "name": "I Introduced To",
                "reverse_name": "Was Introduced To",
                "color": 'lightgreen'
            },
            3: {
                "name": "In relationship with",
                "reverse_name": "In relationship with",
                "color": 'orange'
            },
            5: {
                "name": "Introduced Me To",
                "reverse_name": "Was Introduced By",
                "color": 'lightblue'
            },
            2: {
                "name": "Older sibling of",
                "reverse_name": "Younger sibling of",
                "color": 'orange'
            },
            9: {
                "name": "Parent of",
                "reverse_name": "Child of",
                "color": 'orange'
            },
            15: {
                "name": "Previously In Relationship With",
                "reverse_name": "Previously In Relationship With",
                "color": 'red'
            },
            10: {
                "name": "Reported To",
                "reverse_name": "Was Subordinate Of",
                "color": 'teal'
            },
            16: {
                "name": "Share With",
                "reverse_name": "Is Sharing",
                "color": 'lightblue'
            },
            19: {
                "name": "Shared By",
                "reverse_name": "Shared",
                "color": 'lightblue'
            },
            17: {
                "name": "Shared With",
                "reverse_name": "Was Shared",
                "color": 'lightblue'
            },
            1: {
                "name": "Social Media Friend of",
                "reverse_name": "Social Media Friend of",
                "color": 'yellow'
            },
            13: {
                "name": "Student of",
                "reverse_name": "Teacher of",
                "color": 'yellow'
            },
            14: {
                "name": "Teacher of",
                "reverse_name": "Student of",
                "color": 'yellow'
            },
            8: {
                "name": "Was Introduced By",
                "reverse_name": "Introduced Me To",
                "color": 'lightblue'
            },
            11: {
                "name": "Was Subordinate Of",
                "reverse_name": "Reported To",
                "color": 'teal'
            },
            12: {
                "name": "Worked With",
                "reverse_name": "Worked With",
                "color": 'teal'
            },
            7: {
                "name": "Younger sibling of",
                "reverse_name": "Older sibling of",
                "color": 'orange'
            }
        };

        return <>
            <SubscriptionCheck authenticator={this.props.authenticator} minimum={'pro'}/>
            <ForceGraph3D
                graphData={{
                        nodes: this.state.nodes,
                        links: this.state.links
                    }
                }
                onNodeClick={this.onNodeClick}

                backgroundColor={'white'}

                nodeThreeObject={node => {
                    const sprite = new SpriteText(node.name.split(' ')[0]);
                    sprite.color = node.color;
                    sprite.textHeight = 8;
                    return sprite;
                }}

                linkWidth={2}
                linkThreeObjectExtend={true}
                linkColor = {link => {return colors[link.type].color}}
                linkOpacity = {.75}
                linkThreeObject={link => {
                    // extend link with text sprite
                    const spriteText = this.props.authenticator.library.relationship_type_dict[link.type].name;

                    const sprite = new SpriteText(spriteText);
                    // const sprite = new SpriteText(`${link.source} > ${link.target}`);
                    sprite.color = 'lightgrey';
                    sprite.textHeight = 3.5;
                    return sprite;
                }}
                linkPositionUpdate={(sprite, { start, end }) => {
                    const middlePos = Object.assign(...['x', 'y', 'z'].map(c => ({
                        [c]: start[c] + (end[c] - start[c]) / 2 // calc middle point
                    })));

                    // Position sprite
                    Object.assign(sprite.position, middlePos);
                }}
            />
            {/*<ForceGraph3D*/}
            {/*    graphData={this.state.data}*/}
            {/*    nodeAutoColorBy="group"*/}
            {/*    nodeThreeObject={node => {*/}
            {/*        const sprite = new SpriteText(node.id);*/}
            {/*        sprite.color = node.color;*/}
            {/*        sprite.textHeight = 8;*/}
            {/*        return sprite;*/}
            {/*    }}*/}
            {/*/>*/}


        </>
    }

}