import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";


export default class LootShop extends Component {

    render() {

        return <>
            <Container>
                <h1>Treasure Shop</h1>
                <p>These are unlocks, some may require an additional purchase with real world currency.</p>
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Level Requirement</th>
                        <th>Item</th>
                        <th>Token Cost</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>10</td>
                        <td>New Skin!</td>
                        <td>10</td>
                        <td>Enjoy this makeover for your game.</td>
                    </tr>
                    <tr>
                        <td>100</td>
                        <td>Merch</td>
                        <td>150</td>
                        <td>A choice of Experience Master merchandise for infinite bragging rights.</td>
                    </tr>
                    <tr>
                        <td>120</td>
                        <td>10% off your next real-world purchase.</td>
                        <td>200</td>
                        <td>This is a real world discount worth up to $20USD.</td>
                    </tr>
                    </tbody>
                </Table>
            </Container>
        </>

    }
}
