import React, {Component} from "react";
import Button from "react-bootstrap/Button";


export default class GoogleContactsSync extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false
        };
        this.contactsCallback = this.contactsCallback.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    contactsCallback(data) {
        const requestId = data.id;
        this.props.callback(requestId);
    }

    onClick(event) {
        this.props.authenticator.queuedGet(
            'google/contacts', this.contactsCallback, () => {
            })
    }

    render() {
        return <div>
            <Button onClick={this.onClick}>Sync Contacts with Google</Button>
        </div>
    }

}
