// LOCAL
export const EDNA_API_URL = 'http://127.0.0.1:8000/api_v1/';

// UAT
// export const EDNA_API_URL = 'https://api-uat.experiencemaster.com/api_v1/';
// export const GOOGLE_API = 'https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://api-uat.experiencemaster.com/google_callback/&prompt=consent&response_type=code&client_id=1069555633989-jmf6a4igufdnort10fdqe1ji0prf5u2e.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/contacts.readonly+https://www.googleapis.com/auth/calendar.events+https://www.googleapis.com/auth/calendar.readonly+https://www.googleapis.com/auth/gmail.send&access_type=offline';


// PROD
// export const EDNA_API_URL = 'https://api-v1.experiencemaster.com/api_v1/';
export const GOOGLE_API = 'https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://api-v1.experiencemaster.com/google_callback/&prompt=consent&response_type=code&client_id=1069555633989-jmf6a4igufdnort10fdqe1ji0prf5u2e.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/contacts.readonly+https://www.googleapis.com/auth/calendar.events+https://www.googleapis.com/auth/calendar.readonly+https://www.googleapis.com/auth/gmail.send&access_type=offline';


// S3 Static Files
export const S3_STATIC = 'https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/';


// react-scripts --openssl-legacy-provider start
// it's a version thing, EM is very outdated now.
// react-scripts --openssl-legacy-provider build

