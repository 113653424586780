import React, {Component} from "react";


export default class ContactStars extends Component {

    render() {
        let stars = '';
        if (this.props.contact){
            let starCount = Math.round(
                this.props.contact.experience_sum /
                this.props.experienceMax * 100 / 20);
            if (starCount === 0) {
                if (this.props.contact.experience_sum > 0) {
                    starCount = 1
                }
            }
            for (var i = 1; i <= starCount; i++) {stars+='⭐'}
        }

        return <div>{stars}</div>
    }
}
