import moment from "moment-timezone";
import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import {Link} from "react-router-dom";
import RetailMenu from "./RetailMenu";
import * as queryString from "query-string";

export default class NewUserRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,
            eventType: null,
            eventTypeFrequency: {},

            username: '',
            usernameIsValid: false,
            usernameValidationmessage: '',
            apassword: '',
            apasswordIsValid: false,
            apasswordValidationmessage: '',
            apassword2: '',
            apassword2IsValid: false,
            apassword2Validationmessage: '',
            firstname: '',
            firstnameIsValid: false,
            firstnameValidationmessage: '',
            lastname: '',
            lastnameIsValid: false,
            lastnameValidationmessage: '',
            email: '',
            emailIsValid: false,
            emailValidationmessage: '',
            timezone: '',
            timezoneIsValid: false,
            timezoneValidationmessage: '',
        };

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitErrorCallback = this.onSubmitErrorCallback.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
        this.passwordCheck = this.passwordCheck.bind(this);
    }

    componentDidMount() {
        this.setState({timezone: this.props.authenticator.timeZone.name})
    }


    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates, () => this.passwordCheck());
    }

    onSubmitErrorCallback(data, status) {
        this.setState({isSaved: false, submitted: false, errors: data});
    }

    onSubmitCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState({submitted: true})
        // this.props.callback(data)
    }


    onSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});

        const searchDict = this.props.location &&
            queryString.parse(this.props.location.search);
        let ref = null;
        if (searchDict) {
            if (searchDict.ref) {
                ref = searchDict.ref
            }
        }

        const data = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.apassword,
            first_name: this.state.firstname,
            last_name: this.state.lastname,
            timezone: this.state.timezone,
            ref: ref
        };

        this.props.authenticator.queuedPost(
            'user_registration',
            data,
            this.onSubmitCallback,
            this.onSubmitErrorCallback
        )


    }

    passwordCheck() {
        if (this.state.apassword !== this.state.apassword2) {
            this.setState({
                passwordInvalid: true,
                errors: {apassword2: 'Does not match.'}
            })
        } else {
            let newErrors = {...this.state.errors}
            newErrors.apassword2 = null
            this.setState({
                passwordInvalid: false,
                errors: newErrors
            })
        }
    }

    timezoneList() {
        let timeZones;
        timeZones = moment.tz.names().map((tz) => {
            return tz
        });
        return timeZones
    }

    timezoneOptionList() {
        const timezones = this.timezoneList();
        let optionList = [];
        optionList.push(
            <option key={'-null'}
                    value=''
            >None Selected</option>
        );
        // eslint-disable-next-line no-unused-vars
        timezones.map((value, index) => {
            optionList.push(
                <option key={index}
                        value={value}
                >{value}</option>
            );
            return ''
        });
        return optionList;
    }

    render() {
        if (this.state.submitted) {
            return <>
                <RetailMenu authenticator={this.props.authenticator}/>
                <h1>EM Registration Submitted - Check your email.  -- <Link to="/">Home</Link></h1>
            </>
        }

        return <Container style={{fontFamily: ['Dosis', 'serif']}}>
            <RetailMenu authenticator={this.props.authenticator}/>
            <h1>Experience Master Registration</h1>
            <p>New Subscribers get a 1 Month Basic Subscription for FREE!</p>
            <p>Upgrade your Subscription to receive ANOTHER month FREE!</p>
            <Form onSubmit={this.onSubmit}
                  validated={!this.state.isSaved}>
                {this.state.errors && this.state.errors.detail &&
                <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                {this.state.errors && this.state.errors.non_field_errors &&
                <Alert
                    variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                <FormGroup>
                    <Form.Label column={'username'}>
                        Account Username
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'username'}
                                  name={'username'}
                                  autoComplete="username"
                                  onChange={this.onFieldChange}
                                  value={this.state.username}
                                  isInvalid={!!this.state.errors.username}
                                  required
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.username}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.usernameValidationmessage}
                    </Form.Control.Feedback>
                    <Form.Label column={'apassword'}>
                        Password
                    </Form.Label>
                    <Form.Control type="password"
                                  id={'apassword'}
                                  name={'apassword'}
                                  autoComplete="off"
                                  onChange={this.onFieldChange}
                                  value={this.state.apassword}
                                  isInvalid={this.state.errors && !!this.state.errors.password}
                                  placeholder={'Input New Password'}
                                  minLength={9}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors && this.state.errors.password}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.apasswordValidationmessage}
                    </Form.Control.Feedback>
                    <Form.Label column={'apassword2'}>
                        Confirm Password
                    </Form.Label>
                    <Form.Control type="password"
                                  id={'apassword2'}
                                  name={'apassword2'}
                                  autoComplete="new-password"
                                  onChange={this.onFieldChange}
                                  value={this.state.apassword2}
                                  isInvalid={this.state.errors && !!this.state.errors.password}
                                  minLength={9}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors && this.state.errors.password}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.apassword2Validationmessage}
                    </Form.Control.Feedback>
                    <Form.Label column={'firstname'}>
                        Given Name
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'firstname'}
                                  name={'firstname'}
                                  onChange={this.onFieldChange}
                                  value={this.state.firstname}
                                  required
                                  isInvalid={!!this.state.errors.firstname}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.first_name}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.firstnameValidationmessage}
                    </Form.Control.Feedback>
                    <Form.Label column={'lastname'}>
                        Family Name
                    </Form.Label>
                    <Form.Control type="text"
                                  id={'lastname'}
                                  name={'lastname'}
                                  onChange={this.onFieldChange}
                                  value={this.state.lastname}
                                  required
                                  isInvalid={!!this.state.errors.lastname}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.last_name}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.lastnameValidationmessage}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="valid">
                        We human verify All Given and Family Names.
                    </Form.Control.Feedback>

                    {/*<Form.Label column={'profilename'}>*/}
                    {/*    Profile Name*/}
                    {/*</Form.Label>*/}
                    {/*<Form.Control type="text"*/}
                    {/*              id={'profilename'}*/}
                    {/*              name={'profilename'}*/}
                    {/*              onChange={this.onFieldChange}*/}
                    {/*              value={this.state.profilename}*/}
                    {/*              required*/}
                    {/*              isInvalid={this.state.errors.accountprofilename && !!this.state.errors.accountprofilename.public_name}*/}
                    {/*/>*/}
                    {/*<Form.Control.Feedback type="invalid">*/}
                    {/*    {this.state.errors.accountprofilename && this.state.errors.accountprofilename.public_name}*/}
                    {/*</Form.Control.Feedback>*/}
                    {/*<Form.Control.Feedback type="invalid">*/}
                    {/*    {this.state.profilenameValidationmessage}*/}
                    {/*</Form.Control.Feedback>*/}
                    <Form.Label column={'email'}>
                        Email (Notifications and SMTP Sending Messages)
                    </Form.Label>
                    <Form.Control type="email"
                                  id={'email'}
                                  name={'email'}
                                  onChange={this.onFieldChange}
                                  value={this.state.email}
                                  required
                                  isInvalid={this.state.errors && !!this.state.errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors && this.state.errors.email}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.emailValidationmessage}
                    </Form.Control.Feedback>

                    <Form.Label column={'timezone'}>
                        Default Timezone
                    </Form.Label>
                    <Form.Control as="select"
                                  id={'timezone'}
                                  name={'timezone'}
                                  value={this.state.timezone || ''}
                                  onChange={this.onFieldChange}
                                  isInvalid={!!this.state.errors.timezone}
                                  required={true}
                    >
                        {this.timezoneOptionList()}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        {this.state.errors.timezone}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {this.state.timezoneValidationmessage}
                    </Form.Control.Feedback>

                </FormGroup>
                <Button type={'submit'}
                        disabled={this.state.isSaved}>Submit</Button>
            </Form>
            <Link to="/">Return to EM Homepage</Link>
        </Container>
    }
}
