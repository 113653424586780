import React, {Component} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./helpsymbol.css"

export default class HelpSymbol extends Component {

    render() {
        return (
            <OverlayTrigger overlay={
                <Popover id="tooltip-disabled">
                    <Popover.Title>
                        {this.props.title}
                    </Popover.Title>
                    <Popover.Content>
                        {this.props.content}
                    </Popover.Content>
                </Popover>}
            >
                <div className={'help-symbol'}>?</div>
            </OverlayTrigger>
        )
    }
}