import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import AddUrlModal from "./addUrl";


class ContactUrls extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            requestedItem: null,
            showModal: false
        };
        this.addCallback = this.addCallback.bind(this);
        this.hideCallback = this.hideCallback.bind(this);
        this.showModal = this.showModal.bind(this);

        this.deleteButton = this.deleteButton.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
    }

    componentDidMount() {
        this.setState({
            items: this.props.contact.contacturl_set
        });
    }

    addCallback(newItem) {
        let newItems = [...this.state.items];
        newItems.push(newItem);
        this.setState({items: newItems}, this.hideModal);
    }

    hideCallback() {
        this.hideModal()
    }

    hideModal() {
        this.setState({showModal: false})
    }

    showModal(event) {
        event.preventDefault();
        this.setState({showModal: true})
    }

    deleteCallback() {
        let newItems = [...this.state.items];
        let preppedItems = newItems.filter(
            item => (item.id !== this.state.requestedItem));

        this.setState({items: preppedItems});
    }

    deleteButton(event) {
        const recordId = parseInt(event.currentTarget.dataset.itemId);
        this.setState({requestedItem: recordId});
        this.props.authenticator.queuedDelete(
            'urls', recordId, this.deleteCallback, () => {});
    }

    render() {
        if (!this.props.contact) {
            return <></>
        }
        return (
            <Col align={"left"}>
                {this.state.items.length > 0 && this.state.items.map((item, index) => (

                        <div key={index}>
                            {item.url_type}&nbsp;
                            <a href={item.url}>{item.url.replace(/(^\w+:|^)\/\//, '')}</a>&nbsp;
                            {this.props.editMode &&<Button variant={'danger'}
                                                           onClick={this.deleteButton}
                                                           data-item-id={item.id}>X
                            </Button>}
                        </div>


                ))}

                {this.props.editMode && <>
                <Button size="sm" block
                        onClick={this.showModal}>Add URL</Button>
                <AddUrlModal
                    show={this.state.showModal}
                    callback={this.addCallback}
                    contact={this.props.contact}
                    hideCallback={this.hideCallback}
                    authenticator={this.props.authenticator}
                /></>}
            </Col>
        )
    }
}


export default ContactUrls;
