import moment from "moment-timezone";
import React, {Component} from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";


export default class SharedConnection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            showShareContactModal: false,
            showAddNoteField: false,
            currentNote: '',
            isCoach: false
        };
        this.addSharedNoteCallback = this.addSharedNoteCallback.bind(this);
        this.addSharedNote = this.addSharedNote.bind(this);
        this.clickShareNote = this.clickShareNote.bind(this);
    }


    // onClick(event) {
    //     if (this.state.data.length === 0) {
    //         this.getContactShares()
    //     }
    // }

    addSharedNoteCallback(data) {
        this.setState({currentNote: ''})
        this.props.callback();
    }

    addSharedNote() {
        if (!this.state.currentNote) {
            return
        }

        const body = {
            connection: this.state.currentConnection.id,
            note: this.state.currentNote
        };

        this.props.authenticator.queuedPost(
            'coach/shared/notes',
            body,
            this.addSharedNoteCallback
        );
        this.setState({note: null})
    }


    clickShareNote(event) {
        event.preventDefault();
        this.addSharedNote();
    }

    render() {
        if (!this.props.authenticator) {
            return <div>No Auth</div>
        }
        let connections;
        // TODO: merge these
        if (this.props.contact.coachsharedconnection_set.length > 0) {
            connections = this.props.contact.coachsharedconnection_set
        }
        if (this.props.contact.learnersharedconnection_set.length > 0) {
            connections = this.props.contact.learnersharedconnection_set
        }

        if (!connections) {
            return <div id={'no-connections'}/>
        }

        return <Container>
            <Accordion
                as={Card}
                className={'accordion-container'}
                key={'shares'}
            >
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey={'shares'}
                >
                    Shared Connections
                </Accordion.Toggle>
                <Accordion.Collapse
                    eventKey={'shares'}>
                    <Card.Body>

                        {connections.map(
                            (connection, index, original) => {
                                return <div key={index}>
                                    <Table striped hover size="sm">
                                        <tbody>
                                        {connection.sharedconnectionnote_set.map((note, index, original) => (
                                            <tr key={index}>
                                                <td>{note.created_by === this.props.authenticator.account.user.id ? 'You Said: ' : 'They Said: '}</td>
                                                <td colSpan={2}
                                                    className={'text-left'}>{note.note}</td>
                                                <td>{moment(note.modified).fromNow()}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Form>
                                        <Form.Control
                                            as="textarea"
                                            rows="3"
                                            placeholder="Add Note"
                                            onChange={(event) => {
                                                this.setState({
                                                    currentConnection: connection,
                                                    currentNote: event.currentTarget.value
                                                })
                                            }}
                                            value={this.state.currentNote}
                                        />
                                        <Button type={'submit'}
                                                onClick={this.clickShareNote}>Share
                                            Note</Button>
                                    </Form>

                                </div>
                            })
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>
        </Container>

    }
}

