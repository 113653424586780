import React, {Component} from 'react'
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Creatable from 'react-select/creatable';
import Editor from "./Editor";


class MessageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            messageId: null,
            messageName: '',
            messageSubject: '',
            messageBody: '',
            isSaved: true,
            validated: false,
            mouseOver: false
        };
        this.onFormItemChange = this.onFormItemChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.submitCallback = this.submitCallback.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
    }

    componentDidMount() {
        if (this.props.message) {
            const data = this.props.message;
            this.setState({
                message: data,
                messageId: data.id,
                messageName: data.name,
                messageSubject: data.message_subject,
                messageBody: data.message_body
            })
        }
    }

    onFormItemChange(event) {
        let keyData = {};
        keyData[event.target.name] = event.target.value;
        this.setState(keyData);
        this.setState({isSaved: false})
    }

    submitCallback(data) {
        if (!this.state.message) {
            this.setState({
                messageId: null,
                messageName: '',
                messageSubject: '',
                messageBody: ''
            });
        } else {
            this.setState({
                messageId: data.id,
                messageName: data.name,
                messageSubject: data.message_subject,
                messageBody: data.message_body
            });
        }

        if (this.props.callback) {
            this.props.callback(data);
        }
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        let body = {
            user: this.props.authenticator.account.user.id,
            name: this.state.messageName,
            message_subject: this.state.messageSubject,
            message_body: this.state.messageBody
        };
        if (this.state.messageId) {
            body.id = this.state.messageId;
            this.props.authenticator.queuedPut('message_templates/user',
                this.state.messageId, body, this.submitCallback, null)
        } else {
            this.props.authenticator.queuedPost('message_templates/user',
                body, this.submitCallback, null)
            event.target.reset();
        }
    }

    deleteCallback(data) {
        this.props.callback(data)
    }

    onDeleteClick() {
        this.setState({isSaved: false});
        if (this.state.messageId) {
            this.props.authenticator.queuedDelete('message_templates/user',
                this.state.messageId,
                this.deleteCallback, null)
        }
    }

    render() {
        return (
            <Card border="primary">
                <Form
                    onSubmit={this.onSubmit}
                    validated={!this.state.isSaved}
                >
                    <InputGroup className="mb-3">
                        <Form.Control type="text" rows="3"
                                      name={'messageName'}
                                      placeholder="Template Name"
                                      onChange={this.onFormItemChange}
                                      defaultValue={this.state.messageName}
                                      required
                        />
                        <InputGroup.Append>
                            <Button variant={'danger'} size={'sm'}
                                    onClick={this.onDeleteClick}>X</Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <Form.Control type="text" rows="3"
                                  name={'messageSubject'}
                                  placeholder="Message Subject"
                                  onChange={this.onFormItemChange}
                                  defaultValue={this.state.messageSubject}
                                  required
                    />
                    <Form.Control as="textarea" type="text" rows="3"
                                  name={'messageBody'}
                                  placeholder="Message Body"
                                  onChange={this.onFormItemChange}
                                  defaultValue={this.state.messageBody}
                                  required
                    />
                    {!this.state.isSaved &&
                    <Button variant="primary" type={'submit'}>
                        Save
                    </Button>}
                </Form>
            </Card>
        )
    }

}


class MessageTemplateEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: null
        };
        this.getMessagesCallback = this.getMessagesCallback.bind(this);
        this.getMessages = this.getMessages.bind(this);
    }

    componentDidMount() {
        this.getMessages()
    }

    getMessagesCallback(data) {
        this.setState({messages: data})
    }

    getMessages(data) {
        this.props.authenticator.queuedGet('message_templates/user',
            this.getMessagesCallback)
    }


    render() {
        return (
            <Container>
                <p>There are a few special fields available: {'{{my_name}}'}, {'{{contact_name}}'}, {'{{rendered_on}}'}, and any related fields to: {'{{contact}}'}, {'{{user}}'}.</p>
                <p>We use Django templates, so all of the templating language is available to you: <a href={'https://docs.djangoproject.com/en/3.0/ref/templates/language/'}>Django Template Docs</a></p>
                {this.state.messages && this.state.messages.map((message, index) => (
                    <MessageForm
                        key={index}
                        authenticator={this.props.authenticator}
                        callback={this.getMessages}
                        message={message}
                    />
                ))}
                <Row><Col>New Message Template</Col></Row>
                <MessageForm
                    authenticator={this.props.authenticator}
                    callback={this.getMessages}
                />
            </Container>
        )
    }


}


class MessageTemplates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messageTemplates: [],
            messageTemplatesOpen: false
        };
        this.sendEmail = this.sendEmail.bind(this);
        this.executeSendEmail = this.executeSendEmail.bind(this);
        this.toggleMessageTemplatesOpen = this.toggleMessageTemplatesOpen.bind(this);
        this.getMessageTemplatesCallback = this.getMessageTemplatesCallback.bind(this);
        this.sendEmailCallback = this.sendEmailCallback.bind(this);
    }

    getMessageTemplatesCallback(data) {
        this.setState({messageTemplates: data});
    }

    getMessageTemplates(contact) {
        this.props.authenticator.queuedGet(
            'message_templates',
            this.getMessageTemplatesCallback,
            null,
            null,
            {contact: this.props.contact.id}
        );
    }

    sendEmailCallback(data) {
        this.setState({sendingEmail: false});
        this.props.refreshTranscriptsCallback();
    }

    executeSendEmail(contact, subject, body) {

        this.setState({sendingEmail: true});
        const postBody = {
            contact: contact,
            type: 18, // TODO: send-email-queue this could be a lookup at least
            subject: subject,
            data: body
        };
        this.props.authenticator.queuedPost(
            'transcripts',
            postBody,
            this.sendEmailCallback
        )

    }

    sendEmail(event) {

        this.setState({messageTemplatesOpen: false});
        let messageId = event.target.form.name.slice(13);
        let subject = event.target.form.elements['message-subject-' + messageId].value;
        let body = event.target.form.elements['message-' + messageId].value;
        let contact = this.props.contact.id;
        this.executeSendEmail(contact, subject, body)
    }

    toggleMessageTemplatesOpen(event) {
        event.preventDefault();
        this.setState(prevState => (
            {messageTemplatesOpen: !prevState.messageTemplatesOpen}));
    }

    componentDidMount() {
        this.getMessageTemplates()
    }


    render() {
        return (
            <Container>
                {this.state.messageTemplates.map((message, index) =>
                    (<Accordion
                            as={Card}
                            border={'primary'}
                            className={'accordion-container'}
                            key={index}
                        >
                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey={index}>
                                {message.name}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index}>
                                <Card.Body>
                                    <Form name={'message-form-' + message.id}>
                                        <Row>
                                            <Col sm={2}>

                                                {this.state.sendingEmail ?
                                                    <div>Sending....</div> :
                                                    <Button
                                                        onClick={this.sendEmail}>Send EMail</Button>}
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    id={'message-subject-' + message.id}
                                                    name={'message-subject-' + message.id}
                                                    type="text"
                                                    defaultValue={message.message_subject}/>
                                                {/*<Form.Control as="textarea"*/}
                                                {/*              rows="15"*/}
                                                {/*              placeholder="Message Template"*/}
                                                {/*              defaultValue={message.message_body}*/}
                                                {/*              size="sm"*/}
                                                {/*              id={'message-' + message.id}*/}
                                                {/*              name={'message-' + message.id}*/}
                                                {/*/>*/}
                                                <Editor value={message.message_body}/>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Accordion>
                    )
                )}

            </Container>
        )
    }


}


class SendMessageForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null,
            messageId: null,
            messageName: '',
            messageSubject: '',
            messageBody: '',
            isSaved: true,
            validated: false,
            mouseOver: false,
            sending: false
        };
        this.onFormItemChange = this.onFormItemChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.transcriptCallback = this.transcriptCallback.bind(this);
    }

    componentDidMount() {
        const data = this.props.message;
        this.setState({
            message: data,
            messageId: data.id,
            messageName: data.name,
            messageSubject: data.message_subject,
            messageBody: data.message_body
        })
    }

    onFormItemChange(event) {
        let keyData = {};
        keyData[event.target.name] = event.target.value;
        this.setState(keyData);
        this.setState({isSaved: false})
    }

    transcriptCallback(data) {
        this.setState({sending: false});
        this.props.callback(data);
    }


    onSubmit(event) {
        this.setState({sending: true});
        event.preventDefault();
        const body = {
            contact: this.props.contact.id,
            type: 18, // TODO: send-email-queue this could be a lookup at least
            subject: this.state.messageSubject,
            data: this.state.messageBody
        };
        this.props.authenticator.queuedPost(
            'transcripts', body, this.transcriptCallback);
    }

    render() {
        return (
            <Container>
                <Accordion
                    as={Card}
                    border={'primary'}
                    className={'accordion-container'}
                    key={this.state.messageId}
                >
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey={this.state.messageId}>
                        {this.props.message.name}
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={this.state.messageId}>
                        <Card.Body>
                            <Form
                                onSubmit={this.onSubmit}
                                validated={!this.state.isSaved}
                            >
                                <Form.Control type="text" rows="3"
                                              name={'messageSubject'}
                                              placeholder="Message Subject"
                                              onChange={this.onFormItemChange}
                                              defaultValue={this.state.messageSubject}
                                              required
                                />

                                <Editor value={this.state.messageBody}
                                        onChange={this.onFormItemChange}
                                        readonly={false}
                                />
                                {/*<Form.Control as="textarea" type="text" rows="3"*/}
                                {/*              name={'messageBody'}*/}
                                {/*              placeholder="Message Body"*/}
                                {/*              onChange={this.onFormItemChange}*/}
                                {/*              defaultValue={this.state.messageBody}*/}
                                {/*              required*/}
                                {/*/>*/}
                                {this.state.sending ? <div>Sending...</div> :
                                    <Button variant="primary" type={'submit'}>
                                        Send Message
                                    </Button>}
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Accordion>
            </Container>
        )
    }

}

class UserMessageTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: []
        };
        this.getTemplates = this.getTemplates.bind(this);
        this.getTemplatesCallback = this.getTemplatesCallback.bind(this);
        this.sendMessageCallback = this.sendMessageCallback.bind(this);
    }

    getTemplatesCallback(data) {
        this.setState({templates: data})
    }

    getTemplates() {this.props.authenticator.queuedGet(
        'message_templates',
        this.getTemplatesCallback,
        null,
        'user',
        {contact: this.props.contact.id}
    );
    }

    sendMessageCallback(data) {
        this.props.refreshTranscriptsCallback(data);
    }

    componentDidMount() {
        this.getTemplates()
    }


    render() {
        return (
            <div>
                {this.state.templates && this.state.templates.map(
                    (template, index) => (
                        <SendMessageForm
                            key={index}
                            authenticator={this.props.authenticator}
                            contact={this.props.contact}
                            message={template}
                            callback={this.sendMessageCallback}
                        />
                    )
                )}
                <Row><Col>Add More under <a href={'/account'}>Account
                    Settings</a></Col></Row>

            </div>
        )
    }

}


export class SendMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            selected: null,
            selectedIndex: -1,
            response: null,
            currentSubject: '',
            currentBody: '',
            currentTemplateBody: '',
            editable: false,
            templateEditMode: false,
            temporaryBody: '',
            showHelp: false
        };
        this.getTemplates = this.getTemplates.bind(this);
        this.getTemplatesCallback = this.getTemplatesCallback.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.getRenderedTemplate = this.getRenderedTemplate.bind(this);
        this.getRenderedTemplateCallback = this.getRenderedTemplateCallback.bind(this);
        this.enableTemplateEditMode = this.enableTemplateEditMode.bind(this);
        this.disableTemplateEditMode = this.disableTemplateEditMode.bind(this);
        this.onSubjectChange = this.onSubjectChange.bind(this);
        this.onBodyChange = this.onBodyChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSaveCallback = this.onSaveCallback.bind(this);
        this.onDeleteCallback = this.onDeleteCallback.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.createNewTemplate = this.createNewTemplate.bind(this);
        this.onCreateCallback = this.onCreateCallback.bind(this);
        this.onNewMessage = this.onNewMessage.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onTranscript = this.onTranscript.bind(this);
        this.onEmail = this.onEmail.bind(this);
        this.done = this.done.bind(this);
        this.showHelp = this.showHelp.bind(this);
        this.hideHelp = this.hideHelp.bind(this);
        this.postSave = this.postSave.bind(this);
    }

    showHelp() {
        this.setState({showHelp: true})
    }
    hideHelp() {
        this.setState({showHelp: false})
    }

    enableTemplateEditMode() {
        this.setState({templateEditMode: true})
    }
    disableTemplateEditMode() {
        this.setState({templateEditMode: false})
    }

    getTemplatesCallback(data) {
        this.setState({templates: data})
    }

    getTemplates() {this.props.authenticator.queuedGet(
        'message_templates',
        this.getTemplatesCallback,
        () => {},
    );
    }

    getRenderedTemplateCallback(data) {
        this.setState({
            response: data,
            currentSubject: data.unrendered.message_subject,
            currentBody: data.rendered,
            currentTemplateBody: data.unrendered.message_body,
            editable: (
                this.props.authenticator.account.user.id === data.unrendered.created_by)
        })
    }

    getRenderedTemplate() {
        this.props.authenticator.queuedGet(
            'message_templates',
            this.getRenderedTemplateCallback,
            null,
            this.state.selected.id,
            {contact: this.props.contact.id}
        );
    }

    onSelect(data) {
        // data = {label: "New Template", value: "New Template", __isNew__: true}
        if (data.__isNew__) {
            this.createNewTemplate(data.value);
            return;
        }

        const selectedIndex = data.value;
        const selected = this.state.templates[selectedIndex];
        this.setState({
            selected: selected,
            selectedIndex:selectedIndex}, this.getRenderedTemplate);
    }

    onCreateCallback(data) {
        this.setState({templateEditMode: true, selected: data},
            this.getRenderedTemplate)
    }

    createNewTemplate(templateName) {
        const data = {
            name: templateName,
            message_subject: this.state.currentSubject || 'Hello World',
            message_body: this.state.temporaryBody || this.state.currentTemplateBody || "We should connect!"
        };
        this.props.authenticator.queuedPost(
            'message_templates',
            data,
            this.onCreateCallback,
            () => {}
        );
    }


    onSubjectChange(event) {
        this.setState({currentSubject: event.currentTarget.value})
    }

    onBodyChange(event) {
        const htmlData = event.currentTarget.innerHTML;
        this.setState({temporaryBody: htmlData})
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // tricky, don't update if there's a new temp body
        // this means we are actually still inside the editor.
        return nextState.temporaryBody === this.state.temporaryBody
    }

    postSave() {
        // TODO: sometimes not showing the new template in the drop down...
        this.getRenderedTemplate();
        this.getTemplates();
    }

    onSaveCallback(data) {
        this.setState({temporaryBody: '', templateEditMode: false},
            this.postSave)
    }

    onSave() {
        if (!this.state.temporaryBody) {
            this.setState({templateEditMode: false});
            return;
        }

        const body = this.state.temporaryBody;

        const data = {
            id: this.state.selected.id,
            name: this.state.selected.name,
            message_subject: this.state.currentSubject,
            message_body: body
        };
        this.props.authenticator.queuedPut(
            'message_templates',
            this.state.selected.id,
            data,
            this.onSaveCallback,
            () => {}
        );
    }


    onDeleteCallback(data) {
        this.setState({
            templates: [],
            selected: null,
            selectedIndex: -1,
            response: null,
            currentSubject: '',
            currentBody: '',
            currentTemplateBody: '',
            temporaryBody: '',
            templateEditMode: false,
            editable: false
        }, this.getTemplates);
    }

    onDelete() {
        this.props.authenticator.queuedDelete(
            'message_templates',
            this.state.selected.id,
            this.onDeleteCallback,
            () => {}
        );
    }

    onNewMessage() {
        this.getTemplates();
        this.setState({displayEditor: true})
    }

    onCancel() {
        this.setState({
            displayEditor: false,
            templates: [],
            selected: null,
            selectedIndex: -1,
            response: null,
            currentSubject: '',
            currentBody: '',
            currentTemplateBody: '',
            temporaryBody: '',
            templateEditMode: false,
            editable: false})
    }


    done(data) {
        this.onCancel();
        this.props.callback(data);
    }


    onTranscript(event) {
        const body = {
            contact: this.props.contact.id,
            subject: this.state.currentSubject,
            data: this.state.temporaryBody || this.state.currentBody
        };
        this.props.authenticator.queuedPost(
            'transcripts', body, this.done,
            () => {});
    }

    onEmail(event) {
        const body = {
            contact: this.props.contact.id,
            type: 18, // TODO: send-email-queue this could be a lookup at least
            subject: this.state.currentSubject,
            data: this.state.temporaryBody || this.state.currentBody
        };
        this.props.authenticator.queuedPost(
            'transcripts', body, this.done,
            () => {});
    }


    render() {

        if (!this.state.displayEditor) {
            return <button className={'skin-button'} onClick={this.onNewMessage} style={{marginTop: '2rem'}}>
                     New Message
                   </button>
        }

        const templates = this.state.templates || [];
        const options = templates.map((template, index) => ({value: index, label: template.name}));

        let body = this.state.templateEditMode ? this.state.currentTemplateBody : this.state.currentBody;
        body = this.state.temporaryBody || body;

        let templateSelectorValue;
        if (this.state.selectedIndex >= 0) {
            templateSelectorValue = {label: this.state.selected.name, value : this.state.selectedIndex}
        } else {
            if (this.state.selected) {

                templateSelectorValue = {label: this.state.selected.name, value : this.state.selected.name}
            } else {
                templateSelectorValue = null
            }
        }

        return (<>
            <Modal
                show={this.state.showHelp}
                size="lg"
                centered
                onHide={this.hideHelp}
                className={'skin-modal'}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Template Help</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>There are a few special fields available: {'{{my_name}}'}, {'{{contact_name}}'}, {'{{public_profile}}'}, {'{{rendered_on}}'}, and any related fields to: {'{{contact}}'}, {'{{user}}'} (e.g.. {'{{contact.given_name}}'}</p>
                    <p>We use Django templates, so all of the templating language is available to you: <a href={'https://docs.djangoproject.com/en/3.0/ref/templates/language/'}>Django Template Docs</a></p>

                </Modal.Body>
            </Modal>

            <div className={'skin-row'} style={{marginTop: '2rem'}}>

                    <div className={'skin-column'}>
                        <input placeholder={'Subject'}
                               value={this.state.currentSubject}
                               onChange={this.onSubjectChange}
                               width={'100%'} />
                    </div>
                    <div  className={'skin-column'} style={{textAlign: 'left', color: 'black', background: 'white'}}>
                        <Creatable options={options}
                                   onChange={this.onSelect}
                                   value={templateSelectorValue}
                                   placeholder={'Load or Create Template'}
                                   formatCreateLabel={(inputText) => `Create New Template "${inputText}"`}
                        />
                    </div>
            </div>

            <div style={{textAlign: 'left', display: 'flex', flexDirection: 'row'}}>
                <Editor value={body}
                        onBlur={this.onBodyChange}
                        readonly={false}
                />
            </div>

            {!this.state.templateEditMode ?
            <div className={'skin-row'}>
                <div className={'skin-column'}><button className={'skin-button'} onClick={this.onTranscript}>Save as Private Transcript</button></div>
                {this.state.editable && <div className={'skin-column'}><button className={'skin-button'} onClick={this.enableTemplateEditMode}>Edit Template</button></div>}
                <div className={'skin-column'}><button className={'skin-button'} onClick={this.onCancel}>Cancel</button></div>
                {this.state.currentSubject && <div className={'skin-column'}><button className={'skin-button'} onClick={this.onEmail}>Send as Email</button></div>}
            </div>
                :
            <div className={'skin-row'}>
                <div className={'skin-column'}><button className={'skin-button'} onClick={this.onSave}>Save</button></div>
                <div className={'skin-column'}><button className={'skin-button'} onClick={this.onDelete}>Delete</button></div>
                <div className={'skin-column'}><button className={'skin-button'} onClick={this.disableTemplateEditMode}>Cancel</button></div>
                <div className={'skin-column'}><button className={'skin-button'} onClick={this.showHelp}>Help</button></div>
            </div>
                }
        </>)
    }

}






export default MessageTemplates;
export {MessageTemplateEditor, UserMessageTemplates};
