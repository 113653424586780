import React, {Component} from "react";
import Spinner from "react-bootstrap/Spinner";
import {Redirect} from "react-router";
import "./advisors.css"
import {ScoreEntryRow} from "./ScoreBox";


export class SoonerOrLater extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            hide: false,
            viewSelected: null,
            contacts: [],
            contact: null,
            remaining: 0,
            nextClicked: false
        };
        this.clickCard = this.clickCard.bind(this);
        this.getContactsCallback = this.getContactsCallback.bind(this);
        this.getContacts = this.getContacts.bind(this);
        this.onClickSolButton = this.onClickSolButton.bind(this);
        this.onClickSolButtonCallback = this.onClickSolButtonCallback.bind(this);
    }

    componentDidMount() {
        this.getContacts();
    }


    clickCard(event) {
        this.setState({viewSelected: '/contacts/contact/' + this.state.contact.id.toString()})
    }


    getContactsCallback(responseData) {
        let data = responseData.data;
        let remaining = responseData.remaining;

        let currentContacts = [...data];
        const nextContact = currentContacts.pop();
        this.setState({
            contacts: currentContacts,
            contact: nextContact,
            loading: false,
            remaining: remaining
        })
    }

    getContacts(event) {
        this.setState({loading: true});
        this.props.authenticator.queuedGet('sooner_or_later',
            this.getContactsCallback, null);
    }

    changeItem(event) {

    }

    onClickSolButtonCallback(data) {
        let currentContacts = [...this.state.contacts];
        const nextContact = currentContacts.pop();
        if (nextContact) {
            this.setState({
                remaining: this.state.remaining - 1,
                contacts: currentContacts,
                contact: nextContact});
        } else {
            this.getContacts()
        }
    }

    onClickSolButton(event) {
        const status = event.currentTarget.dataset.status;
        this.setState({nextClicked: true}, () => this.setState({nextClicked: false}))
        this.props.authenticator.queuedPut(
            'sooner_or_later',
            this.state.contact.id,
            {
                id: this.state.contact.id,
                status: status
            }, this.onClickSolButtonCallback);
    }

    render() {
        if (this.state.hide || !this.props.authenticator) {
            return <></>
        }

        if (this.state.viewSelected) {
            return <Redirect to={this.state.viewSelected}/>
        }

        if (!!!this.state.contact) {
            return <></>
        }
        return (
            <div className={'skin-card'} style={{border: 0}}>
                <div className={'sol-card'}>
                    <img src={'sooner_or_later.png'}
                         width={'75%'}
                         alt={'sooner or later'}/>
                    {this.state.loading ? <>
                            <div style={{marginBottom: '12rem'}}/>
                            <Spinner animation="border"
                                     role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            <div style={{marginBottom: '11rem'}}/>
                        </> :
                    <>
                        <div>
                            <h3>{this.state.remaining}</h3>
                            {/*<button className={'sol-button thank-you'}*/}
                            {/*        data-status={27}*/}
                            {/*        onClick={this.onClickSolButton}>Now*/}
                            {/*</button>*/}
                        </div>
                        <div className={'sol-contact'}
                             onClick={this.clickCard}
                        >{this.state.contact.name}</div>
                        <button className={'sol-button sooner'}
                                data-status={34}
                                onClick={this.onClickSolButton}>Sooner
                        </button>
                        <button className={'sol-button later'}
                                data-status={11}
                                onClick={this.onClickSolButton}>Later
                        </button>
                        <div style={{marginBottom: '1rem'}}/>
                        <div className={'sol-sliders'}>
                            {/* This errored because a contact didn't have a score? */}
                            {!this.state.nextClicked && this.state.contact.scores_set[0].scoreentry_set.map((scoreEntry, index) => {
                                return (<div key={index}>
                                        <ScoreEntryRow
                                            score={this.state.contact.scores_set[0]}
                                            scoreEntry={scoreEntry}
                                            authenticator={this.props.authenticator}
                                            contact={this.state.contact}
                                            callBack={this.changeItem}
                                            key={index}
                                            editMode={false}
                                        />
                                        <div style={{
                                            marginBottom: '0.5rem',
                                            fontSize: '0.5rem'
                                        }}>{scoreEntry.item.name}</div>
                                    </div>
                                )
                            })}
                        </div>
                        <button className={'sol-button never'}
                                data-status={3}
                                onClick={this.onClickSolButton}>Never
                        </button>
                        <div style={{marginBottom: '1rem'}}/>
                    </>}
                </div>

            </div>
        )
    }
}
