import JoditEditor from "jodit-react";
import React, {Component} from 'react';


export default class Editor extends Component {
    render() {
        return <JoditEditor
            value={this.props.value}
            config={{
                readonly: false,
                askBeforePasteHTML: false,
                askBeforePasteFromWord: false,
                theme:'em'}}
            tabIndex={1}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}

        />
    }
}
