import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";
import {Link} from "react-router-dom";
import './Retail.css'
import RetailMenu from "./RetailMenu";

export default class RetailPricing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            annually: true,
            selectedTier: 'pro',
        };
        this.clickAnnual = this.clickAnnual.bind(this);
        this.clickTier = this.clickTier.bind(this);
    }

    clickAnnual(event) {
        this.setState({annually: !this.state.annually});
    }

    clickTier(eventKey) {
        this.setState({selectedTier: eventKey})
    }


    render() {
        if (!this.props.authenticator) {
            return <div>Missing Authenticator</div>
        }
        let pricingTableClassName = 'pricing-table ';
        if (this.state.annually) {
            pricingTableClassName += 'pricing-table-annually'
        } else {
            pricingTableClassName += 'pricing-table-monthly'
        }
        let basicTierSelected = 'basic-tier';
        if (this.state.selectedTier === 'basic') {
            basicTierSelected += ' tier-selected'
        }
        let proTierSelected = 'pro-tier';
        if (this.state.selectedTier === 'pro') {
            proTierSelected += ' tier-selected'
        }
        let coachTierSelected = 'coach-tier';
        if (this.state.selectedTier === 'coach') {
            coachTierSelected += ' tier-selected'
        }
        
        const basicCostAnnually = '5';
        const proCostAnnually = '20';
        const coachCostAnnually = '45';

        const basicCostAnnuallySum = 5 * 12;
        const proCostAnnuallySum = 20 * 12;
        const coachCostAnnuallySum = 45 * 12;

        const basicCostMonthly = '6';
        const proCostMonthly = '25';
        const coachCostMonthly = '55';

        return (<div style={{fontFamily: ['Dosis', 'serif']}}>
                <RetailMenu authenticator={this.props.authenticator}/>
                <Container>
                    <Row className={'d-flex align-items-center text-center'}>
                        <p style={{fontSize: '2rem', width: '100%'}}>
                            Experience Master the App<br/>subscriptions are Ad
                            free
                            and<br/>made to last.</p>
                    </Row>
                    <Row className={'d-flex align-items-center text-center'}>
                        <div style={{width: '100%'}}>
                            <Link to="/register">
                                <Button size={'lg'}>Get Started</Button>
                            </Link>
                        </div>
                    </Row>

                    <hr />

                    <Table bordered className={pricingTableClassName}>
                        <thead className={'pricing-table-head'}>
                        <tr className={'desktop-head'}>
                            <th className={'th-switch-cell'}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-1"
                                    label={this.state.annually ? "Annually (20% discount)" : "Monthly"}
                                    onClick={this.clickAnnual}
                                    value={this.state.annually}
                                />
                                <div style={{fontSize: 'small'}}>Click to switch to {this.state.annually ? "Monthly" : "Annually"}</div>
                            </th>
                            <th className={'tier-basic-head'}>
                                <div className={'tier-basic-price'}>
                                    <div className={'tier-price-title'}>
                                        Basic
                                    </div>
                                    <div className={'tier-price-annually'}>
                                        ${basicCostAnnuallySum} (${basicCostAnnually} / mo)
                                    </div>
                                    <div className={'tier-price-monthly'}>
                                        ${basicCostMonthly} / mo
                                    </div>
                                </div>
                            </th>
                            <th className={'tier-pro-head'}>
                                <div className={'tier-pro-price'}>
                                    <div className={'tier-price-title'}>
                                        Pro
                                    </div>
                                    <div className={'tier-price-annually'}>
                                        ${proCostAnnuallySum} (${proCostAnnually} / mo)
                                    </div>
                                    <div className={'tier-price-monthly'}>
                                        ${proCostMonthly} / mo
                                    </div>
                                </div>
                            </th>
                            <th className={'tier-three-head'}>
                                <div className={'tier-price'}>
                                    <div className={'tier-price-title'}>
                                        Corporate
                                    </div>
                                    <div className={'tier-price-annually'}>
                                        ${coachCostAnnuallySum} (${coachCostAnnually} / mo)
                                    </div>
                                    <div className={'tier-price-monthly'}>
                                        ${coachCostMonthly} / mo
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr className={'mobile-head'}>
                            <th className={'th-switch-cell'}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-2"
                                    label={this.state.annually ? "Annually" : "Monthly"}
                                    onClick={this.clickAnnual}
                                    value={this.state.annually}
                                />

                            </th>
                        </tr>
                        <tr className={'mobile-head'}>
                            <th className={'tier-one-head'} colSpan={4}>
                                <Tabs fill defaultActiveKey="pro" id="tabset-1" onSelect={this.clickTier}>
                                    <Tab eventKey="basic" title="Basic">
                                        <div className={'tier-one-tab'}>
                                            <div className={'tier-one-price'}>
                                                <div className={'tier-price-title'}>
                                                    Basic
                                                </div>
                                                <div className={'tier-price-annually'}>
                                                    ${basicCostAnnuallySum} (${basicCostAnnually} / mo)
                                                </div>
                                                <div className={'tier-price-monthly'}>
                                                    ${basicCostMonthly}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="pro" title="Pro">
                                        <div className={'tier-pro-tab'}>
                                            <div className={'tier-pro-price'}>
                                                <div className={'tier-price-title'}>
                                                    Pro
                                                </div>
                                                <div className={'tier-price-annually'}>
                                                    ${proCostAnnuallySum} (${proCostAnnually} / mo)
                                                </div>
                                                <div className={'tier-price-monthly'}>
                                                    ${proCostMonthly}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="corporate" title="Corporate">
                                        <div className={'tier-coach-tab'}>
                                            <div className={'tier-coach-price'}>
                                                <div className={'tier-price-title'}>
                                                    Corporate
                                                </div>
                                                <div className={'tier-price-annually'}>
                                                    ${coachCostAnnuallySum} (${coachCostAnnually} / mo)
                                                </div>
                                                <div className={'tier-price-monthly'}>
                                                    ${coachCostMonthly}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan={4}><h2>Features</h2></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Unlimited Contact Storage</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Contact Scoring with Key Psychological Metrics</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Unlimited Event Scheduling</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Zero Advertisements</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Powerful AI Advisors</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Instantly share contacts with others</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Activity Tracking</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>VR Analytics of Relationships</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Professional Human Coaching Interface</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Application Skins</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td colSpan={4}><h2>Messaging</h2></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Send Emails directly from the contact screen</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Track eMail Reads</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Personalizable Messaging Templates</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Send from your own email address</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Removable EM Branding</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td className={'feature-item-name'}>SMS Messaging</td>*/}
                        {/*    <td className={basicTierSelected}><div className={'item-included-text'}/>Coming Soon</td>*/}
                        {/*    <td className={proTierSelected}><div className={'item-included-text'}/>Coming Soon</td>*/}
                        {/*    <td className={coachTierSelected}><div className={'item-included-text'}/>Coming Soon</td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td colSpan={4}><h2>Scheduling</h2></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Scheduled Meeting Types</td>
                            <td className={basicTierSelected}><div className={'item-included-text'}>1</div></td>
                            <td className={proTierSelected}><div className={'item-included-text'}>3</div></td>
                            <td className={coachTierSelected}><div className={'item-included-text'}>Unlimited</div></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Personalizable URL</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Calendar Picture</td>
                            <td className={basicTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                            <td className={proTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                            <td className={coachTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                        </tr>
                        <tr>
                            <td colSpan={4}><h2>Coaching</h2></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Learner Features</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Coach Features</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-not-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Daily Message Card on Index of Learner</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Coach Shared Contact Status Watch</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Coach Shared Contact Discussions</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Learner Quest Manager</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-not-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Learner XP Tracking</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-not-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Multi-Coach Views</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Multi-Learner Views</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-not-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>

                        <tr>
                            <td colSpan={4}><h2>API Integrations</h2></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Google Contacts & Calendars</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Apple iCloud Contacts</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Zoho CRM</td>
                            <td className={basicTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                            <td className={proTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                            <td className={coachTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td className={'feature-item-name'}>Linked-In</td>*/}
                        {/*    <td className={basicTierSelected}><div className={'item-included-text'}/>Coming Soon</td>*/}
                        {/*    <td className={proTierSelected}><div className={'item-included-text'}/>Coming Soon</td>*/}
                        {/*    <td className={coachTierSelected}><div className={'item-included-text'}/>Coming Soon</td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td className={'feature-item-name'}>Office 365</td>
                            <td className={basicTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                            <td className={proTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                            <td className={coachTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                        </tr>
                        {/*<tr>*/}
                        {/*    <td className={'feature-item-name'}>SalesForce</td>*/}
                        {/*    <td className={basicTierSelected}><div className={'item-included-text'}/>Coming Soon</td>*/}
                        {/*    <td className={proTierSelected}><div className={'item-included-text'}/>Coming Soon</td>*/}
                        {/*    <td className={coachTierSelected}><div className={'item-included-text'}/>Coming Soon</td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td className={'feature-item-name'}>Direct Access to EM REST API to make your own app.</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>

                        <tr>
                            <td colSpan={4}><h2>Security</h2></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Export and Delete your account at any time</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Rest assured, your data and analytics will never EVER be sold or exported in any way.</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Keep your data off of your device</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>DFA</td>
                            <td className={basicTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                            <td className={proTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                            <td className={coachTierSelected}><div className={'item-included-text'}/>Coming Soon</td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Single Instance Isolated Install</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-not-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included-text'}/>Inquire</td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>All code is made in, and will remain in the United States of America</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Privacy Mode</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td colSpan={4}><h2>Support</h2></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Email</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Online Community</td>
                            <td className={basicTierSelected}><div className={'item-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Live Helpdesk</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included'}/></td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        <tr>
                            <td className={'feature-item-name'}>Concierge Onboarding (Live help getting started)</td>
                            <td className={basicTierSelected}><div className={'item-not-included'}/></td>
                            <td className={proTierSelected}><div className={'item-included-text'}/>$100</td>
                            <td className={coachTierSelected}><div className={'item-included'}/></td>
                        </tr>
                        </tbody>
                    </Table>


                    <hr/>
                    <Row>
                        <p>
                            &copy;2020 Fossa Tracks, Inc. <a
                            href={'mailto://michael@experiencemaster.com'}>Email</a>
                        </p>
                        <p>Privacy & Terms</p>
                    </Row>
                </Container>
            </div>
        )
    }
}
