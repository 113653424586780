import {Fireworks} from "fireworks/lib/react";
import moment from "moment-timezone";
import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "./advisors.css"
import {AvailableQuests} from "./AvailableQuests";


export class AssignedQuest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            hide: false,
            questAssignment: null,
            quest: null,


            brandValue: null,
            personalValue: null,
            inclusion: null,
            affinity: null,

        };
        this.getQuestCallback = this.getQuestCallback.bind(this);
        this.getQuest = this.getQuest.bind(this);
        this.showModal = this.showModal.bind(this);
        this.modalCallback = this.modalCallback.bind(this);
    }

    componentDidMount() {
        this.getQuest();
    }

    getQuestCallback(responseData) {
        const questAssignment = responseData;
        const quest = questAssignment.quest;
        this.setState({
            questAssignment: questAssignment,
            quest: quest,
            loading: false,
            showModal: false
        })
    }

    getQuest() {
        this.setState({loading: true});
        if (this.props.learner) {
            this.props.authenticator.queuedGet('quest',
                this.getQuestCallback, null, null, {learner:this.props.learner.id});
        } else {
            this.props.authenticator.queuedGet('quest',
                this.getQuestCallback, null);
        }
    }

    showModal() {
        this.setState({showModal: true})
    }

    modalCallback(responseData) {
        this.setState({showModal: false});
        if (!!responseData) {
            this.getQuestCallback(responseData)
        }
    }


    getStyles = (ref) => {
        if (!ref) {
            return;
        }
        const rgbToHex = (r, g, b) => '#' + [r, g, b]
            .map(x => x.toString(16).padStart(2, '0')).join('');

        const computed = window.getComputedStyle(ref);
        const brandValue = rgbToHex(...computed.getPropertyValue("--brand-value-color").split(',').map(v => parseInt(v, 10)));
        const personalValue = rgbToHex(...computed.getPropertyValue("--personal-value-color").split(',').map(v => parseInt(v, 10)));
        const inclusion = rgbToHex(...computed.getPropertyValue("--inclusion-color").split(',').map(v => parseInt(v, 10)));
        const affinity = rgbToHex(...computed.getPropertyValue("--affinity-color").split(',').map(v => parseInt(v, 10)));

        this.setState({
            brandvalue: brandValue,
            personalValue: personalValue,
            inclusion: inclusion,
            affinity: affinity
        })
    };

    render() {
        if (!this.props.authenticator) {
            return <></>
        }


        if (!this.state.inclusion) {
            return <div className={'inclusion'}
                        ref={ref => this.getStyles(ref)}/>
        }

        const fxProps = {
            x: 100,
            y: 100,
            count: 1,
            interval: this.state.interval,
            colors: [this.state.brandvalue, this.state.personalvalue, this.state.inclusion, this.state.affinity],
        };
        return (
                <>
                    {this.state.showModal && <AvailableQuests authenticator={this.props.authenticator} callback={this.modalCallback}/>}

                    {this.state.loading ? <>
                            <div style={{marginBottom: '12rem'}}/>
                            <Spinner animation="border"
                                     role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            <div style={{marginBottom: '11rem'}}/>
                        </> :
                        this.state.quest ? <>
                            <div className={'skin-card quest-card-1'}>
                                    <div className={'skin-card-header'}>Current Adventure</div>
                                    <div className={'skin-card-title'}>{this.state.quest.name}</div>
                                        <div>{this.state.quest.description}</div>
                                        <div style={{marginBottom: '1rem'}}/>
                                        {this.state.questAssignment.completed ? <>
                                            <h3>Complete!</h3>
                                            <Fireworks {...fxProps} />
                                        </> : <>
                                            {!this.state.questAssignment.expired && !!this.state.questAssignment.expires_on && <div>Expires in {moment(this.state.questAssignment.expires_on).fromNow()}.</div>}
                                            {!this.state.questAssignment.expired && !!this.state.questAssignment.bonus_by && <div>Double XP Bonus! Expires {moment(this.state.questAssignment.bonus_by).fromNow()}.</div>}
                                            {this.state.questAssignment.expired && <div>*Expired*</div>}
                                        </>}
                                    <div>
                                        {(!this.props.learner && (this.state.questAssignment.expired || this.state.questAssignment.completed)) && <Button onClick={this.showModal}>New Adventure</Button>}
                                    </div>
                            </div>
                        </> :
                            <>
                                <div className={'skin-card quest-card-1'}>
                                        <div className={'skin-card-header'}>Choose your Adventure</div>

                                            <button className={'skin-button'} onClick={this.showModal}>New Adventure</button>

                                </div>
                            </>
                    }
                </>

        )
    }
}
