import React, {Component} from "react";
import Form from "react-bootstrap/Form";

export default class SkinSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            skins: [],
            saved: false,
            errors: {},
            skinId: null
        };
        this.apiCallback = this.apiCallback.bind(this);
        this.errorCallback = this.errorCallback.bind(this);
        this.saveChoice = this.saveChoice.bind(this);
        this.getSkins = this.getSkins.bind(this);
        this.getSkinsCallback = this.getSkinsCallback.bind(this);
    }

    componentDidMount() {
        this.getSkins();
    }

    getSkinsCallback(data) {
        this.setState({skins: data})
    }

    getSkins() {
        this.props.authenticator.queuedGet('skins', this.getSkinsCallback)
    }


    errorCallback(data) {
        this.setState({saved: false, errors: data})
    }

    apiCallback(data) {
        window.location.reload();
        // this.setState({saved: true});
        // console.log('apiCallback', this.state.skinId)
        // if (!!!this.state.skinId) {
        //     this.setState({skinId: data.skin});
        //     this.props.authenticator.getAccount();
        // } else {
        //     // Cannot unload a CSS after it's been loaded.
        //     window.location.reload();
        // }
    }

    saveChoice(event) {
        let data;
        let selectedSkin;
        const skin = event.target.value;
        selectedSkin = this.props.authenticator.account.selected_skin;
        const accessPoint = 'selected_skin';
        if (selectedSkin) {
            data = {
                id: selectedSkin.id,
                skin: skin
            };
            this.props.authenticator.queuedPut(
                accessPoint, selectedSkin.id,
                data, this.apiCallback, this.errorCallback)
        } else {
            data = {
                skin: skin,
                user: this.props.authenticator.account.user.id
            };
            this.props.authenticator.queuedPost(
                accessPoint, data,
                this.apiCallback, this.errorCallback)
        }

    }

    render() {
        if (!!!this.props.authenticator.account || !!!this.props.authenticator.account.id) {
            return <div>No AUTH</div>
        }
        if (!this.state.skins) {
            return <div id={'no-skins'}/>
        }

        return <Form className="text-left">
            <Form.Control as="select"
                          id={'skin'}
                          name={'skin'}
                          onChange={this.saveChoice}
                          required
            >
                <option>Select a new Skin</option>
                {this.state.skins.map((skin, index) => {
                    return <option key={index}
                                   value={skin.id}
                    >{skin.name}</option>
                })}
            </Form.Control>
        </Form>
    }

}
