import React, {Component} from 'react';
import Spinner from "react-bootstrap/Spinner";
import {Redirect} from "react-router";


class ContactSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            contacts: [],
            searchBox: null,
            singleResult: null,
            dirty: false
        };

        this.getSuggestionsCallback = this.getSuggestionsCallback.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);

    }

    getSuggestionsCallback(data) {
        const searchString = data.search_string;
        if (searchString !== this.state.searchValue) {
            return;
        }

        const results = data.results;
        
        if (results.length === 1  && this.state.searchValue === results[0].name) {
            let contact = results[0];
            this.setState({singleResult: contact, searchValue: '',
            contacts: [], dirty: false});
            // this.props.searchCallback()
        } else {
            let contacts = [];
            let contactIdList = [];
            results.forEach((item, index) => {
                let contactId = item.contact.id;
                let doesNotExist = contactIdList.indexOf(contactId) < 0;
                if (doesNotExist) {
                    contactIdList.push(item.contact.id);
                    contacts.push(item.contact)
                }
            });
            this.setState({contacts: contacts, dirty: false});
            this.props.searchCallback(results);
        }
    };

    getSuggestions(searchValue) {
        this.props.authenticator.queuedGet(
            'search',
            this.getSuggestionsCallback,
            null,
            null,
            {search: searchValue}
            )
    };

    onChange(event) {
        const searchValue = event.target.value;
        this.setState({
            searchBox: event.target,
            searchValue: searchValue,
            dirty: true
        });
        if (searchValue.length > 1) {
            this.getSuggestions(searchValue);
        }
    };

    onBlur(element) {
        element.currentTarget.value=''
        this.setState({searchValue: '', dirty: false})
        // this.props.searchCallback()
    }

    render() {
        if (!!!this.props.authenticator) {
            return <div>No Auth</div>
        }

        let searchPathRedirect = false;
        if (this.state.searchValue.length > 0 &&
            window.location.pathname !== '/search') {
            searchPathRedirect = true;
        }

        return (
            <div>
                <input list="search-list"
                       autoComplete="false"
                       onChange={this.onChange}
                       placeholder={'Search'}
                       value={this.state.searchValue}
                       onBlur={this.onBlur}
                />
                <datalist id="search-list">
                    {this.state.contacts && this.state.contacts.map(
                        (contact, index) =>
                            (<option key={index}
                                     data-contact-id={contact.id}
                             >
                                {contact.name}
                            </option>)
                    )}
                </datalist>
                {this.state.dirty && <span>
                    <Spinner animation="border"
                            role="status">
                        <span className="sr-only">Searching...</span>
                    </Spinner>
                </span>}
                {/*{!this.state.singleResult && this.state.contacts.length > 0 && <Redirect to={'/'} />}*/}
                {this.state.singleResult  && <Redirect to={'/contacts/contact/' + this.state.singleResult.id + '/'} />}
                {searchPathRedirect && <Redirect to={'/search'}/>}
            </div>
        );
    }
}


export default ContactSearch;
