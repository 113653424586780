import React, {Component} from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
    VictoryContainer,
    VictoryGroup,
    VictoryLabel,
    VictoryPie
} from 'victory';
import HelpSymbol from "./HelpSymbol";
import ScoreSlider from "./ScoreSlider";


export class ScoreEntryRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        };
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    onMouseEnter() {
        this.setState({
            isHovered: true
        });
    }

    onMouseLeave() {
        this.setState({
            isHovered: false
        });
    }

    render() {

        return (
            <Row onMouseEnter={this.onMouseEnter}
                 onMouseLeave={this.onMouseLeave}
                 onMouseUp={this.onMouseLeave}
            >

                {this.props.editMode && <Col className='text-left'>
                    <HelpSymbol
                        title={this.props.scoreEntry.item.name}
                        content={this.props.scoreEntry.item.description}/>
                    {this.props.scoreEntry.item.name}</Col>}
                <Col>
                    <ScoreSlider scoreEntry={this.props.scoreEntry}
                                 score={this.props.score}
                                 contact={this.props.contact}
                                 callBack={this.props.callBack}
                                 authenticator={this.props.authenticator}
                    />
                </Col>
            </Row>
        )
    }
}


class ScorePie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brandValue: null,
            personalValue: null,
            inclusion: null,
            affinity: null,
        };
        this.pieItem = this.pieItem.bind(this)
    }

    pieItem(scoreEntry, index, originalArray) {
        let color = null;
        switch (index) {
            case 0:
                color = this.state.brandValue || '#525174';
                break;
            case 1:
                color = this.state.personalValue || '#348AA7';
                break;
            case 2:
                color = this.state.inclusion || '#5DD39E';
                break;
            case 3:
                color = this.state.affinity || '#BCE784';
                break;
            default:
                color = 'red'
        }

        const ringWidth = Math.round(100 / (originalArray.length + 1));
        const centerAreaRadius = ringWidth;
        const innerRadius = centerAreaRadius + ringWidth * index;
        const radius = innerRadius + ringWidth;

        const value = scoreEntry.value;

        const data = [
            {
                x: 1,
                y: parseInt(value)
            },
            {
                x: 2,
                y: 100 - parseInt(value)
            }
        ];
        return (
            <VictoryPie
                key={'pieItem-' + index}
                standalone={false}
                width={100} height={100}
                data={data}
                innerRadius={innerRadius}
                radius={radius}
                labelRadius={100}
                labels={() => null}
                style={{
                    data: {
                        fill: ({datum}) => {
                            return datum.x === 1 ? color : "transparent"
                        }
                    }
                }}
            />
        )
    }

    getStyles = (ref) => {
        if (!ref) {
            return;
        }
        const computed = window.getComputedStyle(ref);
        const brandValue = computed.getPropertyValue("--brand-value");
        const personalValue = computed.getPropertyValue("--personal-value");
        const inclusion = computed.getPropertyValue("--inclusion");
        const affinity = computed.getPropertyValue("--affinity");
        this.setState({
            brandValue: brandValue,
            personalValue: personalValue,
            inclusion: inclusion,
            affinity: affinity
        })
    };

    render() {
        if (!this.state.inclusion) {
            return <div className={'inclusion'}
                        ref={ref => this.getStyles(ref)}/>
        }
        return (<>
                <VictoryGroup
                    containerComponent={<VictoryContainer
                        style={{touchAction: 'auto', pointerEvents: 'auto'}}/>}
                >
                    {this.props.scoreEntries.map(this.pieItem)}
                    <VictoryLabel
                        textAnchor="middle"
                        verticalAnchor="middle"
                        style={{fontSize: 30}}
                        y={150}
                        x={225}
                        text={Math.round(this.props.score.value)}
                    />
                </VictoryGroup>
            </>
        )
    }
}


class ScoreValue extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (!this.props.score) {
            return <div>No ScoreValue?</div>
        }
        return (
            <Row>
                <Col>
                    <Row>
                        <ScorePie
                            score={this.props.score}
                            scoreEntries={this.props.score.scoreentry_set}
                        />
                    </Row>
                    <Row
                        className="justify-content-md-center">{this.props.score.name}</Row>


                </Col>
            </Row>
        )
    }
}


class ScoreBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            score: null,
        };
        this.changeItem = this.changeItem.bind(this)
        this.getScore = this.getScore.bind(this)
        this.getScoreCallback = this.getScoreCallback.bind(this)
    }

    componentDidMount() {
        const score = this.props.contact.scores_set[0];
        this.setState({
            score: score
        })
    }

    getScoreCallback(data) {
        this.setState({score: data})
    }

    getScore() {
        this.props.authenticator.queuedGet(
            'scores',
            this.getScoreCallback,
            null,
            this.state.score.id)
    }

    changeItem(score, scoreEntry) {
        this.getScore();
    }

    render() {
        if (!this.state.score) {
            return <></>
        }
        return (
            <div className={'score-box'}>
                <ScoreValue
                    score={this.state.score}
                />
                {this.state.score.scoreentry_set.map((scoreEntry, index) => {
                    return (<div key={index}>
                        <ScoreEntryRow
                            score={this.state.score}
                            scoreEntry={scoreEntry}
                            authenticator={this.props.authenticator}
                            contact={this.props.contact}
                            callBack={this.changeItem}
                            editMode={this.props.editMode}
                        />
                        <div style={{marginBottom: '1rem'}}/>
                        </div>
                    )
                })}
                <div>Rank: {this.props.contact.rank}/{this.props.contact.total_scored_contact_count}</div>
            </div>
        )
    }
}

export default ScoreBox;
