import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AddressForm from "./AddressForm";
import moment from "moment-timezone";


export default class Locations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            requestedItem: null,
            showModal: false,
            selectedRecord: null
        };
        this.itemCallback = this.itemCallback.bind(this);
        this.getItems = this.getItems.bind(this);
        this.hideCallback = this.hideCallback.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.getItemsCallback = this.getItemsCallback.bind(this);
        this.deleteButton = this.deleteButton.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
        this.editButton = this.editButton.bind(this);
    }

    componentDidMount() {
        this.setState({
            items: this.props.contact.contactlocation_set
        });
    }

    getItemsCallback(data) {
        this.setState({
            items: data
        });
    }

    getItems() {
        this.props.authenticator.queuedGet(
            'locations',
            this.getItemsCallback,
            null,
            null,
            {contact: this.props.contact.id})
    }


    itemCallback(modifiedItem) {

        let newItems = [...this.state.items];
        let preppedItems = newItems.filter(
            item => (item.id !== modifiedItem.id));
        preppedItems.push(modifiedItem);
        this.setState({items: preppedItems}, this.hideModal);

    }

    hideCallback() {
        this.hideModal()
    }

    hideModal() {
        this.setState({showModal: false, selectedRecord: null})
    }

    showModal(event) {
        event.preventDefault();
        this.setState({showModal: true})
    }

    deleteCallback() {
        let newItems = [...this.state.items];
        let preppedItems = newItems.filter(
            item => (item.id !== this.state.requestedItem));
        this.setState({items: preppedItems});
    }

    deleteButton(event) {
        const recordId = parseInt(event.currentTarget.dataset.itemId);
        this.setState({requestedItem: recordId});
        this.props.authenticator.queuedDelete(
            'locations', recordId, this.deleteCallback, () => {});
    }

    editButton(event) {
        const recordId = parseInt(event.currentTarget.dataset.itemId);
        const record = this.state.items.filter(
            (item) => (item.id === recordId))[0];
        this.setState({selectedRecord: record, showModal: true})
    }

    itemTooltip(item) {
        return <Tooltip className="text-left"
                        style={{textAlign: 'left !important'}}>
            <div>{item.street}</div>
            <div>{item.extended_address}</div>
            <div>{item.city} {item.region} {item.postal_code}</div>
            <div>{item.country} {item.timezone}</div>
        </Tooltip>
    }

    render() {
        return (
            <Col align={"left"}>
                {this.state.items.length > 0 && this.state.items.map((item, index) => {
                    let locationString = item.street + ',' + item.city + ',' + item.region + ',' + item.postal_code + ',' + item.country;
                    let addressURL = encodeURI(locationString);
                    let gBase = 'https://www.google.com/maps/place/';
                    let gLink = gBase + addressURL;
                    return <div key={index}>

                        {item.address_type && <div>{item.address_type}</div>}
                        <OverlayTrigger
                                placement="auto"
                                delay={{show: 750, hide: 3000}}
                                overlay={this.itemTooltip(item)}
                                target={item}
                            >
                                <div>{item.street}, {item.city} {item.region} <a
                                    href={gLink}>gmap</a></div>
                            </OverlayTrigger>
                            {item.timezone &&<div>{moment().tz(item.timezone).format('dddd LL LT')}</div>}
                            {this.props.editMode &&
                            <>
                                <Button variant={'success'}
                                        onClick={this.editButton}
                                        data-item-id={item.id}>E
                                </Button>
                                < Button variant={'danger'}
                                         onClick={this.deleteButton}
                                         data-item-id={item.id}>X
                                </Button>
                            </>
                            }
                            <div className="border-top my-2" />
                        </div>

                })}

                {this.props.editMode &&
                <>
                    <Button size="sm" block onClick={this.showModal}>
                        Add Location
                    </Button>
                    {this.state.showModal &&
                    <Modal
                        show={this.state.showModal}
                        size="lg"
                        aria-labelledby="location-modal-title-vcenter"
                        centered
                        onHide={this.hideModal}
                        className={'skin-modal'}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="location-modal-title-vcenter">
                                Contact Location
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddressForm
                                address={this.state.selectedRecord}
                                authenticator={this.props.authenticator}
                                contact={this.props.contact}
                                callback={this.itemCallback}
                            />
                        </Modal.Body>
                    </Modal>}
                </>}
            </Col>
        )
    }
}

