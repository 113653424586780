import * as queryString from "query-string";
import React, {Component} from "react";
import moment from "moment-timezone";
import {Helmet} from "react-helmet";
import {Link, Redirect} from "react-router-dom";


// const basicCostAnnually = '5';
// const proCostAnnually = '20';
// const coachCostAnnually = '45';

const basicCostAnnuallySum = 5 * 12;
const proCostAnnuallySum = 20 * 12;
const coachCostAnnuallySum = 45 * 12;

const basicCostMonthly = '6';
const proCostMonthly = '25';
const coachCostMonthly = '55';

const packages = {
    'em-basic-1-yr': {
        cost: basicCostAnnuallySum,
        description: 'Experience Master Basic Annual Subscription',
        ap_signature: 'vTmTYmZVWw%2B8WBTjyWfY1JYDkMZbtFmW96f50JXx1vw%3D'
    },
    'em-pro-1-yr': {
        cost: proCostAnnuallySum,
        description: 'Experience Master Pro Annual Subscription',
        ap_signature: 'zaSA7j0ViWEMwYcGEYMST292ajM8SNy%2F9jscqncb6AI%3D'
    },
    'em-corp-1-yr': {
        cost: coachCostAnnuallySum,
        description: 'Experience Master Corporate Seat Annual Subscription',
        ap_signature: 'IcfOz7BCkNBHFtjQD3sq%2FSyYImV0iHVxhc19chOvGLI%3D'
    },
    'em-basic-1-mo': {
        cost: basicCostMonthly,
        description: 'Experience Master Basic Monthly Subscription',
        ap_signature: 'VGpTleYwa%2BcRS7ZfBDiGN3mTjs8k9G2gK8j7YgSnHNY%3D'
    },
    'em-pro-1-mo': {
        cost: proCostMonthly,
        description: 'Experience Master Pro Monthly Subscription',
        ap_signature: 'nnwIG%2FT5v3vwjBRAEA4RSI7SpaLsP1i8XhsrxrmgvIU%3D'
    },
    'em-corp-1-mo': {
        cost: coachCostMonthly,
        description: 'Experience Master Corporate Seat Monthly Subscription',
        ap_signature: 'D4DIeFkIoBRoYyiTdV0OM0XQ9huE73FvDwRb9jD8V2M%3D'
    }
};


class SubscriptionStep1 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packageId: null,
        };
        this.select = this.select.bind(this);
    }

    select(event) {
        event.preventDefault();
        const packageId = event.target.value;
        this.setState({packageId: packageId});
        this.props.stepCallback(packageId)
    }

    render() {
        const expiration = moment(this.props.authenticator.account.subscription.expiration);
        let currentType = '';
        let subType = '';

        const isTyped = (
            this.props.authenticator.account.subscription &&
            this.props.authenticator.account.subscription.type);
        if (isTyped) {
            subType = this.props.authenticator.account.subscription.type.name;
            if (subType.includes('basic')){
                currentType = 'basic'
            } else if (subType.includes('pro')){
                currentType = 'pro'
            } else if (subType.includes('corp')){
                currentType = 'corp'
            }
        }
        let status = '';
        if (this.props.authenticator.account.subscription && this.props.authenticator.account.subscription.status && this.props.authenticator.account.subscription.status.name) {
            status = this.props.authenticator.account.subscription.status.name;
        }

        const isValid = (status === 'VALID');

        return <>
            <h1>Choose your Experience Master Package</h1>
            <div><small><Link to={'/pricing'}>Learn more about
                packages.</Link></small></div>

            <h2>{status}</h2>

            <div>Your
                subscription {expiration > moment() ? 'expires' : 'expired'}&nbsp;
                {expiration.endOf('day').fromNow()}</div>


            <div className={'advisors-container'}>
                {(!isValid || !(currentType === 'pro' || currentType === 'corp')) &&
                <div className={'skin-card'}>
                    <div className={'skin-card-header'}
                         style={{
                             borderWidth: '1rem',
                             borderColor: 'var(--brand-value)',
                             backgroundColor: 'white',
                             display: 'flex',
                             flexDirection: 'row',
                             alignItems: 'center',
                             justifyContent: 'space-evenly'
                         }}>
                        <div style={{
                            borderRadius: '0',
                            height: '3rem',
                            width: '3rem',
                            backgroundColor: 'var(--brand-value)'
                        }}/>
                        <h2>Basic</h2>
                    </div>
                    <div className={'skin-card-title'}>Key Features at an
                        Affordable
                        Price
                    </div>
                    <div className={'skin-card-body'}>
                        <div>
                            <button
                                className={'skin-button'}
                                value={'em-basic-1-yr'}
                                onClick={this.select}
                            >
                                ${basicCostAnnuallySum} / 12 months
                            </button>
                        </div>
                        <div>-OR-</div>
                        <div>
                            <button
                                className={'skin-button'}
                                value={'em-basic-1-mo'}
                                onClick={this.select}
                            >
                                ${basicCostMonthly} / 1 month
                            </button>
                        </div>
                    </div>
                    <div className={'skin-card-footer'}>
                        {isValid && currentType === 'basic' && '**Current Subscription Type**'}
                        {!isValid && currentType === 'basic' && <div><small>Your Previous Subscription Type</small></div>}
                    </div>
                </div>
                }


                {(!isValid || !(currentType === 'corp')) &&
                <div className={'skin-card'}>
                    <div className={'skin-card-header'}
                         style={{
                             borderWidth: '1rem',
                             borderColor: 'var(--personal-value)',
                             backgroundColor: 'white',
                             display: 'flex',
                             flexDirection: 'row',
                             alignItems: 'center',
                             justifyContent: 'space-evenly'
                         }}>
                        <div style={{
                            borderRadius: '50%',
                            height: '3rem',
                            width: '3rem',
                            backgroundColor: 'var(--personal-value)'
                        }}/>
                        <h2>Professional</h2>
                    </div>
                    <div className={'skin-card-title'}>Advanced Features and
                        Learner
                        Functions
                    </div>
                    <div className={'skin-card-body'}>
                        <div>
                            <button
                                className={'skin-button'}
                                value={'em-pro-1-yr'}
                                onClick={this.select}
                            >
                                ${proCostAnnuallySum} / 12 months
                            </button>
                        </div>
                        <div>-OR-</div>
                        <div>
                            <button
                                className={'skin-button'}
                                value={'em-pro-1-mo'}
                                onClick={this.select}>
                                ${proCostMonthly} / 1 month
                            </button>
                        </div>
                    </div>
                    <div className={'skin-card-footer'}>
                        {isValid && currentType === 'pro' && '**Current Subscription Type**'}
                        {isValid && currentType === 'basic' && <div><small>Upgrade to Pro and get Bonus time!</small></div>}
                        {!isValid && currentType === 'pro' && <div><small>Your Previous Subscription Type</small></div>}
                    </div>
                </div>
                }
                <div className={'skin-card'}>
                    <div className={'skin-card-header'}
                         style={{
                             borderWidth: '1rem',
                             borderColor: 'var(--affinity)',
                             backgroundColor: 'white',
                             display: 'flex',
                             flexDirection: 'row',
                             alignItems: 'center',
                             justifyContent: 'space-evenly'
                         }}>
                        <div style={{
                            borderRadius: '0',
                            height: '0',
                            width: '0',
                            borderLeft: '15px solid transparent',
                            borderRight: '15px solid transparent',
                            borderBottom: '26px solid var(--affinity)',
                        }}/>
                        <h2>Corporate</h2>
                    </div>
                    <div className={'skin-card-title'}>All Features for
                        Businesses and
                        Coaches
                    </div>
                    <div className={'skin-card-body'}>
                        <div>
                            <button
                                className={'skin-button'}
                                value={'em-corp-1-yr'}
                                onClick={this.select}
                            >
                                ${coachCostAnnuallySum} / 12 months
                            </button>
                        </div>
                        <div>-OR-</div>
                        <div>
                            <button
                                className={'skin-button'}
                                value={'em-corp-1-mo'}
                                onClick={this.select}>
                                ${coachCostMonthly} / 1 month
                            </button>
                        </div>
                        <hr/>
                        <div>
                            <small>
                                <p>Note: Using an account as an employee of a company requires this type.</p>
                            </small>
                        </div>
                    </div>
                    <div className={'skin-card-footer'}>
                        {isValid && currentType === 'corp' && '**Current Subscription Type**'}
                        {isValid && (currentType === 'basic' || currentType === 'pro') && <div><small>Upgrade to Corporate and get Bonus time!</small></div>}
                        {!isValid && currentType === 'corp' && <div><small>Your Previous Subscription Type</small></div>}
                    </div>
                </div>
            </div>
        </>
    }
}


class SubscriptionStep2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
        };
    }

    pay(emPackage) {
        console.log(emPackage);
    }

    // paymentButton(emPackageId, emPackage) {
    //     return <div
    //         data-ap-widget-type="expressPaymentButton"
    //         data-ap-signature="G2D2F4NJHq6UbVdh1caYicbN9beoW%2BmOCAbcJ%2F87ZQc%3D"
    //         data-ap-seller-id="AV7P5LP286Y2M"
    //         data-ap-access-key="AKIAJ4PNSJ5L6MOWSHMA"
    //         data-ap-lwa-client-id="amzn1.application-oa2-client.2a7fc1c321e34edd89dfc4608d973c3b"
    //         data-ap-return-url="https://experiencemaster.com/payment_callback"
    //
    //         data-ap-cancel-return-url="https://experiencemaster.com/payment_canceled"
    //
    //         data-ap-currency-code="USD"
    //         data-ap-amount={emPackage.cost + '.00'}
    //         data-ap-note={emPackage.description}
    //         data-ap-shipping-address-required="false"
    //         data-ap-payment-action="AuthorizeAndCapture"
    //     />
    // }
    paymentButton(emPackageId, emPackage) {
        return <>

            <Helmet>
                <script async
                        src="https://static-na.payments-amazon.com/OffAmazonPayments/us/js/Widgets.js"/>
            </Helmet>
            <div
                data-ap-widget-type="expressPaymentButton"
                data-ap-signature={emPackage.ap_signature}
                data-ap-seller-id="AV7P5LP286Y2M"
                data-ap-access-key="AKIAJ4PNSJ5L6MOWSHMA"
                data-ap-lwa-client-id="amzn1.application-oa2-client.2a7fc1c321e34edd89dfc4608d973c3b"
                data-ap-return-url="https://experiencemaster.com/payment_callback"

                data-ap-cancel-return-url="https://experiencemaster.com/payment_canceled"

                data-ap-currency-code="USD"
                data-ap-amount={emPackage.cost}
                data-ap-note={emPackage.description}
                data-ap-shipping-address-required="false"
                data-ap-payment-action="AuthorizeAndCapture"
            >
            </div>



        </>

        // <div
        //     data-ap-widget-type="expressPaymentButton"
        //     data-ap-signature="QFeNpPUW3vT9k8X542vF1BVsv6u1clI0Z2gfiZsOqSA%3D"
        //     data-ap-seller-id="AV7P5LP286Y2M"
        //     data-ap-access-key="AKIAJ4PNSJ5L6MOWSHMA"
        //     data-ap-lwa-client-id="amzn1.application-oa2-client.2a7fc1c321e34edd89dfc4608d973c3b"
        //     data-ap-return-url="https://experiencemaster.com/payment_callback"
        //
        //     data-ap-cancel-return-url="https://experiencemaster.com/payment_canceled"
        //
        //     data-ap-currency-code="USD"
        //     data-ap-amount="25"
        //     data-ap-note=""
        //     data-ap-shipping-address-required="false"
        //     data-ap-payment-action="AuthorizeAndCapture"
        // >
        // </div>



        //
        //
        //
        // <div
        //     data-ap-widget-type="expressPaymentButton"
        //     data-ap-signature="opK1QFJBL4sg5zP6ljU7jlOYRdag0ADgFV6EveSmCSA%3D"
        //     data-ap-seller-id="AV7P5LP286Y2M"
        //     data-ap-access-key="AKIAJ4PNSJ5L6MOWSHMA"
        //     data-ap-lwa-client-id="amzn1.application-oa2-client.2a7fc1c321e34edd89dfc4608d973c3b"
        //     data-ap-return-url="https://experiencemaster.com/payment_callback"
        //
        //     data-ap-cancel-return-url="https://experiencemaster.com/payment_canceled"
        //
        //     data-ap-currency-code="USD"
        //     data-ap-amount={emPackage.cost + '.00'}
        //     data-ap-note={emPackage.description}
        //     data-ap-shipping-address-required="false"
        //     data-ap-payment-action="AuthorizeAndCapture"
        // />
    }

    render() {

        const emPackage = packages[this.props.packageId];

        return <>
            <h1>Verify</h1>
            <div className={'advisors-container'}
                 style={{flexDirection: "column", alignItems: 'center'}}>
                <div style={{display: 'flex'}}>
                    {this.paymentButton(this.props.packageId, emPackage)}
                </div>
                <hr/>
                <table style={{border: '1px solid black'}} width={'100%'}>
                    <thead>
                    <tr>
                        <th colSpan={2}>Invoice</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td align={'left'} style={{border: '1px solid black'}}>
                            <div>FOSSA TRACKS INC</div>
                            <div>1925 PALOMAR OAKS WAY SUITE 107</div>
                            <div>CARLSBAD, CA 92008</div>
                            <div>650-390-3024</div>
                        </td>
                        <td align={'right'} style={{border: '1px solid black'}}>
                            <div>Name: {this.props.authenticator.account.user.first_name}&nbsp;{this.props.authenticator.account.user.last_name}</div>
                            <div>User: {this.props.authenticator.account.user.username}</div>
                            <div>{moment().format('dddd LL LT')}</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <table width={'100%'}>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{this.props.packageId}</td>
                                    <td>{emPackage.description}</td>
                                    <td>1</td>
                                    <td>${emPackage.cost}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}/>
                                    <td>TOTAL:</td>
                                    <td>${emPackage.cost}</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    }
}

class SubscriptionStep4 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
        };
        this.getTransaction = this.getTransaction.bind(this);
        this.getTransactionCallback = this.getTransactionCallback.bind(this);
    }

    componentDidMount() {
        this.getTransaction()
    }

    getTransactionCallback(data) {
        this.setState({data: data})
    }

    getTransaction() {
        this.props.authenticator.queuedGet('account_payments',
            this.getTransactionCallback, null, this.props.transactionId)
    }

    render() {
        if (!this.state.data) {
            return <>Loading Invoice</>
        }

        const emPackage = packages[this.state.data.type.name];

        return <>

            <h1>Subscription Activated</h1>

            <div className={'advisors-container'}
                 style={{flexDirection: 'column', alignItems: 'center'}}>
                <table style={{border: '1px solid black'}} width={'100%'}>
                    <thead>
                    <tr>
                        <th colSpan={2}>Invoice</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td align={'left'} style={{border: '1px solid black'}}>
                            <div>FOSSA TRACKS INC</div>
                            <div>1925 PALOMAR OAKS WAY SUITE 107</div>
                            <div>CARLSBAD, CA 92008</div>
                            <div>650-390-3024</div>
                        </td>
                        <td align={'right'} style={{border: '1px solid black'}}>
                            <div>Name: {this.props.authenticator.account.user.first_name}&nbsp;{this.props.authenticator.account.user.last_name}</div>
                            <div>User: {this.props.authenticator.account.user.username}</div>
                            <div>{moment().format('dddd LL LT')}</div>
                            <div>INVOICE_ID: {this.props.transactionId}</div>
                            <div>PAID IN FULL</div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <table width={'100%'}>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Description</th>
                                    <th>Quantity</th>
                                    <th>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{this.state.data.type.name}</td>
                                    <td>{emPackage.description}</td>
                                    <td>1</td>
                                    <td>${emPackage.cost}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}/>
                                    <td>TOTAL:</td>
                                    <td>${emPackage.cost}</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    }
}


export default class Subscriptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stepNumber: 1,
            packageId: null,
            data: null
        };
        this.stepButton = this.stepButton.bind(this);
        this.stepOneCallback = this.stepOneCallback.bind(this);
    }

    componentDidMount() {
        const searchDict = this.props.location &&
            queryString.parse(this.props.location.search);
        if (searchDict.transactionId) {
            this.setState({
                    stepNumber: 4,
                    transactionId: searchDict.transactionId
                },
                this.getTransaction)
        }
    }

    stepButton(event) {
        const stepNumber = parseInt(event.target.value);
        this.setState({stepNumber: stepNumber})
    }

    stepOneCallback(packageId) {
        this.setState({packageId: packageId, stepNumber: 2})
    }

    render() {

        const stepNumber = this.state.stepNumber;
        console.log('Subscriptions.render', stepNumber, stepNumber === 1, typeof (stepNumber));

        return <>
            <div>
                <div className="timing-buttons">
                    <button value={1}
                            onClick={this.stepButton}
                            className={'timing-button ' + (stepNumber === 1 ? 'never' : 'never-outline')}>
                        Package
                    </button>
                    <button value={2}
                            className={'timing-button ' + (stepNumber === 2 ? 'first-contact' : 'first-contact-outline')}>
                        Verify
                    </button>
                    <button value={3}
                            className={'timing-button ' + (stepNumber === 3 ? 'thank-you' : 'thank-you-outline')}>
                        Pay
                    </button>
                    <button value={4}
                            className={'timing-button ' + (stepNumber === 4 ? 'sooner' : 'sooner-outline')}>
                        Begin
                    </button>
                    {/*<button value={11}*/}
                    {/*        onClick={this.stepButton}*/}
                    {/*        className={'timing-button ' + (stepNumber === 5 ? 'later' : 'later-outline')}>Later</button>*/}
                    {/*<button value={25}*/}
                    {/*        onClick={this.stepButton}*/}
                    {/*        className={'timing-button ' + (stepNumber === 6 ? 'sleep' : 'sleep-outline')}>Sleep</button>*/}
                </div>

            </div>
            {stepNumber === 1 &&
            <SubscriptionStep1 stepCallback={this.stepOneCallback}
                               authenticator={this.props.authenticator}/>}
            {stepNumber === 2 &&
            <SubscriptionStep2 packageId={this.state.packageId}
                               authenticator={this.props.authenticator}/>}
            {stepNumber === 4 &&
            <SubscriptionStep4 transactionId={this.state.transactionId}
                               authenticator={this.props.authenticator}/>}

        </>
    }
}

export class SubscriptionCheck extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: '',
            redirect: false
        };
        this.isValid = this.isValid.bind(this);
        this.validation = this.validation.bind(this);
        this.isMinimum = this.isMinimum.bind(this);
    }

    componentDidMount() {
        let status = '';
        if (this.props.authenticator.account.subscription && this.props.authenticator.account.subscription.status && this.props.authenticator.account.subscription.status.name) {
            status = this.props.authenticator.account.subscription.status.name;
        }
        this.setState({status: status}, this.validation);
    }

    validation(){
        const isValid = this.isValid();
        const isMinimum = this.isMinimum();
        const redirect = !(isValid && isMinimum);
        this.setState({redirect: redirect});
    }

    isValid() {
        return this.state.status === 'VALID';
    }

    isMinimum() {
        const minimum = this.props.minimum;
        if (!minimum) {
            return true;
        }
        const isTyped = (
            this.props.authenticator.account.subscription &&
            this.props.authenticator.account.subscription.type);
        let subType = '';
        if (isTyped) {
            subType = this.props.authenticator.account.subscription.type.name;
        }
        if (subType.includes(minimum)) {return true}
        return (minimum === 'pro' && subType.includes('corp'))
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/subscriptions'}/>
        }
        return <></>
    }
}