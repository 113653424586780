import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Modal from "react-bootstrap/Modal";
import DayPicker from "react-day-picker";
import './EventTypeAvailability.css'

class EventTypeAvailabilityInterval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            errors: {},
            validated: false,

            availability: null,
            eventType: null,
            interval: null,
            startTime: '',
            endTime: ''
        };
        this.onStartChange = this.onStartChange.bind(this);
        this.onEndChange = this.onEndChange.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.callback = this.callback.bind(this);
        this.sequentialCheck = this.sequentialCheck.bind(this);
    }

    componentDidMount() {
        this.setState({
            interval: this.props.interval,
            availability: this.props.availability,
            eventType: this.props.eventType,
            startTime: this.props.interval.start_time.split(':').slice(0, 2).join(':'),
            endTime: this.props.interval.end_time.split(':').slice(0, 2).join(':'),
        })

    }

    callback() {
        this.props.callback({
            id: this.state.interval.id,
            event_availability: this.props.availability ? this.props.availability.id : null,
            start_time: this.state.startTime,
            end_time: this.state.endTime
        })
    }

    sequentialCheck() {
        const isSequential = this.state.startTime < this.state.endTime;

        this.setState(
            {validated: isSequential}, () => {
                if (isSequential) {
                    this.setState({errors: {}});
                    this.callback();
                } else {
                    this.setState({errors: {detail: 'Times are not Sequential'}})
                }
            });

    }


    onStartChange(event) {
        this.setState({
            startTime: event.target.value
        }, this.sequentialCheck)
    }

    onEndChange(event) {
        this.setState({
            endTime: event.target.value
        }, this.sequentialCheck)
    }

    onDeleteClick(event) {
        this.props.onDelete(this.state.interval);
    }

    render() {
        return <Form validated={this.state.validated}>
            {this.state.errors && this.state.errors.detail &&
            <Alert
                variant={'danger'}>{this.state.errors.detail}</Alert>}
            <Form.Row>
                <InputGroup>
                    <Form.Group as={Col}>
                        <Form.Control type="time"
                                      id={'startTime'}
                                      name={'startTime'}
                                      placeholder="HH:MM am"
                                      value={this.state.startTime}
                                      onChange={this.onStartChange}
                                      style={{width: '7.5rem'}}
                                      required
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.start_time}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Control type="time"
                                      id={'endTime'}
                                      name={'endTime'}
                                      placeholder="HH:MM pm"
                                      value={this.state.endTime}
                                      onChange={this.onEndChange}
                                      style={{width: '7.5rem'}}
                                      required
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.end_time}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <InputGroup.Append>
                        <Button variant={'danger'} size={'sm'}
                                onClick={this.onDeleteClick}>X</Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form.Row>
        </Form>
    }
}


class EventTypeAvailability extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            errors: {},
            submitted: false,

            availability: null,
            eventType: null,
            unavailable: false,

            intervals: [],
            newIdPointer: 0,

            recurring: false,
            specific: false,

            exceptionDays: [],
            recurringDays: [],
            recurrenceSet: [],
            specificDateSet: []
        };
        this.clickCard = this.clickCard.bind(this);
        this.clickOpenEditModal = this.clickOpenEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);
        this.makeNewInterval = this.makeNewInterval.bind(this);
        this.deleteInterval = this.deleteInterval.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.createAvailabilityCallback = this.createAvailabilityCallback.bind(this);
        this.updateAvailabilityCallback = this.updateAvailabilityCallback.bind(this);
        this.createAvailability = this.createAvailability.bind(this);
        this.updateAvailability = this.updateAvailability.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.updateIntervalCallback = this.updateIntervalCallback.bind(this);
        this.cleanIntervalsForPost = this.cleanIntervalsForPost.bind(this);
        this.sequentialCheck = this.sequentialCheck.bind(this);
        this.selectExceptionDay = this.selectExceptionDay.bind(this);
        this.clickRecurring = this.clickRecurring.bind(this);
        this.clickSpecific = this.clickSpecific.bind(this);
        this.chooseRecurring = this.chooseRecurring.bind(this);
        this.recurringColors = this.recurringColors.bind(this);
        this.cleanSpecificDatesForPost = this.cleanSpecificDatesForPost.bind(this);
        this.toggleUnavailableButton = this.toggleUnavailableButton.bind(this);

    }

    componentDidMount() {
        if (this.props.availability) {
            const availability = this.props.availability;
            const unavailable = availability.unavailable;
            const intervals = availability.eventtypeavailabilityinterval_set ? availability.eventtypeavailabilityinterval_set : [];
            const recurrenceSet = availability.eventtypeavailabilityrecurringdayoftheweek_set ? availability.eventtypeavailabilityrecurringdayoftheweek_set : [];
            const specificDateSet = availability.eventtypeavailabilityspecificdate_set ? availability.eventtypeavailabilityspecificdate_set : [];

            this.setState({
                availability: availability,
                unavailable: unavailable,
                eventType: this.props.eventType,
                intervals: intervals,
                recurrenceSet: recurrenceSet,
                specificDateSet: specificDateSet
            }, this.sequentialCheck);
            let recurringDays = [];
            recurrenceSet.forEach((recurrence) => {
                recurringDays.push(recurrence.available_day)
            });
            recurringDays.sort();
            this.setState({recurringDays: recurringDays});
            if (recurringDays.length > 0) {
                this.setState({recurring: true})
            }

            let exceptionDays = [];
            specificDateSet.forEach((specificDate) => {
                exceptionDays.push(new Date(specificDate.available_date.replace('-', '/').replace('-', '/')))
            });
            this.setState({exceptionDays: exceptionDays});
            if (exceptionDays.length > 0) {
                this.setState({specific: true})
            }
        } else {
            // not really required
            this.setState({
                eventType: this.props.eventType,
                intervals: [],
                exceptionDays: [],
                recurringDays: [],
                recurrenceSet: [],
                specificDateSet: [],
                recurring: false,
                specific: false
            })
        }
    }

    clickCard(event) {
        this.clickOpenEditModal(event)
    }

    clickOpenEditModal(event) {
        this.setState({showModal: true})
    }

    closeEditModal(event) {
        this.setState({showModal: false})
    }

    makeNewInterval() {
        const newId = this.state.newIdPointer - 1;
        this.setState({newIdPointer: newId});
        const interval = {
            id: newId,
            start_time: '08:00',
            end_time: '17:00',
            event_availability: this.state.availability ? this.state.availability.id : null
        };
        let newIntervals = [...this.state.intervals]
        newIntervals.push(interval);
        this.setState({intervals: newIntervals}, this.sequentialCheck);

    }


    deleteInterval(interval) {
        const existingIntervals = [...this.state.intervals];
        let newIntervals = [];
        this.setState({intervals: newIntervals}, () => {
            let intervalId = interval.id;
            existingIntervals.forEach((existingInterval) => {
                let existingId = existingInterval.id;
                if (intervalId !== existingId) {
                    newIntervals.push(existingInterval)
                }
            });
            this.setState({intervals: newIntervals}, this.sequentialCheck)
        });

    }

    sequentialCheck() {
        let lastStart = null;
        let lastEnd = null;
        let isSequential = true;
        this.state.intervals.forEach((interval) => {
            var start = interval.start_time;
            var end = interval.end_time;
            if (!!!lastStart) {
                lastStart = start;
                lastEnd = end;
            } else {
                isSequential &= lastEnd <= start;
            }
        });
        if (isSequential) {
            this.setState({errors: {}})
        } else {
            this.setState({errors: {detail: 'Times overlap.'}})
        }

    }

    updateIntervalCallback(interval) {
        if (!!!interval) {
            return;
        }
        let newIntervals = [];
        for (let index in this.state.intervals) {
            let existingInterval = this.state.intervals[index];
            if (interval.id === existingInterval.id) {
                newIntervals.push(interval)
            } else {
                newIntervals.push(existingInterval)
            }
        }
        this.setState({intervals: newIntervals}, this.sequentialCheck)
    }

    cleanIntervalsForPost() {
        const intervals = [...this.state.intervals];
        const newIntervals = intervals.map((interval) => {
                if (interval.id < 0) {
                    interval.id = null
                }
                return interval;
            }
        );
        return newIntervals;
    }

    cleanRecurrencesForPost() {
        let recurringDays = [...this.state.recurringDays];
        let existingRecords = [];
        let newRecords = [];
        this.state.recurrenceSet.forEach((recurrence) => {
            let existingRecordIndex = recurringDays.indexOf(
                recurrence.available_day);
            if (existingRecordIndex > -1) {
                existingRecords.push(recurrence);
                recurringDays.splice(existingRecordIndex, 1);
            }
        });

        recurringDays.forEach((weekday) => {
            newRecords.push({
                event_availability: this.state.availability ? this.state.availability.id : null,
                available_day: weekday
            })
        });

        return [...existingRecords, ...newRecords];
    }

    formatDateForPython(datetime) {
        return new Date(datetime.toDateString()).toISOString().split('T')[0]
    }

    cleanSpecificDatesForPost() {
        let specificDays = [...this.state.exceptionDays];
        let specificTimes = specificDays.map((value, index, original) => (value.getTime()));
        let existingRecords = [];
        let newRecords = [];
        this.state.specificDateSet.forEach((specificDate) => {
            let recordTime = new Date(specificDate.available_date.replace('-', '/').replace('-', '/')).getTime();
            let existingRecordIndex = specificTimes.indexOf(recordTime);
            if (existingRecordIndex > -1) {
                existingRecords.push(specificDate);
                specificDays.splice(existingRecordIndex, 1);
                specificTimes.splice(existingRecordIndex, 1);
            }
        });

        specificDays.forEach((specificDay) => {
            newRecords.push({
                event_availability: this.state.availability ? this.state.availability.id : null,
                available_date: this.formatDateForPython(specificDay)
            })
        });
        return [...existingRecords, ...newRecords];
    }

    createAvailabilityCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState(
            {
                availability: data,
                intervals: data.eventtypeavailabilityinterval_set ?
                    data.eventtypeavailabilityinterval_set : []
            }
        );
        if (this.props.addNewCallback) {
            this.props.addNewCallback(data)
        }
    }

    updateAvailabilityCallback(data) {
        if (!!!data) {
            return;
        }
        this.setState(
            {
                availability: data,
                intervals: data.eventtypeavailabilityinterval_set ?
                    data.eventtypeavailabilityinterval_set : []
            }
        );
    }

    prepareData() {
        const data = {
            id: this.state.availability ? this.state.availability.id : null,
            event_type: this.state.eventType.id,
            unavailable: this.state.unavailable
        };
        data.eventtypeavailabilityinterval_set = this.cleanIntervalsForPost();
        data.eventtypeavailabilityrecurringdayoftheweek_set = this.cleanRecurrencesForPost();
        data.eventtypeavailabilityspecificdate_set = this.cleanSpecificDatesForPost();
        return data
    }


    createAvailability() {
        const data = this.prepareData();
        this.props.authenticator.queuedPost(
            'event_type_availability',
            data,
            this.createAvailabilityCallback
        )
    }

    updateAvailability() {
        const data = this.prepareData();
        this.props.authenticator.queuedPut(
            'event_type_availability',
            this.state.availability.id,
            data,
            this.updateAvailabilityCallback
        )
    }

    onSubmit() {
        if (!this.state.availability) {
            this.createAvailability()
        } else {
            this.updateAvailability()
        }
        this.setState({showModal: false})

    }

    onCancel() {
        this.setState({
            showModal: false,
            intervals: (this.state.availability && this.state.availability.eventtypeavailabilityinterval_set) ? this.state.availability.eventtypeavailabilityinterval_set : []
        })
    }

    onDelete() {
        this.props.deleteCallback(this.state.availability)
        this.setState({
            showModal: false,
        });
    }

    selectExceptionDay(day, {selected}) {
        let unSelected = [];
        const year = day.getFullYear();
        const month = day.getMonth();
        const dayValue = day.getDate();
        const newDay = new Date(year, month, dayValue, 0, 0, 0);
        const dayTime = newDay.getTime();
        const currentExceptionDays = [...this.state.exceptionDays];
        const cleanExceptions = [];
        currentExceptionDays.forEach((exception) => {
            const exceptionTime = exception.getTime();
            if (exceptionTime === dayTime) {
                unSelected.push(exception);
            } else {
                cleanExceptions.push(exception);
            }
        });
        if (unSelected.length === 0) {
            cleanExceptions.push(newDay)
        }
        this.setState({exceptionDays: cleanExceptions});
    }

    clickRecurring(event) {
        if (this.state.recurring) {
            this.setState({recurring: false, recurringDays: []});
        } else {
            this.setState({recurring: true, specific: false, exceptionDays: []})
        }
    }

    clickSpecific(event) {
        if (this.state.specific) {
            this.setState({specific: false})
        } else {
            this.setState({specific: true, recurring: false, recurringDays: []})
        }
    }

    chooseRecurring(event) {
        event.preventDefault();
        const weekday = parseInt(event.target.value);
        let recurring = [...this.state.recurringDays];
        let index = recurring.indexOf(weekday);
        if (index < 0) {
            recurring.push(weekday)
        } else {
            recurring = recurring.filter(function (value) {
                return value !== weekday
            });
        }

        recurring.sort();
        this.setState({recurringDays: recurring});
    }

    recurringColors() {
        return {
            0: this.state.recurringDays.includes(0) ? 'success' : 'outline-primary',
            1: this.state.recurringDays.includes(1) ? 'success' : 'outline-primary',
            2: this.state.recurringDays.includes(2) ? 'success' : 'outline-primary',
            3: this.state.recurringDays.includes(3) ? 'success' : 'outline-primary',
            4: this.state.recurringDays.includes(4) ? 'success' : 'outline-primary',
            5: this.state.recurringDays.includes(5) ? 'success' : 'outline-primary',
            6: this.state.recurringDays.includes(6) ? 'success' : 'outline-primary',
        }
    }

    timeStringToLocal(timeString) {
        return new Date('1/1/2004 ' + timeString).toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    }

    toggleUnavailableButton() {
        this.setState({unavailable: !this.state.unavailable})
    }

    render() {
        // if (this.state.recurringDays && this.state.recurringDays.length > 0) {
        //     debugger;
        // }
        const weekends = this.state.recurringDays.length ===2 && this.state.recurringDays[0] === 5 && this.state.recurringDays[1] === 6;
        const submitDisabled = !this.state.errors || (this.state.recurringDays.length === 0 && this.state.exceptionDays.length === 0) || (!this.state.unavailable && this.state.intervals.length === 0);
        const recurringColors = this.recurringColors();
        return <Container>
            <Card border={this.state.unavailable ? "warning" :"primary"}
                  onClick={this.clickCard}>
                {!this.state.availability &&
                <Card.Header>Add Availability</Card.Header>}
                {this.state.availability &&
                <Card.Header>
                    {this.state.unavailable &&
                    <span>Unavailable&nbsp;</span>}
                    {this.state.intervals.length > 0 &&
                    this.state.intervals.map((value, index, original) => {
                        let startTime = value.start_time.split(':').slice(0, 2).join(':');
                        startTime = this.timeStringToLocal(startTime)
                        let endTime = value.end_time.split(':').slice(0, 2).join(':');
                        endTime = this.timeStringToLocal(endTime)
                        return <div key={index}>
                            {startTime}-{endTime}
                        </div>
                    })
                    }
                    {this.state.recurringDays && this.state.recurringDays.length > 0 && this.state.recurringDays.length < 7 && !weekends &&
                    <span style={{fontSize: '0.9rem'}}>Every {
                        this.state.recurringDays.map(
                            (recurringWeekday, index, original) => (
                                <div key={index} style={{display: 'inline'}}>
                                    {original.length > 2 && index + 1 === original.length &&
                                    <span>and&nbsp;</span>}
                                    <span>
                                            {new Date(2001, 0, 1 + recurringWeekday).toLocaleString(navigator.language, {weekday: 'short'})}
                                        {original.length > 2 && index + 1 !== original.length && <>,&nbsp;</>}
                                        </span>
                                    {original.length === 2 && index === 0 &&
                                    <span>&nbsp;and&nbsp;</span>}
                                </div>
                            ))}
                            </span>
                    }
                    {this.state.recurringDays.length === 7 && <span style={{fontSize: '0.9rem'}}>Every Day</span>}
                    {weekends && <span style={{fontSize: '0.9rem'}}>Weekends</span>}
                    {this.state.exceptionDays && this.state.exceptionDays.length > 0 &&
                    this.state.exceptionDays.map((value, index, original) => {
                        return <div
                            key={index}>{value.toLocaleDateString()}</div>
                    })
                    }
                </Card.Header>
                }
            </Card>
            <Modal
                show={this.state.showModal}
                size="lg"
                aria-labelledby="availability-vcenter"
                centered
                onHide={this.closeEditModal}
                className={'skin-modal'}
            >
                {this.state.unavailable ?
                    <Modal.Header
                        className={'unavailable'}
                        closeButton>
                        <Col>
                            Unavailable
                        </Col>
                        <Col>
                            <Button size={'sm'} variant="outline-primary"
                                    style={{fontSize: '0.75rem'}}
                                    onClick={this.toggleUnavailableButton}>
                                Make Available
                            </Button>
                        </Col>
                    </Modal.Header>
                    :
                    <Modal.Header
                        className={'available'}
                        closeButton>
                        <Col>
                            Available
                        </Col>
                        <Col>
                            <Button size={'sm'} variant={'outline-warning'}
                                    style={{fontSize: '0.75rem'}}
                                    onClick={this.toggleUnavailableButton}>
                                Make Unavailable
                            </Button>
                        </Col>
                    </Modal.Header>
                }
                <Modal.Body>
                    {/*{this.state.availability.eventtypeavailabilityinterval_set && <Button variant="outline-primary">Make Unavailable</Button>}*/}

                    {this.state.errors && this.state.errors.detail &&
                    <Alert
                        variant={'danger'}>{this.state.errors.detail}</Alert>}

                    {this.state.intervals.length > 0 &&
                        <>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <div>From</div>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <div>To</div>
                                </Form.Group>
                            </Form.Row>
                        </>
                    }
                    {this.state.intervals.map(
                        (interval, index) => {
                            return <EventTypeAvailabilityInterval
                                key={index}
                                authenticator={this.props.authenticator}
                                eventType={this.state.eventType}
                                availability={this.state.availability}
                                interval={interval}
                                onDelete={this.deleteInterval}
                                callback={this.updateIntervalCallback}
                            />
                        }
                    )}
                    <Form.Row>
                        <Button onClick={this.makeNewInterval}
                                variant="outline-primary"
                                size={'sm'}>
                            +Times
                        </Button>
                    </Form.Row>
                    <div className="d-flex flex-column">

                        {!this.state.specific && !this.state.recurring &&
                        <ButtonGroup label={'specific-or-recurring'} size="sm"
                                     className="mt-3">
                            <Button
                                onClick={this.clickRecurring}>Recurring</Button>
                            <Button
                                onClick={this.clickSpecific}>Specific</Button>
                        </ButtonGroup>
                        }
                        {this.state.specific &&
                        <ButtonGroup label={'specific-or-recurring'} size="sm"
                                     className="mt-3">
                            <Button size="sm" variant="outline-primary"
                                    onClick={this.clickRecurring}>Switch to
                                Recurring Days</Button>
                        </ButtonGroup>
                        }
                        {this.state.recurring &&
                        <ButtonGroup label={'specific-or-recurring'} size="sm"
                                     className="mt-3">
                            <Button size="sm" variant="outline-primary"
                                    onClick={this.clickSpecific}>Switch to
                                Specific Dates</Button>
                        </ButtonGroup>
                        }

                    </div>
                    <br/>
                    {this.state.recurring &&
                    <><Form.Row>
                        {/*<Form.Label column={'days-of-the-week'}>*/}
                        {/*    On Every:*/}
                        {/*</Form.Label>*/}
                        <ButtonGroup aria-label="days-of-the-week">
                            <Button name={'Sunday'}
                                    value={6}
                                    variant={recurringColors['6']}
                                    onClick={this.chooseRecurring}>U</Button>
                            <Button name={'Monday'}
                                    value={0}
                                    variant={recurringColors['0']}
                                    onClick={this.chooseRecurring}>M</Button>
                            <Button name={'Tuesday'}
                                    value={1}
                                    variant={recurringColors['1']}
                                    onClick={this.chooseRecurring}>T</Button>
                            <Button name={'Wednesday'}
                                    value={2}
                                    variant={recurringColors['2']}
                                    onClick={this.chooseRecurring}>W</Button>
                            <Button name={'Thursday'}
                                    value={3}
                                    variant={recurringColors['3']}
                                    onClick={this.chooseRecurring}>R</Button>
                            <Button name={'Friday'}
                                    value={4}
                                    variant={recurringColors['4']}
                                    onClick={this.chooseRecurring}>F</Button>
                            <Button name={'Saturday'}
                                    value={5}
                                    variant={recurringColors['5']}
                                    onClick={this.chooseRecurring}>S</Button>
                        </ButtonGroup>
                    </Form.Row>
                        <Form.Row>
                            {this.state.recurringDays.length === 0 &&
                            <>Select at least 1 Weekday.</>
                            }
                            {this.state.recurringDays.length > 0 &&
                            <>On Every&nbsp;</>
                            }
                            {this.state.recurringDays.map(
                                (recurringWeekday, index, original) => (
                                    <div key={index}>
                                        {original.length > 2 && index + 1 === original.length &&
                                        <span>and&nbsp;</span>}
                                        {new Date(2004, 1, 2 + recurringWeekday).toLocaleString(navigator.language, {weekday: 'long'})}
                                        {original.length > 2 && index + 1 !== original.length &&
                                        <span>,&nbsp;</span>}
                                        {original.length === 2 && index === 0 &&
                                        <span>&nbsp;and&nbsp;</span>}
                                    </div>
                                ))}
                        </Form.Row>
                    </>
                    }
                    {this.state.specific &&
                    <Form.Row>
                        <DayPicker
                            fixedWeeks
                            selectedDays={this.state.exceptionDays}
                            onDayClick={this.selectExceptionDay}
                        />
                    </Form.Row>
                    }
                    {!this.state.specific && !this.state.recurring &&
                    <div>Select Specific or Recurring</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button type={'submit'} block disabled={submitDisabled}
                            onClick={this.onSubmit}>Submit</Button>
                    {this.state.availability && this.state.availability.id &&
                    <Button variant="warning"
                            onClick={this.onDelete}>Delete</Button>}
                    <Button variant="secondary"
                            onClick={this.onCancel}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    }

}

export default class EventTypeAvailabilities extends Component {

    constructor(props) {
        super(props);
        this.state = {
            availabilities: [],
            refreshNew: false
        }
        this.addNewCallback = this.addNewCallback.bind(this);
        this.refreshNew = this.refreshNew.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
        this.deleteApiCallback = this.deleteApiCallback.bind(this);
    }

    componentDidMount() {
        this.setState({availabilities: this.props.eventType.eventtypeavailability_set})
    }

    refreshNew() {
        this.setState({refreshNew: false})
    }

    addNewCallback(availability) {
        const newAvailabilities = [...this.state.availabilities, availability];
        this.setState({
            refreshNew: true,
            availabilities: newAvailabilities
        }, this.refreshNew)
    }

    deleteApiCallback(data) {

    }

    deleteCallback(availabilityToRemove) {
        const oldAvailabilities = [...this.state.availabilities];
        this.setState({availabilities: []}, () => {
            this.props.authenticator.queuedDelete(
                'event_type_availability',
                availabilityToRemove.id, this.deleteApiCallback);
            let newAvailabilities = [];
            oldAvailabilities.forEach((availability) => {
                if (availability.id !== availabilityToRemove.id) {
                    newAvailabilities.push(availability)
                }
            });
            this.setState({availabilities: newAvailabilities})
        });

    }

    render() {
        return <div>
            {this.state.availabilities.map(
                (availability, index) => {
                    return <EventTypeAvailability
                        key={index}
                        authenticator={this.props.authenticator}
                        eventType={this.props.eventType}
                        availability={availability}
                        deleteCallback={this.deleteCallback}
                    />
                }
            )}
            {!this.state.refreshNew &&
            <EventTypeAvailability
                authenticator={this.props.authenticator}
                eventType={this.props.eventType}
                addNewCallback={this.addNewCallback}
                availability={null}
            />}
        </div>
    }

}