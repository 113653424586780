import React, {Component} from "react";

import {Helmet} from "react-helmet";
import {Redirect} from "react-router";


export default class Payments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
        };
        this.paymentButton = this.paymentButton.bind(this);
    }

    paymentButton() {
        return <div
            data-ap-widget-type="expressPaymentButton"
            data-ap-signature="G2D2F4NJHq6UbVdh1caYicbN9beoW%2BmOCAbcJ%2F87ZQc%3D"
            data-ap-seller-id="AV7P5LP286Y2M"
            data-ap-access-key="AKIAJ4PNSJ5L6MOWSHMA"
            data-ap-lwa-client-id="amzn1.application-oa2-client.2a7fc1c321e34edd89dfc4608d973c3b"
            data-ap-return-url="https://experiencemaster.com/payment_callback"

            data-ap-cancel-return-url="https://experiencemaster.com/payment_canceled"

            data-ap-currency-code="USD"
            data-ap-amount="0.99"
            data-ap-note="EM Monthly Tier 2"
            data-ap-shipping-address-required="false"
            data-ap-payment-action="AuthorizeAndCapture"
        />
    }

    render() {
        return <>
            <Helmet>
                <script async
                        src="https://static-na.payments-amazon.com/OffAmazonPayments/us/sandbox/js/Widgets.js"/>
            </Helmet>
            <table>
                <tr>
                    <td>Single Payment of $4.99</td>
                    <td>
                        {this.paymentButton()}
                    </td>
                </tr>
            </table>

        </>
    }
}

export class PaymentCallback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transactionId: null,
        };
        this.postTransaction = this.postTransaction.bind(this);
        this.submitCallback = this.submitCallback.bind(this);
    }

    componentDidMount() {
        this.postTransaction()
    }

    submitCallback(data) {
        this.setState({transactionId: data.id})
    }

    postTransaction() {
        const url = window.location.href;
        const data = {
            url: url
        };
        this.props.authenticator.queuedPost('account_payments',
            data, this.submitCallback, null)
    }

    render() {
        console.log('Payments.render', this.state.transactionId)
        const url = '/subscriptions?transactionId=' + this.state.transactionId;
        return <>
            {this.state.transactionId &&
                <Redirect to={url}/>
            }
            <div>Transaction Processing....</div>
        </>
    }
}

export class PaymentCanceled extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
        };
    }

    render() {
        return <>
            <div>Payment Canceled</div>
        </>
    }
}

