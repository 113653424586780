import moment from "moment-timezone";
import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/Spinner";
import {Redirect} from "react-router";
import AdvisorGraphs from "./AdvisorGraphs";
import "./advisors.css"
import {AssignedQuest} from "./AssignedQuest";
import {EmailViews} from "./EmailViews";
import FTUEModal from "./FTUE";
import {SoonerOrLater} from "./SoonerOrLater";
import {SubscriptionCheck} from "./Subscriptions";
import {CoachCard} from "./SuggestionCards";
import TodaysEvents from "./TodaysEvents";
import TopExperience from "./TopExperience";
import VariableAdvisors from "./VariableAdvisors";

const Emoji = props => (
    <span
        className="emoji"
        role="img"
        aria-label={props.label ? props.label : ""}
        aria-hidden={props.label ? "false" : "true"}
    >
        {props.symbol}
    </span>
);

export class ExperienceMasterCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: false,
            viewSelected: null
        };
        this.clickCard = this.clickCard.bind(this);
        this.onClickSolButton = this.onClickSolButton.bind(this);
        this.onClickSolButtonCallback = this.onClickSolButtonCallback.bind(this);
    }


    clickCard(event) {
        if (!!this.props.contact) {
            this.setState({viewSelected: '/contacts/contact/' + this.props.contact.id.toString()})
        }
    }

    onClickSolButtonCallback(event){
        this.props.callback(event)
    }

    onClickSolButton(event) {
        const status = event.currentTarget.dataset.status;
        this.props.authenticator.queuedPut(
            'experiencemaster',
            this.props.contact.id,
            {
                id: this.props.contact.id,
                status: status
            }, this.onClickSolButtonCallback);
    }

    render() {
        if (this.state.hide || !this.props.authenticator) {
            return <></>
        }

        if (this.state.viewSelected) {
            return <Redirect to={this.state.viewSelected}/>
        }


        const statusClassDict = {
            3: 'never-faded',
            12: 'first-contact-faded',
            27: 'thank-you-faded',
            34: 'sooner-faded',
            11: 'later-faded',
            25: 'sleep-faded'
        };
        const contact = this.props.contact;

        let score = null;
        let statusClass;
        if (contact) {
            statusClass = statusClassDict[contact.status];
            if (contact.score > 0) {
                score = this.props.privacyMode ? '**' : Math.trunc(contact.score);
            }
        } else {
            statusClass = ''
        }

        return (
            <div className={'skin-card experience-master-card-1'}>
                    <div
                        className={statusClass + ' skin-card-header'}
                        onClick={this.clickCard}>{this.props.emojiSymbol &&
                    <Emoji symbol={this.props.emojiSymbol}
                           label="sheep"/>}{this.props.name}</div>
                    {contact && <div className={'skin-card-title'} onClick={this.clickCard}>{contact.name}</div>}
                    <div className={'skin-card-body'} onClick={this.clickCard}>
                        {score && <>
                        <div className="skin-row justify-content-center">
                            <h3>{score}</h3></div></>}
                        {contact && contact.last_contact && <>
                                <div
                                    className="justify-content-center">{moment(contact.last_contact).format('LL LT')}</div>
                                <div
                                    className="justify-content-center">{moment(contact.last_contact).fromNow()}</div>
                            </>
                        }
                        {contact && !contact.last_contact && <div>Never Contacted</div>}
                        <div className="skin-row justify-content-center">{this.props.type}</div>
                    </div>
                    <div className={'skin-column'}>
                        {contact && <ButtonGroup className="mt-3" size="sm">
                            <Button className={'sooner-button'}
                                    data-contact-id={contact.id}
                                    data-status={34}
                                    onClick={this.onClickSolButton}>Sooner</Button>
                            <Button
                                className={'view-button'}
                                data-contact-id={contact.id}
                                onClick={this.clickCard}>View</Button>
                            <Button
                                className={'later-button'}
                                data-contact-id={contact.id}
                                data-status={11}
                                onClick={this.onClickSolButton}>Later</Button>
                        </ButtonGroup>}
                    </div>
            </div>
        )
    }
}


// class AdvisorGroup extends Component {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             redirect: null
//         };
//         // clickGroup
//         this.clickCard = this.clickCard.bind(this);
//         this.onClickSolButton = this.onClickSolButton.bind(this);
//         this.onClickSolButtonCallback = this.onClickSolButtonCallback.bind(this);
//     }
//
//     clickCard(event) {
//         const contactId = event.currentTarget.dataset.contactId;
//         this.setState({redirect: '/contacts/contact/' + contactId.toString()})
//     }
//
//     onClickSolButtonCallback(event){
//         this.props.callback(event)
//     }
//
//     onClickSolButton(event) {
//         const status = event.currentTarget.dataset.status;
//         const contactId = event.currentTarget.dataset.contactId;
//         this.props.authenticator.queuedPut(
//             'experiencemaster',
//             contactId,
//             {
//                 id: contactId,
//                 status: status
//             }, this.onClickSolButtonCallback);
//     }
//
//     render() {
//
//         if (this.state.redirect) {
//             return <Redirect to={this.state.redirect}/>
//         }
//         const queryset = this.props.queryset;
//
//         const advisorHeaderClassDict = {
//             'brand_value': 'brand-value',
//             'personal_value': 'personal-value',
//             'inclusion': 'inclusion',
//             'affinity': 'affinity',
//         };
//         const advisorHeaderClass = advisorHeaderClassDict[this.props.advisorKey];
//
//         const advisorCardClassDict = {
//             'brand_value': 'advisor-card brand-value-advisor',
//             'personal_value': 'advisor-card personal-value-advisor',
//             'inclusion': 'advisor-card inclusion-advisor',
//             'affinity': 'advisor-card affinity-advisor',
//         };
//         const advisorCardClass = advisorCardClassDict[this.props.advisorKey];
//
//
//
//         const statusClassDict = {
//             3: 'never-faded',
//             12: 'first-contact-faded',
//             27: 'thank-you-faded',
//             34: 'sooner-faded',
//             11: 'later-faded',
//             25: 'sleep-faded'
//         };
//
//         return <>
//             <div className={'advisor-card-preamble'} />
//
//             <Card name={'advisor-card'} className={advisorCardClass}>
//             <Card.Header className={advisorHeaderClass}>
//                 {this.props.advisorGroupName}
//             </Card.Header>
//             <Card.Body>
//                 {queryset.map((contact, index) => {
//                     const statusClass = statusClassDict[contact.status];
//
//                     return <div key={index}>
//                         <div
//                             className={'advisor-contact-box ' + statusClass}
//                             data-contact-id={contact.id}
//                         >
//                             <div data-contact-name={contact.name}
//                                  data-contact-id={contact.id}
//                                  onClick={this.onClickSolButton}
//                                  data-status={34}
//                                  className="advisor-button sooner"
//                             >S
//                             </div>
//                             <div  data-contact-name={contact.name}
//                                   data-contact-id={contact.id}
//                                   onClick={this.clickCard}
//                                   className={'advisor-main-button'}
//                             >{contact.name}</div>
//                             <div data-contact-name={contact.name}
//                                  data-contact-id={contact.id}
//                                  onClick={this.onClickSolButton}
//                                  data-status={11}
//                                  className="advisor-button later"
//                             >L
//                             </div>
//                         </div>
//                     </div>
//                 })}
//             </Card.Body>
//         </Card>
//         </>
//     }
//
// }


export default class Advisors extends Component {

    constructor(props) {
        super(props);
        this.state = {
            suggestions: null
        };
        this.getSuggestionsCallback = this.getSuggestionsCallback.bind(this);
        this.cardCallback = this.cardCallback.bind(this);
    }

    componentDidMount() {
        this.getSuggestions();
    }

    getSuggestionsCallback(data) {
        this.setState({suggestions: data});

        // this.setState({suggestions: null},
        //     () => {
        //         this.setState({suggestions: data})
        //     })
    }

    getSuggestions() {
        this.props.authenticator.queuedGet(
            'experiencemaster',
            this.getSuggestionsCallback
        );
    }


    cardCallback() {
        // this.setState({suggestions:null}, this.getSuggestions)
        this.getSuggestions();
    }

    render() {
        return <>
            <SubscriptionCheck authenticator={this.props.authenticator}/>
            {this.state.suggestions ?
            <div className={'advisors-container'} id={'advisors'}>
                <FTUEModal
                    authenticator={this.props.authenticator}  />

                <ExperienceMasterCard
                    contact={this.state.suggestions.experience_master}
                    type={this.state.suggestions.experience_master_type}
                    cardId={'em'}
                    name={'Experience Master'}
                    emojiSymbol={'🌟'}
                    authenticator={this.props.authenticator}
                    callback={this.cardCallback}
                    privacyMode={this.props.privacyMode}
                />
                <VariableAdvisors authenticator={this.props.authenticator}/>
                <AdvisorGraphs authenticator={this.props.authenticator}/>
                <TodaysEvents
                    authenticator={this.props.authenticator}
                    />
                <TopExperience
                    authenticator={this.props.authenticator}
                    />
                <EmailViews authenticator={this.props.authenticator}/>
                <SoonerOrLater authenticator={this.props.authenticator}/>
                <AssignedQuest authenticator={this.props.authenticator} />
                <CoachCard
                    authenticator={this.props.authenticator}
                    callback={this.cardCallback}
                />
            </div>
            : <Spinner animation="border"
                       role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>}</>
    }


}