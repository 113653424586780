import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import AddRelationshipModal from "./AddRelationship";


class Relationships extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            reverseItems: [],
            showModal: false
        };
        this.addCallback = this.addCallback.bind(this);
        this.hideCallback = this.hideCallback.bind(this);
        this.showModal = this.showModal.bind(this);

        this.deleteButton = this.deleteButton.bind(this);
        this.deleteCallback = this.deleteCallback.bind(this);
    }

    componentDidMount() {
        this.setState({
            items: this.props.contact.contact_relationship_set,
            reverseItems: this.props.contact.contact_relative_set
        });
    }

    addCallback(newItem) {
        let newItems = [...this.state.items];
        newItems.push(newItem);
        this.setState({items: newItems}, this.hideModal);
    }

    hideCallback() {
        this.hideModal()
    }

    hideModal() {
        this.setState({showModal: false})
    }


    showModal(event) {
        event.preventDefault();
        this.setState({showModal: true})
    }

    displayRelationship(relationship, index) {
        let typeId = relationship.type;
        let types = this.props.authenticator.library.relationship_type_dict;
        let relationshipName = (types && types[typeId] && types[typeId].name);

        return (
            <div key={'relationship-' + relationship.id}>
                {relationshipName}
                &nbsp;{relationship.relative ?
                <a href={'/contacts/contact/' + relationship.relative.id}>
                    {relationship.relative.name}
                </a> : <span>Me/Mine&nbsp;</span>}
                {this.props.editMode &&
                <Button variant={'danger'}
                        onClick={this.deleteButton}
                        data-item-id={relationship.id}>X
                </Button>}
            </div>
        )
    }

    displayReverseRelationship(relationship, index) {
        let typeId = relationship.type;
        let types = this.props.authenticator.library.relationship_type_dict;
        let relationshipName = (types && types[typeId] && types[typeId].reverse_name);

        return (
            <div key={'reverseRelationship-' + relationship.id}>
                {relationshipName}
                &nbsp;
                <a href={'/contacts/contact/' + relationship.contact.id}>
                    {relationship.contact.name}
                </a>&nbsp;
                {this.props.editMode && <Button variant={'danger'}
                                                onClick={this.deleteButton}
                                                data-item-id={relationship.id}>X
                </Button>}
            </div>
        )
    }

    deleteCallback() {
        let newItems = [...this.state.items];
        let preppedItems = newItems.filter(
            item => (item.id !== this.state.requestedItem));

        let newReverseItems = [...this.state.items];
        let preppedReverseItems = newReverseItems.filter(
            item => (item.id !== this.state.requestedItem));

        this.setState({
            items: preppedItems,
            reverseItems: preppedReverseItems});
    }

    deleteButton(event) {
        const recordId = parseInt(event.currentTarget.dataset.itemId);
        this.setState({requestedItem: recordId});
        this.props.authenticator.queuedDelete(
            'relationships', recordId, this.deleteCallback, () => {});
    }

    render() {

        if (!this.props.contact || !this.state.items || !this.state.reverseItems) {
            return;
        }

        const types = this.props.authenticator.library.relationship_types;
        return (
            <Col align={"left"}>
                {this.state.items.length > 0 && this.state.items.map((item, index) => (
                    this.displayRelationship(item, index)
                ))}
                {this.state.reverseItems.length > 0 && this.state.reverseItems.map((item, index) => (
                    this.displayReverseRelationship(item, index)
                ))}
                {this.props.editMode && <>
                    <OverlayTrigger overlay={
                        <Popover id="tooltip-disabled">
                            <Popover.Title>
                                Relationships
                            </Popover.Title>
                            <Popover.Content>
                                <div>Record connections between yourself and {this.props.contact.name} or between {this.props.contact.name} and another contact.</div>
                            </Popover.Content>
                        </Popover>}
                    >
                        <Button size="sm" block
                                onClick={this.showModal}>Add Relationship</Button>
                    </OverlayTrigger>

                    <AddRelationshipModal
                        show={this.state.showModal}
                        callback={this.addCallback}
                        contact={this.props.contact}
                        relationshipTypes={types}
                        hideCallback={this.hideCallback}
                        authenticator={this.props.authenticator}
                    />

                </>}

                {(this.state.reverseItems.length > 0 || this.state.items.length > 0) ?
                    <div className="border-top my-2"/> : <></>}

            </Col>
        )
    }
}


export default Relationships;
