import React, {Component} from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import {Redirect} from "react-router-dom";
import {SubscriptionCheck} from "./Subscriptions";


// class CoachCardEditor extends Component {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             records: null,
//             note: '',
//             noteId: null,
//             saved: true
//         };
//         this.getCallback = this.getCallback.bind(this);
//         this.onChange = this.onChange.bind(this);
//         this.onSubmit = this.onSubmit.bind(this);
//         this.afterSave = this.afterSave.bind(this);
//     }
//
//     componentDidMount() {
//         this.getRecord();
//     }
//
//     getCallback(data) {
//         if (data.length >= 1) {
//             const record = data[0];
//             this.setState({
//                 records: data,
//                 note: record.note,
//                 noteId: record.id,
//             })
//         } else {
//             this.setState({records: data});
//         }
//     }
//
//     getRecord() {
//         this.props.authenticator.queuedGet(
//             'coach/cards',
//             this.getCallback,
//             null,
//             null,
//             {learner: this.props.learner.id}
//         )
//     }
//
//     onChange(event) {
//         this.setState({note: event.target.value, saved: false})
//     }
//
//     afterSave(data) {
//         this.setState({
//             //records: data,
//             //note: data.note,
//             noteId: data.id,
//             saved: true
//         })
//     }
//
//     onSubmit(event) {
//         event.preventDefault();
//
//         let body = {
//             coach: this.props.authenticator.account.coach.id,
//             learner: this.props.learner.id,
//             note: this.state.note
//         };
//         if (this.state.noteId) {
//             body.id = this.state.noteId;
//             this.props.authenticator.queuedPut(
//                 'coach/cards',
//                 this.state.noteId,
//                 body,
//                 this.afterSave,
//                 null
//             );
//         } else {
//             this.props.authenticator.queuedPost(
//                 'coach/cards',
//                 body,
//                 this.afterSave,
//                 null
//             );
//         }
//     }
//
//     render() {
//         if (!!!this.props.learner) {
//             return <div />
//         }
//         const defaultValue = this.state.note || '';
//         return (
//             <Form id={'note-card-form'} name={'note-card-form'}>
//                 <Form.Control type="text" rows="3"
//                               id={'note'}
//                               name={'note'}
//                               placeholder="Message for Learner"
//                               onChange={this.onChange}
//                               defaultValue={defaultValue}
//                 />
//                 {this.state.records && this.state.records.length > 0 && moment(this.state.records[0].modified).format('dddd LL LT')}
//                 {!this.state.saved &&
//                 <Button variant="primary" onClick={this.onSubmit}>
//                     Save
//                 </Button>}
//             </Form>
//
//
//         )
//     }
//
// }
//
// class CoachOfLearner extends Component {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             open: false,
//             records: null
//         };
//         this.toggleLearnerRecord = this.toggleLearnerRecord.bind(this);
//         this.getRecord = this.getRecord.bind(this);
//         this.getCallback = this.getCallback.bind(this);
//     }
//
//     toggleLearnerRecord(event) {
//         event.preventDefault();
//         this.setState(prevState => (
//             {open: !prevState.open}))
//         this.getRecord()
//     }
//
//     getCallback(data) {
//         this.setState({records: data})
//     }
//
//     getRecord() {
//         this.props.authenticator.queuedGet(
//             'learner/coach',
//             this.getCallback,
//             null,
//             this.props.coach.id
//         )
//     }
//
//
//     render() {
//         return (
//             <Container>
//                 <Accordion
//                     as={Card}
//                     border={'primary'}
//                     key={this.props.coach.id}
//                 >
//                     <Accordion.Toggle as={Card.Header}
//                                       eventKey={this.props.coach.id}
//                                       onClick={this.getRecord}
//                     >
//                         {this.props.coach.user.first_name}&nbsp;
//                         {this.props.coach.user.last_name}
//                     </Accordion.Toggle>
//                     <Accordion.Collapse
//                         eventKey={this.props.coach.id}>
//                         <Card.Body>
//                             {!!this.state.records &&
//                             this.state.records.length > 0 &&
//                             <h3>Shared Contacts</h3>}
//                             {!!this.state.records &&
//                             this.state.records.map(
//                                 (share, index) => (
//                                     <Accordion
//                                         as={Card}
//                                         border={'primary'}
//                                         key={share.id}
//                                         className={'text-left'}
//                                     >
//                                         <Accordion.Toggle as={Card.Header}
//                                                           eventKey={share.id}
//                                         >
//                                             <Link to={'/contacts/contact/' + share.learner_contact.id}>
//                                                 {share.learner_contact.name}
//                                                 &nbsp;-&nbsp;
//                                                 {share.learner_contact.status && this.props.authenticator.library.status_type_dict[share.learner_contact.status]}
//                                             </Link>
//                                         </Accordion.Toggle>
//                                         <Accordion.Collapse
//                                             eventKey={share.id}>
//                                             <Card.Body>
//
//                                                 <Row><Col>{share.sharedconnectionnote_set.length} Messages
//                                                     about {share.learner_contact.name}</Col></Row>
//                                                 <Table striped hover size="sm">
//                                                     <tbody>
//                                                     {share.sharedconnectionnote_set.map((note, noteKey) => (
//                                                         <tr key={noteKey}>
//                                                             <td>{note.note}</td>
//                                                             <td>{new Date(note.modified).toDateString()}</td>
//                                                         </tr>
//                                                     ))}
//                                                     </tbody>
//                                                 </Table>
//                                             </Card.Body>
//                                         </Accordion.Collapse>
//                                     </Accordion>
//                                 )
//                             )
//                             }
//                         </Card.Body>
//                     </Accordion.Collapse>
//                 </Accordion>
//
//             </Container>
//
//         )
//     }
//
// }
//
//
// class CoachedLearner extends Component {
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             open: false,
//             record: null
//         };
//         this.toggleLearnerRecord = this.toggleLearnerRecord.bind(this);
//         this.getRecord = this.getRecord.bind(this);
//         this.getCallback = this.getCallback.bind(this);
//     }
//
//     toggleLearnerRecord(event) {
//         event.preventDefault();
//         this.setState(prevState => (
//             {open: !prevState.open}))
//         this.getRecord()
//     }
//
//     getCallback(data) {
//         this.setState({record: data})
//     }
//
//     getRecord() {
//         this.props.authenticator.queuedGet(
//             'learner',
//             this.getCallback,
//             null,
//             this.props.learner.id
//         )
//     }
//
//     render() {
//         return (
//             <Container>
//                 <Accordion
//                     as={Card}
//                     border={'primary'}
//                     key={this.props.learner.id}
//                 >
//                     <Accordion.Toggle as={Card.Header}
//                                       eventKey={this.props.learner.id}
//                                       onClick={this.getRecord}
//                     >
//                         {this.props.learner.user.first_name}&nbsp;
//                         {this.props.learner.user.last_name}&nbsp;
//                         LV: {this.props.learner.user.level}
//                     </Accordion.Toggle>
//                     <Accordion.Collapse
//                         eventKey={this.props.learner.id}>
//                         <Card.Body>
//                             <h3>Current Index Card Text</h3>
//                             <CoachCardEditor
//                                 authenticator={this.props.authenticator}
//                                 learner={this.props.learner}
//                             />
//                             {!!this.state.record &&
//                             this.state.record.sharedconnection_set.length > 0 &&
//                             <h3>Shared Contacts</h3>}
//                             {!!this.state.record &&
//                             this.state.record.sharedconnection_set.map((connection, index) => (
//                                 <Accordion
//                                     as={Card}
//                                     border={'primary'}
//                                     key={index}
//                                 >
//                                     <Accordion.Toggle as={Card.Header}
//                                                       eventKey={index}>
//                                         {connection.learner.user.first_name}&nbsp;
//                                         {connection.learner.user.last_name}&nbsp;
//                                         |&nbsp;{this.props.authenticator.library.status_type_dict[connection.learner_contact.status]}&nbsp;|&nbsp;
//                                         V:{moment(connection.learner_contact.last_viewed).format('dddd LL LT')}&nbsp;
//                                         C:{moment(connection.learner_contact.last_contact).format('dddd LL LT')}
//                                     </Accordion.Toggle>
//                                     <Accordion.Collapse eventKey={index}>
//                                         <Card.Body>
//                                             <SharedConnectionNotes
//                                                 authenticator={this.props.authenticator}
//                                                 connection={connection} />
//                                         </Card.Body>
//                                     </Accordion.Collapse>
//                                 </Accordion>
//                                 )
//                             )
//                             }
//                         </Card.Body>
//                     </Accordion.Collapse>
//                 </Accordion>
//
//             </Container>
//         )
//     }
//
// }
//

export default class Coaching extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            coaching: null,
            selectedLearner: null,
        };
        this.coachingCallback = this.coachingCallback.bind(this);
        this.onLearnerSelect = this.onLearnerSelect.bind(this);
        this.getColumns = this.getColumns.bind(this);
    }

    componentDidMount() {
        this.props.authenticator.queuedGet('coaching', this.coachingCallback);
    }


    coachingCallback(data) {
        this.setState({coaching: data, loading: false})
    }

    getColumns(typeName) {
        const nameFieldName = 'name';
        let returnList = [{
            dataField: nameFieldName,
            text: 'Name',
            sort: true,
            align: 'left',
            filter: textFilter(),
        }
        ];
        if (typeName === 'coach') {
            returnList.push({
                dataField: 'week_of_experience',
                text: 'Experience this Week',
                sort: true,
                align: 'left'
            });
            returnList.push({
                dataField: 'level',
                text: 'Level',
                sort: true,
                align: 'left'
            });
            returnList.push({
                dataField: 'shares',
                text: 'Shares',
                sort: true,
                align: 'left'
            });
            returnList.push({
                dataField: 'coach_name',
                text: 'Coach',
                sort: true,
                align: 'left',
                filter: textFilter()
            });
        }
        if (typeName !== 'coach') {
            returnList.push({
                dataField: 'shares',
                text: 'Shares',
                sort: true,
                align: 'right'
            });
        }

        return returnList;

    }


    onLearnerSelect(learnerId) {
        this.setState({selectedLearner: learnerId});
    };


    getRowEvents() {
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.onLearnerSelect(row.id)
            }
        };
        return rowEvents;
    }

    render() {
        if (this.state.selectedLearner) {
            return <Redirect
                to={'/learner/' + this.state.selectedLearner}/>
        }

        if (this.state.loading) {
            return <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        }

        if (!this.state.coaching) {
            return <div>You don't have a Coach or Learners.</div>
        }

        return <>
            <SubscriptionCheck authenticator={this.props.authenticator} minimum={'pro'}/>
            {this.state.coaching.learners &&
            <Container>
                {this.props.authenticator.account.coach && this.props.authenticator.account.coach.is_active && <>
                    <h1>Our Learners</h1>
                    {this.state.coaching.learners && <div>
                        <BootstrapTable keyField='id'
                                        classes={'data-table-container'}
                                        data={this.state.coaching.learners}
                                        columns={this.getColumns('coach')}
                                        striped={true}
                                        hover={true}
                                        bootstrap4={true}
                                        rowEvents={this.getRowEvents()}
                                        filter={filterFactory()}
                        />
                    </div>
                    }
                </>
                }
                {this.state.coaching.my_coaches.length > 0 && <>
                    <h1>My Coaches</h1>
                    {this.state.coaching.my_coaches && <div>
                        <BootstrapTable keyField='id'
                                        classes={'data-table-container'}
                                        data={this.state.coaching.my_coaches}
                                        columns={this.getColumns('learner')}
                                        striped={true}
                                        hover={true}
                                        bootstrap4={true}
                                        rowEvents={this.getRowEvents()}
                                        filter={filterFactory()}
                        />
                    </div>
                    }
                </>
                }
            </Container>
            }
        </>

    }
}
