import React, {Component} from 'react'
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

export default class ShareModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            relative: '',
            shareRelationshipTypeId: 16,
            show: false,
            contactOptionList: []
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
        this.getContactListCallback = this.getContactListCallback.bind(this);
        this.getContactList = this.getContactList.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
        this.getContactList();
    }

    getContactListCallback(data) {
        this.setState({contacts: data}, this.makeContactOptionList)
    }

    getContactList() {
        this.props.authenticator.queuedGet(
            'contacts/contact_select_list',
            this.getContactListCallback)
    }

    onChange(event) {
        this.setState({relative: event.target.value})
    }

    onSubmitCallback(data) {
        if (!data) {
            return;
        }
        this.setState({show: false})
        this.props.callback(data)
    }

    onSubmit(event) {
        event.preventDefault();
        let relativeId = this.state.relative;
        if (parseInt(relativeId) === 0) {
            relativeId = null
        }

        const body = {
            relative: relativeId,
            type: this.state.shareRelationshipTypeId,
            contact: this.props.contact.id
        };
        this.props.authenticator.queuedPost(
            'relationships',
            body,
            this.onSubmitCallback,
            null,
            null,
            {contact: this.props.contactId})
    }

    makeContactOptionList() {
        const contacts = [
            {id: 0, name: 'Me/Mine'},
            ...this.state.contacts];
        let optionList = [];
        optionList.push(
            <option key={'null'}
                    value=''
            >Select Contact (required)</option>
        );
        if (!!contacts) {
            contacts.forEach((value, index) => {
                optionList.push(
                    <option key={index}
                            value={value.id}
                    >{value.name}</option>
                );
            });
        }
        this.setState({contactOptionList: optionList})
    }

    onHide(event) {
        this.props.hideCallback();
        this.setState({type: 0, mainCategory: null, relative: ''})
    }

    render() {
        return (

            <Modal
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={this.onHide}
                className={'skin-modal'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Share Contact
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id={'relationship-form'}
                          name={'relationship-form'}
                          onSubmit={this.onSubmit}>
                        <Form.Control as="select"
                                      onChange={this.onChange}
                                      value={this.state.relative}
                                      required>
                            {this.state.contactOptionList}
                        </Form.Control>
                        <Button variant="primary" type={'submit'}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        )
    }

}
