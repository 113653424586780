import moment from "moment-timezone";
import React, {Component} from "react";
import {Link} from "react-router-dom";


export class EmailViews extends Component {

    constructor(props) {
        super(props);
        this.state = {
            todaysBeacons: []
        };
        this.getEvents = this.getEvents.bind(this);
        this.getEventsCallback = this.getEventsCallback.bind(this);
    }

    componentDidMount() {
        this.getEvents();
    }

    getEventsCallback(data) {
        this.setState({todaysBeacons: data})
    }

    getEvents() {
        this.props.authenticator.queuedGet(
            'transcript_beacons', this.getEventsCallback, () => {
            })
    }

    render() {

        const beacons = this.state.todaysBeacons || [];

        if (beacons.length === 0) {
            return <div id={'no-beacons'}/>
        }

        const beaconItemList = [];
        beacons.forEach((item) => {
            beaconItemList.push(item)
        });

        return <div className={'read-email-card skin-card'}>
                    <div
                        className={'read-email-card-header skin-card-header'}>Email
                        Views
                    </div>
                    <div className={'skin-card-body'}>
                        {beaconItemList.map((contact, index) => {
                            return <div key={index}>
                                {contact && <>
                                    <Link
                                        to={'/contacts/contact/' + contact.id.toString()}>
                                        {contact.name}</Link>
                                    <div
                                        style={{fontSize: '.75rem'}}>{moment(contact.last_read_email).fromNow()}</div>
                                </>}
                            </div>
                        })}
                    </div>
                </div>
    }

}