import React, {Component} from 'react'
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

class AddRelationshipModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            relative: '',
            type: 0,
            show: false,
            mainCategory: null,
            typeOptionList: [],
            contactOptionList: []
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
        this.setMainCategory = this.setMainCategory.bind(this);
        this.pickType = this.pickType.bind(this);
        this.makeTypeOptionList = this.makeTypeOptionList.bind(this);
        this.getContactListCallback = this.getContactListCallback.bind(this);
        this.getContactList = this.getContactList.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
        this.makeTypeOptionList();
        this.getContactList();
    }

    getContactListCallback(data) {
        this.setState({contacts: data}, this.makeContactOptionList)
    }

    getContactList() {
        this.props.authenticator.queuedGet(
            'contacts/contact_select_list',
            this.getContactListCallback)
    }

    setMainCategory(event) {
        let categoryId = parseInt(event.currentTarget.dataset.typeId);
        this.setState({mainCategory: categoryId})
    }

    onChange(event) {
        this.setState({relative:event.target.value})
    }

    onTypeChange(event) {
        this.setState({type:event.target.value})
    }


    pickType(event) {
        const type = parseInt(event.currentTarget.dataset.typeId);
        this.setState({type:type})
    }

    onSubmitCallback(data) {
        if (!data) {
            return;
        }
        this.setState({show:false})
        this.props.callback(data)
    }

    onSubmit(event) {
        event.preventDefault();
        if (!this.state.type) {return;}
        let relativeId = this.state.relative;
        if (parseInt(relativeId) === 0) {
            relativeId = null
        }

        const body = {
            relative:relativeId,
            type:this.state.type,
            contact:this.props.contact.id
        };
        this.props.authenticator.queuedPost(
            'relationships',
            body,
            this.onSubmitCallback,
            null,
            null,
            {contact: this.props.contactId})
    }

    makeTypeOptionList() {
        const types = this.props.authenticator.library.relationship_types;
        let optionList = [];
        optionList.push(
            <option key={'null'}
                    value=''
            >Select Type (required)</option>
        );
        if (!!types) {
            types.forEach((value, index) => {
                optionList.push(
                    <option key={index}
                            value={value.id}
                    >{value.name}</option>
                );
            });
        }
        this.setState({typeOptionList: optionList});
    }


    makeContactOptionList() {
        const contacts = [
            {id:0, name:'Me/Myself/Mine'},
            ...this.state.contacts];
        let optionList = [];
        optionList.push(
            <option key={'null'}
                    value=''
            >Select Contact (required)</option>
        );
        if (!!contacts) {
            contacts.forEach((value, index) => {
                optionList.push(
                    <option key={index}
                            value={value.id}
                    >{value.name}</option>
                );
            });
        }
        this.setState({contactOptionList: optionList})
    }

    onHide(event) {
        this.props.hideCallback();
        this.setState({type:0, mainCategory: null, relative: ''})
    }


    render() {
        return (

            <Modal
                show={this.props.show}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={this.onHide}
                className={'skin-modal'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add relationship
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!this.state.type && !this.state.mainCategory &&
                    <ButtonGroup vertical>
                        <Button size="lg" data-type-id={1} onClick={this.setMainCategory}>Personal</Button>
                        <Button size="lg" data-type-id={2} onClick={this.setMainCategory}>Business</Button>
                        <Button size="lg" data-type-id={3} onClick={this.setMainCategory}>Education</Button>
                        <Button size="lg" data-type-id={4} onClick={this.setMainCategory}>Family</Button>
                        <Button size="lg" data-type-id={5} onClick={this.setMainCategory}>Special</Button>
                    </ButtonGroup>
                    }
                    {!this.state.type && this.state.mainCategory && this.state.mainCategory === 1 &&
                    <ButtonGroup vertical >
                        <Button data-type-id={4} onClick={this.pickType}>Existing Friend Of</Button>
                        <Button data-type-id={1} onClick={this.pickType}>Social Media Friend of</Button>
                        <Button data-type-id={6} onClick={this.pickType}>I introduced to</Button>
                        <Button data-type-id={3} onClick={this.pickType}>In Relationship With</Button>
                        <Button data-type-id={5} onClick={this.pickType}>Introduced Me to</Button>
                        <Button data-type-id={15} onClick={this.pickType}>Previously In Relationship With</Button>
                        <Button data-type-id={8} onClick={this.pickType}>Was Introduced By</Button>
                    </ButtonGroup>
                    }
                    {!this.state.type && this.state.mainCategory && this.state.mainCategory === 2 &&
                    <ButtonGroup vertical >
                        <Button data-type-id={10} onClick={this.pickType}>Reported To</Button>
                        <Button data-type-id={11} onClick={this.pickType}>Was Subordinate Of</Button>
                        <Button data-type-id={12} onClick={this.pickType}>Worked With</Button>
                        <Button data-type-id={6} onClick={this.pickType}>I Introduced To</Button>
                        <Button data-type-id={5} onClick={this.pickType}>Introduced Me To</Button>
                        <Button data-type-id={8} onClick={this.pickType}>Was Introduced By</Button>
                    </ButtonGroup>
                    }
                    {!this.state.type && this.state.mainCategory && this.state.mainCategory === 3 &&
                    <ButtonGroup vertical >
                        <Button data-type-id={13} onClick={this.pickType}>Student Of</Button>
                        <Button data-type-id={14} onClick={this.pickType}>Teacher Of</Button>
                        <Button data-type-id={20} onClick={this.pickType}>Classmate Of</Button>
                    </ButtonGroup>
                    }
                    {!this.state.type && this.state.mainCategory && this.state.mainCategory === 4 &&
                    <ButtonGroup vertical >
                        <Button data-type-id={9} onClick={this.pickType}>Parent Of</Button>
                        <Button data-type-id={2} onClick={this.pickType}>Older Sibling Of</Button>
                        <Button data-type-id={7} onClick={this.pickType}>Younger Sibling Of</Button>
                    </ButtonGroup>
                    }
                    {!this.state.type && this.state.mainCategory && this.state.mainCategory === 5 &&
                    <ButtonGroup vertical >
                        <Button data-type-id={18} onClick={this.pickType}>EM Coach Of</Button>
                        <Button data-type-id={16} onClick={this.pickType}>Share With</Button>
                    </ButtonGroup>
                    }

                    {!this.state.type && <div className="border-top my-2"/>}

                    <Form id={'relationship-form'} name={'relationship-form'}>
                        <Form.Control as="select"
                                      onChange={this.onTypeChange}
                                      value={this.state.type}
                                      required
                        >
                            {this.state.typeOptionList}
                        </Form.Control>
                        <div className="border-top my-2"/>

                        <Form.Control as="select"
                                      onChange={this.onChange}
                                      value={this.state.relative}
                                      required
                        >
                            {this.state.contactOptionList}
                        </Form.Control>
                        <Button variant="primary" onClick={this.onSubmit}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        )
    }

}

export default AddRelationshipModal;
