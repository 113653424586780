import moment from "moment-timezone";
import React, {Component} from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";


export default class FrequencyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitted: false,
            isSaved: true,
            isValid: false,
            eventType: null,
            eventTypeFrequency: {},

            increments: 30,
            incrementsIsValid: false,
            incrementsValidationmessage: '',

            maxPerDay: null,
            maxPerDayIsValid: false,
            maxPerDayValidationmessage: '',

            minHoursBeforeCreate: 4,
            minHoursBeforeCreateIsValid: false,
            minHoursBeforeCreateValidationmessage: '',

            minutesBefore: 0,
            minutesBeforeIsValid: false,
            minutesBeforeValidationmessage: '',

            minutesAfter: 0,
            minutesAfterIsValid: false,
            minutesAfterValidationmessage: '',

            timezone: 30,
            timezoneIsValid: false,
            timezoneValidationmessage: '',

        };
        this.onFieldChange = this.onFieldChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitCallback = this.onSubmitCallback.bind(this);
        this.onHide = this.onHide.bind(this);
        this.setEventTypeFrequency = this.setEventTypeFrequency.bind(this);
        this.onSubmitErrorCallback = this.onSubmitErrorCallback.bind(this);
    }

    componentDidMount() {
        const eventType = this.props.eventType;
        const eventTypeFrequency = this.props.eventType.eventtypefrequency;
        this.setEventTypeFrequency(eventType, eventTypeFrequency);
    }

    setEventTypeFrequency(eventType, eventTypeFrequency) {
        this.setState({
            eventType: eventType,
            eventTypeFrequency: eventTypeFrequency,
        });
        if (!!eventTypeFrequency) {
            this.setState({
                    increments: eventTypeFrequency.increments,
                    maxPerDay: eventTypeFrequency.max_per_day,
                    minHoursBeforeCreate: eventTypeFrequency.min_hours_before_create,
                    minutesBefore: eventTypeFrequency.minutes_before,
                    minutesAfter: eventTypeFrequency.minutes_after,
                    timezone: eventTypeFrequency.timezone
                }
            )
        }
    }

    onFieldChange(event) {
        const isValid = event.target.validity.valid;
        const validationMessage = event.target.validationMessage;
        const fieldName = event.target.name;
        let newStates = {isSaved: false};
        newStates.isValid = !validationMessage;
        newStates[fieldName] = event.target.value;
        const isValidName = fieldName + 'IsValid';
        newStates[isValidName] = isValid;
        const validationMessageName = fieldName + 'ValidationMessage';
        newStates[validationMessageName] = validationMessage;
        this.setState(newStates);
    }

    onSubmitErrorCallback(data, status) {
        this.setState({
            submitted: false, isSaved: false, success: false, errors: data})
    }

    onSubmitCallback(data) {
        if (!!!data) {return;}
        this.setEventTypeFrequency(this.state.eventType,
            data);

        this.setState({
                show: false, success: true, isSaved: true, submitted: true},
            () => {this.props.callback(data)})
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({isSaved: true});
        const id = this.state.eventTypeFrequency ? this.state.eventTypeFrequency.id: null;
        let data = {
            id: id,
            event_type: this.state.eventType.id,
            increments: this.state.increments,
            max_per_day: this.state.maxPerDay,
            min_hours_before_create: this.state.minHoursBeforeCreate,
            minutes_before: this.state.minutesBefore,
            minutes_after: this.state.minutesAfter,
            timezone: this.state.timezone
        };
        if (id) {
            this.props.authenticator.queuedPut(
                'event_type_frequency',
                id,
                data,
                this.onSubmitCallback,
                this.onSubmitErrorCallback
            )
        } else {
            this.props.authenticator.queuedPost(
                'event_type_frequency',
                data,
                this.onSubmitCallback,
                this.onSubmitErrorCallback
            )
        }
    }

    onHide(event) {
        this.props.callback()
    }

    timezoneList() {
        const timeZones = moment.tz.names().map((tz) => {
            return tz
        });
        return timeZones
    }

    timezoneOptionList() {
        const timezones = this.timezoneList();
        let optionList = [];
        optionList.push(
            <option key={'-null'}
                    value=''
            >None Selected</option>
        );
        // eslint-disable-next-line no-unused-vars
        timezones.map((value, index) => {
            optionList.push(
                <option key={index}
                        value={value}
                >{value}</option>
            );
            return ''
        });
        return optionList;
    }


    render() {
        return <Modal
            show={this.props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={this.onHide}
            className={'skin-modal'}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Advanced Configuration
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={this.onSubmit}
                      validated={!this.state.isSaved}>
                    {this.state.errors && this.state.errors.detail &&
                    <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                    {this.state.errors && this.state.errors.non_field_errors &&
                    <Alert variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
                    <FormGroup>
                        <Form.Label column={'increments'}>
                            Meeting Time Select Increment (in Minutes).
                        </Form.Label>
                        <Form.Control type="number"
                                      id={'increments'}
                                      name={'increments'}
                                      onChange={this.onFieldChange}
                                      value={this.state.increments}
                                      required
                                      isInvalid={!!this.state.errors.increments}
                                      min={5}
                                      max={60}
                                      step={1}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.increments}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.incrementsValidationmessage}
                        </Form.Control.Feedback>

                        <Form.Label column={'maxPerDay'}>
                            Maximum Events of this Type per day.
                        </Form.Label>
                        <Form.Control type="number"
                                      id={'maxPerDay'}
                                      name={'maxPerDay'}
                                      onChange={this.onFieldChange}
                                      value={this.state.maxPerDay || ''}
                                      isInvalid={!!this.state.errors.max_per_day}
                                      step={1}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.max_per_day}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.maxPerDayValidationmessage}
                        </Form.Control.Feedback>

                        <Form.Label column={'minHoursBeforeCreate'}>
                            Minimum Hours from NOW Guests can create events.
                        </Form.Label>
                        <Form.Control type="number"
                                      id={'minHoursBeforeCreate'}
                                      name={'minHoursBeforeCreate'}
                                      onChange={this.onFieldChange}
                                      value={this.state.minHoursBeforeCreate}
                                      required
                                      isInvalid={!!this.state.errors.min_hours_before_create}
                                      min={0}
                                      step={1}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.min_hours_before_create}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.minHoursBeforeCreateValidationmessage}
                        </Form.Control.Feedback>

                        <Form.Label column={'minutesBefore'}>
                            Pre-Event time buffer.
                        </Form.Label>
                        <Form.Control type="number"
                                      id={'minutesBefore'}
                                      name={'minutesBefore'}
                                      onChange={this.onFieldChange}
                                      value={this.state.minutesBefore}
                                      required
                                      isInvalid={!!this.state.errors.minutes_before}
                                      min={0}
                                      max={60}
                                      step={1}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.minutes_before}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.minutesBeforeValidationmessage}
                        </Form.Control.Feedback>

                        <Form.Label column={'minutesAfter'}>
                            Post-Event time buffer.
                        </Form.Label>
                        <Form.Control type="number"
                                      id={'minutesAfter'}
                                      name={'minutesAfter'}
                                      onChange={this.onFieldChange}
                                      value={this.state.minutesAfter}
                                      required
                                      isInvalid={!!this.state.errors.minutes_after}
                                      min={0}
                                      max={60}
                                      step={1}
                        />
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.minutes_after}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.minutesAfterValidationmessage}
                        </Form.Control.Feedback>

                        <Form.Label column={'timezone'}>
                            Override Guest's Timezone with specific Timezone.
                        </Form.Label>
                        <Form.Control as="select"
                                      id={'timezone'}
                                      name={'timezone'}
                                      value={this.state.timezone || ''}
                                      onChange={this.onFieldChange}
                                      isInvalid={!!this.state.errors.timezone}
                        >
                            {this.timezoneOptionList()}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {this.state.errors.timezone}
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {this.state.timezoneValidationmessage}
                        </Form.Control.Feedback>
                    </FormGroup>
                    <Button type={'submit'}
                            disabled={this.state.isSaved}>Save</Button>
                </Form></Modal.Body>
        </Modal>
    }
}