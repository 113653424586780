import React, {Component} from "react";
import Badge from "react-bootstrap/Badge";
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link} from "react-router-dom";

class RecentContactMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contacts: []
        };
        this.getRecent = this.getRecent.bind(this);
        this.recentCallback = this.recentCallback.bind(this);
    }

    componentDidMount() {
    }

    getRecent(event) {
        if (event.target.className === 'dropdown-item') {return;}
        this.props.authenticator.queuedGet(
            'contacts/contact_recent_list', this.recentCallback)
    }

    recentCallback(data) {
        if (!data) {return;}
        this.setState({contacts: data})
    }

    dropDownItem(contact, index) {
        return(
            <NavDropdown.Item as={Link} key={index} to={'/contacts/contact/' + contact.id} eventKey={index}>
                    <Badge variant="primary">{Math.trunc(contact.score)}</Badge>
                    &nbsp;{contact.name}
            </NavDropdown.Item>
        )}

    render() {
        return (
        <NavDropdown title="Recent" id="recent-nav-dropdown" onClick={this.getRecent}>
            {this.state.contacts.map(this.dropDownItem)}
        </NavDropdown>
        )
    }
}

export default RecentContactMenu;
