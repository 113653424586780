import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import "./advisors.css"


export class AvailableQuests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: true,
            loading: true,
            availableQuests: [],
            questChoice: null,
            onSelect: null
        };
        this.getAvailableQuestsCallback = this.getAvailableQuestsCallback.bind(this);
        this.getAvailableQuests = this.getAvailableQuests.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.postSelectedCallback = this.postSelectedCallback.bind(this);
    }

    componentDidMount() {
        this.getAvailableQuests();
    }

    getAvailableQuestsCallback(responseData) {
        this.setState({
            availableQuests: responseData,
            loading: false
        })
    }

    getAvailableQuests(event) {
        this.setState({loading: true});
        this.props.authenticator.queuedGet('available_quests',
            this.getAvailableQuestsCallback, null);
    }

    onHide(event) {
        this.setState({show: false});
        this.props.callback();
    }

    postSelectedCallback(data) {
        this.setState({show: false});
        this.props.callback(data);
    }

    onSelect(event) {
        const quest = event.currentTarget.dataset.questId;
        this.props.authenticator.queuedPost(
            'quest',
            {quest:quest, user:this.props.authenticator.account.user.id},
            this.postSelectedCallback
            )
    }

    render() {
        if (!this.props.authenticator) {
            return <></>
        }



        return (
                <Modal
                    show={this.state.show}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={this.onHide}
                    className={'skin-modal'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Choose Your Adventure
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.loading && <>
                        <div style={{marginBottom: '12rem'}}/>
                        <Spinner animation="border"
                        role="status">
                        <span className="sr-only">Loading...</span>
                        </Spinner>
                        <div style={{marginBottom: '11rem'}}/>
                        </>}


                        <Row className="justify-content-center">
                        {this.state.availableQuests.map((quest, index) => (
                            <Col key={index}>
                                <Card className={'quest-card'}>
                                    <Card.Body>
                                        <Card.Header>Possible Adventure</Card.Header>
                                        <Card.Title className={'quest-title'} >{quest.name}</Card.Title>
                                        <Card.Body className="justify-content-center">
                                            {quest.description}
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button
                                                onClick={this.onSelect}
                                                data-quest-id={quest.id}
                                            >Select Adventure</Button>
                                        </Card.Footer>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                        </Row>
                    </Modal.Body>
                </Modal>
        )
    }
}
