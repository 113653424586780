import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import RetailMenu from "./RetailMenu";

export default class RetailIndex extends Component {

    render() {
        if (!this.props.authenticator) {
            return <div>Missing Authenticator</div>
        }

        return (
            <div id={'em-retail-index'} style={{width: '90%', margin: 'auto'}}>
                <RetailMenu authenticator={this.props.authenticator}/>
                <div id={'em-retail-top-block'}
                     style={{
                         display: 'flex',
                         flexDirection: 'row',
                         alignItems: 'center',
                         justifyContent: 'space-evenly',
                         fontSize: '1.2rem'
                     }}>
                    <div id={'em-top-left'}
                         style={{
                             display: 'flex',
                             flexDirection: 'column',
                             margin: '2rem'
                         }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                        }}>

                            <p style={{
                                fontFamily: ['Dosis', 'serif'],
                                fontSize: '2rem'
                            }}>
                                Experience Master:&nbsp;
                            </p>
                            <p style={{
                                fontFamily: ['Dosis', 'serif'],
                                fontSize: '2rem'
                            }}>
                                Enjoy <strong>Your</strong> Experience.
                            </p>
                        </div>
                        <p>A Zero Ad, 100% private, Customized AI, Contact
                            tracking system.</p>
                        <Link to="/register">
                            <Button size={'lg'}>Click HERE to Sign-Up</Button>
                        </Link>
                        <br/><br/>
                        <p>With a private, personalized Artificial Intelligence,
                            programmed only by you, to help you keep in touch
                            with 100% of the contacts that matter to your
                            life.</p>
                        <p>Designed to keep you effectively communicating
                            with an extremely large list of contacts.</p>

                    </div>
                    <div style={{display: 'flex', maxWidth: '30%'}}>
                        <img
                            src={'https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/mobile_contact_20200612.PNG'}
                            alt={'Mobile Contact View'}
                            width={'100%'}
                        />
                    </div>
                </div>

                <hr id={'em-separator'} style={{
                    width: '50%',
                    border: '1px solid grey',
                    marginTop: '6rem',
                    marginBottom: '6rem'
                }}/>


                <div id={'em-second-block'}
                     style={{
                         display: 'flex',
                         flexDirection: 'column',
                         marginTop: '6rem',
                         width: '90%'
                     }}>
                    <div id={'em-create'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '25%',
                            textAlign: 'center',
                            alignSelf: 'center'
                        }}>
                            <h2>Create</h2>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '50%',
                            alignItems: 'center'
                        }}>
                            <div>
                                <img
                                    height={95}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/logo512.png"
                                    alt="EM Add Contact"
                                    style={{
                                        border: '3px solid white',
                                        marginRight: '1rem'
                                    }}
                                />
                                <img
                                    height={95}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/500px-Google__G__Logo.svg.png"
                                    alt="Google Logo"
                                />
                                <img
                                    height={120}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/apple-logo-rob-janoff-01.jpg"
                                    alt="Apple iCloud"
                                />
                                <img
                                    height={95}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/Microsoft_Office_Excel_(2018%E2%80%93present).svg"
                                    alt="CSV"
                                />
                            </div>

                            <div style={{
                                textAlign: 'center',
                                width: '100%'
                            }}>Create contacts from scratch or easily import
                                from Google,
                                Apple, or CSV.
                            </div>
                        </div>
                    </div>

                    <hr id={'em-separator'} style={{
                        width: '50%',
                        border: '1px solid grey',
                        marginTop: '6rem',
                        marginBottom: '6rem'
                    }}/>


                    <div id={'em-teach'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: '1rem'
                    }}>
                        <div id={'em-step-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Teach</h2>
                        </div>
                        <div id={'em-step-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%'
                             }}>

                            <div id={'em-score-row'} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                flexWrap: 'wrap'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    maxWidth: '40%',
                                    minWidth: '13rem',
                                    alignItems: 'center',
                                    marginBottom: '1rem',
                                    marginTop: '1rem'
                                }}>
                                    <div>Using your own personalized variable
                                        sliders.
                                    </div>
                                    <img
                                        height={125}
                                        src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/variable_sliders_20200622.PNG"
                                        alt="EM Sliders"
                                    />
                                </div>
                                <div style={{
                                    display: 'flex',
                                    maxWidth: '50%',
                                    minWidth: '13rem',
                                    marginBottom: '1rem',
                                    marginTop: '1rem'
                                }}>Considering the ideas of Brand Value,
                                    Personal Value, Inclusion, and Affinity, you
                                    privately teach your personal AI what you
                                    really think.
                                </div>
                                <div style={{
                                    display: 'flex',
                                    maxWidth: '60%',
                                    minWidth: '13rem',
                                    alignItems: 'center',
                                    marginBottom: '1rem',
                                    marginTop: '1rem'
                                }}>
                                    <div>The AI then gives the person a Score
                                        that only you know.
                                    </div>
                                    <img
                                        height={125}
                                        src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/score_20200622.PNG"
                                        alt="EM Score"
                                    />
                                </div>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                marginBottom: '1rem', marginTop: '3rem'
                            }}>
                                <div>You decide which timings for the AI to
                                    consider.
                                </div>
                                <div style={{display: 'flex', maxWidth: '75%'}}>

                                    <img
                                        width={'100%'}
                                        src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/em_timings_06_22_2020.png"
                                        alt="EM Timings"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr id={'em-separator'} style={{
                        width: '50%',
                        border: '1px solid grey',
                        marginTop: '6rem',
                        marginBottom: '6rem'
                    }}/>

                    <div id={'em-enjoy'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem'
                    }}>
                        <div id={'em-step-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Connect</h2>
                        </div>
                        <div id={'em-step-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 alignItems: 'center',
                                 justifyContent: 'space-evenly'
                             }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                marginBottom: '1rem', marginTop: '3rem'
                            }}><div style={{width: '25%'}}>
                                The Experience Master AI and Advisors will
                                suggest who to contact...
                            </div>
                                <img
                                    width={'35%'}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/em_AI_20200622.PNG"
                                    alt="EM AI Advisor"
                                />
                            </div><div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            marginBottom: '1rem', marginTop: '3rem'
                        }}>

                                <div style={{flex: '25%'}}>and even suggests what
                                    to say...&nbsp;
                                </div>
                                <img
                                    height={150}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/example_message_templates_20200622.PNG"
                                    alt="EM Templates"
                                />
                            </div>
                        </div>


                    </div>

                    <hr id={'em-separator'} style={{
                        width: '50%',
                        border: '1px solid grey',
                        marginTop: '6rem',
                        marginBottom: '6rem'
                    }}/>


                </div>


                <div id={'em-retail-third-block'}
                     style={{
                         display: 'flex',
                         flexDirection: 'row',
                         alignItems: 'center',
                         justifyContent: 'space-evenly',
                         fontSize: '1.2rem'
                     }}>

                    <div style={{display: 'flex', maxWidth: '75%'}}>
                        <img
                            width={'100%'}
                            src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/group_of_people_mixed_scores-600x380.png"
                            alt="EM People Score"
                        />
                    </div>
                    <div style={{marginLeft: '3rem'}}>
                        <h2>We all Score each other naturally.</h2>
                        <p>But, we never tell anyone, ever. The Experience
                            Master AI is completely private, and empowers users
                            to effectively keep in touch with thousands of
                            contacts.</p>
                    </div>
                </div>
                <hr id={'em-separator'} style={{
                    width: '50%',
                    border: '1px solid grey',
                    marginTop: '6rem',
                    marginBottom: '6rem'
                }}/>

                <div id={'em-retail-fourth-block'}
                     style={{
                         display: 'flex',
                         flexDirection: 'column',
                         alignItems: 'center',
                         fontSize: '1.2rem'
                     }}>
                    <h2 style={{borderBottom: '1px solid grey', marginBottom: '2rem'}}>Features To Help</h2>

                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Transcripts</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 textAlign: 'center',
                                 alignItems: 'center'
                             }}>
                            <div>A Robust HTML5 WYSIWYG editor for taking notes, and sending high-quality emails.</div>

                            <div style={{display: 'flex', maxWidth: '55%'}}>
                                <img
                                    width={'100%'}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/wysiwyg_transcripts.PNG"
                                    alt="EM Transcripts"
                                />
                            </div>

                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Tracking</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 textAlign: 'center',
                                 alignItems: 'center'
                             }}>

                            <div style={{display: 'flex', maxWidth: '55%', flexWrap: 'wrap'}}>
                                <div>Know when and who read your communications...</div>
                                <img
                                    width={'100%'}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/message_views.PNG"
                                    alt="EM Read Messages"
                                />
                            </div>

                            <div style={{display: 'flex', maxWidth: '55%', flexWrap: 'wrap'}}>
                                <div>Or if your message was not even viewed...</div>
                                <img
                                    width={89}
                                    height={42}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/unread_pill.PNG"
                                    alt="EM Unread Messages"
                                />
                            </div>



                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Templates</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 textAlign: 'center'
                             }}>
                                    <div>Send Ready Made Templates from your own
                                        Email Address.</div>
                                    <div><img
                                        width={'75%'}
                                        src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/example_template.PNG"
                                        alt="Templates"
                                    /></div>
                            </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Scheduling</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 textAlign: 'center',
                                 alignItems: 'center'
                             }}>

                                <div>Let contacts know when you are available.</div>
                                <img
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/scheduling.PNG"
                                    alt="EM Scheduling"
                                    width={'55%'}
                                />
                                <div>Fully integrated with Google Calendar and more...</div>
                            </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Activities</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 textAlign: 'center'
                             }}>

                            <div style={{
                                display: 'flex',
                                maxWidth: '100%',
                                alignItems: 'center', flexWrap: 'wrap'}}>
                                <div>Easily remember what you did.</div>
                                <img
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/small_activities.PNG"
                                    alt="EM Scheduling"
                                    width={'55%'}
                                />
                                <div>..and AI Predictions of what you should be doing.</div>
                            </div>


                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Relationships</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 textAlign: 'center'
                             }}>

                            <div style={{
                                display: 'flex',
                                maxWidth: '100%',
                                alignItems: 'center'}}>
                                <div>Inclusive relationship selectors...</div>
                                <img
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/relationship_buttons_1.PNG"
                                    alt="EM Relationships"
                                    width={'25%'}
                                />
                            </div>

                            <div style={{
                                display: 'flex',
                                maxWidth: '100%',
                                alignItems: 'center'}}>
                                <img
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/relationship_buttons_2.PNG"
                                    alt="EM Business Relationships"
                                    width={'25%'}
                                />
                                <div>with easy to select and well thought out connections for all types of relationship.</div>
                            </div>

                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Searching</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 flex: '50%',
                                 textAlign: 'center',
                                 justifyContent: 'space-evenly',
                                 alignItems: 'center', flexWrap: 'wrap'
                             }}>
                            <div>Dynamic search of all fields to find exactly who you are looking for.</div>
                            <div>
                            <img
                                src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/search_example_1.PNG"
                                alt="EM Search"
                                style={{border:'1px solid grey'}}
                                width={'75%'}
                            />
                            </div>
                            <div>With word highlighting to make it easy to see search contexts.</div>
                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Artificial Intelligences</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 textAlign: 'center'
                             }}>
                            <div style={{marginBottom: '1rem'}}>5 Separate AI Personalities to help guide you to the right conversations.</div>

                            <div style={{
                                display: 'flex',
                                maxWidth: '100%',
                                alignItems: 'center'}}>
                                <div>The Experience Master AI looks across all variables for a solution.</div>
                                <img
                                    width={'25%'}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/em_AI_20200622.PNG"
                                    alt="EM AI Advisor"
                                />
                            </div>

                            <div style={{
                                display: 'flex',
                                maxWidth: '100%',
                                alignItems: 'center'}}>
                                <img
                                    width={'25%'}
                                    src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/advisors_20200625.PNG"
                                    alt="EM AI Advisor"
                                />
                                <div>The Advisor AIs each look at just one variable to make a recommendation.</div>
                            </div>

                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Privacy Mode</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 flex: '50%',
                                 textAlign: 'center',
                                 alignItems: 'center',
                             }}>
                            <div>Privacy Mode allows you to use EM at gatherings where others might scry your private data like Score.</div>

                            <img
                                width={'25%'}
                                src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/privacy_mode_score.PNG"
                                alt="EM AI Advisor"
                            />

                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Google Integration</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 flex: '50%',
                                 textAlign: 'center',
                                 alignItems: 'center',
                                 flexWrap: 'wrap'
                             }}>

                            <img
                                height={95}
                                src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/500px-Google__G__Logo.svg.png"
                                alt="Google Logo"
                            />
                            <div style={{flex: '25%'}}>Download your contacts from Google with a single click.</div>
                            <div style={{flex: '25%'}}>Send emails directly from your GMail account.</div>
                            <div style={{flex: '25%'}}>Read and Write to your Google Calendar.</div>


                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Apple Integration</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 flex: '50%',
                                 textAlign: 'center',
                                 alignItems: 'center'
                             }}>

                            <div>Unprecedented access to your Apple Contacts straight from the iCloud.</div>
                            <img
                                height={120}
                                src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/apple-logo-rob-janoff-01.jpg"
                                alt="Apple iCloud"
                            />
                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Coaching</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 flex: '50%',
                                 textAlign: 'center',
                                 alignItems: 'center'
                             }}>
                            <img
                                src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/coach_card_only.PNG"
                                alt="EM Coaching"
                                width={'50%'}
                            />
                            <div>Human Coaching tools for helping learners like: share tracing, activity graphs, and personalized affirmations.</div>
                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Group Contacts</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 textAlign: 'center'
                             }}>
                            Business groups can have a community list of contacts eliminating duplicates, and enabling consistent follow-ups.
                        </div>
                    </div>
                    <div id={'em-feature'} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginBottom: '2rem',
                        marginTop: '2rem'
                    }}>
                        <div id={'em-feature-name'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '25%',
                                 textAlign: 'center',
                                 alignSelf: 'center'
                             }}>
                            <h2>Gaming</h2>
                        </div>
                        <div id={'em-feature-description'}
                             style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 flex: '50%',
                                 textAlign: 'center',
                                 alignItems: 'center'
                             }}>
                            <div>A game-like interface to help motivate you to reach out, it even has quest like adventures to promote good habits.</div>
                            <img
                                src="https://s3-us-west-2.amazonaws.com/static.experiencemaster.com/images/public/XP_bar.PNG"
                                alt="EM XP Bar"
                                width={'75%'}
                            />

                        </div>
                    </div>
                </div>

                <hr id={'em-separator'} style={{
                    width: '50%',
                    border: '1px solid grey',
                    marginTop: '6rem',
                    marginBottom: '6rem'
                }}/>

                <div id={'em-retail-footer-block'}
                     style={{
                         display: 'flex',
                         flexDirection: 'column',
                         alignItems: 'center',
                         fontSize: '1.2rem'
                     }}>
                        <Link to={'privacy'}>Privacy</Link>
                        <Link to={'terms'}>Terms</Link>
                        <Link to={'security'}>Security</Link>
                        <Link to={'about'}>About Us</Link>
                        <p>
                            &copy;2020 Fossa Tracks, Inc.&nbsp;<a
                            href={'mailto://support@experiencemaster.com'}>Email</a>
                            &nbsp;
                        </p>
                </div>

                <hr id={'em-separator'} style={{
                    width: '50%',
                    border: '1px solid grey',
                    marginTop: '6rem',
                    marginBottom: '6rem'
                }}/>
            </div>
        )
    }
}
