import React, {Component} from "react";
import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import AccountSettingsForm from "./AccountSettingsForm";
import AppleRequests from "./AppleRequests";
import DigestFrequency from "./Digest";
import GoogleLogins from "./GoogleLogins";
import GoogleRequests from "./GoogleRequests";
import SkinSelector from "./SkinSelector";


class SMTPSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account: null,
            id: null,
            use_tls: false,
            host: '',
            susername: '',
            password: '',
            port: '',
            saved: false,
            errors: {}
        };
        this.apiCallback = this.apiCallback.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.putOrPostAccountSettings = this.putOrPostAccountSettings.bind(this);
        this.errorCallback = this.errorCallback.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({
            account: this.props.authenticator.account.id
        });
        if (!this.props.smtpRecord) {
            return
        } else {
            this.setState({
                id: this.props.smtpRecord.id,
                use_tls: this.props.smtpRecord.use_tls,
                host: this.props.smtpRecord.host,
                susername: this.props.smtpRecord.username,
                password: this.props.smtpRecord.password,
                port: this.props.smtpRecord.port
            })
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    errorCallback(data) {
        this.setState({saved: false, errors: data})
    }

    apiCallback(data) {
        this.setState({saved: true})
    }

    putOrPostAccountSettings() {
        const accessPoint = 'account/' + this.state.account.toString() + '/smtp';
        let data = {
            id: this.state.id,
            use_tls: this.state.use_tls,
            host: this.state.host,
            username: this.state.susername,
            password: this.state.password,
            port: this.state.port,
            account: this.state.account
        };
        if (!!this.state.id) {
            this.props.authenticator.queuedPut(
                accessPoint, null,
                data, this.apiCallback, this.errorCallback)
        } else {
            this.props.authenticator.queuedPost(
                accessPoint, data,
                this.apiCallback, this.errorCallback)
        }
    }

    onSubmit(event) {
        event.preventDefault();
        this.putOrPostAccountSettings();
    }

    render() {
        if (!!!this.props.authenticator.account || !!!this.props.authenticator.account.id) {
            return <div>No AUTH</div>
        }
        const account = this.props.authenticator.account.id;
        if (!account) {
            return <div>No Auth</div>
        }
        return <Form key={this.props.index} id='smtpForm' name='smtpForm'
                     className="text-left" onSubmit={this.onSubmit}>
            <Form.Row>
                {this.state.errors && this.state.errors.detail &&
                <Alert variant={'danger'}>{this.state.errors.detail}</Alert>}
                {this.state.errors && this.state.errors.non_field_errors &&
                <Alert
                    variant={'danger'}>{this.state.errors.non_field_errors}</Alert>}
            </Form.Row>
            <Form.Control type="hidden"
                          id={'account'}
                          name={'account'}
                          value={account}
            />
            <Form.Control.Feedback type="invalid">
                {this.state.errors.account}
            </Form.Control.Feedback>
            <Form.Control type="text" rows="3"
                          id={'host'}
                          name={'host'}
                          placeholder="smtp.mydomain.com"
                          value={this.state.host}
                          onChange={this.handleChange}
                          required
            />
            <Form.Control.Feedback type="invalid">
                {this.state.errors.host}
            </Form.Control.Feedback>
            <Form.Control type="text" rows="3"
                          id={'susername'}
                          name={'susername'}
                          value={this.state.susername}
                          onChange={this.handleChange}
                          required
            />
            <Form.Control.Feedback type="invalid">
                {this.state.errors.username}
            </Form.Control.Feedback>
            <Form.Control type="password" rows="3"
                          id={'password'}
                          name={'password'}
                          placeholder={'Type New Password Here'}
                          onChange={this.handleChange}
                          required
                          autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
                {this.state.errors.password}
            </Form.Control.Feedback>
            <Form.Control type="text" rows="3"
                          id={'port'}
                          name={'port'}
                          placeholder="587"
                          value={this.state.port}
                          onChange={this.handleChange}
                          required
                          autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
                {this.state.errors.port}
            </Form.Control.Feedback>
            <FormGroup as={Row}>
                <Form.Label column>Use TLS:</Form.Label>
                <Col>
                    <Form.Control
                        type="checkbox"
                        rows="3"
                        id={'use_tls'}
                        name={'use_tls'}
                        checked={this.state.use_tls}
                        ref={'use_tls'}
                        onChange={this.handleChange}
                    />
                </Col>
            </FormGroup>
            {this.state.saved ?
                <Button variant="success" disabled>
                    Saved
                </Button>
                :
                <Button variant="primary" type={'submit'}>
                    Submit
                </Button>
            }
        </Form>
    }

}


class AccountSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            access: null,
            data: null
        };
    }

    render() {
        if (!this.props.authenticator.account) {
            return <div>No Account</div>
        }
        const account = this.props.authenticator.account;

        return <Container>
            <div>{account ? <h1>User settings
                for: {account.user.username}</h1> : 'no username'}</div>
            <hr/>


            <Accordion
                as={Card}
                className={'accordion-container'}
                key={'primary-account-settings'}
            >
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey={'primary-account-settings'}>
                    Your Account Information
                </Accordion.Toggle>
                <Accordion.Collapse
                    eventKey={'primary-account-settings'}>
                    <Card.Body>
                        <AccountSettingsForm
                            authenticator={this.props.authenticator}/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>

            <Accordion
                as={Card}
                className={'accordion-container'}
                key={'digest-settings'}
            >
                <Card.Header>
                    Daily Digest
                </Card.Header>
                <Card.Body>
                    <DigestFrequency
                        authenticator={this.props.authenticator}/>
                </Card.Body>
            </Accordion>

            {/*<Accordion*/}
            {/*    as={Card}*/}
            {/*    className={'accordion-container'}*/}
            {/*    key={'user-message-templates'}*/}
            {/*>*/}
            {/*    <Accordion.Toggle*/}
            {/*        as={Card.Header}*/}
            {/*        eventKey={'user-message-templates'}>*/}
            {/*        Message Templates*/}
            {/*    </Accordion.Toggle>*/}
            {/*    <Accordion.Collapse*/}
            {/*        eventKey={'user-message-templates'}>*/}
            {/*        <Card.Body>*/}
            {/*            <MessageTemplateEditor*/}
            {/*                authenticator={this.props.authenticator}*/}
            {/*            />*/}
            {/*        </Card.Body>*/}
            {/*    </Accordion.Collapse>*/}
            {/*</Accordion>*/}

            <Accordion
                as={Card}
                className={'accordion-container'}
                key={'smtp-settings'}
            >
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey={'smtp-settings'}>
                    SMTP Server for sending eMail
                </Accordion.Toggle>
                <Accordion.Collapse
                    eventKey={'smtp-settings'}>
                    <Card.Body>
                        {account && account.smtp && account.smtp.length > 0 ? account.smtp.map(
                            (smtpRecord, index) => {
                                return (
                                    <SMTPSettings
                                        authenticator={this.props.authenticator}
                                        smtpRecord={smtpRecord}
                                        key={index}
                                    />
                                )
                            }
                        ) : <SMTPSettings
                            authenticator={this.props.authenticator}
                            key={'blankSMTPForm'}
                        />
                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>


            {/*<Accordion*/}
            {/*    as={Card}*/}
            {/*    className={'accordion-container'}*/}
            {/*    key={'coaching-settings'}*/}
            {/*>*/}
            {/*    <Accordion.Toggle*/}
            {/*        as={Card.Header}*/}
            {/*        eventKey={'coaching-settings'}>*/}
            {/*        Coaching*/}
            {/*    </Accordion.Toggle>*/}
            {/*    <Accordion.Collapse*/}
            {/*        eventKey={'coaching-settings'}>*/}
            {/*        <Card.Body>*/}
            {/*            <CoachingSettingsForm*/}
            {/*                authenticator={this.props.authenticator}/>*/}
            {/*            /!*{account && account.coach && account.coach ?*!/*/}
            {/*            /!*    <div>You are an Active Coach ({account.coach.id}) <Button variant={'secondary'}>Deactivate</Button></div>*!/*/}
            {/*            /!*    :*!/*/}
            {/*            /!*    <Button>Become a Coach</Button>*!/*/}
            {/*            /!*}*!/*/}

            {/*            /!*{account && account.learner_set && account.learner_set.length > 0 ? account.learner_set.map(*!/*/}
            {/*            /!*    (learnerRecord, index) => {*!/*/}
            {/*            /!*        return (*!/*/}
            {/*            /!*            <div>Learner Record</div>*!/*/}
            {/*            /!*        )*!/*/}
            {/*            /!*    }*!/*/}
            {/*            /!*) : <div>Find a Coach</div>*!/*/}
            {/*            /!*}*!/*/}


            {/*        </Card.Body>*/}
            {/*    </Accordion.Collapse>*/}
            {/*</Accordion>*/}

            <Accordion
                as={Card}
                key={'external-api-settings'}
                className={'accordion-container'}
            >
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey={'external-api-settings'}>
                    External APIs
                </Accordion.Toggle>
                <Accordion.Collapse
                    eventKey={'external-api-settings'}>
                    <Card.Body>

                        <Accordion
                            as={Card}
                            key={'apple-settings'}
                            className={'accordion-container'}
                        >
                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey={'apple-settings'}>
                                Apple
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey={'apple-settings'}>
                                <Card.Body>

                                    <Button as={'a'} href={'https://api-v1.experiencemaster.com/apple/step1/'}>Fetch iCloud Contacts</Button>

                                    <AppleRequests
                                        authenticator={this.props.authenticator}/>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Accordion>
                        <Accordion
                            as={Card}
                            key={'google-settings'}
                            className={'accordion-container'}
                        >
                            <Accordion.Toggle
                                as={Card.Header}
                                eventKey={'google-settings'}>
                                Google
                            </Accordion.Toggle>
                            <Accordion.Collapse
                                eventKey={'google-settings'}>
                                <Card.Body>
                                    <GoogleLogins authenticator={this.props.authenticator}/>
                                    <GoogleRequests
                                        authenticator={this.props.authenticator}/>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Accordion>
                        
                        
                        

                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>

            <Accordion
                as={Card}
                key={'skins'}
                className={'accordion-container'}
            >
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey={'skins'}>
                    Skins
                </Accordion.Toggle>
                <Accordion.Collapse
                    eventKey={'skins'}>
                    <Card.Body>
                        <SkinSelector
                            authenticator={this.props.authenticator}/>
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>


        </Container>
    }

}

export default AccountSettings;
